import React, { FC, MouseEvent, useState, useEffect } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import fixWebmDuration from "webm-duration-fix";
import RecordingPreview from "./RecordingPreview";
import { handleUnregister } from "./helpers";
import {
  errorNotification,
  generalNotification,
  getFileFromBlobUrl,
  getMediaErrorMsg,
  isChromeBrowser,
} from "@utils/helpers";
import { t } from "i18next";
import { MAX_VIDEO_DURATION, MIN_VIDEO_DURATION, RECORDING_TYPE_OPTIONS } from "./constants";

type RecordingProps = {
  recordingType: "video" | "screen" | "audio";
  saveName: string;
  minDuration?: number; // in seconds
  maxDuration?: number; // in seconds
  onSubmit: (file: File) => void;
};

const Recording: FC<RecordingProps> = ({
  recordingType,
  saveName,
  minDuration = MIN_VIDEO_DURATION,
  maxDuration = MAX_VIDEO_DURATION,
  onSubmit,
}) => {
  const isChrome = isChromeBrowser();

  // used to fix bug in Chrome with recording duration
  const [fixedURL, setFixedURL] = useState("");
  const [duration, setDuration] = useState(0);

  const { blobType, fileExtension } = RECORDING_TYPE_OPTIONS[recordingType];
  const isVideo = recordingType === "video";
  const isScreen = recordingType === "screen";
  const isAudio = recordingType === "audio";
  const errorType = isAudio ? "audio" : "video";

  const reactMediaRecorder = useReactMediaRecorder({
    askPermissionOnMount: true,
    video: isVideo,
    screen: isScreen,
    audio: true,
    blobPropertyBag: { type: blobType },
    onStop: async (_, blob) => {
      if (isChrome) {
        const fixedBlob = await fixWebmDuration(blob);
        setFixedURL(URL.createObjectURL(fixedBlob));
      }
      handleUnregister(previewStream);
    },
  });

  const {
    status,
    error,
    mediaBlobUrl,
    previewStream,
    startRecording,
    stopRecording,
    clearBlobUrl,
  } = reactMediaRecorder;

  const isRecording = status === "recording";
  const hasMinDurationError = duration < minDuration;
  const hasMaxDurationError = duration > maxDuration;
  const maxDurationInMinutes = maxDuration / 60;
  const canSubmit = status === "stopped" && !hasMinDurationError && !hasMaxDurationError;
  const screenRecordError = getMediaErrorMsg(error, errorType);

  const handleStartRecording = (e: MouseEvent<SVGSVGElement | HTMLButtonElement>): void => {
    e.preventDefault();
    setDuration(0);
    startRecording();
  };

  const handleStopRecording = (e: MouseEvent<SVGSVGElement | HTMLButtonElement>): void => {
    e.preventDefault();
    stopRecording();
    clearBlobUrl();

    if (hasMinDurationError) {
      errorNotification(t("recording.errors.minDuration", { seconds: minDuration }));
    }

    if (hasMaxDurationError) {
      errorNotification(t("recording.errors.maxDuration", { minutes: maxDurationInMinutes }));
    }
  };

  const handleOnSubmit = async (): Promise<void> => {
    if (mediaBlobUrl) {
      const date = new Date().toISOString();
      const videoFile = await getFileFromBlobUrl(
        mediaBlobUrl,
        `${saveName}-recording-${date}.${fileExtension}`,
        { type: blobType },
      );

      onSubmit(videoFile);
    }
  };

  // Calulate recording duration
  useEffect(() => {
    let interval: null | ReturnType<typeof setInterval> = null;

    if (isRecording) {
      interval = setInterval(() => {
        setDuration((duration) => duration + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval as ReturnType<typeof setInterval>);
    };
  }, [duration, isRecording]);

  useEffect(() => {
    screenRecordError &&
      generalNotification("warning", <div style={{ maxWidth: "13rem" }}>{screenRecordError}</div>);
  }, [screenRecordError]);

  // Clear up on component unmount
  useEffect(() => {
    return () => {
      stopRecording();
      clearBlobUrl();
      handleUnregister(previewStream);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RecordingPreview
      {...reactMediaRecorder}
      duration={duration}
      fixedURL={fixedURL}
      recordingType={recordingType}
      canSubmit={canSubmit}
      handleStartRecording={handleStartRecording}
      handleStopRecording={handleStopRecording}
      onSubmit={handleOnSubmit}
    />
  );
};

export default Recording;

import React, { FC, useEffect, useRef } from "react";
import { EditableTextStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

type EditableTextProps = {
  text: string | null;
  placeholder: string;
  canEdit?: boolean;
  autoFocus?: boolean;
  onBlur?: (newContent: string | null) => void;
  onInput?: (newContent: string | null) => void;
};

const EditableText: FC<EditableTextProps> = ({
  text,
  placeholder,
  canEdit = true,
  autoFocus = false,
  onBlur,
  onInput,
}) => {
  const isEditable = canEdit;
  const editableDivRef = useRef<HTMLDivElement>(null);

  const handleOnInput = (e: React.ChangeEvent<HTMLDivElement>): void => {
    if (editableDivRef.current) {
      const newContent = e.target.textContent;
      onInput && onInput(newContent);
    }
  };

  const handleOnBlur = (): void => {
    if (editableDivRef.current) {
      onBlur && onBlur(editableDivRef.current.textContent);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // on enter trigger blur callback
    if (["Enter", "NumpadEnter"].includes(event.code)) {
      event.preventDefault();
      onBlur && onBlur(editableDivRef?.current?.textContent ?? null);
    }
  };

  useEffect(() => {
    if (editableDivRef.current && text !== editableDivRef.current.textContent) {
      editableDivRef.current.textContent = text;
    }
  }, [text]);

  useEffect(() => {
    if (editableDivRef.current && autoFocus) {
      editableDivRef.current.focus();
      const textNode = editableDivRef.current.firstChild as Text | null;
      const textLength = textNode?.textContent?.length ?? 0;
      const selection = window.getSelection();

      if (textNode && selection) {
        selection.collapse(textNode, textLength);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={(theme): SerializedStyles => EditableTextStyles(theme, { isEditable })}>
      {isEditable ? (
        <div
          className="editable-container"
          contentEditable
          suppressContentEditableWarning
          data-text={placeholder}
          ref={editableDivRef}
          onInput={handleOnInput}
          onBlur={handleOnBlur}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <div className="editable-container not-editing">{text || placeholder}</div>
      )}
    </div>
  );
};

export default EditableText;

import React, { FC, useState } from "react";
import { t } from "i18next";
import { DownloadIconSVG, PreviewIconSVG } from "@assets/icons";
import { actionsClassNames } from "@components/CustomTable";
import { Tooltip, Button } from "@epignosis_llc/gnosis";
import { downloadFile, generalNotification } from "@utils/helpers";
import { CourseFile } from "types/entities";
import { PREVIEWABLE_FILE_EXTENSIONS } from "@constants/files";
import { TrashAltSVG } from "@epignosis_llc/gnosis/icons";
import { FileModal } from "@components";
import { deleteCourseFile } from "@api/courses";
import ConfirmationModal from "@components/ReusableModals/ConfirmationModal";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "@constants/queryKeys";
import { AxiosError } from "axios";

type FileActionsProps = {
  file: CourseFile;
  isRowHovered: boolean;
  courseId?: string;
};

const FileActions: FC<FileActionsProps> = ({ file, isRowHovered, courseId }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const { policies, id: fileId } = file;
  const { can_delete: canDelete } = policies ?? {};
  const isFilePreviwable = PREVIEWABLE_FILE_EXTENSIONS.includes(file.mime_type);

  const handleModal = (): void => {
    if (file.url && isFilePreviwable) {
      setIsPreviewModalOpen(true);
    }
  };

  const handleDownloadFile = async (
    file: CourseFile,
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void> => {
    e.preventDefault();
    await downloadFile(file.url ?? "", file.name ?? "");
  };

  const closeModal = (): void => {
    setIsPreviewModalOpen(false);
  };

  const { mutate: deleteFileMutation } = useMutation(
    [queryKeys.courses.deleteFiles],
    ({ courseId, fileId }: { courseId: string; fileId: string }) =>
      deleteCourseFile(courseId, fileId),
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseEdit.fileDeletedSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.courses.files]);
      },
      onError: (error: AxiosError) => {
        generalNotification("error", <p>{error?.response?.data._errors[0].title}</p>);
      },
    },
  );

  return (
    <>
      <div className="table-actions-container">
        <div className={actionsClassNames(isRowHovered)}>
          {isFilePreviwable && (
            <Tooltip content={t("general.preview")}>
              <Button
                variant="ghost"
                noGutters
                onClick={(): void => {
                  handleModal();
                }}
              >
                <PreviewIconSVG height={32} />
              </Button>
            </Tooltip>
          )}

          <Tooltip content={t("general.download")}>
            <Button
              variant="ghost"
              noGutters
              onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                handleDownloadFile(file, e);
              }}
            >
              <DownloadIconSVG height={32} />
            </Button>
          </Tooltip>
          {canDelete && courseId && (
            <Tooltip content={t("general.delete")}>
              <Button
                variant="ghost"
                noGutters
                onClick={(): void => {
                  setIsConfirmationModalOpen(true);
                }}
              >
                <TrashAltSVG height={16} />
              </Button>
            </Tooltip>
          )}
        </div>
        {/* Preview */}
        <FileModal {...file} isOpen={isPreviewModalOpen} onClose={closeModal} />

        {/* Delete  */}
        <ConfirmationModal
          id={fileId}
          header={t("courseEdit.deleteFile")}
          bodyTitle={
            <div
              dangerouslySetInnerHTML={{
                __html: t("courseEdit.confirmDeleteFiles", {
                  title: file.name,
                }),
              }}
            />
          }
          footerButton={t("general.delete")}
          isOpen={isConfirmationModalOpen}
          onClose={(): void => setIsConfirmationModalOpen(false)}
          onConfirm={(): void => {
            if (courseId) {
              deleteFileMutation({ courseId, fileId: fileId.toString() });
            }
          }}
        />
      </div>
    </>
  );
};

export default FileActions;

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { PreviewIconSVG } from "@assets/icons";

export type PreviewActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const PreviewAction: FC<PreviewActionProps> = ({
  tooltipContent,
  icon: Icon = PreviewIconSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.preview")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default PreviewAction;

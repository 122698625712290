import React, { FC } from "react";
import { Button, Drawer, Heading, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import { ActionDrawerContainer } from "./styles";
import i18n from "@utils/i18n";

export type ActionDrawerProps = {
  isOpen: boolean;
  headerTitle: string;
  headerSubtitle?: string;
  showFooter?: boolean;
  size?: "sm" | "md";
  id?: string;
  onClose: () => void;
  onApply?: () => void;
  actionButton?: {
    text?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  customFooter?: JSX.Element;
};

const widthDimensions = {
  sm: "24rem",
  md: "48rem",
};

const ActionDrawer: FC<ActionDrawerProps> = ({
  isOpen,
  onClose,
  onApply,
  headerTitle,
  headerSubtitle,
  showFooter = true,
  size = "sm",
  id,
  children,
  actionButton = {
    text: t("general.apply"),
    isDisabled: false,
    isLoading: false,
  },
  customFooter,
}) => {
  const { lg } = useResponsive();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "left" : "right";
  const width = lg ? widthDimensions[size] : widthDimensions["sm"];
  const { text, isDisabled, isLoading } = actionButton;

  return (
    <Drawer
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      placement={placement}
      width={width}
      css={ActionDrawerContainer}
    >
      <Drawer.Header closable={!lg}>
        <span className="title-container">
          <Heading as="h3">{headerTitle}</Heading>
          {headerSubtitle && <Text fontSize="xs">{headerSubtitle}</Text>}
        </span>
      </Drawer.Header>
      <Drawer.Body>
        <section className="action-drawer-body">{children}</section>
      </Drawer.Body>
      {showFooter && (
        <Drawer.Footer>
          {customFooter ? (
            customFooter
          ) : (
            <>
              <Button
                onClick={onApply ? onApply : onClose}
                disabled={isDisabled}
                isLoading={isLoading}
              >
                {text}
              </Button>
              <Button color="secondary" onClick={onClose}>
                {t("general.cancel")}
              </Button>
            </>
          )}
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default ActionDrawer;

import { ResponseEnvelope } from "types/responses";

export type CourseUnit = {
  unit: {
    id: number;
    name: string;
    type: "assignment" | "ilt" | "scorm" | "survey" | "test";
    is_active: boolean;
  };
  not_attempted: number;
  in_progress: number;
  completed: number;
  not_passed: number | null; // Nullable for survey units;
  average_score: number | null; // Nullable for survey units
};

//respones
export type CourseUnitsRes = ResponseEnvelope<CourseUnit[]>;

export enum CourseActiveTab {
  Files = "files",
  Units = "units",
  Users = "users",
  Branches = "branches",
  Groups = "groups",
}

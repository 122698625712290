import * as Sentry from "@sentry/react";
import { config } from "@config";
import { AxiosError } from "axios";
import { t } from "i18next";
import {
  branchSwitchErrors,
  conferenceErrors,
  couponErrors,
  discussionErrors,
  newMessageErrors,
  noPermissionsError,
  unitErrors,
  updateProfileErrors,
  courseErrors,
  passwordChangeErrors,
  enrollmentErrors,
  paymentErrors,
  deleteThemeErrors,
  subscriptionErrors,
  createThemeErrors,
  courseUnenrollErrors,
  branchesErrors,
  groupsErrors,
  autologinErrors,
  signUpErrors,
  signInErrors,
  userErrors,
  userCoursesErrors,
  userUnitsErrors,
  userBranchesErrors,
  userImpersonationErrors,
  joinGroupErrors,
  uploadFilesErrors,
  questionErrors,
  previewCertificateTemplateErrors,
  previewCertificateErrors,
} from "./errorMessages";
import { generalNotification } from "@utils/helpers";

export type HandledError = {
  status: number;
  id: string;
  errorMsg?: string;
};

type ItemWithExpiration = {
  value: string;
  expiry: number;
};

export const handledApiError = (
  error: AxiosError,
  errorsArray: HandledError[],
  hideNotification = false,
  defaultError: string,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  const status = error?.response?.status;
  const errorId = error?.response?.data._errors[0]?.id;

  const foundError = errorsArray.find(
    (errorItem) => errorItem.status === status && errorItem.id === errorId,
  );

  if (!hideNotification) {
    const shownError = foundError?.errorMsg ? t(foundError?.errorMsg) : defaultError;
    shownError.length > 0 && generalNotification("error", shownError);
  }

  if (handleError) handleError(foundError ?? null, error);
};

export const handleUnitErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, unitErrors, hideNotification, t("errors.units.notAvailable"), handleError);
};

export const handleCourseErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(
    error,
    courseErrors,
    hideNotification,
    t("errors.units.courseNotAvailable"),
    handleError,
  );
};

export const handleQuestionErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, questionErrors, hideNotification, "", handleError);
};

export const handleUserCreateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, updateProfileErrors, true, "", handleError);
};

export const handleGroupsErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, groupsErrors, hideNotification, "", handleError);
};

export const handleUpdateProfileErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, updateProfileErrors, hideNotification, "", handleError);
};

export const handleUserErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userErrors, hideNotification, "", handleError);
};

export const handleUploadFilesErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, uploadFilesErrors, hideNotification, "", handleError);
};

export const handleCouponErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, couponErrors, true, t("errors.coupons.couponInvalid"), handleError);
};

export const handleConferenceErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, conferenceErrors, false, "", handleError);
};

export const handleNoPermissionError = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, noPermissionsError, false, "", handleError);
};

export const handleBranchSwitchErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, branchSwitchErrors, false, "", handleError);
};

export const handleNewMessageErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, newMessageErrors, false, "", handleError);
};

export const handleDiscussionErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, discussionErrors, false, "", handleError);
};

export const handleSignUpErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, signUpErrors, hideNotification, "", handleError);
};

export const handleSignInErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, signInErrors, hideNotification, "", handleError);
};

export const handleImpersonationErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, userImpersonationErrors, hideNotification, "", handleError);
};

export const handleAutologinErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null, axiosError: AxiosError) => void,
): void => {
  handledApiError(error, autologinErrors, hideNotification, "", handleError);
};

export const handlePasswordChangeErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, passwordChangeErrors, hideNotification, "", handleError);
};

export const handlePaymentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, paymentErrors, true, t("errors.coupons.couponInvalid"), handleError);
};

export const handleEnrollmentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, enrollmentErrors, false, "", handleError);
};

export const handleSubscriptionErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, subscriptionErrors, false, "", handleError);
};

export const handleCreateThemeErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, createThemeErrors, false, "", handleError);
};

export const handleDeleteThemeErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, deleteThemeErrors, false, "", handleError);
};

export const handleUnenrollmentErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, courseUnenrollErrors, false, "", handleError);
};

export const handleBranchesErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, branchesErrors, false, "", handleError);
};

export const handleUserCoursesErrors = (
  error: AxiosError,
  hideNotification = false,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userCoursesErrors, hideNotification, "", handleError);
};

export const handleUserUnitsError = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userUnitsErrors, true, "", handleError);
};

export const handleUserBranchesErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, userBranchesErrors, false, "", handleError);
};

export const handleJoinGroupErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, joinGroupErrors, true, "", handleError);
};

export const handlePreviewCertificateTemplateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, previewCertificateTemplateErrors, false, "", handleError);
};

export const handlePreviewCertificateErrors = (
  error: AxiosError,
  handleError?: (foundError: HandledError | null) => void,
): void => {
  handledApiError(error, previewCertificateErrors, false, "", handleError);
};

export const setItemWithExpiration = (key: string, value: string, ttl: number): void => {
  const item: ItemWithExpiration = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiration = (key: string): string | null => {
  const itemString = window.localStorage.getItem(key);
  if (!itemString) return null;

  const item: ItemWithExpiration = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const errorBoundaryHandler = (
  error: Error,
  componentStack: { componentStack: string },
): void => {
  if (config.isStaging() || config.isProd()) {
    Sentry.captureException(error); // log error to sentry
  } else {
    // eslint-disable-next-line no-console
    console.error(error, componentStack);
  }
};

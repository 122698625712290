import { css } from "@emotion/react";

export const completionRulesContainer = ({ unitsToC }) => css`
  .section-title,
  .access-info {
    margin-bottom: 1rem;
  }

  .rule-container {
    display: flex;
    align-items: center;

    p {
      margin: 0 0.5rem;
      margin-bottom: 0;
    }

    .certain-units-container {
      .certain-units-list-item {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;

        &.not-completed {
          color: ${unitsToC.sequentialColor};
          fill: ${unitsToC.sequentialColor};
        }

        .unit-name {
          margin-inline-start: 0.5rem;
        }
      }
    }
  }
`;

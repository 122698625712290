/* 
Note: This conponent is reproducing the functionality of the below files:
Iframe view: talentlms/libraries/EF/views/Unit/Scorm/view_iframe.php
PopUp  view: talentlms/libraries/EF/views/Unit/Scorm/view_popup.php
*/
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { FC, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import InteractiveContentFooter from "../Footers/InteractiveContentFooter";
import FrameScorm from "./FrameScorm";
import PopupScorm from "./PopupScorm";
import { setupContentCoockies } from "./scorm-helpers";
import Skeletons from "@components/Skeleton/Skeleton";
import { getContentTokens, postScormCommit } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { Course, ScormUnit, Section } from "types/entities";
import { ScormCommitRes } from "types/responses";
import { FileFailed, FileProcessing } from "@components";

export type ScormProgressCommit = {
  course_progress: Course["progress"];
  unit_progress: ScormUnit["progress"];
};

type ScormContainerProps = {
  course: Course;
  sections: Section[];
  unit: ScormUnit;
  isPublic?: boolean;
};

// extend window interface
declare const window: {
  messageLaunchURL: string;
  receiveMessage: (e: any) => void;
  tl_sco_data: ScormUnit["scorm_data"] & {
    scorm: {
      frame_border: boolean;
      height: number;
      src: string;
      width: string;
      launch_url: string;
    };
  };
} & Window;

const ScormContainer: FC<ScormContainerProps> = (props) => {
  const queryClient = useQueryClient();
  const { unit, course, isPublic = false } = props;
  const { url, scorm_data, embed_type } = unit;
  const isIframe = embed_type === "embedded";

  const { status, error } = useQuery([queryKeys.contentTokens], () => getContentTokens(), {
    cacheTime: 0,
    onSuccess: (res) => {
      setupContentCoockies(res._data);
    },
  });

  const { mutate: commitMutation } = useMutation(
    queryKeys.contentTokens,
    (data: any) => postScormCommit(window.tl_sco_data.commit_url, data),
    {
      retry: false,
      onSuccess: (res: ScormCommitRes) => {
        if (res._data.unit_progress.status === "completed") {
          // ivalidate queries to update UI after SCORM commit
          queryClient.invalidateQueries([queryKeys.unit, unit.id.toString()]);
          queryClient.invalidateQueries([queryKeys.myCourse, course.id.toString()]);
          queryClient.invalidateQueries([queryKeys.units, course.id.toString()]);
        }
      },
      onError: (error) => {
        console.error("Commit-Fetch error", error);
      },
    },
  );

  useEffect(() => {
    window.tl_sco_data = {
      ...scorm_data,
      scorm: {
        frame_border: false,
        height: 600,
        src: url,
        width: "100%",
        launch_url: window.location.href,
      },
    };
  }, [url, scorm_data]);

  useEffect(() => {
    const receiveMessageExists = typeof window.receiveMessage === "function";
    const hasMessageLaunchURLChanged = window.messageLaunchURL !== window.location.href;

    // Create custom function and variable in window object in order to control listeners for
    // receiving "message" events from SCORM files

    // receiveMessage function exists or launch url has changed
    if (!receiveMessageExists || hasMessageLaunchURLChanged) {
      // remove listener for SCORM "message" event
      window.removeEventListener("message", window.receiveMessage, true);

      // set message new launch url
      const href = window.location.href;
      window.messageLaunchURL = href;

      // create listener function that commits data for SCORM "message" event
      window.receiveMessage = (e: any): void => {
        /**
         * Check "e.origin" to make sure that this message came from  the correct domain,
         * for example "talentlms.com".
         */
        if (e.data.launchURL !== href) return;

        commitMutation(e.data);
      };

      // add listener for SCORM "message" event only if not already exists
      window.addEventListener("message", window.receiveMessage, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <article className="unit-wrapper center scorm">
        <Skeletons.Loader status={status} error={error}>
          {unit.status === "ready" ? (
            isIframe ? (
              <FrameScorm unit={unit} />
            ) : (
              <PopupScorm unit={unit} />
            )
          ) : unit.status === "processing" ? (
            <FileProcessing />
          ) : (
            <FileFailed />
          )}
        </Skeletons.Loader>
      </article>
      <InteractiveContentFooter {...props} isPublic={isPublic} />
    </>
  );
};

export default ScormContainer;

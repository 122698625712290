import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { EditIconSVG } from "@assets/icons";

export type EditActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const EditAction: FC<EditActionProps> = ({ tooltipContent, icon: Icon = EditIconSVG, onClick }) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.edit")} onClick={onClick}>
      <Icon height={32} />
    </ActionButton>
  );
};

export default EditAction;

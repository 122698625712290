import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { ForwardSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";

export type ForwardMessageActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const ForwardMessageAction: FC<ForwardMessageActionProps> = ({
  tooltipContent,
  icon: Icon = ForwardSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("messages.forward")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default ForwardMessageAction;

import React, { FC, MouseEvent } from "react";
import { useResponsive } from "ahooks";
import { useNavigate } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { footerDrawerContainer } from "@components/Discussion/styles";
import { URLS } from "@constants/urls";
import { useUIStore, useUserPreferencesStore } from "@stores";
import permissions from "@utils/permissions";

const DiscussionsFooter: FC = () => {
  const navigate = useNavigate();
  const { hideMainDrawer } = useUIStore((state) => state);
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};
  const { md } = useResponsive();
  const { canCreateDiscussion } = permissions.discussionsPermissions;

  const goToDiscussions = (e: MouseEvent): void => {
    e.preventDefault();
    // Reset userPreference for table states
    resetSettings();
    hideMainDrawer();
    navigate(URLS.user.discussions);
  };

  const goToNewDiscussion = (e: MouseEvent): void => {
    e.preventDefault();
    // Reset userPreference for table states
    resetSettings();
    hideMainDrawer();
    navigate(URLS.user.newDiscussion);
  };

  return (
    <section css={footerDrawerContainer}>
      <Button color="secondary" onClick={goToDiscussions}>
        {md ? t("discussions.gotToDiscussionBoard") : t("mainMenu.discussions")}
      </Button>
      {canCreateDiscussion() && (
        <Button onClick={goToNewDiscussion}>
          {md ? t("discussions.newDiscussion") : t("general.create")}
        </Button>
      )}
    </section>
  );
};

export default DiscussionsFooter;

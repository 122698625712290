import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const skeletonContainer = css`
  .skeleton-wrapper {
    padding: 1rem;

    ${mq["md"]} {
      padding: 1.5rem 2rem;
    }
  }
`;

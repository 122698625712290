import React, { FC } from "react";
import { useQuery } from "react-query";
import { t } from "i18next";
import { Heading, Modal } from "@epignosis_llc/gnosis";
import { userItemContainerModal } from "./styles";
import Skeletons from "@components/Skeleton/Skeleton";
import UserItem from "./UserItem";
import BadgeCategory from "./BadgeCategory";
import { getUserBadges } from "@api/app";
import { useConfigurationStore } from "@stores";
import permissions from "@utils/permissions";
import queryKeys from "@constants/queryKeys";
import { LeaderboardUser } from "types/entities";

type UserBadgesModalProps = {
  userId: string;
  leaderboardUser: LeaderboardUser;
  index: number;
  isOpen: boolean;
  onClose: () => void;
};

const badgeCategories = [
  "activity",
  "assignment",
  "certification",
  "communication",
  "learning",
  "perfectionism",
  "survey",
  "test",
];

const UserBadgesModal: FC<UserBadgesModalProps> = ({
  userId,
  leaderboardUser,
  index,
  isOpen,
  onClose,
}) => {
  const { gamificationSettings } = useConfigurationStore();
  const badges = gamificationSettings?.badges;
  const { canAccessGamification } = permissions.gamificationPermissions;
  const allowGamificationView = canAccessGamification();
  const maxBadges = 64;
  const badgesQuery = `?filter[type]=awarded&sort=criteria&page[size]=${maxBadges}`;

  // get badges
  const {
    data: badgesData,
    status: badgesStatus,
    error: badgesError,
  } = useQuery([queryKeys.users.badges, userId], () => getUserBadges(userId, badgesQuery), {
    select: (res) => res._data,
    enabled: Boolean(allowGamificationView && badges?.enabled),
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Header style={{ paddingInlineStart: "1.5rem" }}>
        <Heading as="h2" size="md" className="header-text">
          {t("general.badges")}
        </Heading>
      </Modal.Header>
      <Modal.Body>
        <span css={userItemContainerModal}>
          <UserItem leaderboardUser={leaderboardUser} index={index} />
        </span>
      </Modal.Body>
      <Modal.Footer style={{ maxHeight: "300px", overflowY: "auto", paddingInlineStart: "1.5rem" }}>
        <Skeletons.Loader status={badgesStatus} error={badgesError}>
          {badgesData && (
            <div className="badges-container">
              {badgeCategories.map((badge) => (
                <BadgeCategory key={badge} title={badge} badges={badgesData} />
              ))}
            </div>
          )}
        </Skeletons.Loader>
      </Modal.Footer>
    </Modal>
  );
};

export default UserBadgesModal;

import { config } from "@config";

export default {
  coursesList: true,
  roleSwitching: true,
  courseEdit: true,
  branchesList: true,
  courseUnits: true,
  singleUnitReports: true,
  categories: true,
  usersList: true,
  groups: true,
  widgetsDashboard: true,
  reports: true,
  //NOT_PRODUCTION_READY
  createTestUnit: !config.isProd(),
  accountAndSettings: !config.isProd(),
} as const;

import React from "react";
import { Select } from "@epignosis_llc/gnosis";
import { Question, QuestionData, QuestionAnswers, QuestionType } from "types/entities";
import {
  LikertScaleAnswer,
  LikertScaleChildren,
  QuestionRouterParams,
} from "types/entities/Question";
import { URLS } from "@constants/urls";
import i18n from "@utils/i18n";
import { SelectOption } from "types/common";

export type FillTheGapResult = {
  JSXOptions: { [x: string]: JSX.Element };
  replacedContent: string;
};

type OptionType = { [x: string]: JSX.Element };

export const getFillTheGapText = (
  text: string,
  options: Record<string, string[]>,
  userAnswers: {
    [key: string]: string;
  },
  onAnswersChange: (answers: { [key: string]: string }) => void,
): FillTheGapResult => {
  const optionsKeys = Object.keys(options);
  const dir = i18n.dir();

  const handleChange = (key: string, value: string): void => {
    onAnswersChange({ ...userAnswers, [key]: value });
  };

  const JSXOptions = optionsKeys.reduce<OptionType>((acc, key) => {
    return {
      ...acc,
      [key]: (
        <span style={{ marginBottom: "0.25rem", display: "inline-block" }}>
          <Select
            id={`select-${key}`}
            key={key}
            name={key}
            className="question-options"
            isRtl={dir === "rtl"}
            placeholder="-"
            isInlineFlex={true}
            onChange={(option): void => {
              const { value } = option as SelectOption;
              handleChange(key, value);
            }}
            options={options[key].map((option) => ({
              value: option,
              label: option,
            }))}
          />
        </span>
      ),
    };
  }, {});

  const replacedContent = text.replace(/\[.+?\]/g, (match) => {
    return `<span> <span id="position-${match}"> </span></span>`;
  });

  return { JSXOptions, replacedContent };
};

export const createUserAnswerArray = (left: string[], right: string[]): QuestionAnswers => {
  return left.map((item, index) => {
    return [item, right[index]];
  });
};

export const createLikertScaleArray = (children: LikertScaleChildren[]): QuestionAnswers => {
  const mappedArray: LikertScaleAnswer[] = [];

  children.map((item) => {
    if (item.user_answers?.possible[0]) {
      mappedArray.push({
        id: item.id,
        user_answer: item.user_answers?.possible[0],
      });
    }
  });

  return mappedArray;
};

export const getUserAnswers = (questionEntry: QuestionData<Question>): QuestionAnswers => {
  if (questionEntry?.question?.user_answers) {
    switch (questionEntry.question.type) {
      case QuestionType.DragAndDrop:
        return createUserAnswerArray(
          questionEntry.question.user_answers.pairs.left,
          questionEntry.question.user_answers.pairs.right,
        );
      case QuestionType.FillTheGap:
        return questionEntry.question.user_answers.gaps;
      default:
        return questionEntry.question.user_answers.possible;
    }
  } else {
    switch (questionEntry?.question.type) {
      case QuestionType.Ordering:
        return questionEntry.question.answers.possible;
      case QuestionType.LikertScale:
        return createLikertScaleArray(questionEntry.question.children);
      default:
        return [] as QuestionAnswers;
    }
  }
};

// Todo: refactor it:
// 1. with clear cases
// 2. with useEffect
// Check if user answers are empty
export const userAnwsersEmpty = (
  userAnswers: QuestionAnswers,
  questionEntry: QuestionData<Question> | undefined,
): boolean => {
  // Free text reply
  if (typeof userAnswers[0] === "string" && userAnswers[0].trim() === "") return true;

  // Likert scale reply
  if (
    questionEntry?.question.type === QuestionType.LikertScale &&
    questionEntry.question.children.length !== userAnswers.length
  )
    return true;

  if (Array.isArray(userAnswers)) {
    if (userAnswers.length === 0) return true;
    return !userAnswers.flat().every((item) => Boolean(item));
  } else {
    if (!questionEntry) return true;
    if (Object.keys(userAnswers).length === 0) return true;
    if (!questionEntry.question.answers?.gaps) return true; // Fill the gaps question

    const userAnswersLength = Object.keys(userAnswers).length;
    const questionsLength = Object.keys(questionEntry.question.answers.gaps).length;
    if (questionsLength !== userAnswersLength) return true;

    return !Object.keys(userAnswers).every((key) => Boolean(userAnswers[key]));
  }
};

export const getQuestionLink = (
  type: "test" | "survey",
  params: QuestionRouterParams,
  isPublic?: boolean,
): string => {
  const testLink = isPublic
    ? URLS.externalCatalog.publicUnit.createTestQuestionLink(params)
    : URLS.user.createTestQuestionLink(params);

  const surveyLink = isPublic
    ? URLS.externalCatalog.publicUnit.createSurveyQuestionLink(params)
    : URLS.user.createSurveyQuestionLink(params);

  return type === "test" ? testLink : surveyLink;
};

//TODO: refactor userAnwsersEmpty and merge these two fns
export const unitUserAnwsersEmpty = (
  userAnswers: QuestionAnswers,
  questionEntry: Question | undefined,
): boolean => {
  // Free text reply
  if (typeof userAnswers[0] === "string" && userAnswers[0].trim() === "") return true;

  if (Array.isArray(userAnswers)) {
    if (userAnswers.length === 0) return true;
    return !userAnswers.flat().every((item) => Boolean(item));
  } else {
    if (!questionEntry) return true;
    if (Object.keys(userAnswers).length === 0) return true;
    if (!questionEntry.answers?.gaps) return true; // Fill the gaps question

    const userAnswersLength = Object.keys(userAnswers).length;
    const questionsLength = Object.keys(questionEntry.answers.gaps).length;
    if (questionsLength !== userAnswersLength) return true;

    return !Object.keys(userAnswers).every((key) => Boolean(userAnswers[key]));
  }
};

import { useQuery, UseQueryResult, UseQueryOptions } from "react-query";
import { getCatalog, getCatalogCategories } from "@api";
import queryKeys from "@constants/queryKeys";
import { CoursesRes, CatalogCategoriesRes } from "types/responses";

// Todo: Strongly type it
export function useGetCatalog(
  apiSearchQuery: string,
  options?: UseQueryOptions<CoursesRes>,
): UseQueryResult<CoursesRes> {
  return useQuery<CoursesRes>(
    [queryKeys.catalog, apiSearchQuery],
    () => getCatalog(apiSearchQuery),
    {
      ...options,
    },
  );
}

export function useGetCatalogCategories(
  apiSearchQuery: string,
  queryKey: string = queryKeys.catalogCategories,
  options?: UseQueryOptions<CatalogCategoriesRes>,
): UseQueryResult<CatalogCategoriesRes> {
  return useQuery<CatalogCategoriesRes>([queryKey], () => getCatalogCategories(apiSearchQuery), {
    ...options,
  });
}

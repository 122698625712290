import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

export const gamificationPermissions = {
  read: "gamification.read",
};

const { read } = gamificationPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => gamification.read
   *
   * Allowed actions:
   * GET gamificatin.settings, gamification.leaderboard, user.badges, user.rewards
   *
   * @returns boolean
   */

  canAccessGamification: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
};

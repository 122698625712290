import { css } from "@emotion/react";

export const StatusTagStyles = ({ courseToc }, color) => css`
  border-radius: 5px;
  padding: 0.125rem 0.25rem 0;
  margin-inline: 0.25rem;
  text-transform: uppercase;
  color: ${courseToc.edit[color].color} !important;
  background-color: ${courseToc.edit[color].background} !important;
  white-space: nowrap;
`;

import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";

const HoverActions: FC = () => {
  return (
    <Button block className="footer-btn">
      {t("courseCatalog.viewCourse")}
    </Button>
  );
};

export default HoverActions;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const mainHeaderContainer = ({ mainHeader, gamification }) => css`
  position: sticky;
  top: 0;
  max-height: 4.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${mainHeader.background};
  padding-inline: 0.5rem 1rem;
  padding-block: 1rem;
  box-shadow: 0px 3px 6px ${mainHeader.boxShadowColor};
  z-index: 2;
  transition: all 0.2s ease;

  ${mq["md"]} {
    padding: 1rem;
    justify-content: flex-start;
  }

  .hamburger-menu {
    display: flex;
    margin-inline-end: 0.5rem;

    ${mq["md"]} {
      display: none;
    }

    svg {
      color: ${mainHeader.mobileSearch.color};
    }
  }

  .logo {
    ${mq["md"]} {
      margin-inline-end: 2rem;
    }

    ${mq["lg"]} {
      margin-inline-start: 1rem;
    }

    img {
      max-height: 38px;

      ${mq["md"]} {
        max-height: 46px;
      }

      ${mq["sm"]} {
        max-width: fit-content;
      }
    }
  }

  .main-header-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #toolbar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      ${mq["md"]} {
        justify-content: space-between;
      }

      .mobile-search-btn {
        margin-inline-end: 0.5rem;
        color: ${mainHeader.mobileSearch.color};
      }

      .gamification-btn {
        .gamification-icon {
          color: ${gamification.color};
        }

        &:hover {
          .gamification-icon {
            color: ${gamification.hover};
          }
        }
      }
    }
  }
`;

export const footerButtons = css`
  text-align: center;
`;

export const mainDrawerContainer = css`
  header {
    height: auto;
    max-height: fit-content;
  }
`;

export const ENDPOINTS = {
  public: {
    login: `/rest-api/oauth/access_token`,
    signup: `/rest-api/v1/signup`,
    guestSignup: `/rest-api/v1/guest-signup`,
    verifySignUp: `/rest-api/v1/signup/verify`,
    passwordReset: `/rest-api/v1/reset-password`,
    changePassword: `/rest-api/v1/force-update-password`,
    refreshToken: `/rest-api/oauth/refresh_token`,
    domainSettings: `/rest-api/v1/domain/settings`,
    ssoDomainSettings: `/rest-api/v1/domain/settings/sso`,
    socialDomainSettings: `/rest-api/v1/domain/settings/social`,
    ldapSsoDomainSettings: `/rest-api/v1/domain/settings/sso/ldap`,
    autologin: `/rest-api/v1/autologin`,
    requestPasswordReset: (login: string) => `/rest-api/v1/reset-password/${login}`,
    autologinToken: (token: string) => `/rest-api/v1/autologin/${token}`,
  },
  accountAndSettings: {
    security: {
      security: `/rest-api/v1/domain/settings/security`,
    },
    export: {
      availableTypes: `/rest-api/v1/export/types`,
    },
    import: {
      import: `/rest-api/v1/import`,
    },
    ftpSync: {
      serverConfigEndpoint: `/rest-api/v1/ftp/config/server`,
      exportConfigEndpoint: `/rest-api/v1/ftp/config/export`,
      importConfigEndpoint: `/rest-api/v1/ftp/config/import`,
      testConnection: `/rest-api/v1/ftp/testconnection`,
      exportNow: `/rest-api/v1/ftp/export`,
      importNow: `/rest-api/v1/ftp/import`,
    },
    courseSettings: {
      courseSettings: `/rest-api/v1/courses/settings`,
    },
  },
  courses: {
    availableGroups: (courseId: string): string =>
      `/rest-api/v1/courses/${courseId}/groups/available`,
    availableBranches: (courseId: string) => `/rest-api/v1/courses/${courseId}/branches/available`,
    branches: (courseId: string): string => `/rest-api/v1/courses/${courseId}/branches`,
    catalogCourseUnits: (courseId: string) => `/rest-api/v1/catalog/courses/${courseId}/units`,
    clone: (courseId: string): string => `/rest-api/v1/courses/${courseId}/clone`,
    contentTokens: `/rest-api/v1/content/tokens`,
    course: (courseId: string): string => `/rest-api/v1/courses/${courseId}`,
    courseFiles: (courseId: string): string => `/rest-api/v1/courses/${courseId}/files`,
    courses: `/rest-api/v1/courses`,
    craftCommit: (unitId: string) => `/rest-api/v1/units/${unitId}/craft/commit`,
    craftDisclaimer: "/rest-api/v1/users/me/craft-disclaimer",
    customFields: `/rest-api/v1/courses/custom-fields`,
    delete: (courseId: string) => `/rest-api/v1/courses/${courseId}`,
    edit: (courseId: string): string => `/rest-api/v1/courses/${courseId}`,
    files: (courseId: string): string => `/rest-api/v1/courses/${courseId}/files`,
    filesEdit: (courseId: string, fileId: string): string =>
      `/rest-api/v1/courses/${courseId}/files/${fileId}`,
    filesDelete: (courseId: string, filedId: string): string =>
      `/rest-api/v1/courses/${courseId}/files/${filedId}`,
    filesUpdate: (courseId: string, filedId: number): string =>
      `/rest-api/v1/courses/${courseId}/files/${filedId}`,
    filesUpload: (courseId: string): string => `/rest-api/v1/courses/${courseId}/files`,
    filesUploadURL: (courseId: string): string => `/rest-api/v1/courses/${courseId}/files/url`,
    groups: (courseId: string) => `/rest-api/v1/courses/${courseId}/groups`,
    image: (courseId: string): string => `/rest-api/v1/courses/${courseId}/image`,
    introVideoDelete: (courseId: string): string => `/rest-api/v1/courses/${courseId}/intro_video`,
    introVideoUpload: (courseId: string): string => `/rest-api/v1/courses/${courseId}/intro_video`,
    myCourseUnits: (courseId: string) => `/rest-api/v1/courses/${courseId}/units`,
    myCourses: `/rest-api/v1/users/me/courses`,
    prerequisiteOptions: (courseId: string): string =>
      `/rest-api/v1/courses/${courseId}/rules/prerequisite-options`,
    publish: (courseId: string) => `/rest-api/v1/courses/${courseId}/publish`,
    rating: (courseId: string): string => `/rest-api/v1/courses/${courseId}/rating`,
    rulesEdit: (courseId: string): string => `/rest-api/v1/courses/${courseId}/rules`,
    sectionCreate: `/rest-api/v1/sections`,
    selfUnenroll: (courseId: string): string => `/rest-api/v1/courses/${courseId}/unenrollment`,
    unenroll: (courseId: string): string => `/rest-api/v1/courses/${courseId}/unenrollment`,
    unitUpload: (courseId: string): string => `/rest-api/v1/courses/${courseId}/units/files`,
    users: (courseId: string): string => `/rest-api/v1/courses/${courseId}/users`,
    unenrolledUsers: (courseId: string): string =>
      `/rest-api/v1/courses/${courseId}/users/unassigned`,
    user: {
      edit: (courseId: string, userId: string): string =>
        `/rest-api/v1/courses/${courseId}/users/${userId}`,
      syncProgress: (courseId: string, userId: string, removeCertificate?: boolean): string =>
        `/rest-api/v1/courses/${courseId}/users/${userId}/sync-progress${
          removeCertificate ? "?remove-certificate=true" : ""
        }`,
      resetProgress: (courseId: string, userId: string, removeCertificate?: boolean): string =>
        `/rest-api/v1/courses/${courseId}/users/${userId}/reset-progress${
          removeCertificate ? "?remove-certificate=true" : ""
        }`,
    },
    youtubeSearch: "/rest-api/v1/youtube-search",
    // units
    unitsOrder: (courseId: string) => `/rest-api/v1/courses/${courseId}/units/order`,
    unit: (unitId: string) => `/rest-api/v1/units/${unitId}`,
    unitEnter: (unitId: string) => `/rest-api/v1/units/${unitId}/enter`,
    unitExit: (unitId: string) => `/rest-api/v1/units/${unitId}/exit`,
    unitComplete: (unitId: string) => `/rest-api/v1/units/${unitId}/complete`,
    unitCompleteWithQuestion: (unitId: string) => `/rest-api/v1/units/${unitId}/complete/question`,
    unitCreate: `/rest-api/v1/units`,
    unitSmartTags: `/rest-api/v1/units/smart-tags`,
    unitAssignment: {
      submit: (unitId: string) => `/rest-api/v1/units/${unitId}/submission`,
      fileUpload: (unitId: string) => `/rest-api/v1/units/${unitId}/submission/file`,
    },
    unitPassword: (unitId: string) => `/rest-api/v1/units/${unitId}/start/password`,
    unitPublish: (unitId: string) => `/rest-api/v1/units/${unitId}/publish`,
    unitSnapshot: (unitId: string) => `/rest-api/v1/units/${unitId}/start/snapshot`,
    unitStart: (unitId: string) => `/rest-api/v1/units/${unitId}/start`,
    unitResults: (unitId: string) => `/rest-api/v1/units/${unitId}/results`,
    unitUpdate: (unitId: string) => `/rest-api/v1/units/${unitId}`,
    unitDelete: (unitId: string, shouldDeleteLinkedUnits?: boolean) =>
      `/rest-api/v1/units/${unitId}${shouldDeleteLinkedUnits ? "?delete-linked=true" : ""}`,
    unitDeleteBookmarks: (unitId: string) => `/rest-api/v1/units/${unitId}/bookmarks`,
    unitIlt: {
      sessions: (unitId: string) => `/rest-api/v1/units/${unitId}/sessions`,
      register: (sessionId: string) => `/rest-api/v1/sessions/${sessionId}/register`,
      unregister: (sessionId: string) => `/rest-api/v1/sessions/${sessionId}/unregister`,
    },
    // tests
    createTestQuestion: (unitId: string) => `/rest-api/v1/units/${unitId}/questions`,
    questionsTags: `/rest-api/v1/questions/tags`,
    testQuestion: (unitId: string, questionId: string) =>
      `/rest-api/v1/units/${unitId}/questions/${questionId}`,
    testQuestions: (unitId: string) => `/rest-api/v1/units/${unitId}/questions`,
    submitTestQuestion: (unitId: string, questionId: string) =>
      `/rest-api/v1/units/${unitId}/questions/${questionId}/submit`,
    resetTest: (unitId: string) => `/rest-api/v1/units/${unitId}/reset`,
  },
  catalog: {
    catalog: "/rest-api/v1/catalog/courses",
    course: (courseId: string): string => `/rest-api/v1/catalog/courses/${courseId}`,
    courseFiles: (courseId: string): string => `/rest-api/v1/catalog/courses/${courseId}/files`,
    categories: "/rest-api/v1/catalog/categories",
    bundles: "/rest-api/v1/catalog/bundles",
    settings: "/rest-api/v1/catalog/settings",
    sampleUnit: (unitId: string) => `/rest-api/v1/catalog/sampleunits/${unitId}`,
    purchase: "/rest-api/v1/catalog/purchase",
    enrollment: "/rest-api/v1/catalog/enrollment",
    subscribe: "/rest-api/v1/catalog/subscribe",
    publicCourse: (key: string): string => `/rest-api/v1/courses/shared/${key}`,
  },
  user: {
    profile: `/rest-api/v1/users/me`,
    files: `/rest-api/v1/users/me/files`,
    password: `/rest-api/v1/users/me/password`,
    updateProfile: `/rest-api/v1/users/me/profile`,
    avatar: `/rest-api/v1/users/me/avatar`,
    certificates: `/rest-api/v1/users/me/certificates`,
    badges: `/rest-api/v1/users/me/badges`,
    rewards: `/rest-api/v1/users/me/rewards`,
    customFields: `/rest-api/v1/users/custom_fields`,
    statistics: `/rest-api/v1/users/me/statistics`,
    groups: `/rest-api/v1/users/me/groups`,
    joinGroup: `/rest-api/v1/groups/join`,
    integrations: `/rest-api/v1/users/me/integrations`,
    branches: `/rest-api/v1/users/me/branches`,
    singleUser: (userId: string) => `/rest-api/v1/users/${userId}`,
  },
  messages: {
    singleMessage: (messageId: number) => `/rest-api/v1/messages/${messageId}`,
    inbox: `/rest-api/v1/messages/inbox`,
    sent: `/rest-api/v1/messages/sent`,
    createMessage: `/rest-api/v1/messages`,
    reply: (messageId: number) => `/rest-api/v1/messages/${messageId}/reply`,
    forward: (messageId: number) => `/rest-api/v1/messages/${messageId}/forward`,
    delete: (messageId: number) => `/rest-api/v1/messages/${messageId}`,
    recipients: `/rest-api/v1/messages/recipients`,
    read: (messageId: number) => `/rest-api/v1/messages/${messageId}/read`,
    unread: (messageId: number) => `/rest-api/v1/messages/${messageId}/unread`,
    attachment: `/rest-api/v1/messages/attachment`,
    unreadMessages: `/rest-api/v1/messages/unread`,
  },
  discussions: {
    discussions: `/rest-api/v1/discussions`,
    active: `/rest-api/v1/discussions/active`,
    audience: `/rest-api/v1/discussions/audience`,
    singleDiscussion: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}`,
    replies: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}/replies`,
    createDiscussion: `/rest-api/v1/discussions`,
    attachment: `/rest-api/v1/discussions/attachment`,
    comment: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}/replies`,
    commentReply: (discussionId: number) => `/rest-api/v1/discussions/replies/${discussionId}`,
    edit: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}`,
    subscribe: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}/subscribe`,
    unsubscribe: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}/unsubscribe`,
    editCommentReply: (commentId: number) => `/rest-api/v1/discussions/replies/${commentId}`,
    like: (discussionId: number) => `/rest-api/v1/discussions/replies/${discussionId}/like`,
    unlike: (discussionId: number) => `/rest-api/v1/discussions/replies/${discussionId}/unlike`,
    delete: (discussionId: number) => `/rest-api/v1/discussions/${discussionId}`,
    deleteComment: (discussionId: number) => `/rest-api/v1/discussions/replies/${discussionId}`,
  },
  dashboard: {
    today: `/rest-api/v1/dashboard/today`,
    todayInstructor: "/rest-api/v1/dashboard/today/instructor",
    dontMissLearner: `/rest-api/v1/dashboard/dontmiss`,
    dontMissInstructor: `/rest-api/v1/dashboard/dontmiss/instructor`,
    portalActivity: `/rest-api/v1/dashboard/widgets/portal-activity`,
    coursePie: `/rest-api/v1/dashboard/widgets/course-pie`,
    overview: `/rest-api/v1/dashboard/widgets/overview`,
    timeline: `/rest-api/v1/dashboard/widgets/timeline`,
    smallestCompletionRate: `/rest-api/v1/dashboard/widgets/low-completion-courses`,
    users: `/rest-api/v1/dashboard/widgets/users`,
    recentCourseActivity: `/rest-api/v1/dashboard/widgets/course-activity`,
  },
  gamification: {
    leaderboard: `/rest-api/v1/gamification/leaderboard`,
    settings: `/rest-api/v1/gamification/settings`,
  },
  search: {
    spotlight: `/rest-api/v1/spotlight`,
  },
  conferences: {
    join: (id: string) => `/rest-api/v1/conferences/${id}/join`,
  },
  calendar: {
    events: "/rest-api/v1/calendar/events",
    export: "/rest-api/v1/calendar/export",
    sync: "/rest-api/v1/calendar/sync",
    statistics: "/rest-api/v1/calendar/statistics",
  },
  branches: {
    courseAction: (branchId: string, courseId: string) =>
      `/rest-api/v1/branches/${branchId}/courses/${courseId}`,
    courses: (branchId: string) => `/rest-api/v1/branches/${branchId}/courses`,
    availableCourses: (branchId: string) => `/rest-api/v1/branches/${branchId}/courses/available`,
    userAction: (branchId: string, userId: string) =>
      `/rest-api/v1/branches/${branchId}/users/${userId}`,
    users: (branchId: string) => `/rest-api/v1/branches/${branchId}/users`,
    availableUsers: (branchId: string) => `/rest-api/v1/branches/${branchId}/users/available`,
    branches: `/rest-api/v1/branches`,
    clone: (branchId: string) => `/rest-api/v1/branches/${branchId}/clone`,
    delete: (branchId: string) => `/rest-api/v1/branches/${branchId}`,
    files: (branchId: string): string => `/rest-api/v1/branches/${branchId}/files`,
    filesEdit: (branchId: string, fileId: string): string =>
      `/rest-api/v1/branches/${branchId}/files/${fileId}`,
    filesUpload: (branchId: string): string => `/rest-api/v1/branches/${branchId}/files`,
    filesUploadURL: (branchId: string): string => `/rest-api/v1/branches/${branchId}/files/url`,
    switch: (branchId: string) => `/rest-api/v1/branch/${branchId}/switch`,
  },
  files: { filesTags: `/rest-api/v1/files/tags` },
  groups: {
    files: (groupId: string): string => `/rest-api/v1/groups/${groupId}/files`,
    filesEdit: (groupId: string, fileId: string): string =>
      `/rest-api/v1/groups/${groupId}/files/${fileId}`,
    filesUpload: (groupId: string): string => `/rest-api/v1/groups/${groupId}/files`,
    filesUploadURL: (groupId: string): string => `/rest-api/v1/groups/${groupId}/files/url`,
    groups: `/rest-api/v1/groups`,
    group: (groupId: string) => `/rest-api/v1/groups/${groupId}`,
    users: (groupId: string) => `/rest-api/v1/groups/${groupId}/users`,
    availableUsers: (groupId: string) => `/rest-api/v1/groups/${groupId}/users/available`,
    groupOwner: (groupId: string, userId: string) =>
      `/rest-api/v1/groups/${groupId}/users/${userId}/owner`,
    userAction: (groupId: string, userId: string) =>
      `/rest-api/v1/groups/${groupId}/users/${userId}`,
    userCoursesAction: (groupId: string, userId: string) =>
      `/rest-api/v1/groups/${groupId}/users/${userId}/courses`,
    courseAction: (groupId: string, courseId: string) =>
      `/rest-api/v1/groups/${groupId}/courses/${courseId}`,
    courses: (groupId: string) => `/rest-api/v1/groups/${groupId}/courses`,
    availableCourses: (groupId: string) => `/rest-api/v1/groups/${groupId}/courses/available`,
  },
  announcements: {
    announcements: `/rest-api/v1/announcements`,
    close: `/rest-api/v1/announcements/close`,
  },
  domainTerms: {
    terms: `/rest-api/v1/domain/terms`,
    accept: `/rest-api/v1/users/me/terms`,
  },
  categories: {
    categories: "/rest-api/v1/categories",
    create: "/rest-api/v1/categories",
    edit: (categoryId: string) => `/rest-api/v1/categories/${categoryId}`,
    delete: (categoryId: string) => `/rest-api/v1/categories/${categoryId}`,
  },
  themes: {
    themes: `/rest-api/v1/themes`,
    create: `/rest-api/v1/themes`,
    active: (themeId: string) => `/rest-api/v1/themes/${themeId}/active`,
    edit: (themeId: string) => `/rest-api/v1/themes/${themeId}`,
    delete: (themeId: string) => `/rest-api/v1/themes/${themeId}`,
  },
  coupons: {
    validation: `/rest-api/v1/coupon/validation`,
  },
  users: {
    avatar: (userId: string) => `/rest-api/v1/users/${userId}/avatar`,
    badges: (userId: string) => `/rest-api/v1/users/${userId}/badges`,
    files: (userId: string): string => `/rest-api/v1/users/${userId}/files`,
    filesEdit: (userId: string, fileId: string): string =>
      `/rest-api/v1/users/${userId}/files/${fileId}`,
    filesUpload: (userId: string): string => `/rest-api/v1/users/${userId}/files`,
    filesUploadURL: (userId: string): string => `/rest-api/v1/users/${userId}/files/url`,
    user: (userId: string) => `/rest-api/v1/users/${userId}`,
    users: `/rest-api/v1/users`,
    types: `/rest-api/v1/users/types/assignable`,
    courses: (userId: string) => `/rest-api/v1/users/${userId}/courses`,
    groups: (userId: string) => `/rest-api/v1/users/${userId}/groups`,
    branches: (userId: string) => `/rest-api/v1/users/${userId}/branches`,
    availableBranches: (userId: string) => `/rest-api/v1/users/${userId}/branches/available`,
    availableGroups: (userId: string) => `/rest-api/v1/users/${userId}/groups/available`,
    availableCourses: (userId: string) => `/rest-api/v1/users/${userId}/courses/available`,
    units: (userId: string) => `/rest-api/v1/reports/users/${userId}/units`,
    completeUserUnit: (unitId: string, userId: string) =>
      `/rest-api/v1/units/${unitId}/users/${userId}/complete`,
    resetUserUnit: (unitId: string, userId: string) =>
      `/rest-api/v1/units/${unitId}/users/${userId}/reset`,
    impersonation: (userId: string) => `/rest-api/v1/users/${userId}/impersonate`,
    stopImpersonation: `/rest-api/v1/users/me/stop-impersonation`,
    payments: (userId: string) => `/rest-api/v1/users/${userId}/invoices`,
  },
  unitReports: {
    unitUsers: (unitId: string) => `/rest-api/v1/reports/units/${unitId}/users`,
  },
  widgetsDashboard: {
    widgets: `/rest-api/v1/users/me/dashboard/widgets`,
    widgetList: `/rest-api/v1/dashboard/widgets`,
  },
  reports: {
    timeline: `/rest-api/v1/reports/timeline`,
    deleteEvent: (eventId: string) => `/rest-api/v1/reports/timeline/${eventId}/destroy`,
    restoreEvent: (eventId: string) => `/rest-api/v1/reports/timeline/${eventId}/restore`,
  },
  microstats: {
    unitMicrostats: (type: string, id: string): string =>
      `/rest-api/v1/reports/${type}/${id}/units-microstats`,
  },
  certificates: {
    templates: "/rest-api/v1/certificate-templates",
    templatePreview: (templateId: string) =>
      `/rest-api/v1/certificate-templates/${templateId}/preview`,
    preview: (courseId: string, userId: string) =>
      `/rest-api/v1/courses/${courseId}/users/${userId}/certificate/preview`,
  },
} as const;

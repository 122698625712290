import { css } from "@emotion/react";

export const tableContainer = css`
  table tbody tr td {
    padding: 0rem 1.5rem;
  }

  .empty-state-container {
    button {
      padding: 0 !important;
    }

    svg {
      height: 100%;
    }
  }

  .table-actions-container {
    position: relative;

    .hover-actions {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: inherit;
      opacity: 0;
    }

    .actions-icon {
      position: absolute;
      inset-inline-end: 0;
      top: 0;
      opacity: 0;
    }

    .is-visible {
      opacity: 1;
    }
  }
`;

export const emptyStateContainer = css`
  .body {
    margin-top: 0;
  }

  .add-item {
    /* margin-top: 32px; */
    padding: 0 !important;
    margin: 0 !important;
    svg {
      height: 100% !important;
    }
  }
`;

import { css } from "@emotion/react";

export const sectionList = css`
  padding: 0;
  margin: 1rem 0 0 0;

  .section-title {
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
  }
`;

export const unitListItem = ({ courseToc }, { hasParentSection }) => css`
  margin: 0 0 0.5rem;
  margin-inline-start: ${hasParentSection ? "1rem" : 0};

  &:last-of-type {
    margin: 0;
    margin-inline-start: ${hasParentSection ? "1rem" : 0};
  }

  &:not(.active) {
    svg {
      fill: ${courseToc.sequentialColor};
    }

    .unit-name {
      color: ${courseToc.sequentialColor};
    }
  }

  &.active {
    svg {
      fill: ${courseToc.color};
    }

    .unit-name {
      color: ${courseToc.color};

      &:hover {
        color: ${courseToc.hover};
      }
    }
  }

  &.readonly {
    .unit-name {
      color: ${courseToc.color};

      &:hover {
        color: ${courseToc.color};
      }
    }
  }

  .list-item-content {
    display: flex;
    align-items: center;
    word-break: break-word;

    svg {
      min-width: 2rem;
    }

    .unit-name {
      margin-inline-start: 0.5rem;
    }

    .sample-unit-tag {
      background: ${courseToc.sampleTagBackground} !important;
      color: ${courseToc.sampleTagColor} !important;
      padding: 0.2rem 0.5rem;
      border-radius: 5px;
      margin-inline-start: 0.5rem;
    }
  }
`;

export const rtlIcon = (isRtl) => css`
  transform: rotate(${isRtl ? "180deg" : "0deg"});
`;

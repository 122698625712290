import { Heading, Text } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { pageHeader } from "./style";

export type PageHeaderProps = {
  title: string;
  subtitle?: string;
};

const PageHeader: FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <div css={pageHeader}>
      <Heading className="title" size="2xl">
        {title}
      </Heading>
      <Text className="subtitle" fontSize="sm">
        {subtitle}
      </Text>
    </div>
  );
};

export default PageHeader;

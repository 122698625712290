import { css } from "@emotion/react";

export const tableActionsStyles = (theme) => css`
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  .empty-state-container {
    button {
      padding: 0;
    }
  }

  .start-actions-container {
    display: flex;
    gap: 0.5rem;

    .action-item {
      background-color: ${theme.chip.success.background};
      font-weight: bold;
      margin-inline-end: 0.5rem;
      color: ${theme.chip.textColor};

      button {
        color: currentColor;
      }

      &:last-of-type {
        margin-inline-end: 0;
      }
    }

    .filter-button {
      &:hover,
      &:active,
      &:focus {
        background-color: ${theme.chip.success.background} !important;
      }
    }

    .filters {
      display: flex;
      margin-inline-start: 0.5rem;
      gap: 0.5rem;
    }
  }

  .end-actions-container {
    display: flex;
    flex-direction: row-reverse;

    .action-item {
      margin-inline-start: 0.5rem;

      &:last-of-type {
        margin-inline-end: 0;
      }
    }

    .right-actions-item {
      padding: 0 1rem !important;
    }
  }
`;

import React, { FC } from "react";
import { m } from "framer-motion";
import { hoverContainerVariants } from "../constants";
import { images } from "@constants/assets";
import { courseThumbnailContainer } from "./styles";
import CardActions from "@components/Cards/components/CardActions";
import { Course } from "types/entities";
import { URLS } from "@constants/urls";
import { useConfigurationStore } from "@stores";
import { FacebookLogoSVG, TwitterLogoSVG, UserUnenrollSVG } from "@assets/icons";
import permissions from "@utils/permissions";
import { useIsExternalView } from "@hooks";
import { getSocialMediaShareURL } from "@utils/helpers";

type CourseThumbnailProps = {
  course: Course;
  isCatalogCourseView: boolean;
  setIsConfirmationModalOpen: (isConfirmationModalOpen: boolean) => void;
};

const handleOpenInNewWindow = (url: string): void => {
  window.open(url, "_blank");
};

const CourseThumbnail: FC<CourseThumbnailProps> = ({
  course,
  isCatalogCourseView,
  setIsConfirmationModalOpen,
}) => {
  const { catalogSettings } = useConfigurationStore();
  const { canUnenrollFromCourse } = permissions.coursesPermissions;
  const allowCourseUnenrollment = canUnenrollFromCourse();
  const isExternalView = useIsExternalView();
  const { id, cover_image, name, availability } = course;
  const canShareOnSocialMedia = Boolean(catalogSettings?.social_catalog) && isCatalogCourseView;
  const isUserEnrolled = Boolean(availability);

  const catalogUrl = isExternalView
    ? URLS.externalCatalog.createCourseLink({
        courseId: id.toString(),
      })
    : URLS.catalog.createCourseLink({
        courseId: id.toString(),
      });

  const actions = [
    ...(isUserEnrolled && allowCourseUnenrollment
      ? [
          {
            icon: UserUnenrollSVG,
            label: "",
            onClick: (): void => setIsConfirmationModalOpen(true),
          },
        ]
      : []),
    ...(canShareOnSocialMedia
      ? [
          {
            icon: FacebookLogoSVG,
            label: "",
            onClick: (): void =>
              handleOpenInNewWindow(getSocialMediaShareURL("facebook", catalogUrl, course)),
          },
          {
            icon: TwitterLogoSVG,
            label: "",
            onClick: (): void =>
              handleOpenInNewWindow(getSocialMediaShareURL("twitter", catalogUrl, course)),
          },
        ]
      : []),
  ];

  return (
    <m.div css={courseThumbnailContainer}>
      <img
        src={cover_image?.default ?? images.cover}
        alt={`${name} cover image.`}
        className="thumbnail-image"
      />

      <m.div
        initial="rest"
        // If there are no actions to be shown, do not apply the hover style
        whileHover={actions.length > 0 ? "hover" : "rest"}
        variants={hoverContainerVariants}
        className="hover-container"
      >
        <CardActions actions={actions} />
      </m.div>
    </m.div>
  );
};

export default CourseThumbnail;

import React, { FC } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";
import { t } from "i18next";
import { skeletonContainer } from "./styles";
import { EmptyDiscussionseSVG } from "@assets/images";
import DrawerDiscussion from "@components/Discussion/DrawerDiscussion";
import { Skeletons } from "@components";
import queryKeys from "@constants/queryKeys";
import { getDiscussions } from "@api/discussions";

const Discussions: FC = () => {
  const {
    status,
    data: discussions,
    error,
  } = useQuery(
    [queryKeys.discussions.discussion],
    () => getDiscussions("?page[number]=1&page[size]=10&sort=-latest_reply"),
    {
      select: (discussions) => ({
        data: discussions._data,
      }),
    },
  );

  return (
    <div css={skeletonContainer}>
      <Skeletons.Loader
        status={status}
        error={error}
        loader={
          <div className="skeleton-wrapper">
            {[1, 2, 3, 4, 5].map((n) => (
              <Skeletons.ListItem key={n} />
            ))}
          </div>
        }
      >
        <>
          {discussions?.data.length ? (
            discussions?.data?.map((discussion) => (
              <DrawerDiscussion key={discussion.id} {...discussion} />
            ))
          ) : (
            <Result
              icon={EmptyDiscussionseSVG}
              title={t("discussions.emptyMessageScreen.title")}
              info={t("discussions.emptyMessageScreen.permissionInfo")}
            />
          )}
        </>
      </Skeletons.Loader>
    </div>
  );
};

export default Discussions;

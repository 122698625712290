import React, { FC, useRef, useState } from "react";
import { SerializedStyles, useTheme } from "@emotion/react";
import classNames from "classnames";
import { Button, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { DragAndDropAreaContainer } from "./styles";
import { FileValidationsObj, MimeType } from "types/entities";
import { convertBytesToSizeUnit, getFileType } from "@utils/helpers";

type DragAndDropAreaProps = {
  maxFiles: number;
  mimeTypeAndFilesizeValidations: FileValidationsObj;
  onFilesDrop: (selectedFiles: FileList) => void;
  className?: string;
};

export const DragAndDropArea: FC<DragAndDropAreaProps> = ({
  maxFiles,
  mimeTypeAndFilesizeValidations,
  onFilesDrop,
  children,
  className,
}): JSX.Element => {
  const { fileInput } = useTheme();
  const [dragging, setDragging] = useState(false);
  const counterRef = useRef(0);
  const filesLengthRef = useRef(0);
  const dropFilesHTMLClasses = classNames({
    "drop-files": true,
    "hidden visually-hidden": !dragging,
  });

  const groupedMimeTypes = Object.keys(mimeTypeAndFilesizeValidations).reduce((obj, currentKey) => {
    const currentValue = mimeTypeAndFilesizeValidations[currentKey];
    !obj[currentValue] ? (obj[currentValue] = [currentKey]) : obj[currentValue].push(currentKey);
    return obj;
  }, {});

  const validationText = Object.keys(groupedMimeTypes).map((fileSizeStr) => {
    const fileSize = parseInt(fileSizeStr);
    const extensions = groupedMimeTypes[fileSizeStr].map((mimeType: MimeType) =>
      getFileType(mimeType),
    );

    // remove duplicate extensions
    const extensionsStr = [...new Set(extensions)].join(", ");

    return (
      <p key={fileSizeStr}>{` ${extensionsStr} (${convertBytesToSizeUnit(fileSize, "MB")} MB)`}</p>
    );
  });

  const handleDrag = (e: React.DragEvent<HTMLDivElement>): void => {
    e.dataTransfer.clearData();
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    // increase counter for elements in dnd area
    counterRef.current = counterRef.current + 1;

    const items = e.dataTransfer.items;
    const files = [];

    // get all items with file type
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        files.push(items[i]);
      }
    }

    if (files.length > 0) {
      filesLengthRef.current = files.length;
      setDragging(true);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    // decrease counter for elements in dnd area
    counterRef.current = counterRef.current - 1;

    if (counterRef.current === 0) {
      setDragging(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    const selectedFiles = e.dataTransfer.files;

    if (selectedFiles?.length) {
      counterRef.current = 0;
      filesLengthRef.current = 0;
      setDragging(false);
      onFilesDrop(selectedFiles);
    }
  };

  return (
    <div
      css={(): SerializedStyles => DragAndDropAreaContainer({ fileInput, dragging })}
      onDrag={handleDrag}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={className}
    >
      <div className={dropFilesHTMLClasses}>
        <div className="drop-files-info">
          <Button rounded>{filesLengthRef.current}</Button>
          <Text fontSize="sm">{t("validationFiles.dropFilesHere", { count: maxFiles })}</Text>

          <div className="rules">
            <Text fontSize="xs" as="div">
              {t("validationFiles.filesAllowedToAttach", { count: maxFiles })}
            </Text>
            <Text fontSize="xs" as="div">
              {t("general.acceptedFiles")}
              {validationText}
            </Text>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

import React, { FC, useRef, useState } from "react";
import { Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { IconChevronDownSVG, VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { AccordionStyles } from "./styles";
import ActionGhostButton from "../ActionGhostButton/ActionGhostButton";
import { useIsOverflow } from "@hooks";
import { SerializedStyles } from "@emotion/react";

type AccordionProps = {
  title: string;
  headerIcon?: JSX.Element;
  options?: DropdownItem[];
  onDropdownItemClick?: (item: DropdownItem) => void;
};

const titleClassnames = (isExpanded: boolean): string =>
  classNames({
    "accordion-title": true,
    "is-expanded": isExpanded,
  });

const contentClassnames = (isExpanded: boolean): string =>
  classNames({
    "accordion-content": true,
    "is-expanded": isExpanded,
  });

const iconClassnames = (isExpanded: boolean): string =>
  classNames({
    "accordion-expand-icon": true,
    "is-expanded": isExpanded,
  });

const Accordion: FC<AccordionProps> = ({
  title,
  headerIcon,
  options = [],
  onDropdownItemClick,
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const canToggle = !isExpanded ? Boolean(isOverflow || children) : true;

  const handleHeaderClick = (): void => {
    if (canToggle) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div css={(theme): SerializedStyles => AccordionStyles(theme, { canToggle })}>
      <div className="accordion-header">
        <div className="accordion-header-content">
          {headerIcon && <div className="accordion-icon">{headerIcon}</div>}
          <div
            className={titleClassnames(isExpanded)}
            dangerouslySetInnerHTML={{ __html: title }}
            ref={ref}
            onClick={handleHeaderClick}
          />
        </div>

        <div className="accordion-actions">
          {options.length > 0 && (
            <Dropdown list={options} onListItemSelect={onDropdownItemClick} placement="bottom-end">
              <ActionGhostButton disabled={false}>
                <VerticalEllipsisSVG height={16} />
              </ActionGhostButton>
            </Dropdown>
          )}
          <IconChevronDownSVG
            height={32}
            className={iconClassnames(isExpanded)}
            onClick={handleHeaderClick}
          />
        </div>
      </div>

      <div className={contentClassnames(isExpanded)}>{children}</div>
    </div>
  );
};

export default Accordion;

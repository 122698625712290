import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useUIStore, useUserPreferencesStore } from "@stores";
import authService from "@utils/services/AuthService";
import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";

type UseLogout = {
  handleLogOut: () => void;
};

const useLogout = (): UseLogout => {
  const { hideMainDrawer } = useUIStore((state) => state);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};

  const handleLogOut = (): void => {
    hideMainDrawer();
    document.body.style.setProperty("overflow", "");
    queryClient.clear();
    authService.removeTokens();
    authService.removeRole();
    resetSettings();

    const ssoLogoutUrl = localStorage.getItem(localStorageKeys.SSO_LOGOUT_URL);

    // if user used sso to login
    ssoLogoutUrl
      ? // redirect to sso logout url
        window.location.replace(ssoLogoutUrl)
      : // logout
        navigate(URLS.login);
  };

  return { handleLogOut };
};

export default useLogout;

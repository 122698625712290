import { css } from "@emotion/react";

export const MultiValueStyles = ({ autocompleteInput, base }) => ({
  ...base,
  margin: "0.25rem 0.5rem 0.25rem 0",
  backgroundColor: autocompleteInput.tagColor,
  flexDirection: "row-reverse",
  ".multi-value-label": {
    padding: 0,
  },
});

export const MultiValueLabelStyles = ({ base }) => ({
  ...base,
  padding: "0.25rem",
  paddingLeft: "0.25rem",
  paddingRight: "0.5rem",
});

export const MultiValueRemoveStyles = ({ autocompleteInput, base }) => ({
  ...base,
  ".multi-value-remove": {
    cursor: "pointer",
  },
  "&:hover": {
    backgroundColor: autocompleteInput.tagColor,
    color: "inherit",
  },
});

export const ValueContainerStyles = ({ base }) => ({
  ...base,
  padding: "0.25rem 0.75rem",
});

export const SelectContainerStyles = ({ base }) => ({
  ...base,
  zIndex: 1050,
});

export const MenuListStyles = ({ base }) => ({
  ...base,
  paddingTop: 0,
  paddingBottom: 0,
});

export const SingleValueStyles = ({ base }) => ({
  ...base,
  position: "absolute",
  maxWidth: "calc(100% - 35px)",
});

export const AutocompleteContainer = (autoCompleteInput, { required }) => css`
  label {
    margin-bottom: 0.5rem;
    ${required && `&::after { content: " *"; color: ${autoCompleteInput.errorBorderColor}}`}
  }
`;

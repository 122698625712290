import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import FooterWrapper from "./components/FooterWrapper";
import { URLS } from "@constants/urls";
import { useIsExternalView } from "@hooks";

const SampleUnitFooter: FC = () => {
  const { courseId } = useParams() as { courseId: string };
  const isExternalView = useIsExternalView();

  const catalogLink = isExternalView
    ? URLS.externalCatalog.createCourseLink({ courseId })
    : URLS.catalog.createCourseLink({ courseId });

  return (
    <FooterWrapper>
      <Button as={Link} to={catalogLink}>
        {t("test.goToCourseOverview")}
      </Button>
    </FooterWrapper>
  );
};

export default SampleUnitFooter;

import React, { FC } from "react";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import classNames from "classnames";
import { completionRulesContainer } from "./styles";
import { Course, MyUnit } from "types/entities";
import { getCourseAccessText, getUnitIconByType } from "@utils/helpers";
import { CompletionIcon } from "@components";

type CourseRules = NonNullable<Course["rules"]>;
type CourseCompletion = NonNullable<CourseRules["completion"]>;

export type ExpirationInfo = Pick<
  Course,
  "starts_at" | "expires_at" | "time_limit" | "retain_access_after_completion"
>;

export type CompletionRulesProps = {
  completion: CourseCompletion;
  expirationInfo: ExpirationInfo;
  units: MyUnit[];
};

const classNameContainer = classNames({
  "rule-container": true,
});

const getUnitIcon = (units: MyUnit[], id: number, name: string): JSX.Element => {
  const unit = units.find((unit) => unit.id === id);

  const { progress, type } = unit ?? {};
  const unitProgress = progress?.status;
  const isSuccessful = unitProgress === "completed";

  const icon =
    unitProgress === "completed" || unitProgress === "failed" ? (
      <CompletionIcon isSuccessful={isSuccessful} />
    ) : (
      type && getUnitIconByType(type)
    );

  return (
    <div className="certain-units-list-item">
      {icon}
      <Text className="unit-name" fontSize="sm">
        {name}
      </Text>
    </div>
  );
};

const CompletionRules: FC<CompletionRulesProps> = ({ completion, expirationInfo, units }) => {
  const accessInfoText = getCourseAccessText(expirationInfo);

  return (
    <section css={completionRulesContainer} className="section-wrapper">
      <Heading className="section-title">{t("myCourses.completionRules")}</Heading>

      {accessInfoText && (
        <Text fontSize="sm" as="div" className="access-info">
          {accessInfoText}
        </Text>
      )}

      {completion?.map(({ rule_type, unit_percentage, complete_units }, i) => (
        <div key={i}>
          <div className={classNameContainer}>
            {rule_type === "certain_units" ? (
              <div className="certain-units-container">
                <>
                  {complete_units?.map(({ id, name }) => {
                    const unitIcon = getUnitIcon(units, id, name);

                    return <div key={id}>{unitIcon}</div>;
                  })}
                </>
              </div>
            ) : (
              <>
                <Text fontSize="sm">
                  {rule_type === "all_units" && t("myCourses.allUnitsMustComplete")}
                  {rule_type === "unit_percentage" &&
                    t("myCourses.youMustCompleteOfTheContent", { number: unit_percentage })}
                  {rule_type === "passed_test" && (
                    <span>
                      <span>{t("myCourses.youMustCompleteTheTest")}:</span>
                      <span>{complete_units?.map(({ name }) => ` "${name}"`)}</span>
                    </span>
                  )}
                </Text>
              </>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};
export default CompletionRules;

import React, { FC, MouseEvent } from "react";
import { t } from "i18next";
import { Button, Loader, Tooltip } from "@epignosis_llc/gnosis";
import { CircleFilledSVG, SquareFilledSVG } from "@assets/icons";

type ActionsProps = {
  answerUploaded: boolean;
  isPostingAssignment: boolean;
  displayRecordingBtn: boolean;
  displayReRecordingBtn: boolean;
  displayStopRecordingBtn: boolean;
  displaySubmitRecordingBtn: boolean;
  hasValidationError: boolean;
  permissionError: string;
  onReset: () => void;
  handleStartRecording: (e: MouseEvent<HTMLButtonElement>) => void;
  handleStopRecording: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Actions: FC<ActionsProps> = ({
  answerUploaded,
  isPostingAssignment,
  displayRecordingBtn,
  displayReRecordingBtn,
  displayStopRecordingBtn,
  displaySubmitRecordingBtn,
  hasValidationError,
  permissionError,
  onReset,
  handleStartRecording,
  handleStopRecording,
}) => {
  return (
    <div className="actions-container">
      <div className="action-btns">
        {permissionError.length > 0 ? (
          <>
            {(displayRecordingBtn || displayReRecordingBtn) && (
              <Tooltip content={permissionError}>
                <Button aria-label="Start recording" onClick={handleStartRecording} disabled>
                  <CircleFilledSVG height={16} /> {t("assignment.startRecording")}
                </Button>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {(displayRecordingBtn || displayReRecordingBtn) && (
              <Button aria-label="Start recording" onClick={handleStartRecording}>
                <CircleFilledSVG height={16} /> {t("assignment.startRecording")}
              </Button>
            )}
          </>
        )}

        {displayStopRecordingBtn && (
          <Button aria-label="Stop recording" onClick={handleStopRecording}>
            <SquareFilledSVG height={16} />
            {t("assignment.stopRecording")}
          </Button>
        )}

        {displaySubmitRecordingBtn && (
          <Button type="submit" disabled={hasValidationError} isLoading={isPostingAssignment}>
            {t("test.submit")}
          </Button>
        )}
      </div>

      <div className="cancel-btn">
        {!answerUploaded && !isPostingAssignment && (
          <Button type="reset" variant="link" onClick={onReset}>
            {t("general.cancel")}
          </Button>
        )}
      </div>

      {answerUploaded && <Loader />}
    </div>
  );
};

export default Actions;

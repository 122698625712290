import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { useConfigurationStore } from "@stores";
import localStorageKeys from "@constants/localStorageKeys";
import { SSOIconSVG } from "@assets/icons";

const SamlOicdSignIn: FC = () => {
  const { domainSettings, ssoDomainSettings } = useConfigurationStore();
  const integrationType = domainSettings?.sso?.integration_type ?? "";
  const showSamlOidcLogIn = ["saml", "oidc"].includes(integrationType);

  const handleSamlClick = (): void => {
    if (showSamlOidcLogIn) {
      const { login_url = "", logout_url = "" } = ssoDomainSettings ?? {};

      // save logout url
      if (logout_url) localStorage.setItem(localStorageKeys.SSO_LOGOUT_URL, logout_url);

      // redirect to the proper url for saml/oidc login
      if (login_url) window.location.replace(login_url);
    }
  };

  return (
    <section className="sso-container">
      <Button
        as="button"
        color="primary"
        iconBefore={SSOIconSVG}
        className="sign-in-type"
        onClick={handleSamlClick}
        variant="outline"
      >
        {integrationType === "saml" ? t("signIn.loginWithSaml") : t("signIn.loginWithOidc")}
      </Button>
    </section>
  );
};

export default SamlOicdSignIn;

import React, { FC } from "react";
import { format } from "date-fns";
import { Text } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightSVG } from "@epignosis_llc/gnosis/icons";
import { getLocale } from "@utils/helpers";
import i18n from "@utils/i18n";
import { useRtlArrowIcons } from "@hooks";

type CustomHeaderWithHiddenYearsProps = {
  isMonthPicker: boolean;
  date: Date;
  prevMonthButtonDisabled: boolean | undefined;
  nextMonthButtonDisabled: boolean | undefined;
  increaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined;
  decreaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined;
  setIsMonthPicker: (isMonthPicker: boolean) => void;
};

const CustomHeaderWithHiddenYears: FC<CustomHeaderWithHiddenYearsProps> = (props) => {
  const { isMonthPicker, date, increaseMonth, decreaseMonth, setIsMonthPicker } = props;

  const isRtl = i18n.dir() === "rtl";
  const Icon = useRtlArrowIcons();

  return (
    <div className="custom-header">
      <button type="button" onClick={decreaseMonth} className="icon-btn">
        {<Icon height={32} />}
      </button>

      <button
        className="date-label"
        type="button"
        onClick={(): void => setIsMonthPicker(!isMonthPicker)}
      >
        <Text fontSize="md" weight="700">
          {format(date, "MMMM", { locale: getLocale() })}
        </Text>
      </button>

      <button type="button" onClick={increaseMonth} className="icon-btn">
        {isRtl ? <ArrowLeftSVG height={32} /> : <ArrowRightSVG height={32} />}
      </button>
    </div>
  );
};

export default CustomHeaderWithHiddenYears;

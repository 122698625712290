import React, { FC } from "react";
import { Text, Heading, MediaPlayer } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { useResponsive } from "ahooks";
import { submittedAnswerContainer } from "./styles";
import { MyUnit } from "types/entities";
import FileCard from "@components/Cards/FileCard/FileCard";
import { images } from "@constants/assets";
import { useConfigurationStore } from "@stores";

type AnswerProps = {
  unit: MyUnit;
};

const config = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
    },
  },
};

const Answer: FC<AnswerProps> = ({ unit }) => {
  const { sm } = useResponsive();
  const { domainSettings } = useConfigurationStore();
  const { upload_limits: uploadLimits } = domainSettings ?? {};

  const { mime_types: audioMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.audio"]) ?? {};
  const { mime_types: documentMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.document"]) ?? {};
  const { mime_types: zipMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.zip"]) ?? {};
  const { mime_types: videoMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.video"]) ?? {};
  const { mime_types: imageMimeTypes = [] } =
    (uploadLimits && uploadLimits["unit.assignment.image"]) ?? {};

  const type = unit.progress?.submission?.type;
  const file = unit.progress?.submission?.file;
  const showTextAnswer = type === "text";
  const textAnswer = unit.progress?.submission?.text?.html as string;

  const fileMimeTypes = [...documentMimeTypes, ...zipMimeTypes];
  const showFile = file?.status === "ready" && fileMimeTypes.includes(file.mime_type);
  const showAudioPlayer = file?.status === "ready" && audioMimeTypes.includes(file.mime_type);
  const showVideoPlayer = file?.status === "ready" && videoMimeTypes.includes(file.mime_type);
  const showImage = file?.status === "ready" && imageMimeTypes.includes(file.mime_type);

  const showAudioVideoProcessing = file?.status === "processing";
  const showFileProcessingFailed = file?.status === "failed";

  return (
    <div css={submittedAnswerContainer}>
      <Heading as="h5" className="header">
        {t("assignment.yourAnswer")}
      </Heading>

      {showTextAnswer && (
        <Text fontSize="sm" as="div" dangerouslySetInnerHTML={{ __html: textAnswer }} />
      )}

      {showFile &&
        (file.mime_type === "application/pdf" ? (
          <div className="file-container">
            <iframe src={file.url} height="100%" width="100%" />
          </div>
        ) : (
          <div className="file-card-container">
            <FileCard {...file} isReadonly />
          </div>
        ))}

      {showImage && (
        <div className="image-container">
          <img src={file.url} />
        </div>
      )}

      {(showAudioPlayer || showVideoPlayer) && (
        <div className="container">
          <div className={showAudioPlayer ? "audio-player" : "video-player"}>
            <MediaPlayer
              type={showAudioPlayer ? "audio" : "video"}
              src={file.url}
              controls
              config={config}
              height="100%"
              width="100%"
            />
          </div>
        </div>
      )}

      {(showAudioVideoProcessing || showFileProcessingFailed) && (
        <div className="file-proccessing-wrapper">
          <img src={images.converting} />
          <div className="text-wrapper">
            <Heading className="title" size={!sm ? "sm" : "3xl"}>
              {t("assignment.fileUnderProcessTitle")}
            </Heading>
            <Heading as="h3" size={!sm ? "xs" : "md"}>
              {t("assignment.fileUnderProcessDescription")}
            </Heading>
          </div>
        </div>
      )}
    </div>
  );
};

export default Answer;

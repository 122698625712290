import { css } from "@emotion/react";

export const courseFilesContainer = css`
  .section-title {
    margin-bottom: 1rem;
  }

  .file-card {
    margin-bottom: 0.5rem;
  }
`;

import { Button, Card } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useResponsive } from "ahooks";
import { hoverWrapper } from "../styles";
import CardActions, { Actions } from "../../components/CardActions";
import { images } from "@constants/assets";
import { Course } from "types/entities";
import { IconType } from "types/common";

type CardHeaderProps = {
  course: Course;
  actions: Actions[];
  allowCourseUnitsView?: boolean;
  courseLink: string;
  icon: IconType;
};

const CardHeader: FC<CardHeaderProps> = (props) => {
  const { sm } = useResponsive();
  const { Thumbnail, Hover } = Card;
  const { course, actions, allowCourseUnitsView = true, courseLink, icon: Icon } = props;

  return (
    <>
      {!sm && allowCourseUnitsView ? (
        <Link to={courseLink}>
          <Thumbnail src={course.cover_image?.default ?? images.cover} alt={course.name} />
        </Link>
      ) : (
        <Thumbnail src={course.cover_image?.default ?? images.cover} alt={course.name} />
      )}

      {sm && (
        <Hover>
          <div css={hoverWrapper}>
            <CardActions actions={actions} />

            {allowCourseUnitsView && (
              <Button
                rounded
                as={Link}
                to={courseLink}
                aria-label={`Play ${course.name}`}
                className="hover-btn"
                data-testid="action-button"
              >
                <Icon height={38} />
              </Button>
            )}
          </div>
        </Hover>
      )}
    </>
  );
};

export default CardHeader;

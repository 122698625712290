import React, { FC } from "react";
import { t } from "i18next";

import { certificationExpirationDate, getCertificationDuration } from "@utils/helpers";
import { Course } from "types/entities";
import { CertificateWithViewboxSVG } from "@assets/icons";

type CertificationInfoProps = {
  certificate: Course["certificate"];
};

const CertificationInfo: FC<CertificationInfoProps> = ({ certificate }) => {
  const { duration = 0, expiration_month = null, expiration_day = null } = certificate ?? {};
  let certificationText = "";

  if (duration !== null) {
    certificationText = t("myCourses.courseLeadCertification", {
      duration: getCertificationDuration(duration),
    });
  } else if (expiration_month !== null && expiration_day !== null) {
    certificationText = t("myCourses.courseLeadCertificationUntil", {
      duration: certificationExpirationDate(expiration_month, expiration_day),
    });
  }

  return (
    <>
      <CertificateWithViewboxSVG height={32} />

      {certificationText}
    </>
  );
};

export default CertificationInfo;

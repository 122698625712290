import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { Course } from "types/entities";

const HOVER_BUTTON_TEXT = {
  completed: "myCourses.mainButtonText.revisitCourse",
  incomplete: "myCourses.mainButtonText.incomplete",
  not_attempted: "myCourses.mainButtonText.notAttempted",
  failed: "myCourses.mainButtonText.revisitCourse",
};

type HoverActionsProps = {
  course: Course;
};

const HoverActions: FC<HoverActionsProps> = ({ course }) => {
  const { progress, availability } = course;
  const isCourseAvailable = Boolean(availability?.is_available);

  return (
    <Button block className="footer-btn">
      {isCourseAvailable
        ? t(HOVER_BUTTON_TEXT[progress?.completion_status ?? ""])
        : t("courseCatalog.viewCourse")}
    </Button>
  );
};

export default HoverActions;

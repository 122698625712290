import React, { Button, Modal } from "@epignosis_llc/gnosis";
import { FC } from "react";
import { t } from "i18next";
import { useMutation, useQueryClient } from "react-query";
import { termsModalFooter, termsModalBody } from "./styles";
import { useConfigurationStore, useUIStore } from "@stores";
import { useLogout } from "@hooks";
import queryKeys from "@constants/queryKeys";
import { putDomainTerms } from "@api/app";

const termsModalCustomStyles = {
  overlay: { zIndex: 10000, backgroundColor: "rgba(0,0,0,0.9)" },
};

const TermsModal: FC = () => {
  const showTermsModal = useUIStore((state) => state.showTermsModal);
  const { domainTerms } = useConfigurationStore();
  const { handleLogOut } = useLogout();
  const queryClient = useQueryClient();

  const { mutate: putDomainTermsMutation, isLoading: putDomainTermsLoading } = useMutation(
    queryKeys.domainTerms.accept,
    putDomainTerms,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.domainTerms.terms]);
      },
    },
  );

  const handleAccept = (): void => {
    if (domainTerms?.terms) {
      putDomainTermsMutation();
    }
  };

  return (
    <Modal size="lg" style={termsModalCustomStyles} isOpen={showTermsModal}>
      <Modal.Header>
        <div className="terms-header">{t("general.termsOfService")}</div>
      </Modal.Header>
      <Modal.Body css={termsModalBody}>
        <div
          className="terms-body"
          dangerouslySetInnerHTML={{ __html: domainTerms?.terms?.html ?? "" }}
        />
      </Modal.Body>
      <Modal.Footer css={termsModalFooter}>
        <div className="terms-footer">
          <Button className="button" color="secondary" onClick={handleLogOut}>
            {t("general.logOut")}
          </Button>
          <Button className="button" isLoading={putDomainTermsLoading} onClick={handleAccept}>
            {t("general.accept")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsModal;

import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { footerNavigationButtonsContainer } from "./styles";
import { useGetUnits, useRtlArrowIcons } from "@hooks";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  getUnitNotAvailableTooltipContent,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import { URLS } from "@constants/urls";
import { MyUnit } from "types/entities";

type FooterNavigationButtonsProps = {
  isPublic?: boolean;
};

const goToPreviousUnitLink = (
  prevUnit: MyUnit | null,
  courseId: string,
  isPublic: boolean,
): string => {
  if (!prevUnit) return "#";

  const isAvailable = !unitHasDelayedAvailability(prevUnit?.availability ?? null);
  if (!isAvailable) return "#";

  const unitLink = isPublic
    ? URLS.externalCatalog.publicUnit.createPublicUnitLink({
        courseId,
        unitId: prevUnit?.id.toString(),
      })
    : URLS.user.createUnitLink({ courseId, unitId: prevUnit?.id.toString() });

  return unitLink;
};

const isPrevButtonDisabled = (prevUnit: MyUnit | null): boolean =>
  unitHasDelayedAvailability(prevUnit?.availability ?? null);

const FooterNavigationButtons: FC<FooterNavigationButtonsProps> = ({
  isPublic = false,
  children,
}) => {
  const { courseId, unitId } = useParams() as { courseId: string; unitId: string };

  const { data: sections } = useGetUnits(courseId, {
    select: (units) => units._data,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const flatUnits = getFlatUnits(sections ?? []);
  const { prevUnit } = getPrevAndNextUnits(flatUnits, parseInt(unitId)) || {};
  const isPrevUnitAvailable = !unitHasDelayedAvailability(prevUnit?.availability ?? null);
  const Icon = useRtlArrowIcons();

  return (
    <div css={footerNavigationButtonsContainer}>
      {prevUnit && (
        <Tooltip
          disabled={isPrevUnitAvailable}
          content={getUnitNotAvailableTooltipContent(prevUnit?.availability ?? null)}
        >
          <Button
            as={Link}
            to={goToPreviousUnitLink(prevUnit, courseId, isPublic)}
            color="secondary"
            noGutters
            className="prev-btn"
            disabled={isPrevButtonDisabled(prevUnit)}
            aria-label="Go to previous unit"
          >
            <Icon height={24} />
          </Button>
        </Tooltip>
      )}

      {children}
    </div>
  );
};

export default FooterNavigationButtons;

import React, { FC, ReactNode, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Loader } from "@epignosis_llc/gnosis";
import { AxiosError } from "axios";
import { IntercomProvider } from "react-use-intercom";
import {
  getDomainSettings,
  getDomainTerms,
  getSSODomainSettings,
  getSocialDomainSettings,
} from "@api/app";
import queryKeys from "@constants/queryKeys";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "@components";
import { InternalServerError, InactiveBranchError } from "@views/Errors";
import { useConfigurationStore, useUIStore } from "@stores";
import localStorageKeys from "@constants/localStorageKeys";
import { useAuth, useGetGamificationSettings, useGetUserProfile } from "@hooks";
import AutologinLoader from "@components/Autologin/AutologinLoader";
import { languageChange, setActiveTheme } from "@utils/helpers";
import permissions from "@utils/permissions";
import { getUserStatistics, getUserIntegrations } from "@api/user";
import { getCatalogSettings } from "@api/catalog";
import { getAnnouncements } from "@api/announcements";

type LayoutWrapperProps = {
  children: ReactNode;
};

const isBranchInactive = (errors: unknown[]): boolean => {
  return errors.some((error) => {
    const errorResponse = (error as AxiosError)?.response;
    const responseStatus = errorResponse?.status;
    const errorId = errorResponse?.data?._errors?.[0]?.id;

    return responseStatus === 403 && errorId === "forbidden.branch_inactive";
  });
};

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const queryClient = useQueryClient();
  const {
    userProfileData,
    domainSettings,
    domainTerms,
    userIntegrations,
    setDomainSettings,
    setDomainTerms,
    setUserProfile,
    setGamificationSettings,
    setSsoDomainSettings,
    setSocialDomainSettings,
    setUserStatistics,
    setCatalogSettings,
    setAnnouncements,
    setUserIntegrations,
  } = useConfigurationStore();
  const showRedirectLoading = useUIStore((state) => state.showRedirectLoading);

  const { integration_type = null } = domainSettings?.sso ?? {};
  const { canAccessGamification } = permissions.gamificationPermissions;
  const { mutate: getGamificationSettingsMutation } = useGetGamificationSettings();
  const { intercom_settings = null } = userIntegrations ?? {};
  const domainSettingsLocale = domainSettings?.locale;
  const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);

  const isAuthenticatedRequestsEnabled =
    !isAuthenticated || !domainTerms ? false : domainTerms.terms === null;

  // get domain settings
  const { status: domainSettingsStatus, error: domainSettingsError } = useQuery(
    queryKeys.domainSettings,
    getDomainSettings,
    {
      onSuccess: (res) => {
        setDomainSettings(res._data);
        const { theme } = res._data;
        setActiveTheme(theme);
      },
      refetchOnWindowFocus: !isAuthenticated,
      cacheTime: 0,
    },
  );

  // get announcements
  useQuery([queryKeys.announcements.announcements, isAuthenticated], getAnnouncements, {
    onSuccess: (res) => setAnnouncements(res._data),
  });

  // get saml and oidc sso domain settings
  useQuery(queryKeys.ssoDomainSettings, getSSODomainSettings, {
    enabled: ["saml", "oidc"].some((type) => type === integration_type),
    onSuccess: (res) => setSsoDomainSettings(res._data),
  });

  // get social media domain login/signup options
  useQuery(queryKeys.socialDomainSettings, getSocialDomainSettings, {
    enabled: domainSettings?.social_login.length !== 0,
    onSuccess: (res) => setSocialDomainSettings(res._data),
  });

  // get user profile data
  const { status: userProfileStatus, error: userProfileError } = useGetUserProfile({
    enabled: isAuthenticated,
    cacheTime: 0,
    onSuccess: (res) => {
      setUserProfile(res._data);

      isAuthenticated && canAccessGamification()
        ? getGamificationSettingsMutation()
        : setGamificationSettings(null);
    },
  });

  const isImpersonationEnabled = userProfileData?.impersonated;

  // get domain terms
  const { status: domainTermsStatus, error: domainTermsError } = useQuery(
    queryKeys.domainTerms.terms,
    getDomainTerms,
    {
      cacheTime: 0,
      enabled: isAuthenticated && Boolean(!isImpersonationEnabled),
      onSuccess: (res) => {
        setDomainTerms(res._data);

        if (res._data.terms) {
          queryClient.cancelQueries();
        }
      },
    },
  );

  //get user statistics
  useQuery([queryKeys.userStatistics], () => getUserStatistics(), {
    enabled: isAuthenticatedRequestsEnabled,
    onSuccess: (res) => setUserStatistics(res._data),
  });

  //get catalog settings
  useQuery([queryKeys.catalogSettings], () => getCatalogSettings(), {
    onSuccess: (res) => setCatalogSettings(res._data),
  });

  // get integration settings
  useQuery([queryKeys.userIntegrations], getUserIntegrations, {
    enabled: isAuthenticated,
    onSuccess: (res) => setUserIntegrations(res._data),
  });

  useEffect(() => {
    // when domain settings locale is defined use only in sign in page

    if (!domainSettingsLocale && localStorageLocale) {
      languageChange(localStorageLocale);
    }
  }, [domainSettingsLocale, localStorageLocale]);

  useEffect(() => {
    if (userProfileData) {
      // when logged in branch and branch locale is set to a specific locale set domain locale, else set user's locale
      const locale =
        domainSettings?.user_selected_locale === false
          ? domainSettings.locale
          : userProfileData.locale;
      languageChange(locale);

      const localStorageLocale = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);

      // Used in public routes and non react helpers
      if (localStorageLocale !== locale) {
        localStorage.setItem(localStorageKeys.LANGUAGE_LOCALE, locale);
      }
    }
  }, [domainSettings?.locale, domainSettings?.user_selected_locale, userProfileData]);

  // required request are fetching data
  const isLoading = [domainSettingsStatus, domainTermsStatus, userProfileStatus].some(
    (status) => status === "loading",
  );
  if (isLoading) return <Loader fullScreen />;

  // branch is inactive
  if (isBranchInactive([domainSettingsError, domainTermsError, userProfileError]))
    return <InactiveBranchError />;

  // an error occurred
  if (domainSettingsError || domainTermsError || userProfileError) return <InternalServerError />;

  // redirecting to core
  if (showRedirectLoading) return <AutologinLoader isFullScreen={true} />;

  return (
    <IntercomProvider appId={intercom_settings?.app_id ?? ""}>
      <Toaster />
      {children}
    </IntercomProvider>
  );
};

export default LayoutWrapper;

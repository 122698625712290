import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { TrashAltSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";

export type DeleteActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const DeleteAction: FC<DeleteActionProps> = ({
  tooltipContent,
  icon: Icon = TrashAltSVG,
  iconHeight = 16,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.delete")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default DeleteAction;

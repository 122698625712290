import React, { FC, useEffect, useState } from "react";
import { Button, Text, Heading, Tag, MediaPlayer, Tooltip } from "@epignosis_llc/gnosis";
import { UnitCompleteSVG } from "@epignosis_llc/gnosis/icons";
import classNames from "classnames";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { useTheme } from "@emotion/react";
import { courseDetailsContainer } from "./styles";
import CompletionRules from "./CompletionRules/CompletionRules";
import Prerequisites from "./Prerequisites/Prerequisites";
import CourseFilesWidget from "./CourseFilesWidget/CourseFilesWidget";
import PaymentModal from "./components/PaymentModal";
import CourseRating from "./components/CourseRating";
import SubscriptionModal from "./components/SubscriptionModal";
import BundleList from "./components/BundleList";
import {
  extractKeyFromURL,
  getIsButtonDisabled,
  getButtonText,
  getPaymentNotificationTranslation,
} from "./helpers";
import { MAX_NUMBER_OF_CHARS, REACT_PLAYER_CONFIG } from "./constants";
import { Course, Section } from "types/entities";
import { URLS } from "@constants/urls";
import permissions from "@utils/permissions";
import { CourseToC } from "@components";
import {
  formatUtcDate,
  generalNotification,
  getFlatUnits,
  getUnitIdToContinue,
  truncate,
} from "@utils/helpers";
import { useConfigurationStore } from "@stores";
import queryKeys from "@constants/queryKeys";
import ExternalPaymentModal from "@components/ReusableModals/ExternalPaymentModal";
import { CourseCategory, EnrollmentPostData } from "types/entities/Courses";
import { useIsExternalView, useSearchQuery } from "@hooks";
import localStorageKeys from "@constants/localStorageKeys";
import ExternalSubscriptionModal from "@components/ReusableModals/ExternalSubscriptionModal";
import { handleEnrollmentErrors, handleUnenrollmentErrors } from "@errors";
import CourseThumbnail from "./components/CourseThumbnail";
import ConfirmationModal from "@components/ReusableModals/ConfirmationModal";
import { findCategoryParents, flatten, getCategoryText } from "@utils/helpers/view";
import { getPublicCourse } from "@views/PublicCourse/api";
import { postEnrollment } from "@api/catalog";
import { putUnenrollFromCourse } from "@api";
import CertificationInfo from "./components/CertificationInfo";
import userRoles from "@constants/userRoles";

const NO_DISPLAYED_CATEGORY = 2;
type SearchQueryFilters = {
  status: string;
  processor: string;
  type: string;
  action: "enrollment" | "payment"; // This is used for external views and enrollement after redirection
};

export type CourseDetailsProps = {
  course: Course;
  sections: Section[];
  isCatalogCourseView?: boolean;
  categoryTree?: CourseCategory[];
};

const containerClassNames = (hasUnits: boolean): string =>
  classNames({
    "empty-content": !hasUnits,
  });

const CourseDetails: FC<CourseDetailsProps> = ({
  course,
  sections,
  isCatalogCourseView = false,
  categoryTree,
}) => {
  const {
    id,
    name,
    code,
    description,
    certificate,
    intro_video,
    custom_fields,
    rules,
    starts_at,
    expires_at,
    retain_access_after_completion,
    time_limit,
    price,
    bundles,
    public_url,
    availability,
    progress,
    level,
    role_in_course,
  } = course;

  const { status, processor, type, action } = useSearchQuery() as SearchQueryFilters;
  const { state } = useLocation();
  const locationState = state as { isPayment?: boolean };
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showmore, setShowMore] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isExternalPaymentModalOpen, setIsExternalPaymentModalOpen] = useState(false);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isExternalSubscriptionModalOpen, setIsExternalSubscriptionModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { domainSettings, catalogSettings, userProfileData, gamificationSettings } =
    useConfigurationStore();
  const { courseOverview } = useTheme();
  const units = getFlatUnits(sections);
  const hasUnits = Boolean(units.length);
  const continueUnitID = hasUnits ? getUnitIdToContinue(units) : null;
  const isUserEnrolled = Boolean(availability);
  const hasCertification = Boolean(certificate);
  const isCompleted = isUserEnrolled && progress?.completion_status === "completed";
  const isLevelEnabled = gamificationSettings?.levels?.enabled;
  const hasPrice = price && price.amount !== 0;
  const unlocksOnHigherLevel = isLevelEnabled
    ? Boolean(userProfileData && level && (userProfileData?.level ?? 0) < level)
    : false;
  const isCourseAvailable = availability //if we are enrolled check availability, else the button is disabled only for level restrictions
    ? availability?.is_available
    : !unlocksOnHigherLevel;
  const unitLink = continueUnitID
    ? URLS.user.createUnitLink({
        courseId: id.toString(),
        unitId: continueUnitID.toString(),
      })
    : "";
  const isExternalView = useIsExternalView();
  const showBundleList = isCatalogCourseView && bundles && bundles.length > 0;
  const isSubscriptionEnabled = Boolean(catalogSettings?.subscription.enabled);

  const categoryTreeText =
    course?.category && categoryTree ? getCategoryText(course?.category, categoryTree) : "";

  const allCategoryParent =
    course?.category && categoryTree
      ? findCategoryParents(course?.category, flatten(categoryTree))
      : [];
  const shouldDisplayTooltip =
    allCategoryParent && allCategoryParent.length > NO_DISPLAYED_CATEGORY;

  const categoryTootltipText = allCategoryParent?.reverse().join(" / ");
  const isUserInstructor = role_in_course === userRoles.INSTRUCTOR;
  const showButtonTooltip =
    Boolean(
      availability &&
        !availability?.is_available &&
        availability.reason === "pending-prerequisites",
    ) || isUserInstructor;

  const categoryText = `${allCategoryParent.length > 2 ? "... / " : ""} ${categoryTreeText}`;

  const { mutate: enrollmentMutation, isLoading: enrollmentMutationLoading } = useMutation(
    [queryKeys.courseEnrollment],
    postEnrollment,
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseCatalog.youEnrolledSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.course, id.toString()]);
      },
      onError: (error: AxiosError) => {
        handleEnrollmentErrors(error);
      },
    },
  );

  const { mutate: unenrollnmentMutation } = useMutation(
    [queryKeys.courseUnenroll],
    (id: string) => putUnenrollFromCourse(id),
    {
      onSuccess: () => {
        generalNotification("success", <p>{t("courseCatalog.youUnenrolledSuccessfully")}</p>);
        queryClient.invalidateQueries([queryKeys.course, id.toString()]);
        queryClient.invalidateQueries([queryKeys.courses]);
        !isCatalogCourseView && navigate(URLS.user.courses);
      },
      onError: (error: AxiosError) => {
        handleUnenrollmentErrors(error);
      },
    },
  );

  const { mutate: getPublicCourseMutation, isLoading: isPublicCourseMutationLoading } = useMutation(
    [queryKeys.publicCourseKey],
    (key: string) => getPublicCourse(key),
    {
      onSuccess: (res) => {
        localStorage.setItem(localStorageKeys.PUBLIC_COURSE, JSON.stringify(res._data.token));

        navigate(
          URLS.externalCatalog.publicUnit.createPublicUnitLink({
            courseId: id.toString(),
            unitId: continueUnitID?.toString(),
          }),
        );
      },
    },
  );

  // permission related
  const { canAccessCourseSharedFiles } = permissions.coursesPermissions;

  const handlePayment = (data: EnrollmentPostData, hasPrice: boolean): void => {
    // If no payment processors are available or the course has no price, enroll the user. Else open the payment modal
    if (!catalogSettings?.processors?.length || !hasPrice) enrollmentMutation(data);
    else setIsPaymentModalOpen(true);
  };

  const handleButtonClick = (): void => {
    const paymentData = {
      id: id,
      type: "course",
    } as EnrollmentPostData;

    // if we are on external view, render the external modals
    if (isExternalView) {
      if (public_url) {
        const key = extractKeyFromURL(public_url);

        if (key) getPublicCourseMutation(key);
        return;
      }

      // Check if the subscriptions are active and the course has a price
      if (catalogSettings?.subscription.enabled && hasPrice) {
        setIsExternalSubscriptionModalOpen(true);
      } else {
        setIsExternalPaymentModalOpen(true);
      }

      return;
    }

    // If the subscriptions are enabled on the portal and we are on catalog view
    if (isSubscriptionEnabled && isCatalogCourseView && hasPrice) {
      const userHasActiveSubscription = userProfileData?.subscription?.status === "active";

      // If the user has and active subscription check whether he is already enrolled to the course.
      // If he is enrolled then navigate to the course. Else enroll the user to the course.
      if (userHasActiveSubscription) {
        isUserEnrolled ? navigate(unitLink) : enrollmentMutation(paymentData);
      } else {
        setIsSubscriptionModalOpen(true);
      }
      return;
    }

    // Render the payment
    if (isCatalogCourseView && !isUserEnrolled) {
      handlePayment(paymentData, Boolean(hasPrice));
    } else {
      if (continueUnitID) {
        navigate(unitLink);
      }
    }
  };

  const showBtn = isCatalogCourseView
    ? isUserEnrolled
      ? Boolean(continueUnitID)
      : true
    : Boolean(continueUnitID);

  useEffect(() => {
    if (
      status &&
      status === "success" &&
      // Here we check if the user has the payment status local storage key in order to show the payment notification.
      // This functionality is introduced in oder to prevent the user from publishing payment notifications though url without any prior payment
      localStorage.getItem(localStorageKeys.PAYMENT_STATUS) === "pending"
    ) {
      const translationText = getPaymentNotificationTranslation(
        type,
        isUserEnrolled,
        processor,
        name,
      );

      generalNotification(
        "success",
        <Text
          fontSize="sm"
          as="p"
          dangerouslySetInnerHTML={{
            __html: translationText,
          }}
        />,
        5000,
      );
    }

    localStorage.removeItem(localStorageKeys.PAYMENT_STATUS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // In this useEffect we check if the url parameters contain the action parameter "enrollment".
  // This is used when a user signs in or signs up from an external view and choosed to enroll in a course.
  // Also we check if the EXTERNAL_SIGNING_SIGNUP storage key exists in order to prevent the user from enrolling though url
  useEffect(() => {
    if (
      action &&
      (action === "enrollment" || action === "payment") &&
      localStorage.getItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP)
    ) {
      if (action === "enrollment") {
        const enrollmentData = {
          id: id,
          type: "course",
        } as EnrollmentPostData;

        enrollmentMutation(enrollmentData);
      } else {
        handleButtonClick();
      }
    }

    localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On component unmount
  useEffect(() => {
    return () => {
      localStorage.removeItem(localStorageKeys.PAYMENT_STATUS);
      localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
    };
  }, []);

  // Get the active bundle from history state and set the active bundle and on change action
  useEffect(() => {
    if (locationState?.isPayment) handleButtonClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState]);

  // Reset history state on unmount
  useEffect(() => {
    window.history.replaceState({}, "");
  }, []);

  return (
    <div css={courseDetailsContainer} className={containerClassNames(hasUnits)}>
      <div className="main-container">
        <div className="preview">
          <CourseThumbnail
            course={course}
            isCatalogCourseView={isCatalogCourseView}
            setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          />

          {showBtn && (
            <Tooltip
              content={
                isUserInstructor
                  ? t("general.youAreInstructorOfTheCourse")
                  : t("general.courseNotAvailablePrerequisites")
              }
              disabled={!showButtonTooltip}
            >
              <Button
                className="resume-btn"
                isLoading={enrollmentMutationLoading || isPublicCourseMutationLoading}
                onClick={(): void => handleButtonClick()}
                disabled={getIsButtonDisabled(
                  Boolean(catalogSettings?.subscription?.enabled),
                  isCatalogCourseView,
                  unlocksOnHigherLevel,
                  availability,
                  role_in_course,
                )}
                block
              >
                {getButtonText(
                  isUserEnrolled,
                  isCatalogCourseView,
                  course,
                  unlocksOnHigherLevel,
                  hasPrice ?? false,
                  Boolean(catalogSettings?.subscription?.enabled),
                  Boolean(userProfileData?.subscription?.status === "active"),
                  catalogSettings?.subscription.price ?? undefined,
                  Boolean(public_url),
                  isExternalView,
                )}
              </Button>
            </Tooltip>
          )}

          {showBundleList && <BundleList bundles={bundles} />}
        </div>
        <div className="info">
          <section className="details-wrapper section-wrapper">
            <div className="details-container section-container">
              {categoryTreeText && (
                <div className="category-container">
                  <Tooltip content={categoryTootltipText} disabled={Boolean(!shouldDisplayTooltip)}>
                    <Text fontSize="sm" className="category" as="div">
                      {categoryText}
                    </Text>
                  </Tooltip>
                </div>
              )}
              {code && <div className="code">{code}</div>}
              <div className="name-wrapper">
                <Heading>{name}</Heading>
              </div>
            </div>

            {domainSettings?.course_rating && <CourseRating course={course} />}

            {isCompleted && progress?.completion_date && (
              <div className="course-completed-text">
                <UnitCompleteSVG height={32} />
                <Text fontSize="sm">
                  {t("myCourses.courseCompletedOn", {
                    date: formatUtcDate(progress.completion_date),
                  })}
                </Text>
              </div>
            )}

            {hasCertification && (
              <div className="certification-container section-container">
                <CertificationInfo certificate={certificate} />
              </div>
            )}
          </section>

          {description && description.html && (
            <section className="description-wrapper section-wrapper">
              <div className="description">
                {showmore
                  ? description && <div dangerouslySetInnerHTML={{ __html: description.html }} />
                  : description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: truncate(description.html, MAX_NUMBER_OF_CHARS),
                        }}
                      />
                    )}
                {description && description.html.length > MAX_NUMBER_OF_CHARS && (
                  <div className="showmore-btn">
                    <Button variant="outline" onClick={(): void => setShowMore(!showmore)}>
                      {showmore ? t("general.viewLess") : t("general.viewMore")}
                    </Button>
                  </div>
                )}
              </div>
            </section>
          )}

          {intro_video && (
            <section className="intro-video-wrapper section-wrapper">
              <MediaPlayer
                id="intro-video"
                className="intro-video"
                type="video"
                src={intro_video.url}
                config={REACT_PLAYER_CONFIG}
                width="100%"
                height="100%"
                controls
              />
            </section>
          )}

          {custom_fields && custom_fields.length > 0 && (
            <section className="custom-field-wrapper section-wrapper">
              {custom_fields?.map((field) => {
                if (!field.value) return null;
                const calculateValue = (): string => {
                  if (field.type === "date") return formatUtcDate(field.value);
                  if (field.type === "checkbox") {
                    return field.value === "on" ? t("general.yes") : t("general.no");
                  }
                  return field.value;
                };
                return (
                  <Tag
                    key={field.id}
                    className="tag"
                    style={{
                      backgroundColor: courseOverview.customFieldTag,
                      color: courseOverview.textColor,
                    }}
                  >
                    {field.name}: {calculateValue()}
                  </Tag>
                );
              })}
            </section>
          )}

          <section className="toc-wrapper section-wrapper">
            <Heading className="section-title">{t("myCourses.content")}</Heading>

            {hasUnits ? (
              <CourseToC
                course={course}
                sections={sections}
                isReadonly={!isUserEnrolled || !isCourseAvailable || isUserInstructor}
              />
            ) : (
              `${t("myCourses.noUnitsAvailable")}`
            )}
          </section>

          {rules?.completion && (
            <CompletionRules
              completion={rules.completion}
              expirationInfo={{
                starts_at,
                expires_at,
                time_limit,
                retain_access_after_completion,
              }}
              units={units}
            />
          )}

          {(Boolean(rules?.prerequisites.length) || unlocksOnHigherLevel) && (
            <Prerequisites course={course} unlocksOnHigherLevel={unlocksOnHigherLevel} />
          )}

          {canAccessCourseSharedFiles() && (
            <CourseFilesWidget courseId={id} isCatalogCourseView={isCatalogCourseView} />
          )}
        </div>
      </div>

      {/* Modals related to Payments  */}
      {isCatalogCourseView && isPaymentModalOpen && (
        <PaymentModal
          paymentData={course}
          isOpen={isPaymentModalOpen}
          onClose={(): void => setIsPaymentModalOpen(false)}
          categoryText={categoryText}
        />
      )}

      {isExternalPaymentModalOpen && (
        <ExternalPaymentModal
          data={course}
          isOpen={isExternalPaymentModalOpen}
          onClose={(): void => setIsExternalPaymentModalOpen(false)}
        />
      )}

      {/* Modals related to subscriptions */}

      {/* Only render the subcription modal if subscriptions are enabled,
      the course has price and the user does not have an active subsciprion already*/}
      {isCatalogCourseView && isSubscriptionModalOpen && (
        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          onClose={(): void => setIsSubscriptionModalOpen(false)}
        />
      )}

      {isExternalSubscriptionModalOpen && (
        <ExternalSubscriptionModal
          isOpen={isExternalSubscriptionModalOpen}
          onClose={(): void => setIsExternalSubscriptionModalOpen(false)}
        />
      )}

      {/* Confirmational Modal for course unenrollment */}
      <ConfirmationModal
        id={id}
        header={t("myCourses.unenrollFromCourse")}
        bodyTitle={
          <div
            dangerouslySetInnerHTML={{
              __html: t("myCourses.unenrollFromCourseConfirmation", {
                name,
              }),
            }}
          />
        }
        footerButton={t("general.unenroll")}
        isOpen={isConfirmationModalOpen}
        onClose={(): void => setIsConfirmationModalOpen(false)}
        onConfirm={(id): void => unenrollnmentMutation(id.toString())}
      />
    </div>
  );
};

export default CourseDetails;

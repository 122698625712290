export const toolbarButtons = {
  moreText: {
    buttons: [
      "bold",
      "italic",
      "underline",
      "fontFamily",
      "textColor",
      "backgroundColor",
      "formatOL",
      "formatUL",
      "align",
      "horizontalDivider",
    ],
    buttonsVisible: 12,
  },
  moreRich: {
    buttons: [
      "paragraphFormat",
      "lineHeight",
      "outdent",
      "indent",
      "quote",
      "insertTable",
      "insertImage",
      "insertLink",
      "insertHR",
      "insertSmartTagsDropdown",
      "html",
    ],
    buttonsVisible: 0,
  },
};

export const toolbarButtonsMinimal = {
  moreText: {
    buttons: ["bold", "italic", "underline"],
    buttonsVisible: 3,
  },
};

export const COURSE_FILES_DEFAULT_STATE = {
  pagination: { number: 1, size: 10000 },
  sorting: ["name"],
  filters: [{ key: "[use_case]", value: "course.image" }],
};

import React, { FC } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import FooterWrapper from "../Footers/components/FooterWrapper";
import UnitFooterNavigationButtons from "../Footers/components/UnitFooterNavigationButtons";
import AvailabilityTimeLimitBar from "../Footers/components/AvailabilityTimeLimitBar";
import SplashScreen from "./components/SplashScreen";
import CompletedSplashScreen from "./components/CompletedSplashScreen";
import { postUnitStart } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { Course, Section } from "types/entities";
import { MyUnitRes } from "types/responses";
import { SurveyUnit } from "types/entities/Survey";
import { FooterButton } from "@components/ReusableComponents";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  unitHasDelayedAvailability,
} from "@utils/helpers";

type SurveyContainerProps = {
  unitRes: MyUnitRes;
  course: Course;
  sections: Section[];
  isPublic?: boolean;
};

const SurveyContainer: FC<SurveyContainerProps> = (props) => {
  const { unitRes, course, sections, isPublic = false } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const unit = unitRes._data as SurveyUnit;
  const meta = unitRes._meta;
  const { content } = unit;
  const { total_questions } = meta;
  const hasZeroQuestions = total_questions === 0;
  const unitId = unit.id.toString();
  const isCompleted = unit.progress?.status === "completed";
  const flatUnits = sections ? getFlatUnits(sections) : [];
  const { nextUnit } = getPrevAndNextUnits(flatUnits, unit?.id) || {};
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);
  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    false,
    course.progress?.completion_status,
    unit.progress?.status,
  );

  const { mutate, isLoading } = useMutation(
    [queryKeys.unitStart, { unitId: unitId }],
    () => postUnitStart(unitId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.unit, unitId]);
      },
    },
  );

  const onComplete = (): void => {
    if (!isCompleted) {
      mutate();
    } else {
      const nextUnitLink = goToNextUnitLink(false, nextUnit, course, isPublic);
      navigate(nextUnitLink);
    }
  };

  return (
    <>
      <article className="unit-wrapper">
        {isCompleted ? (
          <CompletedSplashScreen unitRes={unitRes} isPublic={isPublic} />
        ) : (
          <SplashScreen content={content} total_questions={total_questions} />
        )}
      </article>

      {/* if the next unit has delayed availability */}
      {!isNextUnitAvailable && isCompleted && <AvailabilityTimeLimitBar nextUnit={nextUnit} />}

      <FooterWrapper>
        <UnitFooterNavigationButtons
          unit={unit}
          course={course}
          sections={sections}
          isPublic={isPublic}
        >
          <FooterButton
            unit={unit}
            name="Start survey"
            isLoading={isCompleted ? false : isLoading}
            disabled={continueButtonDisabled || hasZeroQuestions}
            onClick={onComplete}
            buttonText={!isCompleted ? t("survey.startSurvey") : ""}
          />
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default SurveyContainer;

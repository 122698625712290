import React, { FC, useState } from "react";
import { t } from "i18next";
import Select, {
  components,
  DropdownIndicatorProps,
  MenuListProps,
  SingleValue,
} from "react-select";
import { CSSObject } from "@emotion/react";
import {
  ClearIndicatorStyles,
  ControlStyles,
  DropdownIndicatorStyles,
  OptionStyles,
  PlaceholderStyles,
  SingleValueStyles,
  ValueContainerStyles,
} from "../styles";
import MatchPairsMenuDropdown from "./MatchPairsMenuDropdown";

export type AnswerOption = {
  value: string;
  label: string;
};

type MatchPairsProps = {
  index: number;
  leftPair: string;
  answeredQuestions: string[];
  options: AnswerOption[];
  handleChange: (val: SingleValue<AnswerOption>, index: number) => void;
  handleClear: (index: number) => void;
  moveToAnswersPool: (leftPair: string, index: number, answerIndex: number) => void;
};

const MatchPairs: FC<MatchPairsProps> = (props) => {
  const {
    index,
    leftPair,
    answeredQuestions,
    options,
    handleChange,
    moveToAnswersPool,
    handleClear,
  } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const customStyles = {
    control: (base: CSSObject): CSSObject => ControlStyles({ base, isMenuOpen }),
    placeholder: (base: CSSObject): CSSObject => PlaceholderStyles({ base, isMenuOpen }),
    option: (base: CSSObject): CSSObject => OptionStyles({ base }),
    singleValue: (base: CSSObject): CSSObject => SingleValueStyles({ base, isMenuOpen }),
    valueContainer: (base: CSSObject): CSSObject => ValueContainerStyles({ base }),
    clearIndicator: (base: CSSObject): CSSObject => ClearIndicatorStyles({ base, isMenuOpen }),
    dropdownIndicator: (base: CSSObject): CSSObject =>
      DropdownIndicatorStyles({ base, isMenuOpen }),
  };

  const CustomDropdownIndicator = (
    props: DropdownIndicatorProps<AnswerOption>,
  ): JSX.Element | null => {
    if (props.hasValue) {
      return null;
    }
    return <components.DropdownIndicator {...props} />;
  };

  return (
    <div key={`pairs-container-${index}`} className="pairs-container">
      <div className="left-pair-container">
        <div id={`left-pair-${index}`} key={`left-pair-${index}`} className="pair">
          {leftPair}
        </div>
      </div>

      <div className="right-pair-container">
        <Select
          classNamePrefix="react-select"
          className={`${isMenuOpen ? "select-container-open" : "select-container"}`}
          closeMenuOnSelect
          isClearable={true}
          isSearchable={false}
          value={
            answeredQuestions[index]
              ? {
                  label: answeredQuestions[index],
                  value: answeredQuestions[index],
                }
              : null
          }
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: CustomDropdownIndicator,
            MenuList: (props: MenuListProps<AnswerOption>) => (
              <MatchPairsMenuDropdown
                answeredQuestions={answeredQuestions}
                moveToAnswersPool={moveToAnswersPool}
                setIsMenuOpen={setIsMenuOpen}
                answerIndex={index}
                {...props}
              />
            ),
          }}
          options={options}
          placeholder={t("test.selectAnAnswer")}
          styles={customStyles}
          onChange={(val, { action }): void => {
            setIsMenuOpen(false);

            action === "clear"
              ? handleClear(index)
              : handleChange(val as SingleValue<AnswerOption>, index);
          }}
          onMenuOpen={(): void => setIsMenuOpen(true)}
          onMenuClose={(): void => setIsMenuOpen(false)}
          noOptionsMessage={(): string => t("test.availableAnswersUsed")}
          menuIsOpen={isMenuOpen}
        />
      </div>
    </div>
  );
};

export default MatchPairs;

import {
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
  parseISO,
  intervalToDuration,
  addSeconds,
  getYear,
} from "date-fns";
import { utcToZonedTime, toDate, formatInTimeZone } from "date-fns-tz";
import localStorageKeys from "@constants/localStorageKeys";
import { useConfigurationStore } from "@stores";
import { t } from "i18next";
import { Availability } from "types/entities";
import locales from "@constants/locales";

const { getState } = useConfigurationStore;

export const secondsToTime = (
  seconds: number,
  isHumanReadable = false,
  showSeconds = true,
  padZeroes = 2, // Adds zeroes to the left, so we reach max length(padZeroes). eg. padZeroes = 2, 5s -> 05s
): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const { years, months, days, hours, minutes, seconds: secs } = duration;
  const showNoSeconds = !isHumanReadable && !secs ? "00" : "";
  const showNoMinutes = !isHumanReadable && !minutes && hours ? "00:" : "";

  if (!seconds) return "";

  const yearsText = years ? `${years}${isHumanReadable ? "y " : ":"}` : "";
  const monthsText = months ? `${months}${isHumanReadable ? "m " : ":"}` : "";
  const daysText = days ? `${days}${isHumanReadable ? "d " : ":"}` : "";
  const hoursText = hours ? `${hours}${isHumanReadable ? "h " : ":"}` : "";
  const minutesText = minutes
    ? `${minutes.toString().padStart(padZeroes, "0")}${
        isHumanReadable ? "m " : showSeconds ? ":" : ""
      }`
    : "";
  const secondsText =
    showSeconds && secs
      ? `${secs.toString().padStart(padZeroes, "0")}${isHumanReadable ? "s " : ""}`
      : "";
  return `${yearsText}${monthsText}${daysText}${hoursText}${minutesText}${showNoMinutes}${secondsText}${showNoSeconds}`;
};

export const datesDifferenceInSeconds = (startDate: string, endDate = new Date()): number => {
  return differenceInSeconds(parseISO(startDate), endDate);
};

export const getLocale = (): Locale => {
  const localStorageLanguage = localStorage.getItem(localStorageKeys.LANGUAGE_LOCALE);
  return locales[localStorageLanguage ?? "en-US"];
};

type DateFormatOption = "date" | "time" | "datetime" | "datetimeWithSeconds";

export const dateFormatsMapping = {
  DDMMYYYY: "dd/MM/yyyy",
  MMDDYYYY: "MM/dd/yyyy",
  YYYYMMDD: "yyyy/MM/dd",
};

const mexicoCities = ["America/Mexico_City", "America/Monterrey"];

//TODO:Revisit when date-fns-tz is updated. Temporary helper to fix Mexico timezone issue
const incrementHour = (date: string | Date): string => {
  const dateTime = new Date(date);
  dateTime.setHours(dateTime.getHours() + 1);

  return dateTime.toISOString();
};

export const formatUtcDate = (
  dateInUtc: string,
  dateFormatOption: DateFormatOption = "date",
): string => {
  const { userProfileData, domainSettings } = getState();

  if (domainSettings) {
    const { timezone: userTimezone } = userProfileData ?? {};
    const { date_format, time_format, timezone: domainTimezone } = domainSettings;

    // If the user is not authenticated use the domain timezone
    const timezone = userTimezone ?? domainTimezone;
    const dateWithTimezone = timeZonedDate(dateInUtc, timezone).toISOString();
    const formatTemplate = dateFormatsMapping[date_format];

    if (dateFormatOption === "date") {
      return format(parseISO(dateWithTimezone), formatTemplate, { locale: getLocale() });
    }

    if (dateFormatOption === "datetimeWithSeconds") {
      const timeFormatInSeconds = `${time_format}:mm:ss ${time_format === "hh" ? "a" : ""}`;
      const formatString = `${formatTemplate}, ${timeFormatInSeconds}`;

      return format(parseISO(dateWithTimezone), formatString, { locale: getLocale() });
    }

    const timeFormat = `${time_format}:mm ${time_format === "hh" ? "a" : ""}`;
    const formatString =
      dateFormatOption === "time" ? timeFormat : `${formatTemplate}, ${timeFormat}`;

    return format(parseISO(dateWithTimezone), formatString, { locale: getLocale() });
  }
  return dateInUtc;
};

export const getDistanceFromNowInSeconds = (date: Date): number => {
  const distance = formatDistanceToNowStrict(date, { unit: "second" });
  return Number(distance.replace("seconds", "").trim());
};

export const getDurationDateFromSeconds = (seconds: number, date: Date = new Date()): Duration => {
  const startDate = timeZonedDate(date);
  const endDate = timeZonedDate(addSeconds(date, seconds));
  const duration = intervalToDuration({ start: startDate, end: endDate });

  return duration;
};

export const timeZonedDate = (date: string | Date, timezone = "UTC"): Date => {
  const correctDate = mexicoCities.includes(timezone) ? incrementHour(date) : date;
  return parseISO(utcToZonedTime(correctDate, timezone).toISOString());
};

export const getDistanceFromNow = (date: string): string => {
  const inputDate = new Date(date);
  const now = new Date();
  const isInTheFuture = inputDate.getTime() > now.getTime();

  const today = Date.parse(new Date().toISOString());
  const {
    years = 0,
    months = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({ start: Date.parse(date), end: today });

  if (years > 0 || months > 0 || isInTheFuture) {
    return formatUtcDate(date);
  }

  const displayJustNow = days === 0 && hours === 0 && minutes === 0 && seconds <= 59;
  const displayMinutesAgo = days === 0 && hours === 0 && minutes <= 59;
  const displayHoursAgo = days === 0 && hours <= 23 && minutes <= 59;
  const displayYesterday = days === 1 && hours <= 23;

  if (displayJustNow) {
    return t("general.justNow");
  } else if (displayMinutesAgo) {
    return t("general.minute", { count: minutes });
  } else if (displayHoursAgo) {
    return t("general.hour", { count: hours });
  } else if (displayYesterday) {
    return t("general.yesterday");
  }

  return formatUtcDate(date);
};

export const getYearsPickerRange = (date: Date, yearItemNumber = 12): string => {
  const endPeriod = Math.ceil(getYear(date) / yearItemNumber) * yearItemNumber;
  const startPeriod = endPeriod - (yearItemNumber - 1);
  return `${startPeriod} - ${endPeriod}`;
};

export const getDelayInSeconds = (availability: Availability | null): number => {
  // (not enrolled OR enrolled) course without delayed availability
  if (!availability || !availability.delay) return 0;

  // enrolled course with delayed availability
  if (availability.available_on) {
    return Number(getDistanceFromNowInSeconds(toDate(availability.available_on)));
  }

  // not enrolled course with delayed availability
  return availability.delay;
};

export const getDomainDateFormat = (): string => {
  const { domainSettings } = getState();
  const domainDateFormat = domainSettings?.date_format ?? "DDMMYYYY";

  return dateFormatsMapping[domainDateFormat];
};

export const formatDateToUTCstring = (date: Date): string => {
  return formatInTimeZone(date, "Etc/UTC", "yyyy-MM-dd'T'HH:mm:ssxxx");
};

export const formatToISOstring = (date: Date): string => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ssxxx");
};

export const getDomainTimeFormat = (): string => {
  const { domainSettings } = getState();
  const domainTimeFormat = domainSettings?.time_format ?? "HH";

  return `${domainTimeFormat}:mm ${domainTimeFormat === "hh" ? "a" : ""}`;
};

export const getDomainDateFormatWithoutYears = (): string => {
  const { domainSettings } = getState();
  const domainDateFormat = domainSettings?.date_format ?? "DDMMYYYY";

  return domainDateFormat === "DDMMYYYY" ? "dd/MM" : "MM/dd";
};

import React, { FC, useState } from "react";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SpecialZoomLevel, TextDirection, Viewer, Plugin } from "@react-pdf-viewer/core";
import { SerializedStyles } from "@emotion/react";
import PdfViewerToolbar from "./components/PdfViewerToolbar";
import { documentReview } from "./styles";
import { ScreenCollapseSVG } from "@assets/icons";
import { Button } from "@epignosis_llc/gnosis";
// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

type PdfViewerProps = {
  fileUrl: string;
  isRTL?: boolean;
  defaultScale?: number | SpecialZoomLevel;
};

const PdfViewer: FC<PdfViewerProps> = ({
  fileUrl,
  isRTL = false,
  defaultScale = SpecialZoomLevel.PageWidth,
}) => {
  const toolbarPluginInstance = toolbarPlugin({
    fullScreenPlugin: {
      renderExitFullScreenButton: (props) => (
        <div className="exit-fullscreen">
          <Button className="action-btn" variant="link" noGutters onClick={props.onClick}>
            <ScreenCollapseSVG width={32} />
          </Button>
        </div>
      ),
    },
  });
  const [zoomApplied, setZoomApplied] = useState(false);
  const [documentLoaded, setDocumentLoaded] = useState(false);

  // applied the default scale zoom
  const onZoomChange = (): void => {
    setZoomApplied(true);
  };

  const onDocumentLoad = (): void => {
    if (defaultScale === SpecialZoomLevel.PageFit) {
      setDocumentLoaded(true);
    }
  };

  return (
    <div css={(theme): SerializedStyles => documentReview(theme, zoomApplied, documentLoaded)}>
      <PdfViewerToolbar toolbarPluginInstance={toolbarPluginInstance} />

      <div className="viewer-container">
        <Viewer
          fileUrl={fileUrl}
          defaultScale={defaultScale}
          plugins={[toolbarPluginInstance as Plugin]}
          theme={{
            direction: isRTL ? TextDirection.RightToLeft : TextDirection.LeftToRight,
          }}
          onZoom={onZoomChange}
          onDocumentLoad={onDocumentLoad}
        />
      </div>
    </div>
  );
};

export default PdfViewer;

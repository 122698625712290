import { css } from "@emotion/react";

export const completionScreenContainer = ({ units }) => css`
  .headers-wrapper {
    text-align: center;
    margin-top: 2rem;

    h2 {
      margin-bottom: 0.5rem;
    }
  }

  .achievements-container {
    margin-top: 2rem;
    display: flex;
    justify-content: center;

    .icon-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background: ${units.completedScreen.background};
      border-radius: 15px;
      padding: 0.5rem;
      width: 118px;

      svg {
        margin-bottom: 0.25rem;
      }

      a {
        font-weight: 700;
      }
    }
  }

  .buttons-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
      margin-top: 1rem;
      margin-inline-end: 1rem;

      &:last-of-type {
        margin-inline-end: 0;
      }

      svg {
        fill: currentColor;
      }
    }
  }
`;

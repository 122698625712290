import React, { FC, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { t } from "i18next";
import { SingleValue } from "react-select";
import { AxiosError } from "axios";
import { URLS } from "@constants/urls";
import { Branch, UserProfile } from "types/entities";
import authService from "@utils/services/AuthService";
import { parseAccessToken } from "@utils/helpers";
import { postBranchSwitch } from "@api/branch";
import queryKeys from "@constants/queryKeys";
import AutocompleteInput, {
  Option,
} from "@components/FormElements/AutocompleteInput/AutocompleteInput";
import { useConfigurationStore, useUIStore } from "@stores";
import { handleBranchSwitchErrors } from "@errors";

type BranchesSelectProps = {
  branches: Branch[];
};

const getFormattedBranches = (
  branches: Branch[],
  userProfileData: UserProfile | null,
  loggedInBranchId: number,
): Option[] => {
  const formattedBranches = branches.map((branch) => ({
    label: branch.name,
    value: branch.id.toString(),
  }));

  // is not logged in and can access the main portal
  if (loggedInBranchId !== 0 && userProfileData?.main_portal_access) {
    formattedBranches.unshift({ label: t("myBranches.mainPortal"), value: "main" });
  }

  return formattedBranches;
};

const onMenuOpen = (): void => {
  setTimeout(() => {
    const firstOption = document.querySelectorAll(".autocomplete-input-menu-list div")[0];

    if (firstOption) {
      firstOption.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
    }
  }, 100);
};

export const formatOptionLabel = (option: Option): JSX.Element => {
  const { label, value } = option;

  // show main portal bold
  return value === "main" ? <strong>{label}</strong> : <span>{label}</span>;
};

const BranchesSelect: FC<BranchesSelectProps> = ({ branches }) => {
  const queryClient = useQueryClient();
  const { userProfileData } = useConfigurationStore();
  const { hideMainDrawer } = useUIStore((state) => state);

  const token = useMemo(() => authService.getAccessToken(), []);
  const decodeToken = token ? parseAccessToken(token) : null;
  const loggedInBranchId = decodeToken?.sub?.branch_id ?? 0;
  const role = authService.getDefaultRole();

  const branchesOptions = getFormattedBranches(branches ?? [], userProfileData, loggedInBranchId);
  const selectedBranch =
    loggedInBranchId === 0
      ? null
      : branchesOptions.find((option) => option.value === loggedInBranchId.toString()) ?? null;

  const handleOptionChange = (option: SingleValue<Option>): void => {
    const selectedBranch = option?.value;

    if (selectedBranch && selectedBranch !== loggedInBranchId.toString()) {
      switchBranchtMutation(selectedBranch);
    }
  };

  const { mutate: switchBranchtMutation, isLoading: switchBranchtMutationLoading } = useMutation(
    [queryKeys.branchSwitch],
    (branchId: string) => postBranchSwitch(branchId),
    {
      onSuccess: (res) => {
        const { domain, token } = res._data;
        hideMainDrawer();
        queryClient.clear();
        authService.removeTokens();
        window.location.replace(
          `https://${domain}/plus${URLS.autologin}?token=${token}&role=${role}`,
        );
      },
      onError: (error: AxiosError) => {
        handleBranchSwitchErrors(error);
      },
    },
  );

  return (
    <section>
      <AutocompleteInput
        label={t("myProfile.myBranches")}
        optionsArray={branchesOptions}
        value={selectedBranch}
        placeholder={t("myBranches.selectBranch")}
        noOptionsMessage={t("general.noMatchesFound")}
        isDisabled={switchBranchtMutationLoading}
        isLoading={switchBranchtMutationLoading}
        maxMenuHeight={190}
        formatOptionLabel={formatOptionLabel}
        onChange={(option): void => handleOptionChange(option as SingleValue<Option>)}
        onMenuOpen={onMenuOpen}
      />
    </section>
  );
};

export default BranchesSelect;

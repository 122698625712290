import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { useResponsive } from "ahooks";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { externalHeaderContainer } from "./styles";
import { URLS } from "@constants/urls";
import { useConfigurationStore, useUIStore } from "@stores";
import { BrandLogoSVG } from "@assets/images";
import { LoginSVG } from "@assets/icons";
import localStorageKeys from "@constants/localStorageKeys";

const getRedirectUrl = (location: string, courseId: string | undefined): string => {
  switch (location) {
    case "/external-catalog":
      return URLS.catalog.index;

    default:
      return courseId ? URLS.catalog.createCourseLink({ courseId: courseId }) : "";
  }
};

const ExternalHeader: FC = () => {
  const { sm } = useResponsive();
  const { setShowSignInModal, setShowSignUpModal } = useUIStore();
  const { domainSettings } = useConfigurationStore();
  const { courseId } = useParams();
  const logo = domainSettings?.logo?.default;
  const isSignUpEnabled = Boolean(domainSettings?.signup.enabled);

  const handleButtonClick = (type: "signIn" | "signUp"): void => {
    const redirectUrl = getRedirectUrl(window.location.pathname, courseId);

    type === "signIn" ? setShowSignInModal(true) : setShowSignUpModal(true);

    localStorage.setItem(
      localStorageKeys.EXTERNAL_SIGNIN_SIGNUP,
      JSON.stringify({
        redirectUrl: redirectUrl,
      }),
    );
  };

  return (
    <header css={externalHeaderContainer}>
      <Link to={URLS.login} className="logo">
        {logo ? <img src={logo} /> : <BrandLogoSVG height={sm ? 28 : 20} />}
      </Link>

      <div className="action-buttons">
        {isSignUpEnabled && (
          <Button variant="link" noGutters onClick={(): void => handleButtonClick("signUp")}>
            {t("signUp.signUp")}
          </Button>
        )}

        <Button
          iconAfter={LoginSVG}
          variant="link"
          className="login-btn"
          onClick={(): void => handleButtonClick("signIn")}
        >
          {t("signIn.login")}
        </Button>
      </div>
    </header>
  );
};

export default ExternalHeader;

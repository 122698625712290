import { Radio, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import React, { FC, useState, useEffect } from "react";
import { likertScaleContainer } from "./styles";
import { LikertScaleAnswer, LikertScaleChildren } from "types/entities/Question";
import i18n from "@utils/i18n";

type LikertScaleType = {
  radioId: number;
  options: LikertScaleChildren;
  hasCustomAnswers: boolean;
  userAnswers: LikertScaleAnswer[];
  onAnswersChange: (answers: LikertScaleAnswer[]) => void;
};

const getRadioButtonChecked = (
  userAnswers: LikertScaleAnswer[],
  radioId: number,
  selectedAnswer: string,
  answerIndex: number,
  selectedAnswerIndex: number,
): boolean => {
  return userAnswers.some((answer) => {
    return (
      answer.id === radioId &&
      answer.user_answer === selectedAnswer &&
      selectedAnswerIndex === answerIndex
    );
  });
};

const LikertScale: FC<LikertScaleType> = ({
  radioId,
  options,
  hasCustomAnswers,
  onAnswersChange,
  userAnswers,
  ...rest
}) => {
  const { md } = useResponsive();
  const optionsToMap = options.answers.possible;
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(-1);
  const dir = i18n.dir();

  //The initial mapping of the answers
  useEffect(() => {
    if (selectedAnswerIndex == -1 && userAnswers.length) {
      const initialAnswerIndex = optionsToMap.findIndex((option) =>
        userAnswers.some((value) => value.user_answer === option && value.id === radioId),
      );
      setSelectedAnswerIndex(initialAnswerIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers]);

  const handleRadioButtonChange = (value: string, index: number): void => {
    const foundAnswerIndex = userAnswers.findIndex((answer) => answer.id === radioId);

    const newUserAnswers =
      foundAnswerIndex === -1
        ? userAnswers.concat({
            id: radioId,
            user_answer: value,
          })
        : [
            ...userAnswers.slice(0, foundAnswerIndex),
            {
              ...userAnswers[foundAnswerIndex],
              user_answer: value,
            },
            ...userAnswers.slice(foundAnswerIndex + 1),
          ];

    setSelectedAnswerIndex(index);

    onAnswersChange([...newUserAnswers]);
  };

  return (
    <ul css={likertScaleContainer(dir)} {...rest}>
      {optionsToMap.map((option, index) => {
        const answer = hasCustomAnswers ? option : t(`survey.predefinedAnswers.${option}`);
        return (
          <li key={index} className="likert-option">
            <Radio
              id={`${radioId.toString()}-${index}`}
              label={!md ? answer : ""}
              value={option}
              name={`${radioId}-${index}`}
              checked={getRadioButtonChecked(
                userAnswers,
                radioId,
                option,
                index,
                selectedAnswerIndex,
              )}
              onChange={(): void => handleRadioButtonChange(option, index)}
            />
            {md && (
              <Text as="div" fontSize="sm" className="option-label">
                {answer}
              </Text>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default LikertScale;

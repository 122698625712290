import React, { FC } from "react";
import { useQuery } from "react-query";
import { Heading } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { courseFilesContainer } from "./styles";
import { getCourseFiles } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { buildPaginatedSearchQuery } from "@utils/helpers";
import { Pagination as IPagination } from "types/responses/index";
import { ValidFileSortingField } from "types/entities";
import Skeletons from "@components/Skeleton/Skeleton";
import { FileList } from "@components/ReusableComponents";
import { getCatalogCourseFiles } from "@api/catalog";

type CourseFilesWidgetProps = {
  courseId: number;
  isCatalogCourseView?: boolean;
};

const PAGINATION: IPagination = { number: 1, size: 5 };
const SORTING: ValidFileSortingField[] = ["uploaded_at"];

const CourseFilesWidget: FC<CourseFilesWidgetProps> = ({
  courseId,
  isCatalogCourseView = false,
}) => {
  const filesSearchQuery = buildPaginatedSearchQuery({
    pagination: {
      number: PAGINATION.number,
      size: PAGINATION.size,
    },
    sorting: SORTING,
  });

  const {
    data: files,
    status,
    error,
  } = useQuery(
    [queryKeys.myFiles, courseId, filesSearchQuery],
    () =>
      isCatalogCourseView
        ? getCatalogCourseFiles(courseId.toString(), filesSearchQuery)
        : getCourseFiles(courseId.toString(), filesSearchQuery),
    {
      select: (files) => ({
        data: files._data,
        pagination: files._meta?.pagination,
      }),
    },
  );

  const showAllFiles = files?.pagination && files.pagination?.total_results > PAGINATION.size;

  return (
    <section css={courseFilesContainer} className="section-wrapper">
      <Skeletons.Loader status={status} error={error}>
        {files?.data && files.data.length > 0 && (
          <>
            <Heading className="section-title">{t("general.filesUpper")}</Heading>

            <FileList
              files={files.data}
              showAllFiles={showAllFiles}
              courseId={courseId.toString()}
              isCatalogCourseView={isCatalogCourseView}
            />
          </>
        )}
      </Skeletons.Loader>
    </section>
  );
};

export default CourseFilesWidget;

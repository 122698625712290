import { Heading } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import React, { FC } from "react";
import { Badge } from "types/entities";
import { badgeCategoryContainer } from "./styles";
import { BadgeFigure } from "@components/ReusableComponents";

type BadgeCategoryProps = {
  title: string;
  badges: Badge[];
};

const BadgeCategory: FC<BadgeCategoryProps> = ({ title, badges }) => {
  const hasBagdesCategory = badges.some((badge) => badge.type === title);

  return (
    <>
      {hasBagdesCategory && (
        <span css={badgeCategoryContainer}>
          <Heading as="h2" size="md" className="header-text">
            {t(`badges.${title}`)}
          </Heading>
          <div className="badges-container">
            {badges
              .filter((badge) => badge.type === title)
              .map((badge) => (
                <span key={badge.id}>
                  <BadgeFigure
                    type={badge.type}
                    image={badge.image}
                    awarded={badge.awarded}
                    name={badge.name}
                    criteria={badge.criteria}
                  />
                </span>
              ))}
          </div>
        </span>
      )}
    </>
  );
};

export default BadgeCategory;

import { ENDPOINTS } from "@api/endpoints";
import HttpClient from "@api/HttpClient";
import { PublicCourse } from "./types";

type PublicCourseRes = {
  _data: PublicCourse;
};

export const getPublicCourse = async (key: string): Promise<PublicCourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.catalog.publicCourse(key));

  return res.data;
};

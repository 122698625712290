import React, { FC } from "react";
import { t } from "i18next";
import { Result } from "@epignosis_llc/gnosis";
import FillTheGapContainer, { FillTheGapContainerProps } from "../FillTheGapContainer";
import FreeTextContainer from "../FreeTextContainer";
import MultipleChoiceContainer from "../MultipleChoiceContainer";
import OrderingContainer from "../OrderingContainer";
import MatchPairsContainer from "../MatchPairsContainer";
import LikertScaleContainer from "../LikertScaleContainer";
import { UnitUnsupportedSVG } from "@assets/images";
import { Question, QuestionAnswers, QuestionType } from "types/entities";
import { LikertScaleAnswer } from "types/entities/Question";

type QuestionsContainerProps = {
  questionEntry: Question;
  userAnswers: QuestionAnswers;
  onAnswersChange: (answers: QuestionAnswers) => void;
};

const QuestionsContainer: FC<QuestionsContainerProps> = (props) => {
  const { questionEntry, userAnswers, onAnswersChange } = props;

  return (
    <div className="answers-container">
      {questionEntry.type === QuestionType.MultipleChoice && (
        <MultipleChoiceContainer
          questionEntry={questionEntry}
          userAnswers={userAnswers as string[]}
          onAnswersChange={onAnswersChange}
        />
      )}

      {questionEntry.type === QuestionType.Freetext && (
        <FreeTextContainer
          questionEntry={questionEntry}
          userAnswers={userAnswers as string[]}
          onAnswersChange={onAnswersChange}
        />
      )}

      {questionEntry.type === QuestionType.Ordering && (
        <OrderingContainer
          questionEntry={questionEntry}
          onAnswersChange={onAnswersChange}
          userAnswers={userAnswers as string[]}
        />
      )}

      {questionEntry.type === QuestionType.FillTheGap && (
        <FillTheGapContainer
          questionEntry={questionEntry}
          userAnswers={userAnswers as FillTheGapContainerProps["userAnswers"]}
          onAnswersChange={onAnswersChange}
        />
      )}

      {questionEntry.type === QuestionType.DragAndDrop && (
        <MatchPairsContainer
          questionEntry={questionEntry}
          userAnswers={userAnswers}
          onAnswersChange={onAnswersChange}
        />
      )}

      {questionEntry.type === QuestionType.LikertScale && (
        <LikertScaleContainer
          questionEntry={questionEntry}
          userAnswers={userAnswers as LikertScaleAnswer[]}
          onAnswersChange={onAnswersChange}
        />
      )}

      {!Object.values(QuestionType).includes(questionEntry.type) && (
        <Result
          icon={UnitUnsupportedSVG}
          title={t("test.unsupportedUnit")}
          info={t("test.unitUnderConstruction")}
        />
      )}
    </div>
  );
};

export default QuestionsContainer;

import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  background: string;
  progressBackground: string;
  percentColor: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  background: colors.secondary.lighter,
  progressBackground: colors.green.base,
  percentColor: colors.white,
});

import React, { useState } from "react";
import { t } from "i18next";
import { Text, Loader } from "@epignosis_llc/gnosis";
import { AttachmentSVG } from "@epignosis_llc/gnosis/icons";
import { DragAndDropArea, FileInput } from "@components";
import { errorNotification } from "@utils/helpers";
import { UseMutateFunction } from "react-query";
import { FileRes } from "types/responses";
import { ActionDrawerContainer } from "./styles";
import { SerializedStyles } from "@emotion/react";
import { IconType } from "types/common";
import {
  DEFAULT_VALIDATION_TYPES,
  ValidationType,
  getExtraValidExtensions,
  getFilesValidation,
} from "./helpers";

const MAX_ALLOWED_FILES = 100;

type FileUploadAreaProps = {
  title: string;
  subtitle?: string;
  icon?: IconType;
  isLoading: boolean;
  postFileMutation: UseMutateFunction<
    FileRes,
    unknown,
    { file: File; isLastFile: boolean },
    unknown
  >;
  validationTypes?: ValidationType[];
};

const FileUploadArea = (props: FileUploadAreaProps): JSX.Element => {
  const {
    title,
    subtitle = t("files.dragAndDrop"),
    icon: Icon = AttachmentSVG,
    isLoading,
    postFileMutation,
    validationTypes = DEFAULT_VALIDATION_TYPES,
  } = props;
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const mimeTypeAndFilesizeValidations = getFilesValidation(validationTypes);
  const extraValidExtensions = getExtraValidExtensions(validationTypes);

  const handleFilesChanged = (files: File[]): void => {
    setDroppedAttachments(null);

    files.forEach((file, index) => {
      //checking the last file to be uploaded so we can toggleDrawer on success.
      const isLastFile = index + 1 === files.length;
      postFileMutation({ file, isLastFile });
    });
  };

  const handleFilesDrop = (files: FileList): void => {
    setDroppedAttachments(files);
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  return (
    <span css={(theme): SerializedStyles => ActionDrawerContainer(theme)}>
      {!isLoading ? (
        <DragAndDropArea
          maxFiles={MAX_ALLOWED_FILES}
          mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
          onFilesDrop={handleFilesDrop}
          className="upload-prompt-area"
        >
          <FileInput
            id="upload-course-file"
            name="files"
            maxFiles={MAX_ALLOWED_FILES}
            mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
            acceptedFileExtensions={extraValidExtensions}
            addedFiles={droppedAttachments}
            onFileError={handleFileError}
            onFilesChange={handleFilesChanged}
          >
            <div className="upload-prompt">
              <div className="add-content-icon">
                <Icon height={36} />
              </div>
              <Text fontSize="md" weight="400" className="add-files">
                {title}
              </Text>

              {subtitle?.length && (
                <Text
                  fontSize="sm"
                  dangerouslySetInnerHTML={{
                    __html: subtitle,
                  }}
                  className="add-files-subtitle"
                />
              )}
            </div>
          </FileInput>
        </DragAndDropArea>
      ) : (
        <div className="upload-prompt-area">
          <div className="loading-wrapper">
            <Text fontSize="md" weight="700">
              {t("general.uploadingFile", { count: droppedAttachments?.length ?? 0 })}
            </Text>
            <Loader />
          </div>
        </div>
      )}
    </span>
  );
};

export default FileUploadArea;

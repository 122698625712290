import React, { FC, useEffect } from "react";
import { Result, Table, TableProps } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { PaginationProps, TableStatus } from "./types";
import { tableContainer } from "./styles";
import PaginationWithOptions from "@components/Pagination/PaginationWithOptions";
import Skeletons from "@components/Skeleton/Skeleton";
import useUIStore from "@stores/ui";

export type CustomTableProps = {
  tableProps: TableProps;
  tableStatus?: TableStatus;
  paginationProps?: PaginationProps;
  headerElements?: JSX.Element;
  selectable?: boolean;
};

const CustomTable: FC<CustomTableProps> = ({
  tableProps,
  tableStatus,
  paginationProps,
  headerElements,
}) => {
  const { sm } = useResponsive();
  const { status = "success", error = null } = tableStatus ?? {};
  const { setShouldShowConfirmationModal } = useUIStore();

  // Remove don't ask again setting on unmount
  useEffect(() => {
    return () => {
      setShouldShowConfirmationModal(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={tableContainer}>
      {headerElements && headerElements}

      <Skeletons.Loader
        status={status}
        error={error}
        loader={
          <div>
            {sm ? (
              <Skeletons.Table />
            ) : (
              Array.from({ length: 5 }, (_, index) => <Skeletons.ListItem key={index} />)
            )}
          </div>
        }
      >
        {tableProps.rows.length > 0 ? (
          <div>
            <Table {...tableProps} />
            {paginationProps && paginationProps.paginationRes && (
              <PaginationWithOptions {...paginationProps.paginationRes} {...paginationProps} />
            )}
          </div>
        ) : (
          <Result {...tableProps.emptyState} />
        )}
      </Skeletons.Loader>
    </div>
  );
};

export default CustomTable;

import { css } from "@emotion/react";

export const audioPlayerContainer = (hasUrlAndContent, hasUrlAndSecondaryContent) => css`
  position: relative;

  .content {
    padding-bottom: ${hasUrlAndContent ? "2rem" : "0"};
    word-break: break-word;
  }

  .secondary-content {
    padding-top: ${hasUrlAndSecondaryContent ? "2rem" : "0"};
    word-break: break-word;
  }

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .processing {
    top: 45%;
  }
  .subtitle {
    top: 52%;
    white-space: nowrap;
    overflow-wrap: break-word;
  }
`;

import React, { FC, MouseEvent } from "react";
import { t } from "i18next";
import { DownloadIconSVG } from "@assets/icons";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";

export type DownloadActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => Promise<void> | void;
};

const DownloadAction: FC<DownloadActionProps> = ({
  tooltipContent,
  icon: Icon = DownloadIconSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.actions.download")}
      onClick={onClick}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default DownloadAction;

import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient, useMutation } from "react-query";
import { SubmitHandler } from "react-hook-form";
import { Text } from "@epignosis_llc/gnosis";
import VideoRecording from "./components/AudioVideoScreenRecording/VideoRecording";
import AudioRecording from "./components/AudioVideoScreenRecording/AudioRecording";
import ScreenRecording from "./components/AudioVideoScreenRecording/ScreenRecording";
import PreviewModifySubmittedAnswer from "./components/PreviewModifySubmittedAnswer";
import FreeTextAnswer from "./components/FreeTextAnswer";
import FileUploadAnswer from "./components/FileUploadAnswer";
import ReplyOptions from "./components/ReplyOptions";
import Answer from "./components/Answer";
import InstructorReply from "./components/InstructorReply";
import { assignmentContainer } from "./styles";
import { MyUnit } from "types/entities";
import { postAssignmentUnit } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { showCompleteUnitNotification } from "@utils/helpers";
import permissions from "@utils/permissions";

export type Answer = "text" | "file" | "video-upload" | "audio-upload" | "screen-record";

export type FormValues = Partial<{
  text: string;
  file_id: number;
}>;

type AssignmentProps = {
  unit: MyUnit;
  isSampleUnit: boolean;
  isPublic?: boolean;
};

const Assignment: FC<AssignmentProps> = (props) => {
  const { unit, isSampleUnit, isPublic = false } = props;
  const { progress, content } = unit;
  const queryClient = useQueryClient();
  const { courseId, unitId } = useParams() as { courseId: string; unitId: string };
  const [selectAnswerType, setSelectAnswerType] = useState<Answer | null>(null);
  const [showOptions, setShowOptions] = useState(false);
  const hasAnswerSubmission = Boolean(progress?.submission);
  const assignmentIsCompleted = progress?.status === "completed" || progress?.status === "failed";
  const canModifyAnswer = progress?.status !== "completed";

  // permissions related
  const { canCompleteCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsComplete = isPublic ? true : canCompleteCourseUnits();

  const { mutateAsync, isLoading: isLoadingAssignment } = useMutation(
    [queryKeys.assignmentUnit.freeTextReply, { courseId, unitId }],
    (data: FormValues) => postAssignmentUnit(unitId, data),
  );

  const onSubmit: SubmitHandler<FormValues> = (data) =>
    mutateAsync(data, {
      onSettled: () => {
        showOptions && setShowOptions(false);
      },
      onError: () => {
        showCompleteUnitNotification(unit, "error");
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([queryKeys.unit, unitId.toString()]);
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        setSelectAnswerType(null);
      },
    });

  return (
    <article css={assignmentContainer}>
      <Text
        fontSize="lg"
        as="div"
        className="assignment-description"
        dangerouslySetInnerHTML={{ __html: content ?? "" }}
      />

      {/* if unit is sample do not render the completion methods   */}
      {!isSampleUnit && (
        <section>
          {!hasAnswerSubmission && !selectAnswerType && allowCourseUnitsComplete && (
            <section className="reply-options-container">
              <ReplyOptions onSelectAnswer={setSelectAnswerType} />
            </section>
          )}

          {selectAnswerType === "text" && (
            <FreeTextAnswer
              unit={unit}
              onSubmit={onSubmit}
              onReset={(): void => setSelectAnswerType(null)}
            />
          )}

          {selectAnswerType === "file" && (
            <FileUploadAnswer
              unit={unit}
              onSubmit={onSubmit}
              onReset={(): void => setSelectAnswerType(null)}
              isLoadingAssignment={isLoadingAssignment}
            />
          )}

          {selectAnswerType === "video-upload" && (
            <VideoRecording
              unit={unit}
              onSubmit={onSubmit}
              onReset={(): void => setSelectAnswerType(null)}
            />
          )}

          {selectAnswerType === "audio-upload" && (
            <AudioRecording
              unit={unit}
              onSubmit={onSubmit}
              onReset={(): void => setSelectAnswerType(null)}
            />
          )}

          {selectAnswerType === "screen-record" && (
            <ScreenRecording
              unit={unit}
              onSubmit={onSubmit}
              onReset={(): void => setSelectAnswerType(null)}
            />
          )}

          {hasAnswerSubmission && !selectAnswerType && (
            <>
              {!showOptions && <Answer unit={unit} />}

              {canModifyAnswer && allowCourseUnitsComplete && (
                <PreviewModifySubmittedAnswer
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                  setSelectAnswerType={setSelectAnswerType}
                />
              )}
            </>
          )}

          {assignmentIsCompleted && <InstructorReply unit={unit} />}
        </section>
      )}
    </article>
  );
};

export default Assignment;

import React, { FC, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { useConfigurationStore } from "@stores";
import { URLS } from "@constants/urls";
import { FacebookLogoSVG, TwitterLogoSVG } from "@assets/icons";
import { Course } from "types/entities";
import { publicCourseContainer } from "./styles";
import { t } from "i18next";
import GuestSignupModal from "./GuestSignupModal";
import { getSocialMediaShareURL } from "@utils/helpers";

type PublicCourseSignUpProps = {
  course: Course;
};

const PublicCourseSignUp: FC<PublicCourseSignUpProps> = ({ course }) => {
  const { catalogSettings } = useConfigurationStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const canShareOnSocialMedia = Boolean(catalogSettings?.social_catalog);
  const { id, progress } = course;

  const catalogUrl = URLS.externalCatalog.createCourseLink({
    courseId: id.toString(),
  });

  return (
    <div css={publicCourseContainer}>
      <Text fontSize="sm" as="div">
        {progress?.certificate
          ? t("externalCatalog.publicCourse.youMaySignUpCertificate")
          : t("externalCatalog.publicCourse.youMaySignUp")}
      </Text>
      <Button className="sign-up-btn" onClick={(): void => setIsModalOpen(true)}>
        {t("signUp.signUp")}
      </Button>
      {canShareOnSocialMedia && (
        <div className="social-buttons-wrapper">
          <Button
            rounded
            className="social-button"
            noGutters
            as="a"
            href={getSocialMediaShareURL("facebook", catalogUrl, course)}
            target="_blank"
          >
            <FacebookLogoSVG height={16} />
          </Button>

          <Button
            rounded
            className="social-button"
            noGutters
            as="a"
            href={getSocialMediaShareURL("twitter", catalogUrl, course)}
            target="_blank"
          >
            <TwitterLogoSVG height={16} />
          </Button>
        </div>
      )}

      <GuestSignupModal isOpen={isModalOpen} onClose={(): void => setIsModalOpen(false)} />
    </div>
  );
};

export default PublicCourseSignUp;

import React, {
  InputHTMLAttributes,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useRef,
  FormEvent,
} from "react";
import { Label, Button, Tooltip, Chip } from "@epignosis_llc/gnosis";
import { AttachmentSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import { fileInputContainer } from "./styles";
import { ExtendableProps } from "types/utils";
import { FileValidationsObj, MimeType } from "types/entities";
import { convertBytesToSizeUnit, getFileType } from "@utils/helpers";
import i18n from "@utils/i18n";

export type FileInputProps = ExtendableProps<
  InputHTMLAttributes<HTMLInputElement>,
  {
    id: string;
    name: string;
    maxFiles: number;
    mimeTypeAndFilesizeValidations: FileValidationsObj;
    acceptedFileExtensions?: string[];
    selectedFiles?: File[];
    addedFiles: FileList | null;
    tooltipPlacement?: "top" | "bottom" | "left" | "right";
    onFileError: (error: string) => void;
    onFilesChange: (files: File[]) => void;
  }
>;

const FileInput: ForwardRefRenderFunction<HTMLInputElement, FileInputProps> = (props, ref) => {
  const isRtl = i18n.dir() === "rtl";

  const {
    id,
    name,
    maxFiles,
    selectedFiles = [], // the total selected files
    addedFiles, // files given to component in order to added
    mimeTypeAndFilesizeValidations,
    acceptedFileExtensions = [], // Validate files by their extension insted of mime type
    tooltipPlacement = isRtl ? "left" : "right",
    onFileError,
    onFilesChange,
    children,
    ...rest
  } = props;
  const fileInput = useRef<HTMLElement | null>();
  const { sm } = useResponsive();
  const acceptedFileTypes = Object.keys(mimeTypeAndFilesizeValidations);

  // group mime types based on size limit
  //TODO: extract this on a helper fn at file.ts
  const groupedMimeTypes = Object.keys(mimeTypeAndFilesizeValidations).reduce((obj, currentKey) => {
    const currentValue = mimeTypeAndFilesizeValidations[currentKey];
    !obj[currentValue] ? (obj[currentValue] = [currentKey]) : obj[currentValue].push(currentKey);
    return obj;
  }, {});

  const validationTexts = Object.keys(groupedMimeTypes).map((fileSizeStr) => {
    const fileSize = parseInt(fileSizeStr);

    const extensions = groupedMimeTypes[fileSizeStr].map((mimeType: MimeType) =>
      getFileType(mimeType),
    );

    // remove duplicate extensions
    const extensionsStr = [...new Set(extensions)].join(", ");

    return (
      <p key={fileSizeStr}>{` ${extensionsStr} (${convertBytesToSizeUnit(fileSize, "MB")} MB)`}</p>
    );
  });

  const tooltipText = (
    <div className="tooltip">
      {t("general.acceptedFiles")}
      <br />
      {validationTexts}
    </div>
  );

  // handle click on attach file button
  const handleBrowseButtonClick = (e: FormEvent): void => {
    e.preventDefault();
    fileInput.current?.click();
  };

  // Remove selected file
  const removeFile = (index: number): void => {
    if (index >= 0) {
      const currentFiles: File[] = [...selectedFiles];
      currentFiles.splice(index, 1);
      onFilesChange(currentFiles);
    }
  };

  // Remove all selected files
  const removeFiles = (): void => {
    onFilesChange([]);
  };

  // handle the files selected from browing using the file input
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = e.target.files;

    // call function to handle selected files
    if (files?.length) {
      validateSelectedFiles(files);
    }

    // reset input value to trigger file input change every time
    e.target.value = "";
  };

  // validate given files
  const validateSelectedFiles = (files: FileList): void => {
    // save current files number
    let currentFilesNumber = selectedFiles.length;
    let filesToAdd: File[] = [];

    Array.from(files).forEach((file): boolean => {
      // excceds files limit
      if (currentFilesNumber >= maxFiles) {
        onFileError(t("validationFiles.filesAllowedToUpload", { count: maxFiles }));

        return true;
      }

      // validate with file mime type
      // check if file has an accepted mime type
      if (!acceptedFileExtensions.length && !acceptedFileTypes.includes(file.type as MimeType)) {
        onFileError(`${t("notifications.fileTypeNotSupported")}`);

        return true;
      }

      // validate with file extension
      // check if file has an accepted extension or an accepted mime type
      if (acceptedFileExtensions.length) {
        const filename: string = file.name;
        const fileExtension = filename.split(".").pop()?.toLowerCase() as string;
        const hasAcceptedExtension = acceptedFileExtensions.includes(fileExtension);
        const hasAcceptedMimeType = acceptedFileTypes.includes(file.type as MimeType);

        if (!hasAcceptedExtension && !hasAcceptedMimeType) {
          onFileError(`${t("notifications.fileTypeNotSupported")}`);

          return true;
        }
      }

      // file excceds file size limit
      if (file.size > mimeTypeAndFilesizeValidations[file.type]) {
        onFileError(
          t("validationFiles.fileSizeExceedLimit", {
            number: convertBytesToSizeUnit(mimeTypeAndFilesizeValidations[file.type], "MB"),
            type: "MB",
          }),
        );

        return true;
      }

      currentFilesNumber++;
      filesToAdd = [...filesToAdd, file] as File[];

      return true;
    });

    if (filesToAdd.length) {
      const currentFiles: File[] = [...selectedFiles];
      const newFiles = currentFiles.concat(filesToAdd);
      onFilesChange(newFiles);
    }
  };

  useEffect(() => {
    // validate given files and add them to the selected files
    if (addedFiles?.length) {
      validateSelectedFiles(addedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedFiles]);

  useEffect(() => {
    fileInput.current = document.getElementById(id);
  }, [id]);

  return (
    <div css={fileInputContainer} className="file-input">
      <div className="label-container">
        {children ? (
          <div className="children-wrapper" onClick={handleBrowseButtonClick}>
            <Tooltip content={tooltipText} placement="top">
              <>{children}</>
            </Tooltip>
          </div>
        ) : (
          <>
            {selectedFiles.length < maxFiles && (
              <Tooltip content={tooltipText} placement={tooltipPlacement}>
                <Button
                  color="secondary"
                  variant="link"
                  iconBefore={AttachmentSVG}
                  onClick={handleBrowseButtonClick}
                  noGutters
                >
                  {sm && t("general.selectFile")}
                </Button>
              </Tooltip>
            )}
            <Label>
              {selectedFiles.length
                ? t("general.selectedFiles", {
                    count: selectedFiles.length,
                  })
                : t("general.orDropFiles", { count: maxFiles })}
            </Label>
          </>
        )}
      </div>

      {selectedFiles.length > 0 && (
        <div className="attachments-layer">
          <div className="attachments">
            {[...selectedFiles].map((file, index) => {
              return (
                <Chip
                  key={`${file.name}-${index}`}
                  className="attachment-tag"
                  onClose={(): void => removeFile(index)}
                >
                  {file.name}
                </Chip>
              );
            })}

            {selectedFiles.length > 1 && (
              <Button
                color="primary"
                variant="link"
                noGutters
                className="remove-all-files"
                onClick={removeFiles}
              >
                {t("general.removeAll")}
              </Button>
            )}
          </div>
        </div>
      )}

      <input
        id={id}
        type="file"
        name={name}
        ref={ref}
        aria-describedby={`${id}-${name}`}
        onChange={handleOnChange}
        multiple={maxFiles > 1}
        {...rest}
      />
    </div>
  );
};

export default forwardRef(FileInput);

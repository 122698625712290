import HttpClient from "@api/HttpClient";
import { ENDPOINTS } from "@api/endpoints";
import {
  FormCustomFields,
  UnitType,
  CertificateDuration,
  CertificateReassignWhen,
} from "types/entities";
import { CoursePrerequisitesRes, FilesRes, MyUnitRes, SmartTagsRes } from "types/responses";
import { CourseRulesEditData } from "./types";

export type Certificate = {
  id: number;
  duration_type: CertificateDuration;
  duration_days?: number; // min 1, nullable, Required if duration_type === custom
  expiration_month?: number; // min 1, max 12, nullable, Required if duration_type === date
  expiration_day?: number; // min 1, max 31, nullable, Required if duration_type === date
  reassign_when?: CertificateReassignWhen | null; // nullable  // Required if duration_type !== forever
  // If duration_type === forever the only acceptable value is null
};

export type EditCourseData = {
  name?: string; // max length 100
  description?: string | null; // max length 5000, nullable
  code?: string | null; // max length 20, nullable
  price?: number | null; // nullable
  capacity?: number | null; // min 1, nullable
  is_hidden_from_catalog?: boolean;
  is_active?: boolean;
  intro_video_url?: string | null; // max 255, nullable
  time_limit?: number | null; // min 1, nullable, this cannot be set if expires_at is not null
  retain_access_after_completion?: boolean; //this cannot be true if the course has time_limit == null or expires_at == null
  starts_at?: string | null; // nullable, this cannot be after expires_at
  expires_at?: string | null; // nullable, this cannot be before expires_at, this cannot be set if time_limit is not null
  certificate?: Certificate | null;
  category_id?: number | null; // min 1, nullable
  level?: number | null; // min 1, max 20, nullable
  custom_fields?: FormCustomFields | null;
  category?: {
    id: number | null; // id and name cannot be null at the same time
    name: string | null;
  } | null;
};

export type CreateUnitData = {
  course_id: number;
  type: UnitType;
};

/***** COURSE endpoints *****/
export const publishCourse = async (courseId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.publish(courseId));
};

export const editCourse = async (courseId: string, data: EditCourseData): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.edit(courseId), data);
};

/***** COURSE IMAGE endpoints *****/

export const postCourseImage = async (file: File, courseId: string): Promise<FilesRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("course_image", file);

  const res = await HttpClient.post(ENDPOINTS.courses.image(courseId), bodyFormData, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res.data;
};

export const deleteCourseImage = async (courseId: string): Promise<FilesRes> => {
  const res = await HttpClient.delete(ENDPOINTS.courses.image(courseId));

  return res.data;
};

/***** COURSE UNITS endpoints *****/

export const createUnit = async (data: CreateUnitData): Promise<MyUnitRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.unitCreate, data);

  return res.data;
};

export const getUnitSmartTags = async (): Promise<SmartTagsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.unitSmartTags);

  return res.data;
};

export const createSection = async (courseId: number): Promise<void> => {
  const data = { course_id: courseId };
  await HttpClient.post(ENDPOINTS.courses.sectionCreate, data);
};

export const putUnitsOrder = async (courseId: string, unitIds: number[]): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitsOrder(courseId), {
    unit_ids: unitIds,
  });
};

export const deleteUnit = async (
  unitId: string,
  shouldDeleteLinkedUnits?: boolean,
): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.unitDelete(unitId, shouldDeleteLinkedUnits));
};

/***** COURSE RULES endpoints *****/

export const getCoursePrerequisiteOptions = async (
  courseId: string,
): Promise<CoursePrerequisitesRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.prerequisiteOptions(courseId));

  return res.data;
};

export const putCourseRules = async (
  courseId: string,
  data: CourseRulesEditData,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.rulesEdit(courseId), data);
};

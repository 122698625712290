import { palletColorsGenerator, getPlusTheme, getGnosisTheme } from "../utils";
import { COLORS } from "../constants";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#F77A21",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultOrange = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultOrange;

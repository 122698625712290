import { css } from "@emotion/react";

export const ActionGhostButtonStyles = css`
  svg {
    color: black;
  }

  &:hover {
    svg {
      color: inherit;
    }
  }
`;

import React, { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Textarea, InputError, Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { FormValues } from "../Assignment";
import { freeTextAnswerContainer } from "./styles";
import { MyUnit } from "types/entities";
import { FreeTextAnswerValidationSchema } from "@utils/validation";

type FreeTextAnswerProps = {
  unit: MyUnit;
  onReset: () => void;
  onSubmit: (formData: FormValues) => void;
  children?: never;
};

const FreeTextAnswer: FC<FreeTextAnswerProps> = ({ unit, onReset, onSubmit }) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      text: unit.progress?.submission?.text?.html ?? "",
    },
    resolver: yupResolver(FreeTextAnswerValidationSchema),
  });
  const textField = watch("text");

  return (
    <form css={freeTextAnswerContainer} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Textarea
        id="answer"
        placeholder={t("test.writeYourAnswer")}
        status={errors.text ? "error" : "valid"}
        {...register("text")}
      />
      {errors.text && <InputError>{errors.text.message}</InputError>}
      <div className="actions-container">
        <div>
          <Button type="submit" disabled={!textField} isLoading={isSubmitting}>
            {t("test.submit")}
          </Button>
        </div>

        <div>
          <Button type="reset" onClick={onReset} variant="link">
            {t("general.cancel")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default FreeTextAnswer;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const courseDetailsContainer = ({ courseOverview }) => css`
  .main-container {
    display: flex;
    flex-direction: column;

    ${mq["xl"]} {
      flex-direction: row;
      padding: 0.6rem;
    }

    ${mq["lg"]} {
      padding: 0;
    }

    .preview {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      max-width: 584px;

      ${mq["xl"]} {
        max-width: 430px;
        margin-inline: 0 2rem;
        margin-block: 0;
      }

      .resume-btn {
        margin-top: 1rem;
      }

      .subscribe-btn-text {
        display: flex;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 0.5rem;

      ${mq["sm"]} {
        padding: 0;
        flex: 1;
      }

      .section-wrapper {
        margin-top: 3rem;

        &:first-of-type {
          margin-top: 0rem;
        }

        .section-container {
          margin-top: 1.5rem;

          &:first-of-type {
            margin-top: 0rem;
          }
        }
      }

      .details-wrapper {
        .details-container {
          .category-container {
            width: fit-content;

            .category {
              word-break: break-word;

              ${
                ""
                // TODO: uncomment when category become link again
                // background: transparent;
                // border: none;
                // padding: 0;
                // color: ${courseOverview.category.color};

                // &:hover {
                //   color: ${courseOverview.category.hover};
                // }
              }
            }
          }

          .name-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .code {
            color: ${courseOverview.codeColor};
          }
        }

        .course-completed-text {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;

          svg {
            margin-inline-end: 0.5rem;
          }
        }

        .certification-container {
          display: flex;
          align-items: center;

          ${
            ""
            // TODO: uncomment when certificate is available from api
            // .view-certificate-btn {
            //   background: transparent;
            //   border: 0;
            //   padding: 0;
            //   font-style: italic;
            // }
          }
        }
      }
    }

    .description-wrapper {
      .description-title {
        margin-bottom: 0.5rem;
      }

      .description {
        max-width: 1000px;
      }

      .showmore-btn {
        text-align: center;
        margin-top: 1.5rem;
      }
    }

    .intro-video-wrapper {
      .intro-video {
        aspect-ratio: 16/9;

        ${mq["sm"]} {
          max-width: 645px !important;
        }

        ${mq["xl"]} {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .custom-field-wrapper {
      .tag {
        margin-inline-end: 1rem;
        margin-bottom: 0.5rem;
      }
    }

    .price,
    .previous-price {
      color: inherit !important;
    }
  }
`;

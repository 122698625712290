import { css } from "@emotion/react";

export const instructorReplyContainer = css`
  margin-top: 2rem;

  .grade-wrapper {
    display: flex;
    margin-bottom: 0.5rem;

    span {
      margin-inline-start: 0.5rem;
    }
  }

  .reply-wrapper {
    margin-bottom: 1rem;

    .header {
      margin-bottom: 0.5rem;
    }
  }
`;

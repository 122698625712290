/* eslint-disable @typescript-eslint/no-explicit-any */
import { SerializedStyles } from "@emotion/react";
import React, { FC, useRef, useEffect } from "react";
import { scormIFrameContainer } from "./styles";
import { useConfigurationStore } from "@stores";
import { ScormUnit } from "types/entities";

type FrameScormProps = {
  unit: ScormUnit;
};

const FrameScorm: FC<FrameScormProps> = ({ unit }) => {
  const domainSettings = useConfigurationStore((state) => state.domainSettings);
  const iframeEl = useRef(null);
  const { size } = unit;

  // initialize SCORM content from iframe -> Post message for initialization
  useEffect(() => {
    const interval = setInterval(() => {
      if (iframeEl?.current !== null) {
        const scormFrame = (iframeEl?.current as any).contentWindow;
        // Build the following object, through the LMS:
        scormFrame.postMessage((window as any).tl_sco_data, "*");
      } else {
        clearInterval(interval);
      }
    }, 100);

    // Clean up iFrame src attribute
    // more details: talentlms/libraries/EF/views/Unit/Scorm/view_iframe.php
    return () => {
      window.addEventListener("beforeunload", () => {
        (iframeEl?.current as any)?.removeAttribute("src");
        (iframeEl?.current as any)?.remove();
      });

      window.addEventListener("unload", () => {
        (iframeEl?.current as any)?.removeAttribute("src");
        // eslint-disable-next-line react-hooks/exhaustive-deps
        (iframeEl?.current as any)?.remove();
      });
    };
  }, []);

  return (
    <div css={(): SerializedStyles => scormIFrameContainer(size?.width, size?.height)}>
      <iframe
        id="scorm-frame"
        name="scorm-frame"
        ref={iframeEl}
        src={domainSettings?.scorm_iframe_loader}
      />
    </div>
  );
};

export default FrameScorm;

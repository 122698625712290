import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { feedbackContainer } from "../styles";
import { FeedbackIconSVG } from "@assets/images";

type FeedbackProps = {
  feedback: string;
};

const Feedback: FC<FeedbackProps> = ({ feedback }) => {
  return (
    <div css={feedbackContainer} className="answers-content-container">
      <div className="feedback-grid">
        <FeedbackIconSVG height={24} />
        <Text className="feedback-title" fontSize="sm" weight="700">
          {t("test.question_feedback")}
        </Text>
      </div>
      <Text className="feedback-text" fontSize="sm">
        <span dangerouslySetInnerHTML={{ __html: feedback }} />
      </Text>
    </div>
  );
};

export default Feedback;

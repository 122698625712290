import React, { FC } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { errorResultContainer } from "./styles";
import { images } from "@constants/assets";
import { IconType } from "types/common";

type ErrorResultProps = {
  errorImg?: string | IconType;
  title?: string;
};

const ErrorResult: FC<ErrorResultProps> = ({
  errorImg = images.errors.fetchData,
  title = t("general.somethingWentWrongEmptyState"),
}) => (
  <div css={errorResultContainer}>
    <Result icon={errorImg} title={title as string} />
  </div>
);

export default ErrorResult;

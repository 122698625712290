import React, { FC, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useReactMediaRecorder } from "react-media-recorder";
import { Heading, MediaPlayer } from "@epignosis_llc/gnosis";
import { AudioSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import fixWebmDuration from "webm-duration-fix";
import Actions from "./Actions";
import { formContainer } from "./styles";
import queryKeys from "@constants/queryKeys";
import { postAssignmentUnitFile } from "@api/courses";
import { MyUnit } from "types/entities";
import { FormValues } from "@components/Units/Assignment/Assignment";
import { NOTIFICATIONS } from "@channels/notifications";
import {
  getFileFromBlobUrl,
  getMediaErrorMsg,
  isChromeBrowser,
  secondsToTime,
} from "@utils/helpers";
import { errorNotification, generalNotification } from "@utils/helpers/notifications";

type FileForm = { file: File };

type AudioRecordingProps = {
  unit: MyUnit;
  onSubmit: (formData: FormValues) => void;
  onReset: () => void;
};

const recordingUploadErrorNotification = (): void => {
  const errorMsg = (
    <Heading as="h5" size="xs">
      {t("assignment.audioRecordingNotUpload")}
    </Heading>
  );

  PubSub.publish(NOTIFICATIONS, { type: "error", content: errorMsg });
};

const MIN_VIDEO_DURATION = 5; // in seconds
const MAX_VIDEO_DURATION = 3600; // in seconds

const config = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
    },
  },
};

const AudioRecording: FC<AudioRecordingProps> = ({ unit, onSubmit, onReset }) => {
  const isChrome = isChromeBrowser();
  const { id: unitId, name } = unit;
  const { courseId } = useParams() as { courseId: string };
  const [rerecord, setRerecord] = useState(false);
  const [duration, setDuration] = useState(0); // in seconds
  const [isRecording, setIsRecording] = useState(false);
  const [answerUploaded, setAnswerUploaded] = useState(false);
  const [fixedURL, setFixedURL] = useState("");
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    error,
    clearBlobUrl,
    previewStream: audioStream,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    askPermissionOnMount: true,
    blobPropertyBag: { type: "audio/mpeg" },
    onStart: () => {
      setDuration(0);
      setIsRecording(true);
    },
    onStop: async (_, blob) => {
      if (isChrome) {
        const fixedBlob = await fixWebmDuration(blob);
        setFixedURL(URL.createObjectURL(fixedBlob));
      }
      setIsRecording(false);
      handleUnregister();
    },
  });
  const { handleSubmit } = useForm<FileForm>({ mode: "onChange" });

  const { mutate: uploadAssigmentAudioFile, isLoading: isPostingAssignment } = useMutation(
    [queryKeys.assignmentUnit.fileUpload, { courseId, unitId }],
    (file: File) => postAssignmentUnitFile(unitId.toString(), file),
    {
      onSuccess: ({ _data }) => {
        onSubmit({ file_id: _data.id });
        clearBlobUrl();
        setAnswerUploaded(true);
      },
      onError: () => recordingUploadErrorNotification(),
    },
  );

  const audioRecordingError: boolean = [
    // possible errors from useReactMediaRecorder hook
    "media_aborted",
    "permission_denied",
    "no_specified_media_found",
    "media_in_use",
    "invalid_media_constraints",
    "no_constraints",
    "recorder_error",
  ].some((errorType) => errorType === error);
  const showRecording = (status === "recording" || status === "idle") && !answerUploaded;
  const showAudioPreview = Boolean(mediaBlobUrl) && status !== "recording";
  const validationErrorMin =
    status === "stopped" && showAudioPreview && duration < MIN_VIDEO_DURATION;
  const validationErrorMax =
    status === "stopped" && showAudioPreview && duration > MAX_VIDEO_DURATION;
  const displayRecordingBtn =
    (status === "idle" || status === "stopping" || status === "stopped") && !rerecord;
  const displayStopRecordingBtn = status === "recording";
  const displaySubmitRecordingBtn = Boolean(mediaBlobUrl) && status !== "recording";
  const displayReRecordingBtn =
    (status === "idle" || status === "stopping" || status === "stopped") &&
    rerecord &&
    !answerUploaded &&
    !isPostingAssignment;
  const audioError = getMediaErrorMsg(error, "audio");

  const handleStartRecording = useCallback(
    (e): void => {
      e.preventDefault();
      startRecording();
    },
    [startRecording],
  );

  const handleStopRecording = useCallback(
    (e): void => {
      e.preventDefault();
      stopRecording();
      clearBlobUrl();
      setRerecord(true);

      if (duration < MIN_VIDEO_DURATION) {
        errorNotification(t("assignment.fileShouldBeAtLeast"));
      }

      if (duration > MAX_VIDEO_DURATION) {
        errorNotification(t("assignment.fileShouldNotBeBigger"));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stopRecording, clearBlobUrl],
  );

  const handleUnregister = (): void => {
    const mediaStream = audioStream;
    const tracks = mediaStream?.getTracks();
    tracks?.forEach((track) => track.stop());
  };

  useEffect(() => {
    let interval: null | ReturnType<typeof setInterval> = null;
    if (isRecording) {
      interval = setInterval(() => {
        setDuration((duration) => duration + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval as ReturnType<typeof setInterval>);
    };
  }, [isRecording, duration]);

  useEffect(() => {
    return () => {
      stopRecording();
      clearBlobUrl();
      handleUnregister();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = async (): Promise<void> => {
    if (mediaBlobUrl) {
      const date = new Date().toISOString();
      const audioFile = await getFileFromBlobUrl(mediaBlobUrl, `${name}-recording-${date}.mp3`, {
        type: "audio/mpeg",
      });

      uploadAssigmentAudioFile(audioFile);
    }
  };

  useEffect(() => {
    audioError &&
      generalNotification("warning", <div style={{ maxWidth: "13rem" }}>{audioError}</div>);
  }, [audioError]);

  return (
    <form css={formContainer} onSubmit={handleSubmit(handleOnSubmit)}>
      {!audioRecordingError && (
        <>
          {showRecording && (
            <div className="recording-preview">
              <div className="recording-mic">
                <div className="icon-wrapper">
                  <div className="pulse" />
                  <AudioSVG height={64} />
                </div>
              </div>
              {status === "recording" && (
                <div className="text">
                  <Heading as="h4">{t("assignment.recording")}</Heading>
                  <Heading as="h3">{secondsToTime(duration, true)}</Heading>
                </div>
              )}
            </div>
          )}

          {showAudioPreview && (
            <div className="audio-preview">
              <MediaPlayer
                id="audio"
                className="react-player"
                type="audio"
                src={isChrome ? fixedURL : mediaBlobUrl ?? undefined}
                controls
                width="100%"
                height="75px"
                config={config}
              />
            </div>
          )}
        </>
      )}

      <Actions
        answerUploaded={answerUploaded}
        isPostingAssignment={isPostingAssignment}
        displayRecordingBtn={displayRecordingBtn}
        displayReRecordingBtn={displayReRecordingBtn}
        displayStopRecordingBtn={displayStopRecordingBtn}
        displaySubmitRecordingBtn={displaySubmitRecordingBtn}
        permissionError={audioError}
        hasValidationError={validationErrorMax || validationErrorMin}
        onReset={onReset}
        handleStartRecording={handleStartRecording}
        handleStopRecording={handleStopRecording}
      />
    </form>
  );
};

export default AudioRecording;

import { css } from "@emotion/react";

export const modalHeader = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const modalFooter = css`
  text-align: start;

  .cancel-btn {
    margin-inline-start: 1rem;
  }
`;

export const previewModal = css`
  .pdf-container {
    height: 30rem;
    overflow-y: auto;
  }

  .img-container {
    overflow: auto;

    img {
      max-width: 100%;
      max-height: 30rem;
      margin: auto;
    }
  }

  p {
    text-align: center;
    font-style: italic;
  }
`;

import { css } from "@emotion/react";

export const prerequisitesContainer = css`
  ul {
    max-width: fit-content;
    list-style: none;
    padding: 0;
    margin: 0;

    .list-container {
      margin-top: 0.5rem;

      &:first-of-type {
        margin-top: 0;
      }

      a {
        display: inline-flex;
        align-items: center;

        svg {
          margin-inline-end: 0.75rem;
        }

        .icon-wrapper {
          height: 32px;
        }
      }

      .course-title {
        word-break: break-word;
      }
    }

    .divider {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 50%;
      margin: 0.5rem 0;
    }
  }

  .level-container {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .section-title {
    margin-bottom: 1rem;
  }
`;

import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { StatusTagStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

export enum TagColor {
  Inactive = "inactive",
  Unpublished = "unpublished",
}

type StatusTagProps = {
  color?: TagColor;
  text?: string;
};

const StatusTag: FC<StatusTagProps> = ({
  color = TagColor.Inactive,
  text = t("general.inactive"),
}) => (
  <Tag css={(theme): SerializedStyles => StatusTagStyles(theme, color)}>
    <Text fontSize="2xs">{text}</Text>
  </Tag>
);

export default StatusTag;

import React, { FC } from "react";
import classNames from "classnames";
import { Button } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightSVG } from "@epignosis_llc/gnosis/icons";
import { tocTriggerContainer } from "./styles";
import i18n from "@utils/i18n";
import { BulletListSVG } from "@assets/icons";

type ToCTriggerProps = {
  isToCOpen: boolean;
  toggleTocMenu: (value?: boolean) => void;
};

const classNamesContainer = (isToCOpen: boolean): string => classNames({ "is-open": !isToCOpen });

const ToCTrigger: FC<ToCTriggerProps> = ({ isToCOpen, toggleTocMenu }) => {
  const dir = i18n.dir();
  const arrowIcon = dir === "rtl" ? <ArrowRightSVG height={24} /> : <ArrowLeftSVG height={24} />;

  return (
    <div css={tocTriggerContainer} className={classNamesContainer(isToCOpen)}>
      <Button
        className="toc-trigger"
        onClick={(): void => toggleTocMenu()}
        aria-label={`${isToCOpen ? "Close" : "Open"} table of contents`}
        noGutters
        color="secondary"
      >
        {!isToCOpen ? <BulletListSVG height={32} /> : arrowIcon}
      </Button>
    </div>
  );
};

export default ToCTrigger;

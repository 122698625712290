import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { typeScale } from "@styles/typography";

export const formContainer = ({ units }) => css`
  .webcam-preview,
  .video-preview,
  .recording-preview,
  .audio-preview {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

    ${mq["md"]} {
      max-width: 80%;
    }

    ${mq["lg"]} {
      max-width: 500px;
    }

    video {
      width: 100%;
    }
  }

  .webcam-preview {
    position: relative;
    line-heiglt: 0;

    .video-status {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 10px;
      background: rgba(255, 255, 255, 0.1);

      ul,
      li {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
      }

      ul {
        justify-content: space-between;
      }

      &.screen {
        li,
        svg {
          color: ${units.assignment.durationColorScreen};
        }
      }

      li,
      svg {
        color: ${units.assignment.durationColor};
      }

      li {
        font-size: ${typeScale["2xl"]};
        font-weight: bold;
      }
    }
  }

  .react-player {
    line-height: 0;
  }

  .recording-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .img {
      display: flex;
      overflow: hidden;
      justify-content: center;

      img {
        margin: -70px 0 -60px;
        width: 75%;
        height: 75%;
        object-fit: cover;
      }
    }

    .recording-mic {
      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        color: ${units.assignment.recordingButton.color};
        background: ${units.assignment.recordingButton.background};
      }

      .pulse {
        width: 100px;
        height: 100px;
        border: 5px solid ${units.assignment.recordingButton.background};
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        animation: pulsate infinite 1.5s;
      }

      @-webkit-keyframes pulsate {
        0% {
          -webkit-transform: scale(1, 1);
          opacity: 1;
        }
        100% {
          -webkit-transform: scale(1.3, 1.3);
          opacity: 0;
        }
      }
    }

    .text {
      text-align: center;
      margin-top: 2rem;
    }
  }

  .audio-preview {
    margin: 0 auto 2rem;
  }

  .actions-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;

    .action-btns {
      display: flex;
    }

    .cancel-btn {
      margin-top: 1rem;
    }

    button {
      margin: 0 0.5rem;

      svg {
        margin-inline-end: 0.5rem;
      }
    }
  }
`;

import { TableProps } from "@epignosis_llc/gnosis";
import { useUserPreferencesStore } from "@stores";
import { QueryFilter } from "types/common";
import { defineSortOrder } from "./view";
import { Pagination as IPagination } from "types/responses/index";

type DefaultStateType = {
  pagination: IPagination;
  filters: QueryFilter[];
  sorting: TableProps["sorting"];
  searchValue?: string;
  url: string;
};

const filterSearch = (filters: QueryFilter[]): string => {
  const filterSearch = filters.find(
    (item) =>
      item.key === "[keyword][like]" ||
      item.key === "[name][like]" ||
      item.key === "[description][like]",
  );

  return filterSearch ? filterSearch.value : "";
};

const handleTableState = (defaultValues: DefaultStateType): DefaultStateType => {
  const userStore = useUserPreferencesStore;
  const { getState: getUserPreferencesState } = userStore();
  const { settings } = getUserPreferencesState() ?? {};

  // Build default values from user preferences
  const sortSetting = settings[defaultValues.url]?.sorting?.[0];
  const sortOrder = sortSetting && defineSortOrder(sortSetting);
  const defaultSorting = sortOrder
    ? {
        column: sortOrder.str,
        isDescending: sortOrder.hasMinus,
      }
    : defaultValues.sorting;

  const defaultFilters =
    (settings[defaultValues.url]?.filters as QueryFilter[]) ?? defaultValues.filters;

  const defaultPagination = settings[defaultValues.url]?.pagination ?? defaultValues.pagination;

  return {
    pagination: {
      number: defaultPagination.number,
      size: defaultPagination.size,
    },
    filters: defaultFilters,
    sorting: defaultSorting,
    searchValue: filterSearch(defaultFilters),
    url: defaultValues.url,
  };
};

export default handleTableState;

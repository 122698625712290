import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";
import {
  CalendarEventsRes,
  CalendarExportRes,
  CalendarStatisticsRes,
  CalendarSyncRes,
} from "types/responses";

export const getCalendarEvents = async (queryStr = ""): Promise<CalendarEventsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.calendar.events}${queryStr}`);

  return res.data;
};

export const getCalendarExport = async (queryStr = ""): Promise<CalendarExportRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.calendar.export}${queryStr}`);

  return res.data;
};

export const getCalendarSync = async (): Promise<CalendarSyncRes> => {
  const res = await HttpClient.get(ENDPOINTS.calendar.sync);

  return res.data;
};

export const getCalendatStatistics = async (): Promise<CalendarStatisticsRes> => {
  const res = await HttpClient.get(ENDPOINTS.calendar.statistics);

  return res.data;
};

export const postCalendarSync = async (): Promise<CalendarSyncRes> => {
  const res = await HttpClient.post(ENDPOINTS.calendar.sync);

  return res.data;
};

import React, { ChangeEvent, FC } from "react";
import { Textarea } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { FreeTextQuestion } from "types/entities";

type FreeTextContainerProps = {
  questionEntry: FreeTextQuestion;
  userAnswers: string[];
  onAnswersChange: (answers: string[]) => void;
};

const FreeTextContainer: FC<FreeTextContainerProps> = ({ onAnswersChange, userAnswers }) => (
  <form autoComplete="off">
    <div className="answers-content-container">
      <Textarea
        id="answer"
        label={t("test.answer")}
        placeholder={t("test.writeYourAnswer")}
        value={userAnswers[0] ?? ""}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>): void => {
          onAnswersChange([e.target.value]);
        }}
      />
    </div>
  </form>
);

export default FreeTextContainer;

import React, { FC, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { FileListStyle } from "./styles";
import FileName from "./Components/FileName";
import FileTag from "./Components/FileTag";
import { CourseFile } from "types/entities";
import { URLS } from "@constants/urls";
import FileActions from "./Components/FileActions";
import { SerializedStyles } from "@emotion/react";
import { CourseActiveTab } from "@views/Course/types";

type FileListProps = {
  files: CourseFile[];
  title?: string;
  showAllFiles?: boolean;
  courseId?: string;
  isCatalogCourseView?: boolean;
  isLearnerView?: boolean;
};

const FileList: FC<FileListProps> = ({
  files,
  title,
  showAllFiles = false,
  courseId,
  isCatalogCourseView = false,
  isLearnerView = true,
}) => {
  const [hoveredRow, setHoveredRow] = useState<CourseFile | null>(null);

  // TODO: provide call to decouple navigation from this component
  const showAllFilesLink = (): string => {
    if (!isLearnerView) {
      return URLS.courses.getSingleCourse({ courseId, activeTab: CourseActiveTab.Files });
    }

    return isCatalogCourseView
      ? URLS.catalog.createCatalogCourseFilesLink({ courseId })
      : URLS.user.createCourseFilesLink({ courseId });
  };

  return (
    <div css={(theme): SerializedStyles => FileListStyle(theme)} className="files-container">
      {title && (
        <Text fontSize="md" weight="700" className="files-header" as="div">
          {title}
        </Text>
      )}
      <table>
        <tbody>
          {files
            // learner fetches only shared files but has no 'shared' property in those files
            ?.filter((file) => file.shared || isLearnerView)
            .map((file) => {
              const isRowHovered = hoveredRow?.id === file.id;
              return (
                <tr
                  key={file.id}
                  onMouseEnter={(): void => setHoveredRow(file)}
                  onMouseLeave={(): void => setHoveredRow(null)}
                >
                  <td className="tag-column">
                    <FileTag type={file.type} />
                  </td>
                  <td className="file-column">
                    <FileName key={file.id} {...file} />
                  </td>
                  <td>
                    <FileActions
                      courseId={courseId}
                      key={file.id}
                      file={file}
                      isRowHovered={isRowHovered}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {showAllFiles && (
        <div className="view-all-wrapper">
          <Button variant="outline" as={Link} to={showAllFilesLink()}>
            {t("myCourses.viewMoreFiles")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FileList;

import { yup } from "./validation";

export const SignInFormValidationSchema = yup.object().shape({
  username: yup.string().trim().required(),
  password: yup.string().trim().required(),
});

export const LdapSignInFormValidationSchema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required()
    .matches(/^(?=(_*\w+(\w*[._@'-]*\w*)*))\1*$/),
  password: yup.string().trim().required(),
});

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const drawerDiscussionContainer = ({ discussions }) => css`
  .link-wrapper {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid ${discussions.border};

    ${mq["md"]} {
      padding: 1.5rem 2rem;
    }

    .discussion-info {
      width: 100%;
      margin-inline-start: 0.5rem;
      color: ${discussions.linkColor};
    }

    .creator-latestBy {
      color: ${discussions.color};
    }
  }
`;

export const DiscussionContainer = ({ discussions }, { dir }) => css`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${discussions.border};
  cursor: pointer;
  display: flex;

  ${mq["md"]} {
    padding: 1rem 1rem;
  }

  &:nth-of-type(odd) {
    background-color: ${discussions.listBackgroundColor};
  }

  ${mq["lg"]} {
    &:hover {
      background-color: ${discussions.hover};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-inline-start: 0.5rem;
    position: relative;

    ${mq["md"]} {
      margin-inline-start: 1rem;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .details {
        display: flex;
        align-items: center;

        .tag {
          margin-inline-start: 0.5rem;
          padding: 0 0.75rem;
        }

        .icon {
          padding-inline-start: 0.5rem;
          min-width: 24px;
        }
      }

      .replies {
        color: ${discussions.color};
        display: flex;
        padding-inline-start: 1rem;

        b {
          padding-right: 0.25rem;
        }
      }
    }

    .body {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .body-item {
        width: 100%;

        ${mq["md"]} {
          width: auto;
        }

        .body-replies {
          padding-right: 1rem;
        }
      }
    }

    .actions-icons {
      position: absolute;
      top: 55%;
      left: ${dir === "rtl" ? 0 : "auto"};
      right: ${dir === "rtl" ? "auto" : 0};
      transform: translateY(-50%);
      line-height: 1rem;
      display: flex;

      .actions-icon {
        padding: 0 1rem;
      }
    }

    .date {
      color: ${discussions.color};
    }
  }
`;

export const footerDrawerContainer = css`
  display: flex;
  justify-content: center;

  button {
    margin: 0 0.5rem;
  }
`;

export const discussionComment = ({ discussions }) => css`
  padding: 1rem;
  box-shadow: 0px 3px 6px ${discussions.boxShadowColor};
  border: 1px solid ${discussions.border};
  border-radius: 5px;
  margin-bottom: 1rem;

  ${mq["sm"]} {
    padding: 1.5rem 2rem;
  }

  .comment-container {
    display: flex;

    .comment-avatar-container {
      margin-inline-end: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .comment-avatar {
        margin-bottom: 0.75rem;
      }

      .comment-like {
        text-align: center;

        svg {
          &:hover {
            cursor: pointer;
          }

          &.outlined-icon {
            &:hover {
              fill: ${discussions.hoverIcon};
            }
          }

          &.filled-icon {
            fill: ${discussions.filledIcon};
          }
        }
      }
    }

    .comment-content {
      width: 100%;

      .comment-header-container {
        min-height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 0.75rem;

        .comment-header {
          display: flex;
          align-items: center;
          white-space: nowrap;

          .comment-header-content {
            margin-inline-end: 0.5rem;
          }
        }

        .comment-date {
          white-space: nowrap;
          text-align: end;
        }
      }

      .comment-content-container {
        .comment-attachments {
          margin-top: 1rem;
        }
      }
    }
  }

  .reply-form-wrapper {
    margin-top: 1.5rem;
    text-align: end;
  }

  .replies-wrapper {
    margin-top: 1rem;

    .more-less-replies {
      margin-inline-start: 1rem;
    }

    .replies {
      border-inline-start: 3px solid ${discussions.border};
      margin-top: 1.5rem;
      padding: 1rem;
    }
  }
`;

export const disscusionThreadReplyContainer = ({ discussions }) => css`
  .reply-container {
    display: flex;
    padding: 1rem;

    &:nth-of-type(odd) {
      background-color: ${discussions.listBackgroundColor};
    }

    .reply-avatar {
      margin-inline-end: 1rem;
    }

    .reply-content {
      width: 100%;

      .reply-header-container {
        min-height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 0.75rem;

        .reply-header {
          display: flex;
          align-items: center;
          white-space: nowrap;

          .reply-header-content {
            margin-inline-end: 0.5rem;
          }
        }

        .reply-date {
          white-space: nowrap;
          text-align: end;
        }
      }
    }
  }
`;

export const disscusionThreadReplyFormContainer = ({ discussions }) => css`
  padding: 1.5rem 2rem;
  box-shadow: 0px 3px 6px ${discussions.boxShadowColor};
  border: 1px solid ${discussions.border};
  border-radius: 5px;
  text-align: start;
  display: flex;

  .avatar {
    margin-inline-end: 1rem;
  }

  .grid-container {
    width: 100%;

    footer {
      .cancel-btn {
        margin-inline-start: 1rem;
      }
    }
  }
`;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const pageHeader = () => css`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .title {
    width: 100%;

    ${mq["sm"]} {
      width: auto;
    }
  }

  .subtitle {
    width: 100%;
    margin-left: 0.125rem;

    ${mq["sm"]} {
      padding-left: 0.5rem;
      width: auto;
      margin-left: 0;
    }
  }
`;

import React, { FC } from "react";
import { t } from "i18next";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { surveyContainer } from "./styles";
import { SurveyIntroSVG } from "@assets/images";

type SplashScreenProps = {
  content: string | null;
  total_questions: number;
};

const SplashScreen: FC<SplashScreenProps> = (props) => {
  const { content, total_questions } = props;

  return (
    <div css={surveyContainer}>
      <SurveyIntroSVG className="icon" />
      <Heading as="h4">
        {t("survey.surveyQuestionsTotalNumber", { count: total_questions })}
      </Heading>

      {content && (
        <div className="description">
          <Text fontSize="sm">{content}</Text>
        </div>
      )}
    </div>
  );
};

export default SplashScreen;

import { Button, Input, InputError, Text } from "@epignosis_llc/gnosis";
import { CheckCircledSVG, ExclamationCircledSVG } from "@epignosis_llc/gnosis/icons";
import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { couponContainer } from "./styles";
import { NewPriceData } from "./PaymentModal";
import queryKeys from "@constants/queryKeys";
import { getCouponValidation } from "@api";
import { CouponFormValidationSchema } from "@utils/validation";
import { getCurrencyPrice } from "@utils/helpers";
import { useConfigurationStore } from "@stores";
import { handleCouponErrors } from "@errors";

type CouponFormData = {
  text: string;
};

type CouponFormProps = {
  id?: number;
  dataType: string;
  isSubsription?: boolean;
  handleValidCoupon: (newData: NewPriceData) => void;
};

const CouponForm: FC<CouponFormProps> = ({ id, dataType, isSubsription, handleValidCoupon }) => {
  const { domainSettings } = useConfigurationStore();
  const [showValidText, setShowValidText] = useState(false);
  const [discountInfo, setDiscountInfo] = useState<string | null>(null);

  const {
    register,
    formState: { errors },
    getValues,
    handleSubmit,
    setError,
    watch,
  } = useForm<CouponFormData>({
    mode: "onChange",
    resolver: yupResolver(CouponFormValidationSchema),
    defaultValues: {
      text: "",
    },
  });

  const { mutate: couponValidationMutation, isLoading: isCouponValidationMutationLoading } =
    useMutation([queryKeys.couponValidation], (coupon: string) => getCouponValidation(coupon), {
      onSuccess: (res) => {
        const newData = {
          newPrice: res._data.price,
          coupon: getValues("text").trim(),
        };

        handleValidCoupon(newData);
        setShowValidText(true);

        if (res._data.fixed_amount_discount)
          setDiscountInfo(
            getCurrencyPrice(
              res._data.fixed_amount_discount.amount,
              res._data.fixed_amount_discount.currency,
              domainSettings?.locale ?? "",
            ),
          );
        else setDiscountInfo(`${res._data.percentage_discount}%`);
      },
      onError: (error: AxiosError) => {
        handleCouponErrors(error, (foundError) => {
          if (foundError?.errorMsg) {
            setError("text", { type: "manual", message: t(foundError?.errorMsg) });
          }
        });
      },
    });

  const handleData = (data: CouponFormData): void => {
    setShowValidText(false);
    setDiscountInfo(null);
    couponValidationMutation(
      id
        ? `?coupon=${data.text}&id=${id}&type=${dataType}`
        : `?coupon=${data.text}&type=${dataType}`,
    );
  };

  return (
    <form autoComplete="off" css={couponContainer} onSubmit={handleSubmit(handleData)}>
      <div className="input-field-wrapper">
        <div className="input-container">
          <Input
            id="coupon"
            placeholder={t("coupons.enterCouponCode")}
            label={t("coupons.enterCoupon")}
            status={errors.text ? "error" : "valid"}
            {...register("text")}
          />
        </div>

        <Button
          isLoading={isCouponValidationMutationLoading}
          type="submit"
          variant="outline"
          disabled={!watch("text").trim()}
        >
          {t("general.apply")}
        </Button>
      </div>

      {errors.text && (
        <InputError className="invalid-coupon-text">
          <ExclamationCircledSVG height={16} /> {errors.text.message}
        </InputError>
      )}

      {showValidText && (
        <Text fontSize="sm" as="div" className="valid-coupon-text">
          <CheckCircledSVG height={16} />
          {t(isSubsription ? "coupons.couponValidSubscription" : "coupons.couponValid", {
            amount: discountInfo,
          })}
        </Text>
      )}
    </form>
  );
};

export default CouponForm;

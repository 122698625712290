import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  closeBtnColor: {
    base: string;
    hover: string;
  };
  dialog: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  closeBtnColor: {
    base: colors.black,
    hover: colors.secondary.base,
  },
  dialog: colors.white,
});

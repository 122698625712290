import React, { FC, useState, ReactNode, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Breadcrumb, Text } from "@epignosis_llc/gnosis";
import { useScrollToPageTop } from "@hooks";
import { SpotlightSearch, Toolbar } from "@layouts";
import { icons } from "@constants/assets";
import { useConfigurationStore } from "@stores";
import { Link } from "@components/ReusableComponents";

type PageProps = {
  pageTitle: string;
  children: ReactNode;
  siteNamePrefix?: boolean;
  toolbar?: ReactNode;
  breadcrumbOptions?: { title: string; path: string | null }[];
};

const Page: FC<PageProps> = ({
  pageTitle,
  siteNamePrefix = true,
  children,
  toolbar = null,
  breadcrumbOptions = null,
}) => {
  const [toolbarEl, setToolbarEl] = useState<null | Element>(null);
  const [breadcrumbEl, setBreadcrumbEl] = useState<null | Element>(null);
  const domainSettings = useConfigurationStore((state) => state.domainSettings);
  const { site_name = "", favicon = null } = domainSettings ?? {};

  const title = siteNamePrefix ? `${site_name} | ${pageTitle}` : pageTitle;
  useScrollToPageTop();

  useEffect(() => {
    setToolbarEl(document.querySelector("#toolbar"));
    setBreadcrumbEl(document.querySelector("#breadcrumb"));
  }, [setToolbarEl, setBreadcrumbEl]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href={favicon ?? icons.favicon} />
          <title>{title}</title>
        </Helmet>

        {/* For now he hide the Spotlight for admins and instructors unit its fixed */}
        {toolbarEl && (
          <Toolbar toolbarEl={toolbarEl}>{toolbar ? toolbar : <SpotlightSearch />}</Toolbar>
        )}
        {breadcrumbEl && breadcrumbOptions && (
          <Breadcrumb separator="/" breadcrumbEl={breadcrumbEl}>
            {breadcrumbOptions.map(({ title, path }) => (
              <Breadcrumb.Item key={title}>
                {path ? (
                  <Link to={path}>
                    <Text fontSize="sm">{title}</Text>
                  </Link>
                ) : (
                  <a className="current">
                    <Text fontSize="sm" weight="700">
                      {title}
                    </Text>
                  </a>
                )}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        )}
        {children}
      </HelmetProvider>
    </>
  );
};

export default Page;

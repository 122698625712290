import React, { FC, useState } from "react";
import { useResponsive } from "ahooks";
import DatePicker from "react-datepicker";
import { SerializedStyles } from "@emotion/react";
import classNames from "classnames";
import { Input } from "@epignosis_llc/gnosis";
import "react-datepicker/dist/react-datepicker.css";
import { dateInput } from "./styles";
import { getLocale } from "@utils/helpers";
import i18n from "@utils/i18n";
import { DurationSVG } from "@assets/icons";
import { t } from "i18next";

export type TimeInputProps = {
  value?: Date | null;
  id?: string;
  size?: "md" | "lg";
  label?: string;
  required?: boolean;
  altLabel?: boolean;
  className?: string;
  inline?: boolean;
  placeholderText?: string;
  timeFormat?: string;
  status?: "valid" | "error";
  disabled?: boolean;
  isReadOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  tooltipContent?: string;
  onChange: (selectedDate: Date | null) => void;
  handleInputChange?: (eventValue: string) => void;
};

const TimeInput: FC<TimeInputProps> = (props) => {
  const { sm } = useResponsive();
  const {
    id,
    className,
    isReadOnly = sm, // On mobile screens the "true" causes the calendar to not open
    value = new Date(),
    timeFormat = "HH:mm",
    placeholderText = timeFormat.toUpperCase(),
    disabled = false,
    timeIntervals = 15,
    timeCaption = t("general.time"),
    size = "md",
    label,
    altLabel = false,
    required = false,
    inline = false,
    status = "valid",
    tooltipContent,
    onChange,
    handleInputChange,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const hasLabel = Boolean(label);

  const containerClassNames = classNames({
    valid: status === "valid",
    error: status === "error",
    "alt-label": hasLabel && altLabel,
    [className ?? ""]: true,
    inline,
    "time-only": true,
  });

  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "bottom-end" : "bottom-start";

  const onChangeDate = (date: Date): void => {
    setIsOpen(false);
    onChange(date);
  };

  const handleCalendarClose = (): void => {
    setIsOpen(false);
  };

  const handleChangeRaw = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (handleInputChange) handleInputChange(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.code === "Backspace") {
      onChange(null);
    }
  };

  return (
    <div
      css={(theme): SerializedStyles => dateInput(theme, { size })}
      className={containerClassNames}
    >
      <DatePicker
        wrapperClassName="branded-datepicker"
        id={id}
        className={className}
        readOnly={isReadOnly}
        selected={value}
        placeholderText={placeholderText}
        autoComplete="off"
        dateFormat={timeFormat}
        timeFormat={timeFormat}
        disabled={disabled}
        locale={getLocale()}
        open={isOpen}
        popperPlacement={placement}
        showPopperArrow={false}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        customInput={
          <Input
            id={id ?? ""}
            size={size}
            iconAfter={DurationSVG}
            label={label}
            tooltipContent={tooltipContent}
            containerAttrs={{ className: required ? "required" : "" }}
            data-lpignore="true"
          />
        }
        onFocus={(): void => setIsOpen(true)}
        onClickOutside={handleCalendarClose}
        onChange={onChangeDate}
        onChangeRaw={handleChangeRaw}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
export default TimeInput;

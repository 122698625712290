import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { Course } from "types/entities";
import { getCourseRestriction } from "@utils/helpers";
import { useConfigurationStore } from "@stores";
import Price from "@components/Price/Price";

export type CatalogCourseCardFooterProps = {
  course: Course;
  children?: never;
};

const UnenrolledActions: FC<CatalogCourseCardFooterProps> = ({ course }) => {
  const { userProfileData, gamificationSettings, catalogSettings } = useConfigurationStore();
  const { level: userLever = 0, subscription } = userProfileData ?? {};
  const { expires_at, price, discounted_price, level: courseLevel = 0 } = course;
  const hasRestriction = getCourseRestriction(course).length > 0;
  const hasExpirationResctriction = Boolean(expires_at);
  const hasPrice = price && price.amount !== 0;
  const isLevelEnabled = Boolean(gamificationSettings?.levels?.enabled);
  const unlocksOnHigherLevel = isLevelEnabled && Number(userLever) < Number(courseLevel);

  // If a subscription is active via catalog settings and the course has price and the couse has no level restriction
  if (catalogSettings?.subscription.enabled && hasPrice && !unlocksOnHigherLevel) {
    return (
      <Button block className="footer-btn">
        {subscription?.status === "active" ? (
          // For adding the course into the subscription
          <Price value={0} prevValue={discounted_price ? discounted_price.amount : price.amount} />
        ) : (
          // For subscribing
          <Text fontSize="sm" as="div" className="subscribe-btn-text">
            {t("general.subscribe")} (
            <Price value={catalogSettings?.subscription.price?.amount ?? 0} />)
          </Text>
        )}
      </Button>
    );
  }

  return (
    <>
      {/* TODO: Check if the following code can be simplified */}

      {/* If the user is not enrolled to the course, we check if we have a price (only if is not lvl restricted) */}
      {hasPrice && !unlocksOnHigherLevel ? (
        <Button block className="footer-btn">
          <Price
            value={discounted_price ? discounted_price.amount : price.amount}
            prevValue={discounted_price ? price.amount : undefined}
          />
        </Button>
      ) : (
        <Button
          block
          className="footer-btn"
          color={
            hasRestriction
              ? hasExpirationResctriction && !unlocksOnHigherLevel
                ? "danger"
                : "secondary"
              : "primary"
          }
        >
          {hasRestriction ? getCourseRestriction(course) : t("courseCatalog.getCourse")}
        </Button>
      )}
    </>
  );
};

export default UnenrolledActions;

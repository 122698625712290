import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { questionsInfoContainer } from "./styles";

type QuestionsInfoProps = {
  totalQuestions: number;
};

const QuestionsInfo: FC<QuestionsInfoProps> = ({ totalQuestions }) => {
  return (
    <div css={questionsInfoContainer}>
      <Text
        fontSize="sm"
        dangerouslySetInnerHTML={{
          __html: t("test.questionsTotalNumber", {
            count: totalQuestions,
          }),
        }}
      />
    </div>
  );
};

export default QuestionsInfo;

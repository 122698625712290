import React from "react";
import { t } from "i18next";
import {
  OpenedMessageSVG,
  SettingsSVG,
  FolderSVG,
  MyCoursesSVG,
  BadgeIconSVG,
  ChatCloudsSVG,
  CalendarSVG,
  CatalogSVG,
  GroupsSVG,
} from "@epignosis_llc/gnosis/icons";
import { URLS } from "@constants/urls";
import { useConfigurationStore } from "@stores";
import { userHasRequiredPermissions } from "@utils/permissions";
import { messagesPermissions } from "@utils/permissions/messages";
import { profilePermissions } from "@utils/permissions/profile";
import { reportsPermissions } from "@utils/permissions/reports";
import { coursesPermissions } from "@utils/permissions/courses";
import { discussionsPermissions } from "@utils/permissions/discussions";
import { catalogPermissions } from "@utils/permissions/catalog";
import { gamificationPermissions } from "@utils/permissions/gamification";
import { SystemOption } from "types/entities/Spotlight";
import { calendarPermissions } from "@utils/permissions/calendar";
import authService from "@utils/services/AuthService";
import {
  AdditionEventSVG,
  AllUsersSVG,
  BranchIconSVG,
  CategoriesSVG,
  CertificateWithViewboxSVG,
  TimelineSVG,
} from "@assets/icons";
import { usersPermissions } from "@utils/permissions/users";
import { categoriesPermissions } from "@utils/permissions/categories";
import { groupPermissions } from "@utils/permissions/groups";
import { branchPermissions } from "@utils/permissions/branches";
import userRoles from "@constants/userRoles";
import { resources } from "@utils/i18n";

const { read: messagesRead, create: messagesCreate } = messagesPermissions;
const { read: profileRead } = profilePermissions;
const { read: coursesRead, create: coursesCreate, union_read: unionRead } = coursesPermissions;
const { read: discussionsRead, create: discussionsCreate } = discussionsPermissions;
const { read: catalogRead } = catalogPermissions;
const { read: gamificationRead } = gamificationPermissions;
const { read: calendarRead } = calendarPermissions;
const { getState } = useConfigurationStore;
const { read: usersRead, create: usersCreate } = usersPermissions;
const { read: categoriesRead, create: categoryCreate } = categoriesPermissions;
const { read: groupRead, create: groupCreate } = groupPermissions;
const { read: branchRead } = branchPermissions;
const {
  read: reportsRead,
  // courseReports,
  // userReports,
  // testReports,
  // surveyReports,
  // assignmentReports,
  // iltReports,
  // groupReports: userGroupReports,
  // branchReports,
  timeline,
} = reportsPermissions;

export type SystemOptions = {
  [key: string]: SystemOption;
};

const languages = Object.keys(resources);

const getTranslations = (key: string): string[] =>
  languages.reduce((array, lng) => {
    array.push(t(`systemOptions.${key}`, { lng }).toLowerCase());
    return array;
  }, [] as string[]);

// apply profile changes

const getSystemOptions = (userRole: string): SystemOptions => {
  return {
    inboxMessages: {
      id: "inboxMessages",
      searchArray: getTranslations("inboxMessages"),
      permissions: [messagesRead],
      redirectUrl: URLS.user.messages,
      icon: <OpenedMessageSVG height={32} />,
    },
    myProfile: {
      id: "myProfile",
      searchArray: getTranslations("myProfile"),
      permissions: [profileRead],
      redirectUrl: URLS.user.profile,
      icon: <SettingsSVG height={32} />,
    },
    myFiles: {
      id: "myFiles",
      searchArray: getTranslations("myFiles"),
      permissions: [profileRead],
      redirectUrl: URLS.user.files,
      icon: <FolderSVG height={32} />,
      isEnabled: () => userRole !== userRoles.ADMINISTRATOR,
    },
    myCertificates: {
      id: "myCertificates",
      searchArray: getTranslations("myCertificates"),
      permissions: [reportsRead],
      redirectUrl: URLS.user.certificates,
      icon: <CertificateWithViewboxSVG height={32} />,
      isEnabled: () => userRole === userRoles.LEARNER,
    },
    myGroups: {
      id: "myGroups",
      searchArray: getTranslations("myGroups"),
      permissions: [profileRead],
      redirectUrl: URLS.user.groups,
      icon: <GroupsSVG height={32} />,
    },
    myCourses: {
      id: "myCourses",
      searchArray: getTranslations("myCourses"),
      permissions: [coursesRead],
      redirectUrl: URLS.user.courses, // On MainSearch.tsx we handle the url for adm and instr
      icon: <MyCoursesSVG height={32} />,
    },
    myBadges: {
      id: "myBadges",
      searchArray: getTranslations("myBadges"),
      permissions: [gamificationRead],
      isEnabled: () => Boolean(getState().gamificationSettings?.badges?.enabled),
      redirectUrl: URLS.user.badges,
      icon: <BadgeIconSVG height={32} />,
    },
    discussions: {
      id: "discussions",
      searchArray: getTranslations("discussions"),
      permissions: [discussionsRead],
      redirectUrl: URLS.user.discussions,
      icon: <ChatCloudsSVG height={32} />,
    },
    calendar: {
      id: "calendar",
      searchArray: getTranslations("calendar"),
      permissions: [calendarRead],
      redirectUrl: URLS.user.calendar,
      icon: <CalendarSVG height={32} />,
    },
    catalog: {
      id: "catalog",
      searchArray: getTranslations("catalog"),
      permissions: [catalogRead],
      redirectUrl: URLS.catalog.index,
      icon: <CatalogSVG height={32} />,
    },
    allUsers: {
      id: "allUsers",
      searchArray: getTranslations("allUsers"),
      permissions: [usersRead],
      redirectUrl: URLS.users.users,
      icon: <AllUsersSVG height={32} />,
    },
    allCourses: {
      id: "allCourses",
      searchArray: getTranslations("allCourses"),
      permissions: [unionRead],
      redirectUrl: URLS.courses.courses,
      icon: <MyCoursesSVG height={32} />,
    },
    categories: {
      id: "categories",
      searchArray: getTranslations("categories"),
      permissions: [categoriesRead],
      redirectUrl: URLS.categories.categories,
      icon: <CategoriesSVG height={32} />,
    },
    groups: {
      id: "groups",
      searchArray: getTranslations("groups"),
      permissions: [groupRead],
      redirectUrl: URLS.groups.groups,
      icon: <GroupsSVG height={32} />,
    },
    branches: {
      id: "branches",
      searchArray: getTranslations("branches"),
      permissions: [branchRead],
      redirectUrl: URLS.branches.branches,
      icon: <BranchIconSVG height={32} />,
    },
    // notifications: {
    //   id: "notifications",
    //   searchArray: getTranslations("notifications"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: "",
    // },
    // automations: {
    //   id: "automations",
    //   searchArray: getTranslations("automations"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: "",
    // },
    // import: {
    //   id: "import",
    //   searchArray: getTranslations("import"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: "",
    // },
    // export: {
    //   id: "export",
    //   searchArray: getTranslations("export"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: "",
    // },
    // reports: {
    //   //TODO: Add custom reports
    //   id: "reports",
    //   searchArray: getTranslations("reports"),
    //   permissions: [
    //     courseReports,
    //     userReports,
    //     testReports,
    //     surveyReports,
    //     assignmentReports,
    //     iltReports,
    //     userGroupReports,
    //     branchReports,
    //   ],
    //   redirectUrl: URLS.reports.reports,
    //   icon: <ReportsSVG height={32} />,
    //   requireAllPermissions: false,
    // },
    // userSettings: {
    //   id: "userSettings",
    //   searchArray: getTranslations("userSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // themesSettings: {
    //   id: "themesSettings",
    //   searchArray: getTranslations("themesSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // certificationSettings: {
    //   id: "certificationSettings",
    //   searchArray: getTranslations("certificationSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // gamificationSettings: {
    //   id: "gamificationSettings",
    //   searchArray: getTranslations("gamificationSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // ecommerceSettings: {
    //   id: "eCommerceSettings",
    //   searchArray: getTranslations("eCommerceSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // domainSettings: {
    //   id: "domainSettings",
    //   searchArray: getTranslations("domainSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // subscriptionSettings: {
    //   id: "subscriptionSettings",
    //   searchArray: getTranslations("subscriptionSettings"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <GearIconSVG height={32} />,
    // },
    // billingHistory: {
    //   id: "billingHistory",
    //   searchArray: getTranslations("billingHistory"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <BillingSVG height={32} />,
    // },
    addUser: {
      id: "addUser",
      searchArray: getTranslations("addUser"),
      permissions: [usersCreate],
      redirectUrl: URLS.users.create,
      icon: <AdditionEventSVG height={32} />,
    },
    addCourse: {
      id: "addCourse",
      searchArray: getTranslations("addCourse"),
      permissions: [coursesCreate],
      redirectUrl: "",
      icon: <AdditionEventSVG height={32} />,
    },
    addCategory: {
      id: "addCategory",
      searchArray: getTranslations("addCategory"),
      permissions: [categoryCreate],
      redirectUrl: URLS.categories.categories,
      icon: <AdditionEventSVG height={32} />,
    },
    addGroup: {
      id: "addGroup",
      searchArray: getTranslations("addGroup"),
      permissions: [groupCreate],
      redirectUrl: URLS.groups.create,
      icon: <AdditionEventSVG height={32} />,
    },
    // addBranch: {
    //   id: "addBranch",
    //   searchArray: getTranslations("addBranch"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <AdditionEventSVG height={32} />,
    // },
    // addNotification: {
    //   id: "addNotification",
    //   searchArray: getTranslations("addNotification"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <AdditionEventSVG height={32} />,
    // },
    // addUserType: {
    //   id: "addUserType",
    //   searchArray: getTranslations("addUserType"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <AdditionEventSVG height={32} />,
    // },
    // infographic: {
    //   id: "infographic",
    //   searchArray: getTranslations("infographic"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <InfographicSVG height={32} />,
    // },
    // userReports: {
    //   id: "userReports",
    //   searchArray: getTranslations("userReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // courseReports: {
    //   id: "courseReports",
    //   searchArray: getTranslations("courseReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // branchReports: {
    //   id: "branchReports",
    //   searchArray: getTranslations("branchReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // groupReports: {
    //   id: "groupReports",
    //   searchArray: getTranslations("groupReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // scormReports: {
    //   id: "scormReports",
    //   searchArray: getTranslations("scormReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // testReports: {
    //   id: "testReports",
    //   searchArray: getTranslations("testReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // surveyReports: {
    //   id: "surveyReports",
    //   searchArray: getTranslations("surveyReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // assignmentReports: {
    //   id: "assignmentReports",
    //   searchArray: getTranslations("assignmentReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // iltReports: {
    //   id: "iltReports",
    //   searchArray: getTranslations("iltReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    // customReports: {
    //   id: "customReports",
    //   searchArray: getTranslations("customReports"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <ReportsSVG height={32} />,
    // },
    timeline: {
      id: "timeline",
      searchArray: getTranslations("timeline"),
      permissions: [timeline],
      redirectUrl: URLS.reports.reports,
      icon: <TimelineSVG height={32} />,
      isEnabled: () => userRole !== userRoles.LEARNER,
    },
    // myTimeline: {
    //   id: "myTimeline",
    //   searchArray: getTranslations("myTimeline"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <TimelineSVG height={32} />,
    // },
    // myInfographic: {
    //   id: "myInfographic",
    //   searchArray: getTranslations("myInfographic"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <InfographicSVG height={32} />,
    // },
    addDiscussion: {
      id: "addDiscussion",
      searchArray: getTranslations("addDiscussion"),
      permissions: [discussionsRead, discussionsCreate],
      redirectUrl: URLS.user.newDiscussion,
      icon: <AdditionEventSVG height={32} />,
    },
    // addEvent: {
    //   id: "addEvent",
    //   searchArray: getTranslations("addEvent"),
    //   permissions: [],
    //   redirectUrl: URLS.user.calendar,
    //   icon: <AdditionEventSVG height={32} />,
    // },
    // conferences: {
    //   id: "conferences",
    //   searchArray: getTranslations("conferences"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: "",
    // },
    // addConference: {
    //   id: "addConference",
    //   searchArray: getTranslations("addConference"),
    //   permissions: [],
    //   redirectUrl: "",
    //   icon: <AdditionEventSVG height={32} />,
    // },
    sendMessage: {
      id: "sendMessage",
      searchArray: getTranslations("sendMessage"),
      permissions: [messagesCreate],
      redirectUrl: URLS.user.newMessage,
      icon: <OpenedMessageSVG height={32} />,
    },
  };
};

export const getSystemResutls = (inputValue: string): SystemOption[] => {
  const userRole = authService.getDefaultRole();
  const userPermissions = getState().userProfileData?.permissions[userRole] ?? [];
  const systemOptions = getSystemOptions(userRole);

  const systemOptionsKeys = Object.keys(systemOptions).filter((key) => {
    const option = systemOptions[key];
    const requireAllPermissions = option.requireAllPermissions ?? true;

    // user don't have permission to see this option
    if (option.permissions.length > 0) {
      if (!userHasRequiredPermissions(option.permissions, userPermissions, requireAllPermissions))
        return false;
    }

    // user can't see this option because of isEnabled special case
    if (option?.isEnabled) {
      if (!option.isEnabled()) return false;
    }

    // check if input string exists in the option search array
    return option.searchArray.some((item: string) => item.includes(inputValue.toLowerCase()));
  });

  return systemOptionsKeys.map((key) => systemOptions[key]);
};

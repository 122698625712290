import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  background: string;
  backgroundHover: string;
  backgroundHoverShadow: string;
  color: string;
  colorHover: string;
  hamburgerBackground: string;
  border: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  background: colors.primary.base,
  backgroundHover: colors.primary.darker,
  backgroundHoverShadow: colors.primary.darker,
  color: colors.white,
  colorHover: colors.white,
  hamburgerBackground: colors.white,
  border: colors.primary.base,
});

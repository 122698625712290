import React, { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, FormError, Modal, Text } from "@epignosis_llc/gnosis";
import { PrivateSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { AxiosError } from "axios";
import { handlePaymentErrors, invalidCouponErrors } from "@errors";
import PrerequisitesInfo from "./PrerequisitesInfo";
import { modalBody, modalFooter } from "./styles";
import PaymentTabs from "./PaymentTabs";
import CouponForm from "./CouponForm";
import { Course } from "types/entities";
import { useConfigurationStore } from "@stores";
import { Bundle, CatalogPurchase, CatalogSettings, Processors } from "types/entities/Catalog";
import { postCatalogPurchase } from "@api/catalog";
import queryKeys from "@constants/queryKeys";
import { generalNotification, groupBy } from "@utils/helpers";
import { Price } from "types/entities/Common";
import localStorageKeys from "@constants/localStorageKeys";

export type PaymentModalProps = {
  paymentData: Course | Bundle;
  isOpen: boolean;
  onClose: () => void;
  categoryText?: string;
};

export type NewPriceData = {
  newPrice: Price;
  coupon: string;
};

function isCourse(paymentData: Course | Bundle): paymentData is Course {
  return (paymentData as Course).category !== undefined;
}

const getCanEnroll = (
  processors: CatalogSettings["processors"] | undefined,
  hasEnoughCredits: boolean,
  currentProcessor: Processors | "",
  price: Price | null,
): boolean => {
  if (!processors) return false; // If no processors are available, return false
  if (!price?.amount) return true; // If we dont have a price, return true
  if (currentProcessor === "credits") return hasEnoughCredits; // If we have a credits as processors, check if we have enough credits

  return true; // If the processor is stripe or paypal return true
};

const getDependentCoursesNum = (course: Course): number | "multi" => {
  if (course.rules.prerequisites.length === 0) return 0;

  const groupedSets = groupBy(
    course.rules.prerequisites,
    (prerequisiteItem) => prerequisiteItem.rule_set,
  );

  const filteredSets = Object.values(groupedSets).map((grouppedSet) =>
    grouppedSet.filter((item) => !item.completion_status),
  );

  // If we only have one set of prerequisites
  if (filteredSets.length === 1) return filteredSets[0].length;

  // If any of the sets have zero length
  if (filteredSets.some((item) => item.length === 0)) return 0;

  return "multi";
};

const PaymentModal: FC<PaymentModalProps> = ({ paymentData, isOpen, onClose, categoryText }) => {
  const queryClient = useQueryClient();
  const { catalogSettings, userProfileData } = useConfigurationStore();
  const [newPaymentData, setNewPaymentData] = useState<Course | Bundle>(paymentData);
  const [showInvalidCouponMessage, setShowInvalidCouponMessage] = useState(false);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const { processors, coupons_enabled } = catalogSettings ?? {};
  const dataType = isCourse(newPaymentData) ? "course" : newPaymentData.type;
  const [activeTab, setActiveTab] = useState<number>(0);
  const hasEnoughCredits = newPaymentData.discounted_price
    ? newPaymentData.discounted_price.amount <= (userProfileData?.credits as number)
    : (newPaymentData.price?.amount as number) <= (userProfileData?.credits as number);

  const currentProcessor = processors ? processors.sort()[activeTab] : "";

  const canEnroll = getCanEnroll(
    processors,
    hasEnoughCredits,
    currentProcessor,
    newPaymentData.price,
  );
  const dependentCoursesNum =
    dataType === "course" ? getDependentCoursesNum(paymentData as Course) : 0;
  const isStripeOrPaypal = ["stripe", "paypal"].includes(currentProcessor);

  const { mutate: paymentMutation, isLoading: paymentMutationLoading } = useMutation(
    [queryKeys.courseEnrollment],
    (newPaymentData: CatalogPurchase) => postCatalogPurchase(newPaymentData),
    {
      onSuccess: (res) => {
        const isCourse = dataType === "course";

        queryClient.invalidateQueries([queryKeys.userProfile]); // Invalidate user credits

        if (isCourse) {
          queryClient.invalidateQueries([queryKeys.course, newPaymentData.id.toString()]);
        } else {
          // Data type is bundle
          queryClient.invalidateQueries([queryKeys.catalog]);
          queryClient.invalidateQueries([queryKeys.bundles]);
        }

        if (isStripeOrPaypal && res._data.redirect_url) {
          // Create a payment status local storage key in order to capture correctly the payment event from the return_url.
          localStorage.setItem(localStorageKeys.PAYMENT_STATUS, "pending");
          window.location.replace(res._data.redirect_url);
        } else {
          generalNotification(
            "success",
            t(isCourse ? "general.paymentSuccessCourse" : "general.paymentSuccessBundle", {
              name: newPaymentData.name,
            }),
          );
          handleModalClose();
        }
      },

      onError: (error: AxiosError) => {
        handlePaymentErrors(error, (foundError) => {
          // If the found error is for invalid coupon, show the error message and reset the coupon.
          setNewPaymentData(paymentData);
          if (foundError && invalidCouponErrors.includes(foundError?.id)) {
            setShowInvalidCouponMessage(true);
            setDiscountCoupon("");
          } else {
            generalNotification(
              "error",
              t(foundError?.errorMsg ?? "general.somethingWentWrongPleaseTryAgain"),
            );
          }
        });
      },
    },
  );

  const handlePayment = (): void => {
    const pathURL = window.location.pathname;

    const { id, discounted_price, price } = newPaymentData;

    // Build the object to mutate
    const paymentPostData = {
      id,
      type: dataType,
      processor: currentProcessor,
      price: discounted_price ? discounted_price.amount : price?.amount,
      ...(catalogSettings?.global_discount && { discount: catalogSettings.global_discount }),
      ...(discountCoupon.length > 0 && { coupon: discountCoupon }),
      ...(isStripeOrPaypal && {
        // Add status and the currrrent processor on the url to capture the payment event
        return_path: `${pathURL}?status=success&processor=${currentProcessor}&type=payment`,
        cancel_path: pathURL,
      }),
    } as CatalogPurchase;

    paymentMutation(paymentPostData);
  };

  const handleValidCoupon = (newData: NewPriceData): void => {
    const { newPrice, coupon } = newData;

    const paymentData = { ...newPaymentData, discounted_price: newPrice };
    setNewPaymentData(paymentData);
    coupon.length > 0 && setDiscountCoupon(coupon);
  };

  const handleModalClose = (): void => {
    setNewPaymentData(paymentData); //reset the paymentData
    setDiscountCoupon("");
    setShowInvalidCouponMessage(false);
    onClose();
  };

  useEffect(() => {
    setNewPaymentData(paymentData);
  }, [paymentData]);

  useEffect(() => {
    if (discountCoupon.length > 0) setShowInvalidCouponMessage(false);
  }, [discountCoupon]);

  // If there is an update on the number of processors and we have no processors, close the modal
  useEffect(() => {
    if (processors?.length === 0) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processors]);

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Modal.Header style={{ paddingBottom: "0.75rem", border: 0 }}>
        <Text fontSize="xl" weight="700">
          {t("general.payment")}
        </Text>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <div css={modalBody}>
          {Boolean(dependentCoursesNum) && (
            <PrerequisitesInfo
              coursesNum={dependentCoursesNum === "multi" ? 0 : dependentCoursesNum}
              hasMultiPrerequisites={dependentCoursesNum === "multi"}
            />
          )}

          {showInvalidCouponMessage && (
            <div className="invalid-coupon-container">
              <FormError>
                <Text fontSize="sm" as="div">
                  {t("payments.invalidCouponInfo")}
                </Text>
              </FormError>
            </div>
          )}

          {processors && (
            <PaymentTabs
              data={newPaymentData}
              processors={processors}
              setActiveTab={setActiveTab}
              categoryText={categoryText}
            />
          )}

          {/* If coupons are enabled  */}
          {Boolean(coupons_enabled) && (
            <CouponForm
              id={newPaymentData.id}
              dataType={dataType}
              handleValidCoupon={handleValidCoupon}
            />
          )}

          {/* If we have payment with stripe or paypal, show the redirection information */}
          {isStripeOrPaypal && (
            <div className="redirection-info">
              <PrivateSVG height={24} />
              <Text fontSize="sm">
                {t("payments.redirectSafely", {
                  name: currentProcessor.charAt(0).toUpperCase() + currentProcessor.slice(1),
                })}
              </Text>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button onClick={handlePayment} isLoading={paymentMutationLoading} disabled={!canEnroll}>
            {t("general.checkout")}
          </Button>
          <Button color="secondary" onClick={handleModalClose} className="cancel-btn">
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentModal;

import React, { FC } from "react";
import HoverActions from "./HoverActions";
import EnrolledActions from "./EnrolledActions";
import UnenrolledActions from "./UnenrolledActions";
import { Course } from "types/entities";

export type CatalogCourseCardFooterProps = {
  isHovering?: boolean;
  course: Course;
  children?: never;
};

const CatalogCourseCardFooter: FC<CatalogCourseCardFooterProps> = ({ isHovering, course }) => {
  const { availability } = course;
  const isUserEnrolled = Boolean(availability);

  if (isHovering) return <HoverActions />;

  return isUserEnrolled ? (
    <EnrolledActions course={course} />
  ) : (
    <UnenrolledActions course={course} />
  );
};

export default CatalogCourseCardFooter;

import React, { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, InputError, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import TestIntroHeader from "../TestIntroHeader";
import FooterNavigationButtons from "../FooterNavigationButtons";
import QuestionsInfo from "../QuestionsInfo";
import { passwordFormContainer } from "./styles";
import queryKeys from "@constants/queryKeys";
import { postUnitPassword } from "@api/courses";
import { generalNotification } from "@utils/helpers";
import FooterWrapper from "@components/Units/Footers/components/FooterWrapper";
import { MyUnitRes } from "types/responses";
import { TestUnit } from "types/entities";
import { PasswordFormValidationSchema } from "@utils/validation";
import { FooterButton } from "@components/ReusableComponents";
import { IconEnterSVG } from "@assets/images";

type PasswordFormProps = {
  unitRes: MyUnitRes;
  isPublic?: boolean;
  children?: never;
};

type UnitData = {
  password: string;
};

const PasswordForm: FC<PasswordFormProps> = ({ unitRes, isPublic = false }) => {
  const unit = unitRes._data as TestUnit;
  const { handleSubmit, register, formState, setError } = useForm<UnitData>({
    mode: "onChange",
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(PasswordFormValidationSchema),
  });
  const { errors, dirtyFields, isSubmitting } = formState;
  const totalQuestions = unitRes._meta.total_questions;

  const queryClient = useQueryClient();
  const { mutateAsync: postPassword } = useMutation(
    [queryKeys.unitPassword, { unitId: unit.id.toString() }],
    (data: UnitData) => postUnitPassword(unit.id.toString(), data.password),
    {
      onError: () => {
        setError("password", { type: "manual" });
        generalNotification("error", <p>{t("notifications.incorrectPassword")}</p>);
      },
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.unit, unit.id.toString()]);
      },
    },
  );

  return (
    <>
      <article className="unit-wrapper">
        <form
          css={passwordFormContainer}
          id="password-form"
          className="unit-container"
          autoComplete="off"
          onSubmit={handleSubmit((data) => postPassword(data))}
        >
          <TestIntroHeader unitRes={unitRes} />
          <Text
            fontSize="sm"
            weight="700"
            as="div"
            className="label"
            dangerouslySetInnerHTML={{
              __html: t("test.passwordProtected"),
            }}
          />

          <div className="input-container">
            <Input
              {...register("password")}
              status={errors.password ? "error" : "valid"}
              type="password"
              id="password"
              data-lpignore="true"
              iconAfter={IconEnterSVG}
            />
            {errors.password && <InputError>{errors.password.message}</InputError>}
          </div>
        </form>
      </article>
      <QuestionsInfo totalQuestions={totalQuestions} />
      <FooterWrapper>
        <FooterNavigationButtons isPublic={isPublic}>
          <FooterButton
            unit={unit}
            type="submit"
            form="password-form"
            disabled={!dirtyFields.password}
            isLoading={isSubmitting}
            buttonText={t("test.startTest")}
            onClick={handleSubmit((data) => postPassword(data))}
          />
        </FooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default PasswordForm;

import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { t } from "i18next";
import FooterWrapper from "@components/Units/Footers/components/FooterWrapper";
import { Course, MyUnit, QuestionAnswers, Section, QuestionType } from "types/entities";
import UnitFooterNavigationButtons from "@components/Units/Footers/components/UnitFooterNavigationButtons";
import { FooterButton } from "@components/ReusableComponents";
import { putCompleteUnitWithQuestion } from "@api/courses";
import {
  generalNotification,
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  unitUserAnwsersEmpty,
} from "@utils/helpers";
import queryKeys from "@constants/queryKeys";
import { HandledError, handleUnitErrors } from "@errors";

type CompleteWithQuestionFooterProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  userAnswers: QuestionAnswers;
  setUserAnswers: (userAnswers: QuestionAnswers) => void;
  setIsAnswerWrong: (isAnswerWrong: boolean) => void;
  isPublic?: boolean;
};

const CompleteWithQuestionFooter: FC<CompleteWithQuestionFooterProps> = (props) => {
  const {
    course,
    sections,
    unit,
    userAnswers,
    setUserAnswers,
    setIsAnswerWrong,
    isPublic = false,
  } = props;
  const question = unit?.completion_method?.question;
  const unitId = unit?.id.toString();
  const courseId = course?.id.toString();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isCompleted = unit?.progress?.status === "completed";
  const flatUnits = getFlatUnits(sections);
  const { nextUnit } = (unit && getPrevAndNextUnits(flatUnits, unit.id)) || {};
  const nextUnitLink = goToNextUnitLink(false, nextUnit, course, isPublic);
  const isUnitWithQuestion = unit.completion_method?.type === "question";

  // on plain units isContinueButtonTraversalDisabled is false because you can always complete it and you cannot fail it
  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    false,
    course.progress?.completion_status,
    unit.progress?.status,
  );

  const isFooterButtonDisabled =
    (question &&
      unit.progress?.status !== "completed" &&
      unitUserAnwsersEmpty(userAnswers, question)) ||
    continueButtonDisabled;

  //plain unit completion method "with a question"
  const { mutate: completeWithQuestionMutation, isLoading: isCompleteWithQuestionMutationLoading } =
    useMutation(() => putCompleteUnitWithQuestion(userAnswers, unitId, question?.id as number), {
      onError: (error: AxiosError) => {
        const handleError = (error: HandledError | null): void => {
          if (error?.id === "conflict.unit_completion_not_allowed.incorrectanswers") {
            setIsAnswerWrong(true);
            error?.errorMsg && generalNotification("info", t(error.errorMsg));
          }
        };
        handleUnitErrors(error, true, handleError);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);
        queryClient.invalidateQueries([queryKeys.unit, unitId]);
        setIsAnswerWrong(false);

        //if mutation is successful, navigate to the next unit
        if (nextUnitLink !== "#") navigate(nextUnitLink);
      },
    });

  const onCompleteWithQuestion = (): void => {
    if (!isCompleted) {
      completeWithQuestionMutation();
    } else {
      if (nextUnitLink !== "#") navigate(nextUnitLink);
    }
  };

  useEffect(() => {
    if (
      unit.completion_method &&
      isUnitWithQuestion &&
      unit.completion_method.question?.type === QuestionType.Ordering
    ) {
      setUserAnswers(unit.completion_method.question.answers.possible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnitWithQuestion, unit]);

  return (
    <>
      <FooterWrapper>
        <UnitFooterNavigationButtons
          course={course}
          sections={sections}
          unit={unit}
          isPublic={isPublic}
        >
          {question && (
            <FooterButton
              unit={unit}
              name="Submit question"
              isLoading={isCompleteWithQuestionMutationLoading}
              onClick={onCompleteWithQuestion}
              disabled={isFooterButtonDisabled}
              buttonText={!isCompleted ? t("general.submitAnswer") : ""}
            />
          )}
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default CompleteWithQuestionFooter;

import { setCookie } from "@utils/helpers";
import { ContentTokensRes } from "types/responses";

export const setupContentCoockies = (contentTokens: ContentTokensRes["_data"]): void => {
  const cookies = Object.entries(contentTokens);

  cookies.forEach(([key, value]) => {
    setCookie(key, value, "/", ".talentlms.com");
  });
};

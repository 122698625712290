import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import { SerializedStyles } from "@emotion/react";
import { testIntroHeaderContainer } from "./styles";
import { TestIntroSVG } from "@assets/images";
import { MyUnitRes } from "types/responses";
import { TestUnit } from "types/entities";

type TestIntroHeaderProps = {
  unitRes: MyUnitRes;
  children?: never;
};

const TestIntroHeader: FC<TestIntroHeaderProps> = ({ unitRes }) => {
  const unit = unitRes._data as TestUnit;
  const {
    content,
    test_options: { maxtimeallowed },
  } = unit;
  const { xs } = useResponsive();

  return (
    <div css={(): SerializedStyles => testIntroHeaderContainer(content || maxtimeallowed)}>
      {xs && <TestIntroSVG className="icon" />}

      {content && (
        <div className="description">
          <Text fontSize="sm">
            <span dangerouslySetInnerHTML={{ __html: content }}></span>
          </Text>
        </div>
      )}

      {maxtimeallowed && (
        <Text fontSize="sm" weight="700" as="div" className="duration">
          {t("test.minutesToComplete", { count: maxtimeallowed })}
        </Text>
      )}
    </div>
  );
};

export default TestIntroHeader;

import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { PlayUnitSVG, ShowIconSVG } from "@epignosis_llc/gnosis/icons";
import CardBody from "../components/CardBody";
import CardFooter from "../components/CardFooter";
import CardHeader from "../components/CardHeader";
import { bodyWrapper } from "../styles";
import MyCourseCardFooter from "./components/MyCourseCardFooter";
import { Course } from "types/entities";
import { URLS } from "@constants/urls";
import permissions from "@utils/permissions";
import { useConfigurationStore } from "@stores";
import { downloadFile } from "@utils/helpers";
import { CertificateWithViewboxSVG } from "@assets/icons";

const { Header, Body } = Card;

export type CourseCardProps = {
  course: Course;
};
const MyCourseCard: FC<CourseCardProps> = ({ course }) => {
  const [isHovering, setIsHovering] = useState(false);
  const { domainSettings } = useConfigurationStore();

  // permissions related
  const { canAccessCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsView = canAccessCourseUnits();
  const showCourseInfoPage = Boolean(domainSettings?.show_course_info_page && allowCourseUnitsView);

  const unitContinueLink = URLS.user.createUnitLink({
    courseId: course.id.toString(),
    unitId: "continue",
  });
  const courseLink =
    showCourseInfoPage || !course.availability?.is_available
      ? URLS.user.createCourseLink({ courseId: course.id.toString() })
      : unitContinueLink;

  const actions = [
    ...(course.progress?.certificate
      ? [
          {
            label: t("courseCatalog.downloadCertificate"),
            icon: CertificateWithViewboxSVG,
            onClick: async (): Promise<void> =>
              await downloadFile(
                course.progress?.certificate?.url ?? "",
                course.progress?.certificate?.course_name ?? "",
              ),
          },
        ]
      : []),
  ];

  return (
    <Card
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
    >
      <Header ratio={[3, 2]}>
        <CardHeader
          course={course}
          actions={actions}
          allowCourseUnitsView={allowCourseUnitsView}
          courseLink={courseLink}
          icon={showCourseInfoPage ? ShowIconSVG : PlayUnitSVG}
        />
      </Header>
      <Link
        to={allowCourseUnitsView ? courseLink : "#"}
        css={bodyWrapper}
        className={!allowCourseUnitsView ? "disabled" : ""}
        data-testid="card-body"
      >
        <Body>
          <CardBody course={course} className="body-container" />
        </Body>

        <CardFooter>
          <MyCourseCardFooter
            isHovering={allowCourseUnitsView ? isHovering : false}
            course={course}
          />
        </CardFooter>
      </Link>
    </Card>
  );
};

export default MyCourseCard;

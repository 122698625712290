import { css } from "@emotion/react";

export const textWithIconContainer = (theme) => css`
  display: flex;
  align-items: center;
  .prefix-icon {
    display: flex;
    align-items: center;
  }

  .text-with-icon-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .heading {
      &-black {
        color: ${theme.textWithIcon.headingBlack};
      }

      &-grey {
        color: ${theme.textWithIcon.headingGrey};
      }
    }
  }
`;

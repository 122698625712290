import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { LoginSVG } from "@assets/icons";

export type LoginActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const LoginAction: FC<LoginActionProps> = ({ tooltipContent, icon: Icon = LoginSVG, onClick }) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("users.tooltip.loginAs")} onClick={onClick}>
      <Icon height={30} />
    </ActionButton>
  );
};

export default LoginAction;

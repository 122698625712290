import React, { FC, ReactNode } from "react";
import { Button, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { CaretDownSVG } from "@epignosis_llc/gnosis/icons";
import { ButtonWithDropdownStyles } from "./styles";
import { SerializedStyles } from "@emotion/react";

type ButtonWithDropdownProps = {
  color?: "primary" | "secondary" | "danger" | "success";
  children: ReactNode;
  list: DropdownItem[];
  onButtonClick: () => void;
  onListItemClick: (item: DropdownItem) => void;
};

const ButtonWithDropdown: FC<ButtonWithDropdownProps> = ({
  color,
  children,
  list,
  onButtonClick,
  onListItemClick,
}) => {
  const hasList = list.length > 0;

  return (
    <div css={(): SerializedStyles => ButtonWithDropdownStyles(hasList)}>
      <Button color={color} className="start-button" onClick={onButtonClick}>
        {children}
      </Button>

      {hasList && (
        <>
          <div className="vertical-line"></div>
          <Dropdown list={list} onListItemSelect={onListItemClick}>
            <Button color={color} className="end-button" noGutters>
              <CaretDownSVG height={16}></CaretDownSVG>
            </Button>
          </Dropdown>
        </>
      )}
    </div>
  );
};

export default ButtonWithDropdown;

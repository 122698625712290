import { t } from "i18next";
import { gamificationNotification } from "@utils/helpers/notifications";
import { GamificationBadge } from "types/entities";
import { GamificationRes } from "types/responses";

type Events = Record<
  GamificationRes["type"],
  {
    pointsContent: (points: number) => string;
    badgeContent: (name: string, criteria: GamificationBadge["criteria"]) => string;
  }
>;

type BadgeNotificationProps = {
  content: string;
  badge: GamificationBadge;
};

// Mapping to determine dynamic content in the different gamification events
const events: Events = {
  "user.login": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.userLogin", { points: points }),
    badgeContent: (name: string, criteria: GamificationBadge["criteria"]): string =>
      t("notifications.gamification.loginBadgeUnlock", { name: name, count: criteria.count }),
  },
  "discussion.create": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.discussionCreate", { points: points }),
    badgeContent: (name: string, criteria: GamificationBadge["criteria"]): string =>
      t("notifications.gamification.discussionBadgeUnlock", { name: name, count: criteria.count }),
  },
  "discussion.reply": {
    pointsContent: (points: number): string =>
      t("notifications.gamification.discussionReply", { points: points }),
    badgeContent: (name: string, criteria: GamificationBadge["criteria"]): string =>
      t("notifications.gamification.discussionBadgeUnlock", { name: name, count: criteria.count }),
  },
};

const pointsNotification = (content: string): void => {
  gamificationNotification({
    type: "success",
    iconType: "points",
    content: content,
  });
};

const badgeNotification = ({ content, badge }: BadgeNotificationProps): void => {
  gamificationNotification({
    type: "success",
    iconType: "badge",
    content: content,
    heading: t("general.congratulations"),
    badge: badge,
  });
};

export const showGamificationNotification = (gamification: GamificationRes[]): void => {
  gamification.forEach((gamificationRes) => {
    const eventType = events[gamificationRes.type];
    const points = gamificationRes.points;
    const badges = gamificationRes.badges;

    if (points) {
      pointsNotification(eventType.pointsContent(points));
    }

    if (badges?.length > 0) {
      badges.map((badge) => {
        badgeNotification({
          content: eventType.badgeContent(badge.name, badge.criteria),
          badge: badge,
        });
      });
    }
  });
};

import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { PermissionsRoute, PrivateRoute, ExternalRoute } from "@components";
import { URLS } from "@constants/urls";
import { DashboardLayout, BasicLayout, UnitLayout, LayoutWrapper, ExternalLayout } from "@layouts";
import { discussionsPermissions } from "@utils/permissions/discussions";
import { profilePermissions } from "@utils/permissions/profile";
import { reportsPermissions } from "@utils/permissions/reports";
import { gamificationPermissions } from "@utils/permissions/gamification";
import { coursesPermissions } from "@utils/permissions/courses";
import { messagesPermissions } from "@utils/permissions/messages";
import { catalogPermissions } from "@utils/permissions/catalog";
import { calendarPermissions } from "@utils/permissions/calendar";
import CustomizeTheming from "@views/CustomizeTheming/CustomizeTheming";
import { accountPermissions } from "@utils/permissions/account";
import { featureFlags } from "@config";
import { groupPermissions } from "@utils/permissions/groups";
import { branchPermissions } from "@utils/permissions/branches";
import { categoriesPermissions } from "@utils/permissions/categories";
import { usersPermissions } from "@utils/permissions/users";
import { importExportPermissions } from "@utils/permissions/importExport";

// Lazy load views
const RootPage = React.lazy(() => import("./views/RootPage/RootPage"));
const SignIn = React.lazy(() => import("./views/SignIn/SignIn"));
const AutoLogin = React.lazy(() => import("./views/AutoLogin/AutoLogin"));
const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));
const MyCourse = React.lazy(() => import("./views/Course/MyCourse"));
const Unit = React.lazy(() => import("./views/Unit/Unit"));
const SampleUnit = React.lazy(() => import("./views/Unit/SampleUnit"));
const UnitResults = React.lazy(() => import("./views/Unit/UnitResults"));
const Question = React.lazy(() => import("./views/Unit/Question"));
const UnitContinue = React.lazy(() => import("./views/Unit/UnitContinue"));
const MyCourses = React.lazy(() => import("./views/MyCourses/MyCourses"));
const Catalog = React.lazy(() => import("./views/Catalog/Catalog"));
const Calendar = React.lazy(() => import("./views/Calendar/Calendar"));
const SearchResults = React.lazy(() => import("./views/SearchResults/SearchResults"));
const ProfileSettings = React.lazy(() => import("./views/Settings/ProfileSettings"));
const MyFiles = React.lazy(() => import("./views/MyFiles/MyFiles"));
const MyBadges = React.lazy(() => import("./views/MyBadges/MyBadges"));
const Messages = React.lazy(() => import("./views/Messages/Messages"));
const NewMessage = React.lazy(() => import("./views/Messages/NewMessage"));
const MessageThread = React.lazy(() => import("./views/Messages/MessageThread"));
const Discussions = React.lazy(() => import("./views/Discussions/Discussions"));
const NewDiscussion = React.lazy(() => import("./views/Discussions/NewDiscussion"));
const EditDiscussion = React.lazy(() => import("./views/Discussions/DiscussionEdit"));
const DiscussionThread = React.lazy(() => import("./views/Discussions/DiscussionThread"));
const MyCourseFiles = React.lazy(() => import("./views/MyCourseFiles/MyCourseFiles"));
const CatalogCourse = React.lazy(() => import("./views/Course/CatalogCourse"));
const MyGroups = React.lazy(() => import("./views/MyGroups/MyGroups"));
const MyCertificates = React.lazy(() => import("./views/MyCertificates/MyCertificates"));
const CourseEdit = React.lazy(() => import("./views/CourseEdit/CourseEdit"));
const NotFound = React.lazy(() => import("./views/Errors/NotFound"));
const SignUp = React.lazy(() => import("./views/SignUp/SignUp"));
const MyPayments = React.lazy(() => import("./views/MyPayments/MyPayments"));
const Courses = React.lazy(() => import("./views/Courses/Courses"));
const UnitEdit = React.lazy(() => import("./views/UnitEdit/UnitEdit"));
const EmailVerification = React.lazy(() => import("./views/EmailVerification/EmailVerification"));
const Branches = React.lazy(() => import("./views/Branches/Branches"));
const Branch = React.lazy(() => import("./views/Branch/Branch"));
const PasswordReset = React.lazy(() => import("./views/PasswordReset/PasswordReset"));
const Groups = React.lazy(() => import("./views/Groups/Groups"));
const Group = React.lazy(() => import("./views/Group/Group"));
const Categories = React.lazy(() => import("./views/Categories/Categories"));
const ChangePassword = React.lazy(() => import("./views/ChangePassword/ChangePassword"));
const Course = React.lazy(() => import("./views/Course/Course"));
// const SingleUnitReports = React.lazy(() => import("./views/UnitReports/Unit"));
const Users = React.lazy(() => import("./views/Users/Users"));
const User = React.lazy(() => import("./views/User/User"));
const AccountAndSettings = React.lazy(
  () => import("./views/AccountAndSettings/AccountAndSettings"),
);

const UsersSettings = React.lazy(() => import("./views/User/components/UserSettings/UserSettings"));
const GroupSettings = React.lazy(
  () => import("./views/Groups/components/GroupSettings/GroupSettings"),
);
const Reports = React.lazy(() => import("./views/Reports/Reports"));

const { read: discussionsRead, create: discussionsCreate } = discussionsPermissions;
const { read: profileRead } = profilePermissions;
const {
  read: reportsRead,
  // courseReports,
  // userReports,
  // testReports,
  // surveyReports,
  // assignmentReports,
  // iltReports,
  // groupReports: userGroupReports,
  // branchReports,
  timeline,
} = reportsPermissions;
const { read: gamificationRead } = gamificationPermissions;
const {
  read: coursesRead,
  union_read: coursesUnionRead,
  unit_read: coursesUnitRead,
  files_shared_read: coursesFilesSharedRead,
} = coursesPermissions;

const { read: messagesRead, create: messagesCreate } = messagesPermissions;
const { read: catalogRead } = catalogPermissions;
const { read: calendarRead } = calendarPermissions;
const { read: accountRead, update: accountUpdate } = accountPermissions;
const { createImport, createExport } = importExportPermissions;
const { read: usersRead, update: usersUpdate, create: usersCreate } = usersPermissions;
const { read: groupRead, create: groupCreate } = groupPermissions;
const { read: categoriesRead } = categoriesPermissions;

const { read: branchRead } = branchPermissions;
const notLearnerRoles = ["administrator", "instructor"];

const AppRoutes: FC = () => {
  return (
    <LayoutWrapper>
      <Routes>
        {/* Public pages */}
        <Route element={<BasicLayout />}>
          <Route path={URLS.root} element={<RootPage />} />
          <Route path={URLS.login} element={<SignIn />} />
          <Route path={URLS.autologin} element={<AutoLogin />} />
          <Route path={URLS.signup} element={<SignUp />} />
          <Route path={URLS.emailVerification} element={<EmailVerification />} />
          <Route path={URLS.passwordReset} element={<PasswordReset />} />
          <Route path={URLS.changePassword} element={<ChangePassword />} />
        </Route>

        {/* External view routes */}
        <Route element={<ExternalRoute />}>
          <Route element={<ExternalLayout />}>
            <Route>
              <Route path={URLS.externalCatalog.index} element={<Catalog />} />
              <Route path={URLS.externalCatalog.course} element={<CatalogCourse />} />
            </Route>
          </Route>
          <Route element={<UnitLayout />}>
            <Route>
              <Route path={URLS.externalCatalog.sampleUnit} element={<SampleUnit />} />
            </Route>
          </Route>
        </Route>

        {/* Public Course/Unit routes */}
        <Route element={<ExternalRoute />}>
          <Route element={<UnitLayout />}>
            <Route>
              <Route path={URLS.externalCatalog.publicUnit.index} element={<Unit isPublic />} />
              <Route
                path={URLS.externalCatalog.publicUnit.unitResults}
                element={<UnitResults isPublic />}
              />
              <Route
                path={URLS.externalCatalog.publicUnit.testQuestion}
                element={<Question type="test" isPublic />}
              />
              <Route
                path={URLS.externalCatalog.publicUnit.surveyQuestion}
                element={<Question type="survey" isPublic />}
              />
            </Route>
          </Route>
        </Route>

        {/* Private pages (routes) */}
        <Route element={<PrivateRoute />}>
          {/* Dashboard layout */}
          <Route element={<DashboardLayout />}>
            <Route path={URLS.dashboard} element={<Dashboard />} />

            {/* User pages */}
            <Route element={<PermissionsRoute permissions={[profileRead]} />}>
              <Route path={URLS.user.profile} element={<ProfileSettings />} />
              <Route path={URLS.user.files} element={<MyFiles />} />
              <Route path={URLS.user.groups} element={<MyGroups />} />
              <Route path={URLS.user.payments} element={<MyPayments />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[reportsRead]} />}>
              <Route path={URLS.user.certificates} element={<MyCertificates />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[gamificationRead]} />}>
              <Route path={URLS.user.badges} element={<MyBadges />} />
            </Route>

            {/* Courses pages */}
            <Route element={<PermissionsRoute roles={["learner"]} permissions={[coursesRead]} />}>
              <Route path={URLS.user.courses} element={<MyCourses />} />
            </Route>
            <Route
              element={
                <PermissionsRoute
                  roles={["learner"]}
                  permissions={[coursesRead, coursesUnitRead]}
                />
              }
            >
              <Route path={URLS.user.course} element={<MyCourse />} />
            </Route>
            <Route
              element={
                <PermissionsRoute
                  roles={["learner"]}
                  permissions={[coursesRead, coursesFilesSharedRead]}
                />
              }
            >
              <Route path={URLS.user.courseFiles} element={<MyCourseFiles />} />
            </Route>

            {/* Calendar page */}
            <Route element={<PermissionsRoute permissions={[calendarRead]} />}>
              <Route path={URLS.user.calendar} element={<Calendar />} />
            </Route>

            {/* Catalog pages */}
            <Route element={<PermissionsRoute permissions={[catalogRead]} />}>
              <Route path={URLS.catalog.index} element={<Catalog />} />
              <Route path={URLS.catalog.course} element={<CatalogCourse />} />
              <Route
                path={URLS.catalog.courseFiles}
                element={<MyCourseFiles isCatalogCourseView={true} />}
              />
            </Route>

            {/* Account and settings page */}

            {featureFlags.accountAndSettings && (
              <Route
                element={
                  <PermissionsRoute
                    permissions={[accountRead, createExport, createImport]}
                    requireAll={false}
                  />
                }
              >
                <Route path={URLS.accountAndSettings} element={<AccountAndSettings />} />
              </Route>
            )}

            {/* Categories page */}
            {featureFlags.categories && (
              <Route element={<PermissionsRoute permissions={[categoriesRead]} />}>
                <Route path={URLS.categories.categories} element={<Categories />} />
              </Route>
            )}

            {/* Discussions pages */}
            <Route element={<PermissionsRoute permissions={[discussionsRead]} />}>
              <Route path={URLS.user.singleDiscussion} element={<DiscussionThread />} />
              <Route path={URLS.user.editDiscussion} element={<EditDiscussion />} />
            </Route>
            <Route
              element={<PermissionsRoute permissions={[discussionsRead, discussionsCreate]} />}
            >
              <Route path={URLS.user.newDiscussion} element={<NewDiscussion />} />
            </Route>

            {/* Messages pages */}
            <Route element={<PermissionsRoute permissions={[messagesRead, messagesCreate]} />}>
              <Route path={URLS.user.newMessage} element={<NewMessage />} />
            </Route>
            <Route element={<PermissionsRoute permissions={[messagesRead]} />}>
              <Route path={URLS.user.inboxMessage} element={<MessageThread type="inbox" />} />
              <Route path={URLS.user.sentMessage} element={<MessageThread type="sent" />} />
            </Route>

            {/* Search pages */}
            <Route path={URLS.search.all} element={<SearchResults />} />
            <Route element={<PermissionsRoute permissions={[accountRead, accountUpdate]} />}>
              <Route path={URLS.user.customizeTheming} element={<CustomizeTheming />} />
            </Route>
          </Route>

          {/* Dashboard layout - noMainContentPadding */}
          <Route element={<DashboardLayout />}>
            {/* Messages page */}
            <Route element={<PermissionsRoute permissions={[messagesRead]} />}>
              <Route path={URLS.user.messages} element={<Messages />} />
            </Route>

            {/* Discussions page */}
            <Route element={<PermissionsRoute permissions={[discussionsRead]} />}>
              <Route path={URLS.user.discussions} element={<Discussions />} />
            </Route>
          </Route>

          {/* Unit pages */}
          <Route
            element={
              <PermissionsRoute roles={["learner"]} permissions={[coursesRead, coursesUnitRead]} />
            }
          >
            {/* Unit layout */}
            <Route element={<UnitLayout />}>
              <Route path={URLS.user.unit} element={<Unit />} />
              <Route path={URLS.catalog.sampleUnit} element={<SampleUnit />} />
              <Route path={URLS.user.unitResults} element={<UnitResults />} />
              <Route path={URLS.user.testQuestion} element={<Question type="test" />} />
              <Route path={URLS.user.surveyQuestion} element={<Question type="survey" />} />
            </Route>

            <Route path={URLS.user.unitContinue} element={<UnitContinue />} />
          </Route>

          {/****************************/}
          {/* Admin / Instructor pages */}
          {/****************************/}

          <Route element={<DashboardLayout />}>
            {/* Courses pages */}
            {featureFlags.coursesList && (
              <>
                <Route
                  element={
                    <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
                  }
                >
                  <Route path={URLS.courses.courses} element={<Courses />} />
                </Route>
              </>
            )}

            {featureFlags.courseUnits && (
              <Route
                element={
                  <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
                }
              >
                <Route path={URLS.courses.singleCourse} element={<Course />} />
              </Route>
            )}

            {/* Branches page */}
            {featureFlags.branchesList && (
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    permissions={[branchRead]}
                    requireAll={false}
                  />
                }
              >
                <>
                  <Route path={URLS.branches.branches} element={<Branches />} />
                  <Route path={URLS.branches.branch} element={<Branch />} />
                </>
              </Route>
            )}

            {/* Users page */}
            {featureFlags.usersList && (
              <>
                <Route
                  element={
                    <PermissionsRoute
                      roles={notLearnerRoles}
                      permissions={[usersRead]}
                      requireAll={false}
                    />
                  }
                >
                  <Route path={URLS.users.users} element={<Users />} />
                  <Route path={URLS.users.user} element={<User />} />
                </Route>
                <Route element={<PermissionsRoute permissions={[usersRead, usersUpdate]} />}>
                  <Route path={URLS.users.edit} element={<UsersSettings />} />
                </Route>
                <Route element={<PermissionsRoute permissions={[usersCreate]} />}>
                  <Route path={URLS.users.create} element={<UsersSettings />} />
                </Route>
              </>
            )}

            {/* Groups pages */}
            {featureFlags.groups && (
              <>
                <Route
                  element={
                    <PermissionsRoute
                      roles={notLearnerRoles}
                      permissions={[groupRead]}
                      requireAll={false}
                    />
                  }
                >
                  <Route path={URLS.groups.groups} element={<Groups />} />
                  <Route path={URLS.groups.group} element={<Group />} />
                </Route>
                <Route element={<PermissionsRoute permissions={[groupCreate]} />}>
                  <Route path={URLS.groups.create} element={<GroupSettings />} />
                </Route>
              </>
            )}

            {/* Reports pages */}
            {featureFlags.reports && (
              <Route
                element={
                  <PermissionsRoute
                    roles={notLearnerRoles}
                    // For now we only take into account the timeline permission because on reports route the only tab is the timeline
                    permissions={[
                      // courseReports,
                      // userReports,
                      // testReports,
                      // surveyReports,
                      // assignmentReports,
                      // iltReports,
                      // userGroupReports,
                      // branchReports,
                      timeline,
                    ]}
                    requireAll={false}
                  />
                }
              >
                <Route path={URLS.reports.reports} element={<Reports />} />
              </Route>
            )}

            {/* {featureFlags.singleUnitReports && (
              <Route element={<PermissionsRoute roles={notLearnerRoles} permissions={[]} />}>
                <Route path={URLS.units.singleUnitReports} element={<SingleUnitReports />} />
              </Route>
            )} */}
          </Route>

          {/* Manage course pages */}
          {featureFlags.courseEdit && (
            <Route element={<UnitLayout />}>
              <Route
                element={
                  <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
                }
              >
                <Route path={URLS.courses.edit} element={<CourseEdit />} />
              </Route>

              <Route
                element={
                  <PermissionsRoute roles={notLearnerRoles} permissions={[coursesUnionRead]} />
                }
              >
                <Route path={URLS.units.edit} element={<UnitEdit />} />
              </Route>
            </Route>
          )}

          {/* 404 page */}
          <Route path="*" element={<BasicLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
      </Routes>
    </LayoutWrapper>
  );
};
export default AppRoutes;

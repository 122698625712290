import React, { FC, SyntheticEvent } from "react";
import MainMenu from "../../components/MainNav/MainMenu";
import { mainMenuContainer } from "./styles";
import { MainDrawerContent } from "@stores/ui";

type MainMenuMobileProps = {
  showMainDrawer: (content: MainDrawerContent) => void;
  hideMainDrawer: () => void;
};

const MainMenuMobile: FC<MainMenuMobileProps> = ({ hideMainDrawer, showMainDrawer }) => {
  const hideDrawer = (): void => {
    hideMainDrawer();
  };
  const openMainDrawer = (e: SyntheticEvent, contentType: MainDrawerContent): void => {
    e.preventDefault();
    showMainDrawer(contentType);
  };

  return (
    <div css={mainMenuContainer}>
      <MainMenu
        onToggle={hideDrawer}
        topMenuOnClick={hideDrawer}
        bottomMenuOnClick={openMainDrawer}
      />
    </div>
  );
};

export default MainMenuMobile;

import { HandledError } from "./errors";

export const courseErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const unitErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.unit_not_available",
    errorMsg: "errors.units.notAvailable",
  },
  {
    status: 404,
    id: "not_found.unit_not_found",
    errorMsg: "errors.units.notFound",
  },
  {
    status: 409,
    id: "conflict.course_traversal_not_allowed",
    errorMsg: "errors.units.traversalNotAllowed",
  },
  {
    status: 409,
    id: "conflict.course_has_not_started_yet",
    errorMsg: "errors.units.courseNotStarted",
  },
  {
    status: 409,
    id: "conflict.prerequisites_not_completed",
    errorMsg: "errors.units.prerequisitesError",
  },
  {
    status: 409,
    id: "conflict.course_is_not_active",
    errorMsg: "errors.units.courseNotActive",
  },
  {
    status: 409,
    id: "conflict.course_level_has_not_been_reached",
    errorMsg: "errors.units.levelNotReached",
  },
  {
    status: 409,
    id: "conflict.unit_completion_not_allowed.incorrectanswers",
    errorMsg: "notifications.incorrectAnswer",
  },
  // Uncomment when the API removes status 500 and replace it with 409
  //   {
  //     status: 500,
  //     id: "server_error",
  //     errorMsg: "errors.units.accessExpired",
  //   },
];

export const questionErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.invalid_gaps",
    errorMsg: "errors.questions.invalidText",
  },
];

export const groupErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const updateProfileErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.email_already_exist",
    errorMsg: "errors.profile.emailAlreadyExist",
  },
  {
    status: 400,
    id: "bad_request.username_already_exist",
    errorMsg: "errors.profile.usernameAlreadyExist",
  },
  {
    status: 400,
    id: "bad_request.email_updated_recently",
    errorMsg: "errors.profile.emailRecentlyUpdated",
  },
  {
    status: 400,
    id: "bad_request.user_avatar_upload_failed",
    errorMsg: "notifications.messages.errorUploadAvatar",
  },
  {
    status: 400,
    id: "bad_request.same_as_current_password",
    errorMsg: "profileSettings.modal.currentAndNewAreTheSame",
  },
  {
    status: 400,
    id: "bad_request.reuse_old_password",
    errorMsg: "profileSettings.modal.newPasswordReused",
  },
  {
    status: 403,
    id: "forbidden.reached_plan_max_users",
    errorMsg: "signUp.validationMessages.maxUsersReached",
  },
  {
    status: 403,
    id: "forbidden.reached_branch_max_users",
    errorMsg: "errors.users.reachedMaxBranchUsers",
  },
];

export const couponErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.coupon_expired",
    errorMsg: "errors.coupons.couponExpired",
  },
  {
    status: 400,
    id: "bad_request.invalid_coupon",
    errorMsg: "errors.coupons.couponInvalid",
  },
];

export const userErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const userBranchesErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const autologinErrors: HandledError[] = [
  {
    status: 401,
    id: "invalid_grant",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_required_to_update_password",
    errorMsg: "signIn.validationMessages.changePassword",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "errors.branchSwitch.cannotAccessPlus",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "signUp.validationMessages.restrictedToBranch",
  },
  {
    status: 403,
    id: "forbidden.plus_is_disabled",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
];

export const signInErrors: HandledError[] = [
  {
    status: 401,
    id: "unsupported_grant_type",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 401,
    id: "invalid_grant",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 401,
    id: "invalid_client",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 403,
    id: "forbidden.plus_is_disabled",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "signIn.validationMessages.usernameOrPassword",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "signIn.validationMessages.restrictedToBranch",
  },
  {
    status: 403,
    id: "forbidden.locked_portal",
    errorMsg: "signIn.validationMessages.lockedPortal",
  },
  {
    status: 403,
    id: "forbidden.user_is_locked",
    errorMsg: "signIn.validationMessages.lockedUser",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "signIn.validationMessages.notBranchMember",
  },
  {
    status: 403,
    id: "forbidden.user_required_to_update_password",
    errorMsg: "signIn.validationMessages.changePassword",
  },
  {
    status: 403,
    id: "forbidden.only_admins_can_login_on_cancelled_subscription",
    errorMsg: "signIn.validationMessages.onlyAdminsCancelledSubscription",
  },
  {
    status: 409,
    id: "conflict.user_not_active",
    errorMsg: "signIn.validationMessages.userNotActive",
  },
];

export const signUpErrors: HandledError[] = [
  ...signInErrors,
  {
    status: 403,
    id: "forbidden.signup_disabled",
    errorMsg: "signUp.validationMessages.signUpDisabled",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "signUp.validationMessages.branchNotFound",
  },
  {
    status: 403,
    id: "forbidden.reached_plan_max_users",
    errorMsg: "signUp.validationMessages.maxUsersReached",
  },
];

export const passwordChangeErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.same_as_current_password",
    errorMsg: "profileSettings.modal.currentAndNewAreTheSame",
  },
  {
    status: 400,
    id: "bad_request.reuse_old_password",
    errorMsg: "profileSettings.modal.newPasswordReused",
  },
  {
    status: 400,
    id: "bad_request.password_update_token_invalid_or_expired",
    errorMsg: "signIn.expiredPasswordChangeToken",
  },
];

export const conferenceErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict.conference_has_not_started",
    errorMsg: "errors.conference.notStarted",
  },
];

export const noPermissionsError: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const branchSwitchErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_not_allowed_to_access_plus",
    errorMsg: "errors.branchSwitch.cannotAccessPlus",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "errors.branchSwitch.cannotLoginToMainPortal",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "errors.branchSwitch.notMember",
  },
];

export const newMessageErrors = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.recipients_not_found",
    errorMsg: "errors.messages.recipientNotFound",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_message_user_recipient",
    errorMsg: "errors.messages.invalidRecipientMessage",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_course_recipient",
    errorMsg: "errors.messages.invalidRecipientCourse",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_branch_recipient",
    errorMsg: "errors.messages.invalidRecipientBranch",
  },
  {
    status: 409,
    id: "conflict.invalid_message_recipient.invalid_group_recipient",
    errorMsg: "errors.messages.invalidRecipientGroup",
  },
];

export const discussionErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.discussions.singleLevelNesting",
  },
];

export const invalidCouponErrors = [
  "bad_request.invalid_coupon",
  "bad_request.coupon_expired",
  "bad_request.updated_coupon",
];

export const paymentErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.invalid_coupon",
  },
  {
    status: 400,
    id: "bad_request.coupon_expired",
  },
  {
    status: 400,
    id: "bad_request.updated_coupon",
  },
  {
    status: 402,
    id: "payment_required",
    errorMsg: "payments.errors.paymentRequired",
  },
  {
    status: 402,
    id: "payment_required.price_changed",
    errorMsg: "payments.errors.priceChanged",
  },
  { status: 429, id: "too_many_requests", errorMsg: "payments.errors.tooManyRequests" },
];

export const enrollmentErrors: HandledError[] = [
  {
    status: 402,
    id: "payment_required",
    errorMsg: "errors.enrollment.paymentRequired",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.enrollment.noPermissions",
  },
  {
    status: 409,
    id: "conflict.user_already_enrolled",
    errorMsg: "errors.enrollment.alreadyEnrolled",
  },
];

export const subscriptionErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "errors.subscriptions.notEnabled",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict",
    errorMsg: "errors.subscriptions.alreadyInEffect",
  },
  { status: 429, id: "too_many_requests", errorMsg: "payments.errors.tooManyRequests" },
];

export const createThemeErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 400,
    id: "bad_request.invalid_theme_name",
    errorMsg: "errors.theme.invalidThemeName",
  },
  {
    status: 400,
    id: "bad_request.theme_name_already_exists",
    errorMsg: "errors.theme.nameAlreadyExists",
  },
  {
    status: 400,
    id: "bad_request.missing_or_invalid_theme_option",
    errorMsg: "errors.theme.missingOrInvalid",
  },
];

export const deleteThemeErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request.cannot_delete_default_theme",
    errorMsg: "errors.theme.cannotDelete",
  },
  {
    status: 403,
    id: "bad_request.invalid_theme_name",
    errorMsg: "errors.general.noPermissions",
  },
];

export const courseUnenrollErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 409,
    id: "conflict.user_not_enrolled",
    errorMsg: "errors.unenrollment.userNotEnrolled",
  },
];

export const groupsErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "groups.courses.errors.groupNotFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "groups.courses.errors.courseNotFound",
  },
];

export const branchesErrors: HandledError[] = [
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "branches.courses.errors.branchNotFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "branches.courses.errors.courseNotFound",
  },

  {
    status: 403,
    id: "forbidden.reached_branch_max_users",
    errorMsg: "branches.courses.errors.branchLimitReached",
  },
];

export const userCoursesErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 404,
    id: "not_found.certificate_not_found",
    errorMsg: "errors.courses.certificateNotFound",
  },
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 409,
    id: "conflict.no_licences_available",
    errorMsg: "users.errors.noLicencesAvailable",
  },
];

export const userUnitsErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
];

export const userImpersonationErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 403,
    id: "forbidden.user_not_member_in_branch",
    errorMsg: "signIn.validationMessages.notBranchMember",
  },
  {
    status: 403,
    id: "forbidden.cannot_login_to_main_portal",
    errorMsg: "signIn.validationMessages.restrictedToBranch",
  },
  {
    status: 403,
    id: "forbidden.user_sso",
    errorMsg: "users.impersonate.validationMessages.userSSO",
  },
];

export const joinGroupErrors: HandledError[] = [
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "myGroups.invalidKey",
  },
  {
    status: 409,
    id: "conflict.no_group_redemptions_left",
    errorMsg: "myGroups.keyHasExpired",
  },
  {
    status: 409,
    id: "conflict.user_already_enrolled",
    errorMsg: "myGroups.alreadyEnrolled",
  },
];

export const uploadFilesErrors: HandledError[] = [
  {
    status: 400,
    id: "bad_request",
    errorMsg: "general.uploadFileFailed",
  },
  {
    status: 400,
    id: "bad_request.invalid_url",
    errorMsg: "files.urlNotValid",
  },
  {
    status: 403,
    id: "forbidden.no_privileges",
    errorMsg: "errors.general.noPermissions",
  },
  {
    status: 404,
    id: "not_found.user_not_found",
    errorMsg: "users.errors.notFound",
  },
  {
    status: 404,
    id: "not_found.group_not_found",
    errorMsg: "groups.courses.errors.groupNotFound",
  },
  {
    status: 404,
    id: "not_found.branch_not_found",
    errorMsg: "branches.courses.errors.branchNotFound",
  },
  {
    status: 404,
    id: "not_found.file_not_found",
    errorMsg: "errors.files.fileNotFound",
  },
  {
    status: 404,
    id: "not_found.course_not_found",
    errorMsg: "errors.courses.notFound",
  },
  {
    status: 413,
    id: "file_too_large",
    errorMsg: "errors.files.uploadFileTooBig",
  },
  {
    status: 415,
    id: "unsupported_media_type",
    errorMsg: "errors.files.uploadFileUnsupported",
  },
];

export const previewCertificateTemplateErrors = [
  {
    status: 404,
    id: "not_found.certificate_template_not_found",
    errorMsg: "errors.certificateTemplate.notFound",
  },
];

export const previewCertificateErrors = [
  {
    status: 404,
    id: "not_found.certificate_not_found",
    errorMsg: "errors.certificate.notFound",
  },
];

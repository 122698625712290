import React, { FC } from "react";
import SkeletonCard from "./SkeletonCard";
import SkeletonTabWidget from "./SkeletonTabWidget";
import SkeletonLoader, { SkeletonLoaderProps } from "./SkeletonLoader";
import SkeletonGeneral from "./SkeletonGeneral";
import SkeletonTitle from "./SkeletonTitle";
import SkeletonListItem from "./SkeletonListItem";
import SkeletonTable, { SkeletonTableProps } from "./SkeletonTable";
import SkeletonBox from "./SkeletonBox";
import SkeletonPieChart from "./SkeletonPieChart";
import SkeletonBoxWidget from "./SkeletonBoxWidget";
import SkeletonCategory from "./SkeletonCategory";
import SkeletonAccordion from "./SkeletonAccordion";

export type SkeletonCompoundProps = {
  Card: FC;
  Loader: FC<SkeletonLoaderProps>;
  TabWidget: FC;
  Box: FC;
  WidgetBox: FC;
  General: FC;
  Title: FC;
  ListItem: FC;
  Table: FC<SkeletonTableProps>;
  PieChart: FC;
  Category: FC;
  Accordion: FC;
};

const Skeletons: FC & SkeletonCompoundProps = ({ children }) => (
  <div className="skeleton-container">{children}</div>
);

Skeletons.Card = SkeletonCard;
Skeletons.Loader = SkeletonLoader;
Skeletons.TabWidget = SkeletonTabWidget;
Skeletons.General = SkeletonGeneral;
Skeletons.Title = SkeletonTitle;
Skeletons.ListItem = SkeletonListItem;
Skeletons.Table = SkeletonTable;
Skeletons.Box = SkeletonBox;
Skeletons.PieChart = SkeletonPieChart;
Skeletons.WidgetBox = SkeletonBoxWidget;
Skeletons.Category = SkeletonCategory;
Skeletons.Accordion = SkeletonAccordion;

export default Skeletons;

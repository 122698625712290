import { css } from "@emotion/react";

export const ToggleContainer = ({ formFields, toggleSwitch }, { isChecked, isDisabled }) => css`
  display: inline-block;
  cursor: ${isDisabled ? "not-allowed" : "pointer"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  .switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 17px;
    background-color: ${isDisabled
      ? toggleSwitch.disabledBackground
      : isChecked
      ? toggleSwitch.backgroundEnabled
      : toggleSwitch.backgroundDisabled};
    border-radius: 10px;
    transition: background-color 0.3s;
  }

  .thumb {
    position: absolute;
    top: 1px;
    margin-inline-start: ${isChecked ? "16px" : "2px"};
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
  }

  .label {
    margin-inline-start: unset;
    cursor: pointer;
    white-space: nowrap;

    &.label-before {
      margin-inline-end: 0.5rem;
    }

    &.label-after {
      margin-inline-start: 0.5rem;
    }

    &.required {
      &::after {
        content: " *";
        color: ${formFields.requiredFieldAsterisk};
      }
    }
  }
`;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { GlobalStyleVariables } from "@styles";
const { mainDrawerMinWidth } = GlobalStyleVariables;

export const mainNavContainer = ({ sidebar }) => css`
  display: none;

  .sidebar-container {
    max-width: ${mainDrawerMinWidth};
  }

  .nav-handle {
    min-height: auto;

    .nav-item {
      border: 0;
    }
  }

  ${mq["md"]} {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background: ${sidebar.background};
    color: ${sidebar.color};
    z-index: 1001;
  }
`;

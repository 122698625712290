import { css } from "@emotion/react";

export const EditableTextStyles = ({ editableText }, { isEditable }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  word-break: break-word;

  .editable-container {
    width: 100%;
    cursor: ${isEditable ? "pointer" : "default"};

    &[contentEditable="true"]:empty:before {
      content: attr(data-text);
      color: ${editableText.placeholderColor};
    }

    &:focus {
      outline: none;
    }

    .placeholder {
      color: ${editableText.placeholderColor};
    }
  }
`;

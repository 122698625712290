import React, { FC } from "react";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import FooterWrapper from "./components/FooterWrapper";
import UnitFooterNavigationButtons from "./components/UnitFooterNavigationButtons";
import AvailabilityTimeLimitBar from "./components/AvailabilityTimeLimitBar";
import { Course, MyUnit, Section } from "types/entities";
import permissions from "@utils/permissions";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import { Link } from "@components/ReusableComponents";
import i18n from "@utils/i18n";

type InteractiveContentFooterProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isPublic?: boolean;
};

const InteractiveContentFooter: FC<InteractiveContentFooterProps> = ({
  course,
  sections: sections,
  unit,
  isPublic = false,
}) => {
  const flatUnits = sections ? getFlatUnits(sections) : [];
  const { nextUnit } = getPrevAndNextUnits(flatUnits, unit.id) || {};
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);

  const currentFlatUnit = flatUnits.find(({ id }) => id === unit.id);
  const { status: unitProgressStatus } = currentFlatUnit?.progress ?? {};
  const isUnitCompleted = unitProgressStatus === "completed";

  const isContinueButtonTraversalDisabled =
    course?.rules.traversal === "sequential"
      ? unitProgressStatus !== "completed"
      : !nextUnit && course.progress?.completion_status !== "completed";
  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    isContinueButtonTraversalDisabled,
    course.progress?.completion_status,
    unitProgressStatus,
  );
  const nextUnitLink = goToNextUnitLink(
    isContinueButtonTraversalDisabled,
    nextUnit,
    course,
    isPublic,
  );

  // permissions related
  const { canCompleteCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsComplete = isPublic ? true : canCompleteCourseUnits();
  const completedWithNoPermission = !allowCourseUnitsComplete && isUnitCompleted;
  const isRtl = i18n.dir() === "rtl";

  return (
    <>
      {/* if the next unit has delayed availability */}
      {!isNextUnitAvailable && isUnitCompleted && <AvailabilityTimeLimitBar nextUnit={nextUnit} />}

      <FooterWrapper>
        <UnitFooterNavigationButtons
          course={course}
          sections={sections}
          unit={unit}
          isPublic={isPublic}
        >
          {(allowCourseUnitsComplete || completedWithNoPermission) && (
            <Tooltip content={t("general.pendingUnitCompletion")} disabled={isUnitCompleted}>
              <Button
                type="button"
                as={Link}
                disabled={continueButtonDisabled}
                to={nextUnitLink}
                iconAfter={isRtl ? ArrowLeftSVG : ArrowRightSVG}
                className="btn-complete-wrapper"
              >
                {t("general.continue")}
              </Button>
            </Tooltip>
          )}
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default InteractiveContentFooter;

import React, { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, InputError, Button, Heading, FormError, Text } from "@epignosis_llc/gnosis";
import { useMutation, useQueryClient } from "react-query";
import { SerializedStyles } from "@emotion/react";
import { AxiosError } from "axios";
import { loginForm } from "./styles";
import { signin, LoginPostData } from "@api/app";
import { LocationState, URLS } from "@constants/urls";
import authService from "@utils/services/AuthService";
import { useConfigurationStore, useUIStore } from "@stores";
import { AuthenticationExpireSVG } from "@assets/images";
import { SignInFormValidationSchema } from "@utils/validation";
import localStorageKeys from "@constants/localStorageKeys";
import { showGamificationNotification } from "@utils/helpers";
import queryKeys from "@constants/queryKeys";
import SignInOrUpText from "@components/ReusableComponents/SignInOrUpText/SignInOrUpText";
import { HandledError, handleSignInErrors } from "@errors";
import { useEnrollmentMutation } from "@hooks";
import UserBranches from "./components/UserBranches";

type SignInFormProps = {
  // form opens on modal
  showOnModal?: boolean;
};

const SignInForm: FC<SignInFormProps> = ({ showOnModal = false }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userProfileData, domainSettings } = useConfigurationStore();
  const login = userProfileData?.login;
  const { setShowSessionExpirationModal, setLoginType } = useUIStore();
  const location = useLocation();
  const { from: redirectPath } = (location?.state as LocationState) ?? "";
  const signup = domainSettings?.signup;
  const isSignUpEnabled = signup?.enabled;
  const { impersonator_username: impersonatorUsername, impersonated: isImpersonated } =
    userProfileData ?? {};

  const noUserImpersonated = Boolean(!impersonatorUsername) && isImpersonated;

  /* The redirect countdown */
  const [timeLeft, setTimeLeft] = useState(30);

  const [signinError, setSigninError] = useState<string | JSX.Element>("");

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const { enrollmentMutation } = useEnrollmentMutation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginPostData>({
    mode: "onChange",
    resolver: yupResolver(SignInFormValidationSchema),
    defaultValues: {
      // set username if form opens on modal
      username: !showOnModal
        ? ""
        : impersonatorUsername && isImpersonated
        ? impersonatorUsername
        : login,
      password: "",
    },
  });

  const storageKey = localStorage.getItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
  const externalEnrollment = storageKey ? JSON.parse(storageKey).enrollment === true : false;
  const externalId = storageKey ? JSON.parse(storageKey).courseId : "";
  const plainRedirectUrl = storageKey ? JSON.parse(storageKey).redirectUrl : "";
  const activeBundle = storageKey ? JSON.parse(storageKey).activeBundle : "";
  const [isRestrictedBranchError, setIsRestrictedBranchError] = useState(false);

  const { mutate: signInMutation, isLoading: signInLoading } = useMutation(
    [queryKeys.signin],
    (data: LoginPostData) => signin(data),
    {
      onSuccess: (res) => {
        const authData = res;
        authService.setTokens(authData);
        authService.setDefaultRole(authData.default_role);
        setLoginType("direct");
        queryClient.invalidateQueries([queryKeys.catalogSettings]);

        // If the sign-in is performed from external catalog
        if (storageKey) {
          externalEnrollment && enrollmentMutation();

          if (plainRedirectUrl) {
            // if login or sign-up is performed from public header
            navigate(plainRedirectUrl);
          } else {
            //redirect to course details page
            if (externalId) {
              //check for payment

              externalEnrollment
                ? navigate(URLS.catalog.createCourseLink({ courseId: externalId }))
                : navigate(URLS.catalog.createCourseLink({ courseId: externalId }), {
                    state: { isPayment: true },
                  });
            } else {
              //navigate on catalog and check for active bundle
              activeBundle
                ? navigate(URLS.catalog.index, { state: { activeBundle } })
                : navigate(URLS.catalog.index);
            }
          }

          localStorage.removeItem(localStorageKeys.EXTERNAL_SIGNIN_SIGNUP);
        } else {
          if (!showOnModal) {
            // delete stored settings for showing announcements
            localStorage.removeItem(localStorageKeys.ANNOUNCEMENTS);

            // redirect to pathname or to dashboard
            navigate(timeLeft && redirectPath ? redirectPath : URLS.dashboard);
          } else {
            // hide sign in modal
            setShowSessionExpirationModal(false);
            queryClient.clear();
          }
        }

        const gamification = authData?._meta?.gamification;
        if (gamification) {
          showGamificationNotification(gamification);
        }
      },
      onError: (error: AxiosError) => {
        const handleError = (foundError: HandledError | null, axiosError: AxiosError): void => {
          if (foundError?.errorMsg) {
            setIsRestrictedBranchError(false);
            if (foundError?.id === "forbidden.user_required_to_update_password") {
              const updatePasswordToken: string =
                axiosError?.response?.data._meta.update_password_token;
              if (updatePasswordToken) {
                localStorage.setItem(localStorageKeys.UPDATE_PASSWORD_TOKEN, updatePasswordToken);
                navigate(URLS.changePassword);
              }
            } else if (
              foundError?.id === "forbidden.plus_is_disabled" ||
              foundError?.id === "forbidden.user_not_allowed_to_access_plus"
            ) {
              const coreUrl = window.location.origin;
              window.location.replace(coreUrl);
            } else if (foundError?.id === "forbidden.cannot_login_to_main_portal") {
              setIsRestrictedBranchError(true);
              setSigninError(
                <UserBranches
                  branches={axiosError?.response?.data._meta.branches}
                  errorMessage={t(foundError?.errorMsg)}
                />,
              );
            } else {
              setSigninError(t(foundError.errorMsg));
            }
          }
        };
        handleSignInErrors(error, true, handleError);
      },
    },
  );

  const handleLogin = (data: LoginPostData): void => {
    signInMutation(data);
  };

  const handleLogOut = (): void => {
    queryClient.clear();
    authService.removeTokens();
    authService.removeRole();

    // hide sign in modal
    setShowSessionExpirationModal(false);

    navigate(URLS.login);
  };

  return (
    <form
      css={(theme): SerializedStyles =>
        loginForm(theme, { showOnModal }, { isRestrictedBranchError })
      }
      onSubmit={handleSubmit(handleLogin)}
      autoComplete="off"
    >
      {!showOnModal ? (
        <Heading size="2xl" className={!isSignUpEnabled ? "reset-heading" : ""}>
          {t("signIn.login")}
        </Heading>
      ) : (
        <div className="modal-title-container">
          <Heading size="md">{t("signIn.justToBeSafe")}</Heading>
        </div>
      )}
      {isSignUpEnabled && <SignInOrUpText isModalOpen={showOnModal} isLoginSelected={true} />}

      <section className="form-content">
        {showOnModal && (
          <div className="svg-text-wrapper">
            <AuthenticationExpireSVG className="content-svg" />
            <Text fontSize="md" className="password-text">
              {!noUserImpersonated
                ? t("signIn.enterYourPassword")
                : t("signIn.youCanNoLongerLogin")}
            </Text>
          </div>
        )}

        {/* show username when form opens on modal or the user login is not set */}
        {(!showOnModal || !login) && (
          <div className="form-item">
            <Input
              {...register("username")}
              id="username"
              data-testid="username"
              label={t("signIn.usernameOrEmail")}
              size="lg"
              status={errors.username ? "error" : "valid"}
            />
            {errors.username && (
              <InputError data-testid="username-error">{errors.username.message}</InputError>
            )}
          </div>
        )}

        {!noUserImpersonated && (
          <div className="form-item">
            <Input
              {...register("password")}
              id="password"
              data-testid="password"
              type="password"
              label={t("signIn.password")}
              size={showOnModal ? "md" : "lg"}
              status={errors.password ? "error" : "valid"}
            />
            {errors.password && <InputError>{errors.password.message}</InputError>}
          </div>
        )}

        {Boolean(signinError) && (
          <div className="login-error-wrapper">
            <FormError className="branches-error-container">
              <p className="login-error-container">{signinError}</p>
            </FormError>
          </div>
        )}
      </section>

      {showOnModal ? (
        <section className="footer-container">
          <div className="btns-wrapper">
            <Button color="secondary" type="button" onClick={handleLogOut}>
              {t("general.logOut")}
            </Button>
            {!noUserImpersonated && (
              <Button className="login-btn" type="submit" isLoading={signInLoading}>
                {t("signIn.login")}
              </Button>
            )}
          </div>
        </section>
      ) : (
        <Button type="submit" block isLoading={signInLoading} className="sign-in-btn">
          {t("signIn.login")}
        </Button>
      )}
    </form>
  );
};

export default SignInForm;

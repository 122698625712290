import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, InputError, Button, Heading, FormError } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { loginForm } from "./styles";
import { getLdapToken, LdapSigninPostData, postLdapSignin } from "@api/app";
import { LdapSignInFormValidationSchema } from "@utils/validation";
import { URLS } from "@constants/urls";
import localStorageKeys from "@constants/localStorageKeys";

const LDAPSignInForm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signinError, setSigninError] = useState("");

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LdapSigninPostData>({
    mode: "onChange",
    resolver: yupResolver(LdapSignInFormValidationSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmitSuccess = async (formData: LdapSigninPostData): Promise<void> => {
    try {
      const formRes = await postLdapSignin(formData);
      const { login_url, logout_url } = formRes._data;

      // make GET request to get the autologin token
      const tokenRes = await getLdapToken(login_url);
      const token = tokenRes._data.token;

      // save logout url
      if (logout_url) localStorage.setItem(localStorageKeys.SSO_LOGOUT_URL, logout_url);

      if (token) {
        navigate(`${URLS.autologin}?token=${token}`);
      }
    } catch (error) {
      const err = error as AxiosError;
      const status = err?.response?.status ?? null;
      const errors = err?.response?.data._errors ?? [];
      const errorId = errors[0]?.id;
      const errorTitle = errors[0]?.title;

      if (status === 400) {
        setSigninError(
          errorId === "bad_request.sso_wrong_userpass"
            ? t("signIn.validationMessages.usernameOrPassword")
            : errorTitle,
        );
      }
    }
  };

  return (
    <form
      css={(theme): SerializedStyles =>
        loginForm(theme, { showOnModal: false }, { isRestrictedBranchError: false })
      }
      onSubmit={handleSubmit(onSubmitSuccess)}
      autoComplete="off"
    >
      <Heading size="2xl" className="reset-heading">
        {t("signIn.login")}
      </Heading>

      <section className="form-content">
        <div className="form-item">
          <Input
            {...register("username")}
            id="username"
            data-testid="username"
            label={t("signIn.username")}
            size="lg"
            status={errors.username ? "error" : "valid"}
          />
          {errors.username && (
            <InputError data-testid="ldap-username-error">{errors.username.message}</InputError>
          )}
        </div>

        <div className="form-item">
          <Input
            {...register("password")}
            id="password"
            data-testid="password"
            type="password"
            label={t("signIn.password")}
            size="lg"
            status={errors.password ? "error" : "valid"}
          />
          {errors.password && (
            <InputError data-testid="ldap-password-error">{errors.password.message}</InputError>
          )}
        </div>

        {Boolean(signinError) && (
          <div className="login-error-wrapper">
            <FormError>
              <p className="login-error-container">{signinError}</p>
            </FormError>
          </div>
        )}
      </section>

      <Button type="submit" block isLoading={isSubmitting} className="sign-in-btn">
        {t("signIn.login")}
      </Button>
    </form>
  );
};

export default LDAPSignInForm;

import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import FooterWrapper from "./components/FooterWrapper";
import UnitFooterNavigationButtons from "./components/UnitFooterNavigationButtons";
import AvailabilityTimeLimitBar from "./components/AvailabilityTimeLimitBar";
import { footerInfoTextContainer } from "./styles";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import { Course, MyUnit, Section } from "types/entities";
import permissions from "@utils/permissions";
import { FooterButton } from "@components/ReusableComponents";
import { URLS } from "@constants/urls";
import { Session } from "types/entities/Unit";

type IltFooterProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  hasSessions: boolean;
  isRegistered: boolean;
  registeredSession: Session | undefined;
  isPublic?: boolean;
};

const IltFooter: FC<IltFooterProps> = ({
  course,
  sections,
  unit,
  hasSessions,
  isRegistered,
  registeredSession,
  isPublic = false,
}) => {
  const { courseId, unitId } = useParams() as { courseId: string; unitId: string };
  const navigate = useNavigate();
  const isCompleted = unit?.progress?.status === "completed" || unit.progress?.status === "failed";
  const flatUnits = sections ? getFlatUnits(sections) : [];
  const { nextUnit } = getPrevAndNextUnits(flatUnits, parseInt(unitId)) || {};
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);
  // const isContinueButtonTraversalDisabled =
  //   unit?.progress?.status !== "completed" && course?.rules.traversal === "sequential";

  const isContinueButtonTraversalDisabled =
    course?.rules.traversal === "sequential"
      ? unit?.progress?.status !== "completed"
      : !nextUnit && course.progress?.completion_status !== "completed";

  const nextUnitLink = goToNextUnitLink(
    isContinueButtonTraversalDisabled,
    nextUnit,
    course,
    isPublic,
  );
  const today = Date.parse(new Date().toISOString());
  const hasSessionStarted =
    registeredSession && Date.parse(registeredSession?.sessions[0].start_datetime) < today;

  // permissions related
  const { canCompleteCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsComplete = isPublic ? true : canCompleteCourseUnits();

  const onComplete = (): void => {
    if (nextUnit) navigate(nextUnitLink);
    else if (course.progress?.completion_status === "completed")
      navigate(URLS.user.createCourseResultsLink({ courseId }));
  };

  const BTN_TEXT = {
    pending: hasSessionStarted ? t("ilt.footer.pendingAnswer") : t("ilt.footer.expectingEvent"),
    incomplete: isRegistered ? t("ilt.footer.expectingEvent") : t("ilt.footer.selectSession"),
    not_attempted: t("ilt.footer.selectSession"),
  };

  const notCompletedOrFailed =
    unit.progress?.status === "not_attempted" ||
    unit.progress?.status === "incomplete" ||
    unit.progress?.status === "pending";

  // hide button if you don't have permission and the Ilt unit is not completed or failed
  const hideButton = !allowCourseUnitsComplete && notCompletedOrFailed;
  const showText = !isCompleted;

  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    isContinueButtonTraversalDisabled,
    course.progress?.completion_status,
    unit.progress?.status,
  );

  return (
    <>
      {/* if the next unit has delayed availability */}
      {!isNextUnitAvailable && isCompleted && <AvailabilityTimeLimitBar nextUnit={nextUnit} />}

      {showText && (
        <div css={footerInfoTextContainer}>
          <Text fontSize="sm">
            {t(hasSessions ? BTN_TEXT[unit.progress?.status ?? ""] : "ilt.footer.noSessions")}
          </Text>
        </div>
      )}

      <FooterWrapper>
        <UnitFooterNavigationButtons
          course={course}
          sections={sections}
          unit={unit}
          isPublic={isPublic}
        >
          {!hideButton && (
            <FooterButton
              unit={unit}
              onClick={onComplete}
              disabled={continueButtonDisabled || !hasSessions}
            />
          )}
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default IltFooter;

import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Text, Heading, Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import classNames from "classnames";
import { completionScreenContainer } from "./styles";
import {
  CompletedSVG,
  CourseFailedSVG,
  // LinkedInSVG,
  // InstructorSVG,
  // PointsSVG,
} from "@assets/images";
import { URLS } from "@constants/urls";
import { downloadFile, getFlatUnits } from "@utils/helpers";
import { Course, CourseProgress, Section } from "types/entities";
import FooterWrapper from "@components/Units/Footers/components/FooterWrapper";
import { defaultFooterContainer } from "@components/Units/Footers/styles";
import { useRtlArrowIcons } from "@hooks";
import PublicCourseSignUp from "../components/PublicCourseSignUp";
import { CertificateWithViewboxSVG } from "@assets/icons";

type CompletionScreenProps = {
  course: Course;
  units: Section[];
  isPublic?: boolean;
};

const handleCertificateDownload = (certificate: CourseProgress["certificate"]): void => {
  if (certificate) {
    downloadFile(certificate.url, certificate.course_name);
  }
};

const getBtnClassNames = (): string =>
  classNames({
    "btn-nav": true,
  });

const CompletionScreen: FC<CompletionScreenProps> = ({ course, units, isPublic = false }) => {
  const Icon = useRtlArrowIcons();
  const { progress, id } = course;
  const isCompleted = progress?.completion_status === "completed";
  const flatUnits = units ? getFlatUnits(units) : [];
  const publicCourseLink = URLS.externalCatalog.createCourseLink({ courseId: id.toString() });
  const goToPreviousUnitLink =
    flatUnits.length > 0
      ? isPublic
        ? URLS.externalCatalog.publicUnit.createPublicUnitLink({
            courseId: id.toString(),
            unitId: flatUnits[flatUnits?.length - 1]?.id.toString(),
          })
        : URLS.user.createUnitLink({
            courseId: id.toString(),
            unitId: flatUnits[flatUnits?.length - 1]?.id.toString(),
          })
      : isPublic
      ? publicCourseLink
      : URLS.user.courses;

  return (
    <>
      <article className="unit-wrapper">
        <div className="unit-container" css={completionScreenContainer}>
          {isCompleted ? (
            <CompletedSVG className="image-banner" data-testid="image-banner" />
          ) : (
            <CourseFailedSVG className="image-banner" data-testid="image-banner" />
          )}

          <div className="headers-wrapper">
            <Heading as="h1" size="2xl">
              {isCompleted ? t("general.congratulations") : t("myCourses.youDidntQuiteMakeit")}
            </Heading>
            <Text fontSize="sm">
              {isCompleted
                ? t("myCourses.youSuccessfullyCompletedCourse")
                : t("myCourses.unfortunatelyYouDidntReach")}
              !
            </Text>
          </div>

          {/* TODO: when gamification info is available from API */}
          {/* <section className="achievements-container">
            <div className="icon-container">
              <PointsSVG height={32} data-testid="badge" />
              <Text fontSize="sm">
                You&apos;ve earned
                <br />
                <b>{200}</b> points
              </Text>
            </div>
          </section> */}

          {!isCompleted ? (
            // TODO: When functionality of ask instructor to retake this course is ready
            <section className="buttons-container">
              {/* <Button variant="outline" iconBefore={InstructorSVG}>
                {t("test.askInstructorToRetake")}
              </Button> */}
            </section>
          ) : (
            progress.certificate &&
            !isPublic && ( // Show certificate when the course is not public
              <section className="buttons-container">
                <Button
                  data-testid="certificate-button"
                  variant="outline"
                  iconBefore={CertificateWithViewboxSVG}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                    e.preventDefault();
                    handleCertificateDownload(progress.certificate);
                  }}
                >
                  {t("test.downloadYourCertificate")}
                </Button>
                {/* TODO: When functionality with LinkedIn is ready
                <Button variant="outline" iconBefore={LinkedInSVG}>
                  {t("test.shareOnLinkedIn")}
                </Button>
                */}
              </section>
            )
          )}

          {isPublic && <PublicCourseSignUp course={course} />}
        </div>
      </article>

      <FooterWrapper>
        <div css={defaultFooterContainer}>
          <Button
            as={Link}
            to={goToPreviousUnitLink}
            color="secondary"
            noGutters
            className={getBtnClassNames()}
            aria-label="Go to previous unit"
          >
            <Icon height={24} />
          </Button>

          <div className="btn-complete-wrapper">
            <Button as={Link} to={isPublic ? publicCourseLink : URLS.user.courses}>
              {t(isPublic ? "test.goToCourseOverview" : "test.goToMyCourses")}
            </Button>
          </div>
        </div>
      </FooterWrapper>
    </>
  );
};

export default CompletionScreen;

import React, { FC } from "react";
import { Tag, Text } from "@epignosis_llc/gnosis";
import { getUnitIconByType, getIconByCompletion } from "@utils/helpers";
import { t } from "i18next";
import { CourseTocItemProps } from "./CourseTocItem";

type CourseTocItemContentProps = CourseTocItemProps & {
  isSampleUnit?: boolean;
};

const CourseTocItemContent: FC<CourseTocItemContentProps> = ({
  progress,
  type,
  name,
  isSampleUnit = false,
  isReadonly,
  isLearnerView,
}) => {
  const isCompleted = progress?.status === "completed" || progress?.status === "failed";

  return (
    <div className="list-item-content">
      {isLearnerView || isReadonly || !isCompleted
        ? getUnitIconByType(type)
        : getIconByCompletion(progress?.status, 32)}

      <Text className="unit-name" fontSize="sm">
        {name}
      </Text>

      {isSampleUnit && <Tag className="sample-unit-tag">{t("general.sample").toUpperCase()}</Tag>}
    </div>
  );
};

export default CourseTocItemContent;

import React, { FC, useEffect, useState } from "react";
import { t } from "i18next";
import { MyUnit, PlayerState } from "types/entities";
import { TimeLimitBar } from "@components";

export type UnitTimeLimitBarProps = {
  unit: MyUnit;
  playerState?: PlayerState;
  onComplete: () => void;
  children?: never;
};

const UnitTimeLimitBar: FC<UnitTimeLimitBarProps> = ({ unit, playerState, onComplete }) => {
  const { progress, completion_method, media_options } = unit;
  const { sync_timer = null } = media_options ?? {};
  const { time = null } = completion_method ?? {};
  const { isPlaying = false, duration: mediaDuration = null } = playerState ?? {};

  const isCompleted = progress?.status === "completed";
  const duration = sync_timer ? mediaDuration : time;
  const isCounterPlaying = sync_timer ? isPlaying : true;

  const [counter, setCounter] = useState<number | null>(!isCompleted ? duration : null);

  // when counter becomes <= 0, means that the required duration for unit completion has passed
  const canComplete = !isCompleted && typeof counter === "number" && counter <= 0;

  // Update counter when duration from react player changes
  useEffect(() => {
    if (mediaDuration && sync_timer) setCounter(mediaDuration);
  }, [mediaDuration, sync_timer]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typeof counter === "number" && isCounterPlaying) {
        isCounterPlaying && setCounter((counter) => (counter as number) - 1);
      }
    }, 1000);

    if (typeof counter !== "number" || counter <= 0) {
      clearInterval(intervalId);
      if (canComplete) onComplete();
    }

    return (): void => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, isCounterPlaying]);

  if (typeof counter !== "number" || typeof duration !== "number" || counter <= 0) return null;

  return (
    <TimeLimitBar initialTime={duration} currentTime={counter} text={t("general.completesIn")} />
  );
};

export default UnitTimeLimitBar;

import React, { FC } from "react";
import { useResponsive } from "ahooks";
import { format } from "date-fns";
import { Grid, Button, Text, Tooltip } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import RegisterButton from "./RegisterButton";
import { ClockSVG, SeatSVG, LocationSVG, WebinarSVG } from "@assets/icons";
import { formatUtcDate, getFormattedUserName, truncate } from "@utils/helpers";
import { Session, SessionItem } from "types/entities/Unit";
import { useConfigurationStore } from "@stores";
import { UserAvatar } from "@components/ReusableComponents";

type IltItemProps = {
  session: Session;
  sessionItem: SessionItem;
  selectedItem: { id: number; name: string } | null;
  isMultiSession: boolean;
  isSessionFull: boolean;
  canUnregister: boolean;
  registerUnregisterLoading: boolean;
  joinUrlLoading: boolean;
  registerUnregisterTooltipContent: string;
  isButtonDisabled: boolean;
  handleRegisterUnregister: (isRegistered: boolean, id: number, name: string) => void;
  handleJoin: (webinar_id: string) => void;
};

const IltItem: FC<IltItemProps> = (props) => {
  const {
    session,
    sessionItem,
    selectedItem,
    isMultiSession,
    isSessionFull,
    canUnregister,
    registerUnregisterLoading,
    joinUrlLoading,
    registerUnregisterTooltipContent,
    isButtonDisabled,
    handleRegisterUnregister,
    handleJoin,
  } = props;
  const { getState } = useConfigurationStore;
  const { domainSettings } = getState();
  const { md } = useResponsive();
  const joinBtnTooltipContent = !sessionItem.join_url ? t("ilt.youCanJoinSessions") : "";
  const {
    name: ownerName,
    surname: ownerSurname,
    avatar: ownerAvatar,
    login: ownerLogin,
  } = session.owner;
  const sessionItemDescription = sessionItem?.description?.html;

  const instructorName = getFormattedUserName({
    name: ownerName,
    surname: ownerSurname,
    login: ownerLogin,
    userFormat: domainSettings?.user_format as string,
  });

  return (
    <div className="body">
      <div className="date" style={{ background: sessionItem.color }}>
        <Text fontSize="2xl">{format(Date.parse(sessionItem.start_datetime), "d")}</Text>
        <Text fontSize="sm">
          {format(Date.parse(sessionItem.start_datetime), "MMM").toUpperCase()}
        </Text>
      </div>
      <div className="session-details">
        <div className="header">
          <Text fontSize="md" weight="700" as="div" className="header-title">
            {truncate(sessionItem.name, md ? 50 : 20)}
          </Text>

          {!isMultiSession && md && (
            <RegisterButton
              session={session}
              disabled={isButtonDisabled}
              isLoading={registerUnregisterLoading && selectedItem?.id === sessionItem.id}
              canUnregister={canUnregister}
              tooltipContent={registerUnregisterTooltipContent}
              handleClick={(): void =>
                handleRegisterUnregister(session.is_registered, sessionItem.id, sessionItem.name)
              }
            />
          )}
        </div>

        {sessionItemDescription && (
          <Text
            fontSize="sm"
            as="div"
            className="session-description"
            dangerouslySetInnerHTML={{ __html: sessionItemDescription }}
          />
        )}

        <div className="info-container">
          <Grid templateColumns={[1, 1, 2, 2, 4]} gap={md ? 1 : 0.5}>
            <div className="time">
              <ClockSVG height={22} />
              <div className="info">
                <Text fontSize="xs">{t("ilt.time")}</Text>
                <Text fontSize="sm">
                  {formatUtcDate(sessionItem.start_datetime, "time")} -{" "}
                  {formatUtcDate(sessionItem.end_datetime, "time")}
                </Text>
              </div>
            </div>

            <div className="instructor">
              <UserAvatar
                avatar={ownerAvatar}
                imageSize="sm"
                avatarSize="xs"
                name={ownerName}
                alt={`${ownerName} ${ownerSurname}`}
                bgColor="#FF8000"
                className="avatar"
              />

              <div className="info">
                <Text fontSize="xs">{t("general.instructor")}</Text>
                <Text fontSize="sm">{instructorName}</Text>
              </div>
            </div>

            <div className="capacity">
              <SeatSVG height={22} />
              <div className="info">
                <Text fontSize="xs">{t("ilt.seats")}</Text>
                <div>
                  <Text fontSize="sm" className={`${isSessionFull ? "registered-users-full" : ""}`}>
                    {session.users_registered} {t("ilt.registeredLower")}
                  </Text>
                  {session.capacity && ` / ${session.capacity}`}{" "}
                </div>
              </div>
            </div>

            <div className="location">
              {sessionItem.type === "classroom" ? (
                <>
                  {sessionItem.location && (
                    <>
                      <LocationSVG height={22} />
                      <div className="info">
                        <Text fontSize="xs">{t("ilt.location")}</Text>
                        <Text fontSize="sm">{sessionItem.location}</Text>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="webinar">
                    <WebinarSVG height={22} />
                    <Text fontSize="sm">{t("ilt.webinar")}</Text>
                  </div>
                  {session.is_registered && sessionItem.webinar_id && (
                    <Tooltip content={joinBtnTooltipContent} disabled={!joinBtnTooltipContent}>
                      <Button
                        className="join-btn"
                        disabled={!sessionItem.join_url}
                        isLoading={joinUrlLoading}
                        onClick={(): void => handleJoin(sessionItem.webinar_id as string)}
                        variant="link"
                      >
                        {t("general.join")}
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          </Grid>
        </div>
      </div>

      {!isMultiSession && !md && (
        <footer className="register-btn-mobile">
          <RegisterButton
            session={session}
            disabled={isButtonDisabled}
            isLoading={registerUnregisterLoading && selectedItem?.id === sessionItem.id}
            canUnregister={canUnregister}
            tooltipContent={registerUnregisterTooltipContent}
            handleClick={(): void =>
              handleRegisterUnregister(session.is_registered, sessionItem.id, sessionItem.name)
            }
          />
        </footer>
      )}
    </div>
  );
};

export default IltItem;

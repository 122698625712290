import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { FormValues } from "../Assignment";
import { fileUploadContainer } from "./styles";
import queryKeys from "@constants/queryKeys";
import { DragAndDropArea, FileInput } from "@components";
import { postAssignmentUnitFile } from "@api/courses";
import { MyUnit } from "types/entities";
import { getFileValidations, buildFileValidations } from "@utils/helpers";
import { errorNotification } from "@utils/helpers/notifications";

export type FileUploadAnswerProps = {
  unit: MyUnit;
  isLoadingAssignment: boolean;
  onReset: () => void;
  onSubmit: (formData: FormValues) => void;
};

type FileUploadAnswerForm = { file: File | null };

const MAX_ALLOWED_FILES = 1;

const FileUploadAnswer: FC<FileUploadAnswerProps> = ({
  unit,
  isLoadingAssignment,
  onSubmit,
  onReset,
}) => {
  const { courseId, unitId } = useParams() as { courseId: string; unitId: string };
  const file = unit.progress?.submission?.file;
  const [droppedAttachments, setDroppedAttachments] = useState<FileList | null>(null);
  const [attachments, setAttachments] = useState<File[]>((): File[] => {
    if (!file) return [];
    return [new File([], file?.name, { type: file?.mime_type })];
  });

  const [hasNewFileForSubmission, setHasNewFileForSubmission] = useState(!attachments[0]);

  const validations = getFileValidations([
    "unit.assignment.audio",
    "unit.assignment.video",
    "unit.assignment.document",
    "unit.assignment.image",
    "unit.assignment.zip",
  ]);
  const mimeTypeAndFilesizeValidations = buildFileValidations(validations);

  const { handleSubmit, setValue, getValues } = useForm<FileUploadAnswerForm>({
    mode: "onChange",
  });

  const { mutate: postFileMutation, isLoading: isPostingFile } = useMutation(
    [queryKeys.assignmentUnit.fileUpload, { courseId, unitId }],
    (file: File) => postAssignmentUnitFile(unitId, file),
    {
      onSuccess: ({ _data }) => {
        onSubmit({ file_id: _data.id });
      },
    },
  );

  const handleOnSubmit = async (): Promise<void> => {
    if (attachments[0]) {
      await postFileMutation(attachments[0]);
    }
  };

  const handleFileError = (error: string): void => {
    errorNotification(`${error}`);
  };

  const handleFilesChanged = (files: File[]): void => {
    setHasNewFileForSubmission(!!files.length);

    if (getValues("file")) {
      setValue("file", null);
    }

    setAttachments(files);
    setDroppedAttachments(null);
  };

  const handleFilesDrop = (files: FileList): void => {
    setHasNewFileForSubmission(true);
    setDroppedAttachments(files);
  };

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} css={fileUploadContainer}>
      <div className="dnd-wrapper">
        {mimeTypeAndFilesizeValidations && (
          <DragAndDropArea
            maxFiles={MAX_ALLOWED_FILES}
            mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
            onFilesDrop={handleFilesDrop}
            className="dnd-container"
          >
            <FileInput
              className="upload-assignment"
              id="upload-assignment"
              name="assignment-file"
              maxFiles={MAX_ALLOWED_FILES}
              mimeTypeAndFilesizeValidations={mimeTypeAndFilesizeValidations}
              selectedFiles={attachments}
              addedFiles={droppedAttachments}
              tooltipPlacement="top"
              onFileError={handleFileError}
              onFilesChange={handleFilesChanged}
            />
          </DragAndDropArea>
        )}
      </div>

      <div className="actions-container">
        <Button
          type="submit"
          isLoading={isPostingFile || isLoadingAssignment}
          disabled={!hasNewFileForSubmission}
        >
          {t("test.submit")}
        </Button>
        <Button type="reset" variant="link" onClick={onReset}>
          {t("general.cancel")}
        </Button>
      </div>
    </form>
  );
};

export default FileUploadAnswer;

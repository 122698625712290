import React, { FC, useState, useEffect, useCallback, MouseEvent } from "react";
import { useParams } from "react-router";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import fixWebmDuration from "webm-duration-fix";
import { useReactMediaRecorder } from "react-media-recorder";
import { Heading, Loader, MediaPlayer } from "@epignosis_llc/gnosis";
import { VideoSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import Actions from "./Actions";
import { formContainer } from "./styles";
import queryKeys from "@constants/queryKeys";
import { postAssignmentUnitFile } from "@api/courses";
import { MyUnit } from "types/entities";
import { FormValues } from "@components/Units/Assignment/Assignment";
import { NOTIFICATIONS } from "@channels/notifications";
import {
  getFileFromBlobUrl,
  getMediaErrorMsg,
  isChromeBrowser,
  secondsToTime,
} from "@utils/helpers";
import { errorNotification, generalNotification } from "@utils/helpers/notifications";

type FileForm = { file: File };

type VideoRecordingProps = {
  unit: MyUnit;
  onSubmit: (formData: FormValues) => void;
  onReset: () => void;
};

const recordingUploadErrorNotification = (): void => {
  const errorMsg = (
    <Heading as="h5" size="xs">
      {t("assignment.videoRecordNotUpload")}
    </Heading>
  );

  PubSub.publish(NOTIFICATIONS, { type: "error", content: errorMsg });
};

const MIN_VIDEO_DURATION = 5; // in seconds
const MAX_VIDEO_DURATION = 3600; // in seconds

const config = {
  file: {
    attributes: {
      controlsList: "nodownload noplaybackrate",
      disablePictureInPicture: true,
    },
  },
};

const VideoRecording: FC<VideoRecordingProps> = ({ unit, onSubmit, onReset }) => {
  const isChrome = isChromeBrowser();
  const { id: unitId, name } = unit;
  const { courseId } = useParams() as { courseId: string };
  const [rerecord, setRerecord] = useState(false);
  const [duration, setDuration] = useState(0); // in seconds
  const [isRecording, setIsRecording] = useState(false);
  const [answerUploaded, setAnswerUploaded] = useState(false);
  const [fixedURL, setFixedURL] = useState("");
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    error,
    clearBlobUrl,
    previewStream: videoStream,
  } = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: true,
    blobPropertyBag: { type: "video/mp4" },
    onStart: () => {
      setDuration(0);
      setIsRecording(true);
    },
    onStop: async (_, blob) => {
      if (isChrome) {
        const fixedBlob = await fixWebmDuration(blob);
        setFixedURL(URL.createObjectURL(fixedBlob));
      }
      setIsRecording(false);
      handleUnregister();
    },
  });
  const { handleSubmit } = useForm<FileForm>({ mode: "onChange" });

  const { mutate: uploadAssigmentVideoFile, isLoading: isPostingAssignment } = useMutation(
    [queryKeys.assignmentUnit.fileUpload, { courseId, unitId }],
    (file: File) => postAssignmentUnitFile(unitId.toString(), file),
    {
      onSuccess: ({ _data }) => {
        onSubmit({ file_id: _data.id });
        clearBlobUrl();
        setAnswerUploaded(true);
      },
      onError: () => recordingUploadErrorNotification(),
    },
  );

  const cameraRecordingError: boolean = [
    // possible errors from useReactMediaRecorder hook
    "media_aborted",
    "permission_denied",
    "no_specified_media_found",
    "media_in_use",
    "invalid_media_constraints",
    "no_constraints",
    "recorder_error",
  ].some((errorType) => errorType === error);
  const showWebcam = (status === "recording" || status === "idle") && !answerUploaded;
  const showVideoPreview =
    Boolean(mediaBlobUrl) &&
    status !== "recording" &&
    status !== "idle" &&
    status !== "acquiring_media";
  const validationErrorMin =
    status === "stopped" && showVideoPreview && duration < MIN_VIDEO_DURATION;
  const validationErrorMax =
    status === "stopped" && showVideoPreview && duration > MAX_VIDEO_DURATION;
  const displayRecordingBtn =
    (status === "idle" || status === "stopping" || status === "stopped") && !rerecord;
  const displayStopRecordingBtn = status === "recording";
  const displaySubmitRecordingBtn =
    Boolean(mediaBlobUrl) &&
    status !== "recording" &&
    status !== "idle" &&
    status !== "acquiring_media";
  const displayReRecordingBtn =
    (status === "idle" || status === "stopping" || status === "stopped") &&
    rerecord &&
    !answerUploaded &&
    !isPostingAssignment;
  const videoError = getMediaErrorMsg(error, "video");

  const handleStartRecording = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      startRecording();
    },
    [startRecording],
  );

  const handleStopRecording = useCallback(
    (e: MouseEvent<HTMLButtonElement>): void => {
      e.preventDefault();
      stopRecording();
      clearBlobUrl();
      setRerecord(true);

      if (duration < MIN_VIDEO_DURATION) {
        errorNotification(t("assignment.fileShouldBeAtLeast"));
      }

      if (duration > MAX_VIDEO_DURATION) {
        errorNotification(t("assignment.fileShouldNotBeBigger"));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stopRecording, clearBlobUrl, setRerecord],
  );

  const handleUnregister = (): void => {
    const mediaStream = videoStream;
    const tracks = mediaStream?.getTracks();
    tracks?.forEach((track) => track.stop());
  };

  useEffect(() => {
    let interval: null | ReturnType<typeof setInterval> = null;
    if (isRecording) {
      interval = setInterval(() => {
        setDuration((duration) => duration + 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval as ReturnType<typeof setInterval>);
    };
  }, [isRecording, duration]);

  useEffect(() => {
    return () => {
      stopRecording();
      clearBlobUrl();
      handleUnregister();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    videoError &&
      generalNotification("warning", <div style={{ maxWidth: "13rem" }}>{videoError}</div>);
  }, [videoError]);

  const handleOnSubmit = async (): Promise<void> => {
    if (mediaBlobUrl) {
      const date = new Date().toISOString();
      const videoFile = await getFileFromBlobUrl(mediaBlobUrl, `${name}-recording-${date}.webm`, {
        type: "video/webm",
      });

      uploadAssigmentVideoFile(videoFile);
    }
  };

  return (
    <form css={formContainer} onSubmit={handleSubmit(handleOnSubmit)}>
      {!cameraRecordingError && (
        <>
          {status === "acquiring_media" && <Loader />}
          {showWebcam && (
            <div className="webcam-preview">
              <MediaPlayer
                className="react-player"
                type="video"
                src={videoStream as MediaStream}
                width="100%"
                height="100%"
                playing
                controls={false}
              />
              <div className="video-status">
                <ul>
                  <li>
                    <VideoSVG height={24} />
                  </li>
                  <li>{secondsToTime(duration, true)}</li>
                </ul>
              </div>
            </div>
          )}

          {showVideoPreview && (
            <div className="video-preview">
              <MediaPlayer
                type="video"
                className="react-player"
                src={isChrome ? fixedURL : mediaBlobUrl ?? undefined}
                controls
                width="100%"
                height="100%"
                config={config}
              />
            </div>
          )}
        </>
      )}

      <Actions
        answerUploaded={answerUploaded}
        isPostingAssignment={isPostingAssignment}
        displayRecordingBtn={displayRecordingBtn}
        displayReRecordingBtn={displayReRecordingBtn}
        displayStopRecordingBtn={displayStopRecordingBtn}
        displaySubmitRecordingBtn={displaySubmitRecordingBtn}
        permissionError={videoError}
        hasValidationError={validationErrorMin || validationErrorMax}
        onReset={onReset}
        handleStartRecording={handleStartRecording}
        handleStopRecording={handleStopRecording}
      />
    </form>
  );
};

export default VideoRecording;

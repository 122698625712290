import axios from "axios";
import { useConfigurationStore } from "@stores";
import { DomainSettings, UploadValidation, FileValidationsObj } from "types/entities";

export const downloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
  const link = createLinkElement();
  link.href = fileUrl;
  link.download = fileName;

  if (link.origin !== location.origin) {
    const corsEnabled = await isCorsEnabled(fileUrl);

    if (corsEnabled) {
      await getFile(fileUrl);
      return;
    } else {
      // force to open file in new tab when getting CORS error
      link.target = "_blank";
    }
  }

  link.click();
};

const createLinkElement = (): HTMLAnchorElement => {
  const link = document.createElement("a");
  link.rel = "noopener";

  return link;
};

const isCorsEnabled = async (url: string): Promise<boolean> => {
  try {
    const response = await axios({
      url: url,
      method: "HEAD",
    });

    return response?.status >= 200 && response?.status <= 299;
  } catch {
    return false;
  }
};

const getFile = async (fileUrl: string): Promise<void> => {
  const filenameFromUrl = getFilenameFromUrl(fileUrl);

  const response = await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Disposition": "attachment",
      filename: filenameFromUrl,
    },
  });

  if (response.status === 200) {
    const link = createLinkElement();
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute("download", filenameFromUrl);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const getFileFromBlobUrl = async (
  blobUrl: string,
  fileName: string,
  fileOptions?: FilePropertyBag,
): Promise<File> => {
  return await axios.get(blobUrl, { responseType: "blob" }).then((response) => {
    return new File([response.data], fileName, fileOptions);
  });
};

export const getFileValidations = (
  paths: string | string[],
): UploadValidation | UploadValidation[] | null => {
  const { getState } = useConfigurationStore;
  const domainSettings = getState().domainSettings;
  let validations: UploadValidation | UploadValidation[] | null = null;

  if (Array.isArray(paths)) {
    validations = paths.reduce((array, path) => {
      array.push((domainSettings as DomainSettings).upload_limits[path]);
      return array;
    }, [] as UploadValidation[]);
  }

  if (typeof paths === "string") {
    validations = (domainSettings as DomainSettings).upload_limits[paths];
  }

  return validations;
};

export const buildFileValidations = (
  validations: UploadValidation[] | UploadValidation | null,
): FileValidationsObj => {
  let result: FileValidationsObj | null = null;

  if (Array.isArray(validations)) {
    // is array of objects
    const obj = validations
      .flatMap(({ mime_types, size_limit }) =>
        mime_types.map((mimeType) => ({ [mimeType]: size_limit })),
      )
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as FileValidationsObj;

    result = obj;
  } else {
    // is object
    const { mime_types: mimeTypes, size_limit: sizeLimit } = validations as UploadValidation;
    const obj = mimeTypes
      .map((mimeType) => ({ [mimeType]: sizeLimit }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {}) as FileValidationsObj;

    result = obj;
  }

  return result;
};

const getFilenameFromUrl = (url: string): string => {
  const filename = new URL(url).pathname.split("/").pop() as string;
  return filename;
};

import { useRef, useState, useEffect } from "react";

type RecordingStatus = "loading" | "success" | "error";
type RecordingType = "video" | "audio";

type Recording = {
  status: RecordingStatus;
  mediaRecorder: MediaRecorder | undefined;
  getRecordingFile: (name: string) => File;
  hasContent: boolean;
};

const useRecording = (type: RecordingType): Recording => {
  const [status, setStatus] = useState<RecordingStatus>("loading");
  const mediaRecorder = useRef<MediaRecorder>();
  const [chunks, setChunks] = useState<Blob[]>([]);

  const handleOnDataAvailable = (event: BlobEvent): void => {
    if (event.data.size > 0) {
      setChunks((chunks) => [...chunks, event.data]);
    }
  };

  const getRecordingFile = (name: string): File => {
    const date = new Date().toISOString();

    return new File(chunks, `${name}-${date}.webm`, { type: `${type}/webm` });
  };

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: type === "video" && true,
      })
      .then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = handleOnDataAvailable;
        mediaRecorder.current.onpause = mediaRecorder.current.requestData;
        mediaRecorder.current.onerror = (): void => setStatus("error");
      })
      .then(() => setStatus("success"));

    return (): void => {
      if (mediaRecorder.current?.state === "inactive") {
        mediaRecorder.current?.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [type]);

  return {
    status,
    mediaRecorder: mediaRecorder.current,
    getRecordingFile,
    hasContent: chunks.length > 0,
  };
};

export default useRecording;

import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Text, Tooltip } from "@epignosis_llc/gnosis";
import { branchesErrorContainer } from "../styles";
import { Link } from "react-router-dom";
import { UserBranch } from "types/entities/User";
import { SerializedStyles } from "@emotion/react";

type BranchWithTooltipProps = {
  branch: UserBranch;
};

type UserBranchesProps = {
  branches: UserBranch[];
  errorMessage: string;
  isImpersonation?: boolean;
  handleAutologin?: (userId: string, branchId: string) => void;
  userId?: string;
};

const getRestrictedBranchUrl = (branchUrl: string): string => {
  return `https://${branchUrl}/plus`;
};

const BranchWithTooltip: FC<BranchWithTooltipProps> = ({ branch }) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const overflowRef = useRef<HTMLElement | null>(null);

  const [isOverflowActive, setIsOverflowActive] = useState(false);

  const BranchText = (
    <Text as="div" fontSize="sm" className="overflow-text">
      {branch.name}
    </Text>
  );

  useLayoutEffect(() => {
    if (componentRef.current) {
      overflowRef.current = componentRef.current.querySelector(".overflow-text");
    }
  }, []);

  useEffect(() => {
    const el = overflowRef.current;

    if (el) {
      setIsOverflowActive(el.offsetWidth < el.scrollWidth);
    }
  }, [overflowRef]);

  return (
    <Tooltip content={BranchText} disabled={!isOverflowActive}>
      <span ref={componentRef}>{BranchText}</span>
    </Tooltip>
  );
};

const UserBranches: FC<UserBranchesProps> = ({
  branches,
  errorMessage,
  userId,
  isImpersonation,
  handleAutologin,
}) => {
  return (
    <section
      css={(): SerializedStyles =>
        branchesErrorContainer({ isImpersonation: Boolean(isImpersonation) })
      }
    >
      <Text fontSize="sm" as="div" className="title">
        {errorMessage}
      </Text>
      <div className="branches-container">
        {branches.map((branch) => (
          <Link
            to={handleAutologin ? "#" : getRestrictedBranchUrl(branch.url)}
            onClick={(): void =>
              handleAutologin && handleAutologin(userId ?? "", branch.id.toString())
            }
            key={branch.id}
          >
            <BranchWithTooltip branch={branch} />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default UserBranches;

import React, { FC, ReactNode, memo } from "react";
import { SerializedStyles } from "@emotion/react";
import { iFrameContainer } from "./styles";
import { MyUnit } from "types/entities";
import i18n from "@utils/i18n";
import { PdfViewer } from "@components";

const IFrameContainer: FC<MyUnit> = ({ url, content, secondary_content, size, file, children }) => {
  const { preview_url } = file ?? {};
  const hasUrlAndContent = Boolean(url && content);
  const hasUrlAndSecondaryContent = Boolean(url && secondary_content);

  const renderPreview = (): ReactNode => {
    if (preview_url) {
      const isRtl = i18n.dir() === "rtl";

      return (
        <div className="pdf-viewer">
          <PdfViewer fileUrl={preview_url} isRTL={isRtl} />
        </div>
      );
    }

    if (url) {
      return <iframe src={url} data-testid="iframe-container" className="iframe-container" />;
    }

    return null;
  };

  return (
    <div
      className="unit-container"
      css={(): SerializedStyles =>
        iFrameContainer(size?.width, size?.height, hasUrlAndContent, hasUrlAndSecondaryContent)
      }
    >
      {content && <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>}

      {renderPreview()}

      {secondary_content && (
        <div
          className="secondary-content"
          dangerouslySetInnerHTML={{ __html: secondary_content }}
        ></div>
      )}
      {children}
    </div>
  );
};

// eslint-disable-next-line react/display-name
export default memo(IFrameContainer, () => true);

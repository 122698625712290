import React, { FC } from "react";
import { Button, ProgressBar, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { SerializedStyles } from "@emotion/react";
import { courseCardProgressBar } from "../../components/styles";
import { getButtonColor } from "../../helpers";
import { Course } from "types/entities";
import { formatUtcDate, getButtonAvailabilityText } from "@utils/helpers";
import { useConfigurationStore } from "@stores";
import Price from "@components/Price/Price";

export type EnrolledActionsProps = {
  course: Course;
  children?: never;
};

const MAIN_BUTTON_TEXT = {
  completed: "general.progress.completed",
  failed: "general.progress.notPassed",
};

const EnrolledActions: FC<EnrolledActionsProps> = ({ course }) => {
  const { catalogSettings } = useConfigurationStore();
  const { availability, expires_at, role_in_course, progress } = course;
  const isCourseAvailable = Boolean(availability?.is_available);
  const hasExpirationResctriction = Boolean(expires_at);
  const isLearner = role_in_course === "learner";
  const isCourseCompleted =
    progress?.completion_status === "completed" || progress?.completion_status === "failed";
  const isSubscriptionEnabled = Boolean(catalogSettings?.subscription.enabled);

  // If subscriptions are enabled
  if (isSubscriptionEnabled && availability?.reason === "cancelled") {
    return (
      <Button block className="footer-btn">
        <Text fontSize="sm" as="div" className="subscribe-btn-text">
          {t("general.subscribe")} (
          <Price value={catalogSettings?.subscription.price?.amount ?? 0} />)
        </Text>
      </Button>
    );
  }

  // TODO: Check if the following code can be simplified
  return (
    <>
      {
        //course is completed or failed
        isCourseCompleted ? (
          //check if course is available
          <Button
            block
            color={isCourseAvailable ? getButtonColor(progress.completion_status) : "secondary"}
            className="footer-btn"
          >
            {isCourseAvailable
              ? t(MAIN_BUTTON_TEXT[progress.completion_status])
              : getButtonAvailabilityText(course)}
          </Button>
        ) : (
          //if course is not attempted or incomplete then check if we have any restrictions to show, else show progress
          <>
            {!isCourseAvailable || (hasExpirationResctriction && isLearner) ? (
              <Button
                block
                className="footer-btn"
                color={hasExpirationResctriction && isLearner ? "danger" : "secondary"}
              >
                {hasExpirationResctriction && isLearner ? (
                  <>
                    {t("restrictions.expires", {
                      expiresAt: expires_at && formatUtcDate(expires_at),
                    })}
                  </>
                ) : (
                  getButtonAvailabilityText(course)
                )}
              </Button>
            ) : (
              <ProgressBar
                percent={progress?.completion_percentage ?? 0}
                rounded={false}
                css={(theme): SerializedStyles =>
                  courseCardProgressBar(theme, {
                    progress: progress?.completion_percentage ?? 0,
                  })
                }
              />
            )}
          </>
        )
      }
    </>
  );
};

export default EnrolledActions;

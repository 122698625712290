import { config } from "@config";

const imagesPath = `${config.ASSETS_URL}/assets/images`;
const iconsPath = `${config.ASSETS_URL}/assets/icons`;
const filesPath = `${config.ASSETS_URL}/assets/files`;

const images = {
  badges: {
    set1: `${imagesPath}/badges/set1.png`,
    set2: `${imagesPath}/badges/set2.png`,
    set3: `${imagesPath}/badges/set3.png`,
    set4: `${imagesPath}/badges/set4.png`,
    set5: `${imagesPath}/badges/set5.png`,
    set6: `${imagesPath}/badges/set6.png`,
  },
  converting: `${imagesPath}/converting.png`,
  cover: `${imagesPath}/default_cover_image.png`,
  errors: {
    fetchData: `${imagesPath}/errors/fetch_data.svg`,
    internalServer: `${imagesPath}/errors/internal_server.svg`,
    notFound: `${imagesPath}/errors/not_found.svg`,
  },
  signIn: `${imagesPath}/clouds1.svg`,
};

const icons = {
  favicon: `${iconsPath}/default-favicon.png`,
};

const files = {
  importSamplesXLS: `${filesPath}/Import-Samples.xls`,
};

export { images, icons, files };

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const profileHeader = ({ profile }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: break-word;

  .avatar {
    img {
      border: 1px solid #108ee9;
      border-radius: 50%;
    }

    ${mq["sm"]} {
      align-self: flex-start;
    }
  }

  .info-container {
    flex: 3 1 0;
    padding: 1rem 0.5rem;

    ${mq["sm"]} {
      padding: 0.5rem 1rem 1rem 1.4rem;
    }

    .email {
      color: ${profile.emailColor};
    }
  }
`;

export const profileContainer = ({ profile }) => css`
  border-top: 1px solid #ccc;

  section {
    border-bottom: 1px solid #ccc;
    padding: 2rem;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 0.5rem;
        padding: 0 0.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }

        a {
          display: flex;
          align-items: center;
          color: ${profile.menuItem.color};

          &:hover {
            color: ${profile.menuItem.hoverColor};
          }

          &.selected {
            color: ${profile.menuItem.hoverColor};
          }
        }

        svg {
          margin-inline-end: 0.5rem;
        }
      }
    }
  }

  footer {
    margin: 2rem 0 1rem;
    text-align: center;
  }
`;

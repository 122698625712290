import React, { FC } from "react";
import { useResponsive } from "ahooks";
import { SerializedStyles } from "@emotion/utils";
import { useQueryClient } from "react-query";
import { Sidebar, Dropdown, DropdownItem, Tooltip } from "@epignosis_llc/gnosis";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import classNames from "classnames";
import { roleMenuItem } from "./styles";
import { UserProfile } from "types/entities";
import authService from "@utils/services/AuthService";
import i18n from "@utils/i18n";

type RoleMenuItemProps = {
  title: string;
  drawerKey: string;
  userProfileData: UserProfile | null;
  isActive: boolean;
  icon: JSX.Element | null;
  badgeNumber?: number;
  isCollapsed?: boolean;
};

const roleContainerClassNames = (singleRole: boolean): string =>
  classNames({
    "single-role": singleRole,
  });

const RoleMenuItem: FC<RoleMenuItemProps> = ({
  title,
  drawerKey,
  icon,
  isCollapsed,
  isActive,
  userProfileData,
}) => {
  const defaultRole = authService.getDefaultRole();
  const { available_roles = [] } = userProfileData ?? {};
  const queryClient = useQueryClient();
  const dir = i18n.dir();
  const isRTL = dir === "rtl";
  const roleLabel = t(`general.${defaultRole}`);
  const { md } = useResponsive();

  const userRoleOptions: DropdownItem[] = available_roles.map((role) => {
    return { label: t(`general.${role}`), value: role };
  });

  const handleRoleSelected = (item: DropdownItem): void => {
    const newRole = item.value;

    if (newRole !== defaultRole) {
      authService.setDefaultRole(newRole ?? "learner");
      queryClient.invalidateQueries();
    }
  };

  return (
    <Tooltip content={t("general.role")} disabled={!isCollapsed} placement="right">
      <li
        css={(theme): SerializedStyles => roleMenuItem(theme, { dir, defaultRole })}
        className={roleContainerClassNames(userRoleOptions.length === 1)}
        key={title}
        title={!isCollapsed ? t("general.role") : ""}
        id={drawerKey}
      >
        {userRoleOptions.length > 1 && (
          <Dropdown
            placement={!md ? "bottom-start" : "end-top"}
            onListItemSelect={handleRoleSelected}
            list={userRoleOptions}
          >
            <div className="sidebar-wrapper">
              <Sidebar.Item
                icon={icon}
                label={roleLabel}
                isExpanded={!isCollapsed}
                isActive={isActive}
              />
              {!isCollapsed && (
                <div className="arrow-right">
                  {isRTL ? <ArrowLeftSVG height={32} /> : <ArrowRightSVG height={32} />}
                </div>
              )}
            </div>
          </Dropdown>
        )}
      </li>
    </Tooltip>
  );
};

export default RoleMenuItem;

import React, { FC, useRef } from "react";
import { SerializedStyles } from "@emotion/utils";
import { Text, Heading, Tooltip, Button, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightSVG, VerticalEllipsisSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { tocHeader } from "./styles";
import { StatusTag, Link } from "@components/ReusableComponents";
import { useIsOverflow } from "@hooks";
import { TypographyLevels } from "@styles/utils";
import classNames from "classnames";
import i18n from "@utils/i18n";

export type HeaderProps = FC<{
  title: string;
  titleSize?: TypographyLevels;
  titleLink?: string;
  imageUrl?: string;
  imageAlt?: string;
  dropdownOptions?: DropdownItem[];
  isInactive?: boolean;
  isSelected?: boolean;
  backLink?: {
    href: string;
    text: string;
  };
  onDropdownOptionSelect?: (item: DropdownItem) => void;
}>;

const headerContainerClasses = (isSelected: boolean): string =>
  classNames({
    "header-container": true,
    "is-selected": isSelected,
  });

const Header: HeaderProps = ({
  title,
  titleSize = "2xl",
  titleLink = "#",
  imageUrl,
  imageAlt = "Title image",
  backLink,
  dropdownOptions,
  children,
  isInactive = false,
  isSelected = false,
  onDropdownOptionSelect,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isOverflow = useIsOverflow(ref);
  const dir = i18n.dir();
  const isRtl = dir === "rtl";

  const handleDropdownSelect = (item: DropdownItem): void => {
    onDropdownOptionSelect && onDropdownOptionSelect(item);
  };

  return (
    <header
      css={(theme): SerializedStyles => tocHeader(theme, Boolean(children))}
      className="admin"
    >
      {backLink && (
        <div className="link-container">
          <div className="link">
            <Button as={Link} to={backLink.href} variant="link" noGutters aria-label="Go back">
              {isRtl ? <ArrowRightSVG height={24} /> : <ArrowLeftSVG height={24} />}
            </Button>

            <Link to={backLink.href}>
              <Text fontSize="md">{backLink.text}</Text>
            </Link>
          </div>

          {children}
        </div>
      )}

      <div className={headerContainerClasses(isSelected)}>
        <div className="content-container">
          {imageUrl && <img src={imageUrl} alt={imageAlt} />}

          <Tooltip content={title} disabled={!isOverflow}>
            <span className="title" ref={ref}>
              {titleLink ? (
                <Link to={titleLink} color="secondary">
                  <Heading size={titleSize}>{title}</Heading>
                </Link>
              ) : (
                <Heading size={titleSize}>{title}</Heading>
              )}
            </span>
          </Tooltip>
        </div>

        <div className="actions-container">
          {isInactive && <StatusTag text={t("general.draft")} />}

          {dropdownOptions && (
            <Dropdown
              list={dropdownOptions}
              placement="bottom-end"
              onListItemSelect={handleDropdownSelect}
            >
              <Button variant="ghost" noGutters as="div">
                <VerticalEllipsisSVG height={16} />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { FC, useEffect, useState } from "react";
import QuestionsTypesContainer from "../QuestionsTypesContainer";
import Feedback from "./Feedback";
import { Course, MyUnit, QuestionAnswers, Section, QuestionType } from "types/entities";

type CompleteWithQuestionProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isAnswerWrong: boolean;
  setUserAnswersParent: (answers: QuestionAnswers) => void;
};

const CompleteWithQuestion: FC<CompleteWithQuestionProps> = (props) => {
  const { unit, isAnswerWrong, setUserAnswersParent } = props;
  const question = unit?.completion_method?.question;
  const feedback = question?.feedback;
  const [userAnswers, setUserAnswers] = useState<QuestionAnswers>([]);

  const onUsersAnswersChange = (answers: QuestionAnswers): void => {
    setUserAnswers(answers);
    setUserAnswersParent(answers);
  };

  useEffect(() => {
    if (unit.completion_method && unit.completion_method.question?.type === QuestionType.Ordering) {
      setUserAnswers(unit.completion_method.question.answers.possible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  return (
    <>
      {question && (
        <div className="question">
          <QuestionsTypesContainer
            questionEntry={question}
            userAnswers={userAnswers}
            onAnswersChange={onUsersAnswersChange}
          >
            {unit?.test_options?.check_answers && isAnswerWrong && feedback && (
              <div className="answers-container">
                <Feedback feedback={feedback?.text} />
              </div>
            )}
          </QuestionsTypesContainer>
        </div>
      )}
    </>
  );
};

export default CompleteWithQuestion;

import React, { FC } from "react";
import { LabelOnHoverWrapper } from "@components";
import { Button, Card, Text, Tooltip } from "@epignosis_llc/gnosis";
import { ShowIconSVG, ArrowDownSVG, InfoCircledSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { hoverContainer } from "./styles";
import { NotSharedSVG, SharedSVG } from "@assets/icons";
import { Tag } from "types/entities/Common";

const { Hover } = Card;

type FileHoverProps = React.HTMLAttributes<HTMLElement> & {
  isPreviewable: boolean;
  size: string;
  shared: boolean;
  tags: Tag[];
};

const FileHover: FC<FileHoverProps> = ({ isPreviewable, size, shared, tags, ...rest }) => {
  const sharedTooltipText = shared ? t("general.sharedWithUsers") : t("general.notSharedWithUsers");

  const icon = isPreviewable ? (
    <Button aria-label="file preview" rounded>
      <ShowIconSVG height={32} />
    </Button>
  ) : (
    <Button aria-label="file download" rounded>
      <ArrowDownSVG height={32} />
    </Button>
  );

  const label = isPreviewable ? (
    <Text fontSize="sm">{t("myFiles.previewFile")}</Text>
  ) : (
    <Text
      fontSize="sm"
      dangerouslySetInnerHTML={{ __html: t("general.downloadFile", { bytes: size }) }}
    />
  );

  const infoContent = (
    <>
      <Text as="div" fontSize="xs" weight="700">
        {t("general.tags")}
      </Text>
      <div className="tags">
        <Text fontSize="xs" as="div">
          {tags.map((tag) => `#${tag.name}`).join(" ")}
        </Text>
      </div>
    </>
  );

  return (
    <div css={hoverContainer} {...rest}>
      <Hover>
        <LabelOnHoverWrapper icon={icon} label={label}>
          <div className="hover-info-container">
            {shared !== undefined && (
              <Tooltip placement="top" content={sharedTooltipText}>
                <span aria-label="shared" className="shared">
                  {shared ? <SharedSVG height={32} /> : <NotSharedSVG height={32} />}
                </span>
              </Tooltip>
            )}
            <Tooltip
              placement="top"
              disabled={tags.length === 0}
              content={infoContent}
              className="info-tooltip"
            >
              <span aria-label="shared" className={`info ${tags.length === 0 ? "hidden" : ""}`}>
                <InfoCircledSVG height={21} />
              </span>
            </Tooltip>
          </div>
        </LabelOnHoverWrapper>
      </Hover>
    </div>
  );
};

export default FileHover;

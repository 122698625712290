import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { ToggleButtonStyles } from "./styles";
import i18n from "@utils/i18n";
import { SerializedStyles } from "@emotion/react";

export type ToggleButtonProps = React.HTMLProps<HTMLButtonElement> & {
  buttonText: string;
  onClick?: () => void;
};

const ToggleButton: FC<ToggleButtonProps> = ({ buttonText, onClick }) => {
  const isRtl = i18n.dir() === "rtl";

  return (
    <Button
      css={(theme): SerializedStyles => ToggleButtonStyles(theme)}
      type="button"
      variant="ghost"
      block
      noGutters
      onClick={onClick}
      iconAfter={isRtl ? ArrowLeftSVG : ArrowRightSVG}
    >
      {buttonText}
    </Button>
  );
};

export default ToggleButton;

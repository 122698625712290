import { t } from "i18next";
import { yup } from "./validation";

export const GroupFormValidationSchema = yup.object().shape(
  {
    name: yup.string().trim().required().max(80),
    description: yup.string().max(500, t("errors.groups.descriptionMaxLength")),
    key: yup.string().when("key", (value) => {
      if (value) {
        return yup
          .string()
          .min(6, t("errors.groups.groupKeyMinLength"))
          .max(20, t("errors.groups.groupKeyMaxLength"));
      } else {
        return yup
          .string()
          .transform((value, originalValue) => {
            // Convert empty values to null
            if (!value) {
              return null;
            }
            return originalValue;
          })
          .nullable()
          .optional();
      }
    }),
    max_key_redemptions: yup
      .string()
      .matches(/^[1-9]\d*$|^\s*$/, t("errors.groups.redemptionsIsNotValid"))
      .transform((value) =>
        Number(value) > 16777215 ? t("errors.groups.redemptionsIsNotValid") : value,
      ),
    price: yup.string().matches(/^[0-9]*(.[^,]\d{1,3})?$/, t("errors.groups.priceIsNotValid")),
  },
  [["key", "key"]],
);

export const JoinGroupValidationSchema = yup.object().shape({
  groupKey: yup.string().trim().required(),
});

import React, { useState } from "react";
import { ToggleContainer } from "./style";
import { SerializedStyles } from "@emotion/utils";
import { Text } from "@epignosis_llc/gnosis";
import classNames from "classnames";

interface ToggleProps {
  labelBefore?: string;
  labelAfter?: string;
  defaultChecked?: boolean;
  isDisabled?: boolean;
  required?: boolean;
  onChange?: () => void;
}

const ToggleSwitch: React.FC<ToggleProps> = ({
  labelBefore,
  labelAfter,
  defaultChecked = false,
  isDisabled = false,
  required = false,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const labelAfterClassname = classNames("label", "label-after", {
    required,
  });

  const handleToggle = (): void => {
    if (!isDisabled) {
      setIsChecked((prev) => !prev);
      if (onChange) onChange();
    }
  };

  return (
    <div css={(theme): SerializedStyles => ToggleContainer(theme, { isChecked, isDisabled })}>
      {labelBefore && (
        <Text fontSize="sm" className="label label-before" onClick={handleToggle}>
          {labelBefore}
        </Text>
      )}

      <div className="switch" onClick={handleToggle}>
        <div className="thumb" />
      </div>

      {labelAfter && (
        <Text fontSize="sm" className={labelAfterClassname} onClick={handleToggle}>
          {labelAfter}
        </Text>
      )}
    </div>
  );
};

export default ToggleSwitch;

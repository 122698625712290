import React, { FC } from "react";
import ContentLoader from "react-content-loader";
import i18n from "@utils/i18n";

const SkeletonTitle: FC = () => {
  const isRTL = i18n.dir() === "rtl";

  return (
    <ContentLoader
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ minWidth: "100%", width: "100%" }}
      height={30}
      rtl={isRTL}
    >
      <rect x="0" y="0" rx="0" ry="0" width="225" height="30" />
    </ContentLoader>
  );
};

export default SkeletonTitle;

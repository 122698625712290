import React, { FC } from "react";
import { Sidebar, Tooltip } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { LeavePortalSVG } from "@epignosis_llc/gnosis/icons";
import { isMainMenuItemActiveForDrawer } from "@utils/helpers";
import useUIStore, { MainDrawerContent } from "@stores/ui";
import { MainNavData } from "@components/MainNav/MainNav";
import { useGetAutologinURL } from "@hooks";
import { useMutation } from "react-query";
import queryKeys from "@constants/queryKeys";
import { handleImpersonationErrors } from "@errors/errors";
import { AxiosError } from "axios";
import { stopUserImpersonation } from "@views/User/api";
import { URLS } from "@constants/urls";

type AutoLoginButtonProps = {
  title: string;
  drawerKey: string;
  isCollapsed?: boolean;
  navState?: MainNavData;
  icon?: JSX.Element | null;
};

const AutoLoginButton: FC<AutoLoginButtonProps> = ({
  title,
  drawerKey,
  isCollapsed = false,
  navState = null,
  icon = <LeavePortalSVG height={32} />,
}) => {
  const setShowRedirectLoading = useUIStore((state) => state.setShowRedirectLoading);
  const { mutate: switchFromPlusToTalentMutation } = useGetAutologinURL();
  const isImpersonation = drawerKey === "backToAdmin";

  const handleClick = (): void => {
    if (isImpersonation) {
      stopImpersonationMutation();
    } else {
      setShowRedirectLoading(true);
      switchFromPlusToTalentMutation();
    }
  };

  const { mutate: stopImpersonationMutation } = useMutation(
    [queryKeys.users.stopImpersonation],
    () => stopUserImpersonation(),
    {
      onSuccess: (res) => {
        const response = res._data;
        const { token, domain } = response;
        window.location.replace(
          `https://${domain}/plus${URLS.autologin}?token=${token}&role=administrator&redirect_path=${URLS.users.users}`,
        );
      },

      onError: (error: AxiosError) => {
        handleImpersonationErrors(error);
      },
    },
  );

  return (
    <li key={title} id={drawerKey ?? ""} className={drawerKey}>
      <Tooltip content={t(title)} disabled={!isCollapsed} placement="right">
        <Link
          to="#"
          title={!isCollapsed ? t(title) : ""}
          onClick={handleClick}
          className="nav-item leave-beta"
        >
          <Sidebar.Item
            icon={icon}
            label={t(title)}
            isExpanded={!isCollapsed}
            isActive={isMainMenuItemActiveForDrawer(navState, drawerKey as MainDrawerContent)}
          />
        </Link>
      </Tooltip>
    </li>
  );
};

export default AutoLoginButton;

import { useEffect, useRef, RefObject, DependencyList } from "react";

const useTrap = <E extends HTMLElement>(...deps: DependencyList): RefObject<E> => {
  const elementRef = useRef<E>(null);
  const visibleEls: Element[] = [];

  useEffect(() => {
    const allFocusableElements = elementRef.current?.querySelectorAll(
      'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
    );

    allFocusableElements?.forEach((el) => {
      const { display, visibility } = window.getComputedStyle(el);
      const { width, height } = el.getBoundingClientRect();

      if (display !== "none" && visibility !== "hidden" && width && height) {
        visibleEls.push(el);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  return elementRef;
};

export default useTrap;

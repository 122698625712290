import React, { FC } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";
import { t } from "i18next";
import { skeletonContainer } from "./style";
import { DrawerMessage, Skeletons } from "@components";
import { EmptyMessagesSVG } from "@assets/images";
import queryKeys from "@constants/queryKeys";
import { getInboxMessages } from "@api/messages";

const Messages: FC = () => {
  const {
    status,
    data: messages,
    error,
  } = useQuery(
    [queryKeys.messages.inbox],
    () => getInboxMessages("?page[number]=1&page[size]=10&sort=-sent_at"),
    {
      select: (messages) => ({
        data: messages._data,
      }),
    },
  );

  return (
    <div css={skeletonContainer}>
      <Skeletons.Loader
        status={status}
        error={error}
        loader={
          <div className="skeleton-wrapper">
            {[1, 2, 3, 4, 5].map((n) => (
              <Skeletons.ListItem key={n} />
            ))}
          </div>
        }
      >
        <section>
          {messages?.data?.length ? (
            messages?.data?.map((message) => <DrawerMessage key={message.id} {...message} />)
          ) : (
            <Result
              icon={EmptyMessagesSVG}
              title={t("messages.noInbox")}
              info={t("messages.noInboxSubtitle")}
            />
          )}
        </section>
      </Skeletons.Loader>
    </div>
  );
};

export default Messages;

export const DEFAULT_CONFIGURATION = {
  attribution: false,
  iconsTemplate: "my_fa",
  imageInsertButtons: ["imageBack", "|", "imageByURL", "insertImagesDropdown"],
  imageUploadRemoteUrls: false,
  key: "aLF3c1A7D6A4D3E3B1F2xROKLJKYHROLDXDRH1e1YYGRe1Bg1G3I3A2B5D6A3D3E4D2F2==",
  listAdvancedTypes: false,
  linkInsertButtons: ["linkBack"],
  zIndex: 1,
  fontFamily: {
    "Mulish, Arial, sans-serif": "Mulish",
    "Arial, Helvetica, sans-serif": "Arial",
    "Georgia, serif": "Georgia",
    "Verdana, Geneva, sans-serif": "Verdana",
  },
  fontFamilyDefaultSelection: "Mulish",
  linkEditButtons: ["linkOpen", "linkEdit", "linkRemove"],
  imageEditButtons: [
    "imageAlign",
    "imageCaption",
    "imageRemove",
    "imageLink",
    "linkEdit",
    "linkRemove",
    "imageDisplay",
    "imageAlt",
  ],
  tableEditButtons: [
    "tableRemove",
    "tableRows",
    "tableColumns",
    "tableCellBackground",
    "tableCellHorizontalAlign",
  ],
};

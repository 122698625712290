import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useConfigurationStore } from "@stores";
import { Button } from "@epignosis_llc/gnosis";
import { socialSignIn } from "./styles";
import { FacebookSVG, GoogleSVG, LinkedinSVG } from "@assets/icons";

const SocialSignIn: FC = () => {
  const { t } = useTranslation();
  const { domainSettings, socialDomainSettings } = useConfigurationStore();

  const handleClick = (social: string): void => {
    const navigateTo = socialDomainSettings?.filter((set) => {
      return social === set.type && set.url;
    })[0].url;

    navigateTo && window.location.replace(navigateTo);
  };

  return (
    <div css={socialSignIn}>
      <section className="form-content">
        {domainSettings?.social_login.includes("facebook") && (
          <Button
            as="button"
            color="primary"
            iconBefore={FacebookSVG}
            className="social-button"
            onClick={(): void => handleClick("facebook")}
            variant="outline"
          >
            {t("signIn.loginWithFacebook")}
          </Button>
        )}
        {domainSettings?.social_login.includes("google") && (
          <Button
            as="button"
            color="primary"
            iconBefore={GoogleSVG}
            className="social-button"
            onClick={(): void => handleClick("google")}
            variant="outline"
          >
            {t("signIn.loginWithGoogle")}
          </Button>
        )}
        {domainSettings?.social_login.includes("linkedin") && (
          <Button
            as="button"
            color="primary"
            iconBefore={LinkedinSVG}
            className="social-button"
            onClick={(): void => handleClick("linkedin")}
            variant="outline"
          >
            {t("signIn.loginWithLinkedin")}
          </Button>
        )}
      </section>
    </div>
  );
};

export default SocialSignIn;

import React, { FC } from "react";
import { useResponsive } from "ahooks";
import { Button, Text } from "@epignosis_llc/gnosis";
import { AudioSVG, VideoSVG, UploadSVG, UnitAssignmentSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { Answer } from "../Assignment";
import { replyOptionsContainer } from "./styles";
import { ScreenSVG } from "@assets/icons";

type ReplyOptionsProps = {
  onSelectAnswer: (type: Answer) => void;
  children?: never;
};

const ReplyOptions: FC<ReplyOptionsProps> = ({ onSelectAnswer }) => {
  const { sm } = useResponsive();
  const textSize = sm ? "sm" : "xs";

  return (
    <div css={replyOptionsContainer}>
      <div className="reply-option">
        <Button onClick={(): void => onSelectAnswer("text")} rounded>
          <UnitAssignmentSVG height={48} />
        </Button>
        <Text fontSize={textSize} weight="700">
          {t("assignment.textReply")}
        </Text>
      </div>
      <div className="reply-option">
        <Button onClick={(): void => onSelectAnswer("file")} rounded>
          <UploadSVG height={24} />
        </Button>
        <Text fontSize={textSize} weight="700">
          {t("general.uploadFile")}
        </Text>
      </div>
      <div className="reply-option">
        <Button onClick={(): void => onSelectAnswer("video-upload")} rounded>
          <VideoSVG height={24} />
        </Button>
        <Text fontSize={textSize} weight="700">
          {t("assignment.recordVideo")}
        </Text>
      </div>
      <div className="reply-option">
        <Button onClick={(): void => onSelectAnswer("audio-upload")} rounded>
          <AudioSVG height={48} />
        </Button>
        <Text fontSize={textSize} weight="700">
          {t("assignment.recordAudio")}
        </Text>
      </div>
      <div className="reply-option">
        <Button onClick={(): void => onSelectAnswer("screen-record")} rounded>
          <ScreenSVG height={24} />
        </Button>
        <Text fontSize={textSize} weight="700">
          {t("assignment.recordScreen")}
        </Text>
      </div>
    </div>
  );
};

export default ReplyOptions;

import { css } from "@emotion/react";

export const FileListStyle = ({ courseEdit }) => css`
  margin-top: 0.5rem;

  & > table {
    width: 100%;
  }

  .files-header {
    margin-bottom: 0.5rem;
  }

  table {
    tr:hover {
      color: ${courseEdit.uploadFile.textHoverColor};
    }

    tbody {
      .tag-column {
        white-space: nowrap;
        padding-inline-end: 0.5rem;
      }

      .file-column {
        width: 100%;
        span {
          text-align: start;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
        }
      }
      .file-name {
        cursor: default;
      }
    }
  }

  .view-all-wrapper {
    text-align: center;
    margin-top: 1rem;
  }

  .table-actions-container {
    position: relative;
    margin-inline-start: 1rem;

    .hover-actions {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: inherit;
      opacity: 0;
    }

    .actions-icon {
      position: absolute;
      inset-inline-end: 0;
      top: 0;
      opacity: 0;
    }

    .is-visible {
      opacity: 1;
    }
  }
`;

export const FileTagStyles = ({ fileCard }, size) => css`
  background: ${fileCard.fileType.background} !important;
  color: ${fileCard.fileType.color} !important;
  border-radius: 3px;
  width: 100%;
  justify-content: center;
  padding: ${size === "sm" ? "0.2rem 0.5rem" : "0.5rem 0.75rem 0.4rem"};
  cursor: default;
`;

import React, { FC } from "react";
import { useResponsive } from "ahooks";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { profileHeader } from "./styles";
import { UserAvatar } from "@components/ReusableComponents";
import { useConfigurationStore } from "@stores";
import { getFormattedUserName } from "@utils/helpers";

const ProfileHeader: FC = () => {
  const { sm } = useResponsive();
  const { userProfileData } = useConfigurationStore();
  const { name = "", surname = "", login = "", avatar = null, email } = userProfileData ?? {};

  const avatarSize = sm ? "lg" : "md";

  return (
    <section css={profileHeader}>
      <div className="avatar">
        <UserAvatar
          avatar={avatar}
          imageSize="md"
          avatarSize={avatarSize}
          name={name}
          alt={`${name} ${surname}`}
          bgColor="#FF8000"
        />
      </div>
      <div className="info-container">
        <div className="title-container">
          <Heading className="full-name" size={sm ? "4xl" : "lg"}>
            {getFormattedUserName({ name, surname, login })}
          </Heading>
        </div>
        <Text fontSize={sm ? "md" : "xs"} className="email">
          {email}
        </Text>
      </div>
    </section>
  );
};

export default ProfileHeader;

import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { t } from "i18next";
import { PlusIconSVG } from "@assets/icons";

export type AddActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const AddAction: FC<AddActionProps> = ({
  tooltipContent,
  icon: Icon = PlusIconSVG,
  iconHeight = 16,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.add")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default AddAction;

import React, { FC } from "react";
import PlainSplashScreen from "./screens/PlainSplashScreen";
import SnapshotSplashScreen from "./screens/SnapshotSplashScreen";
import PasswordForm from "./screens/PasswordForm";
import CompletedSplashScreen from "./screens/CompletedSplashScreen";
import { MyUnitRes } from "types/responses";
import { Course, Section } from "types/entities";

export type TestContainerProps = {
  unitRes: MyUnitRes;
  course: Course;
  units: Section[];
  isPublic?: boolean;
};

const TestContainer: FC<TestContainerProps> = ({ unitRes, course, units, isPublic = false }) => {
  const unit = unitRes._data;
  const { test_options, progress } = unit;
  const { test_security_type = null } = test_options ?? {};
  const { status } = progress ?? {};

  const hasRules = Boolean(test_security_type);
  const isCompleted = status === "completed" || status === "failed";
  const showPlainSplashScreen = !hasRules && !isCompleted;
  const showSnapshotSplashScreen = test_security_type === "evidence" && !isCompleted;
  const showPasswordForm = test_security_type === "password" && !isCompleted;

  return (
    <>
      {isCompleted && (
        <CompletedSplashScreen
          unitRes={unitRes}
          course={course}
          units={units}
          isPublic={isPublic}
        />
      )}
      {showPlainSplashScreen && <PlainSplashScreen unitRes={unitRes} isPublic={isPublic} />}
      {showSnapshotSplashScreen && <SnapshotSplashScreen unitRes={unitRes} isPublic={isPublic} />}
      {showPasswordForm && <PasswordForm unitRes={unitRes} isPublic={isPublic} />}
    </>
  );
};

export default TestContainer;

import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { OpenedMessageSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";

export type OpenedMessageActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const OpenedMessageAction: FC<OpenedMessageActionProps> = ({
  tooltipContent,
  icon: Icon = OpenedMessageSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("messages.view")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default OpenedMessageAction;

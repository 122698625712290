import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import useUIStore from "@stores/ui";
const { getState } = useUIStore;

export const main = ({ widgetsDashboard }) => {
  const isCollapsed = getState().mainNavState === "collapsed";
  const sidebarWidth = isCollapsed ? "5rem" : "16rem";

  return css`
    position: relative;
    display: flex;
    background: white;

    .main-content-container {
      width: 100%;
      max-width: 100%;

      ${mq["md"]} {
        max-width: calc(100% - ${sidebarWidth});
      }

      .announcements {
        margin-bottom: 1rem;

        ${mq["lg"]} {
          margin-bottom: 2rem;
        }
      }

      &.widgets-dashboard {
        background: ${widgetsDashboard.background};

        .main-content-wrapper {
          max-width: 100%;
          padding-top: 1.5rem;
        }
      }
    }
  `;
};

export const content = () => css`
  position: relative;

  .main-content-wrapper {
    position: relative;
    max-width: 1920px;
    padding: 1rem;
    z-index: 1;

    ${mq["lg"]} {
      padding: 2rem 2rem 3rem;
    }
  }

  &.no-padding {
    .main-content-wrapper {
      padding: 0;
    }
  }
`;

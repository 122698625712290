import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Sidebar, Tooltip } from "@epignosis_llc/gnosis";
import useUIStore, { MainDrawerContent } from "@stores/ui";
import { UserProfile } from "types/entities";
import { UserAvatar } from "@components/ReusableComponents";
import classNames from "classnames";
import { isValidPathname } from "@utils/helpers/url";
import { profileURLS } from "@constants/urls";

type ProfileMenuItemProps = {
  title: string;
  drawerKey: string;
  userProfileData: UserProfile | null;
  isActive: boolean;
  isCollapsed?: boolean;
  handleClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    drawerKey: MainDrawerContent,
  ) => void;
};

const navItemClassNames = (isValidUserPath: boolean): string =>
  classNames({
    profile: true,
    active: isValidUserPath,
  });

const ProfileMenuItem: FC<ProfileMenuItemProps> = ({
  title,
  drawerKey,
  isCollapsed = false,
  isActive,
  userProfileData,
  handleClick,
}) => {
  const { name = "", surname = "", avatar = null } = userProfileData ?? {};
  const { activeTab } = useUIStore();
  const isValidUserPath = isValidPathname(activeTab, profileURLS);

  const icon = (
    <div className="user-avatar">
      <UserAvatar
        avatar={avatar}
        imageSize="sm"
        avatarSize="xs"
        name={name}
        alt={`${name} ${surname}`}
        bgColor="#FF8000"
      />
    </div>
  );

  return (
    <Tooltip content={title} disabled={!isCollapsed} placement="right">
      <li className={navItemClassNames(isValidUserPath)} key={title} id={drawerKey ?? ""}>
        <Link
          to="#"
          title={!isCollapsed ? title : ""}
          className="nav-item"
          onClick={(e): void => handleClick(e, drawerKey as MainDrawerContent)}
        >
          <Sidebar.Item icon={icon} label={title} isExpanded={!isCollapsed} isActive={isActive} />
        </Link>
      </li>
    </Tooltip>
  );
};

export default ProfileMenuItem;

import React, { FC, useRef, MouseEvent, useState, useEffect } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { useQueryClient } from "react-query";
import { popupContainer } from "./styles";
import { Course, MyUnit } from "types/entities";
import { useAuth, useUrlFromContent } from "@hooks";
import { getPopupParams } from "@utils/helpers";
import queryKeys from "@constants/queryKeys";

type PopupIFrameContainerProps = {
  unit: MyUnit;
  course: Course;
};

const PopupIFrameContainer: FC<PopupIFrameContainerProps> = ({ course, unit, children }) => {
  const { isAuthenticated } = useAuth();
  const { url, content, id } = unit;
  const src = useUrlFromContent(url, content);
  const popupWindowRef = useRef<null | Window>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const queryClient = useQueryClient();
  const courseId = course?.id.toString();
  const unitId = id.toString();

  const handleShowPopup = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    // pop up closed or not exists
    if (!popupWindowRef.current || popupWindowRef.current.closed) {
      popupWindowRef.current = window.open(
        src,
        "iframe_popup",
        getPopupParams(window.screen.height, window.screen.width),
      );

      setIsPopupOpen(true);
    } else {
      popupWindowRef.current.focus();
    }
  };

  useEffect(() => {
    const timer = setInterval(function () {
      if (popupWindowRef.current && popupWindowRef.current.closed) {
        clearInterval(timer);
        setIsPopupOpen(false);

        if (["xapi", "cmi5"].includes(unit.type)) {
          queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
          queryClient.invalidateQueries([queryKeys.units, courseId]);
          queryClient.invalidateQueries([queryKeys.unit, unitId]);
        }
      }
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupOpen]);

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      popupWindowRef.current?.close();
      setIsPopupOpen(false);
    };
  }, []);

  // close pop up when user not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      if (popupWindowRef.current && !popupWindowRef.current.closed) {
        popupWindowRef.current.close();
      }

      setIsPopupOpen(false);
    }
  }, [isAuthenticated]);

  return (
    <div css={popupContainer}>
      <div className="pop-up-content">
        {!isPopupOpen ? (
          <>
            <Text as="div" fontSize="sm" weight="700" className="pop-up-content-text">
              {t("popup.willOpenInNewWindow")}
            </Text>
            <Text as="div" fontSize="sm" className="pop-up-content-text">
              {t("popup.whenNewWindowOpen")}
            </Text>
            <Button className="pop-up-content-button" onClick={handleShowPopup}>
              {t("popup.start")}
            </Button>
          </>
        ) : (
          <>
            <Text as="div" fontSize="sm" weight="700" className="pop-up-content-text">
              {t("popup.openedInNewWindow")}
            </Text>
            <Text as="div" fontSize="sm" className="pop-up-content-text">
              {t("popup.doNotClose")}
            </Text>
          </>
        )}
      </div>
      {children}
    </div>
  );
};

export default PopupIFrameContainer;

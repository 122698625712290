import { Theme } from "types/entities";
import { useUIStore } from "@stores";
import { themes, COLORS } from "@styles";
import { palletColorsGenerator, getPlusTheme, getGnosisTheme } from "@styles/utils";

const { getState } = useUIStore;

export const setActiveTheme = (theme: Theme): void => {
  const setTheme = getState().setTheme;
  // set a default theme
  if (theme.default) {
    setTheme(themes[theme.id] ?? themes.defaultBlue);
  } else {
    if (theme.primary_color) {
      const THEME_COLOURS = {
        ...COLORS,
        primary: theme.primary_color,
      };

      const colors = palletColorsGenerator(THEME_COLOURS);
      const customTheme = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

      setTheme(customTheme);
    }
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { scormPopUpContainer } from "./styles";
import { ScormPopupSVG } from "@assets/images";
import { ScormUnit } from "types/entities";
import { getPopupParams } from "@utils/helpers";
import { useAuth } from "@hooks";
import { useConfigurationStore } from "@stores";

type PopupScormProps = {
  unit: ScormUnit;
};

const PopupScorm: FC<PopupScormProps> = ({ unit }) => {
  const { isAuthenticated } = useAuth();
  const domainSettings = useConfigurationStore((state) => state.domainSettings);
  const popupWindowRef: any = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleShowPopup = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    // pop up closed or not exists
    if (!popupWindowRef.current || popupWindowRef.current.closed) {
      popupWindowRef.current = window.open(
        domainSettings?.scorm_popup_loader,
        "tl_scorm_frame",
        getPopupParams(window.screen.height, window.screen.width),
      );

      setIsPopupOpen(true);

      // TODO: find better way to determine when to postMessage
      setTimeout(() => {
        popupWindowRef.current.postMessage((window as any).tl_sco_data, "*");
      }, 2000);

      // pop exists
    } else {
      popupWindowRef.current.focus();
    }
  };

  const handleUnload = (): void => {
    if (popupWindowRef.current && !popupWindowRef.current.closed) {
      popupWindowRef.current.close();
    }
  };

  // we must check on every re-render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!popupWindowRef.current || popupWindowRef.current.closed) {
      setIsPopupOpen(false);
    }
  });

  // Force close the popup window if the user closes the parent tab / window
  // more details: talentlms/libraries/EF/views/Unit/Scorm/view_popup.php
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      handleUnload();
    };
  }, []);

  // close pop up when user not authenticated
  useEffect(() => {
    if (!isAuthenticated) {
      if (popupWindowRef.current && !popupWindowRef.current.closed) {
        popupWindowRef.current.close();
      }

      setIsPopupOpen(false);
    }
  }, [isAuthenticated]);

  return (
    <div css={scormPopUpContainer}>
      <div className="pop-up-content-wrapper">
        <ScormPopupSVG className="pop-up-content-image" />
        {unit?.progress?.status === "completed" ? (
          <>
            <Text as="div" fontSize="sm" weight="700" className="pop-up-content-text">
              {t("popup.completedAndPassed")}
            </Text>
            {!isPopupOpen && (
              <Button className="pop-up-content-button" onClick={handleShowPopup}>
                {t("popup.revisit")}
              </Button>
            )}
          </>
        ) : (
          <>
            {!isPopupOpen ? (
              <>
                <Text as="div" fontSize="sm" weight="700" className="pop-up-content-text">
                  {t("popup.willOpenInNewWindow")}
                </Text>
                <Text as="div" fontSize="sm" className="pop-up-content-text">
                  {t("popup.whenNewWindowOpen")}
                </Text>
                <Button className="pop-up-content-button" onClick={handleShowPopup}>
                  {unit?.progress?.status === "not_attempted"
                    ? t("popup.start")
                    : t("popup.resume")}
                </Button>
              </>
            ) : (
              <>
                <Text as="div" fontSize="sm" weight="700" className="pop-up-content-text">
                  {t("popup.openedInNewWindow")}
                </Text>
                <Text as="div" fontSize="sm" className="pop-up-content-text">
                  {t("popup.doNotClose")}
                </Text>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PopupScorm;

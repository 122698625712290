import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { badgeItemContainer } from "./styles";
import { Badge } from "types/entities";
import { BadgeFigure } from "@components/ReusableComponents";
import { t } from "i18next";

type BadgeItemProps = Pick<Badge, "image" | "name" | "criteria" | "awarded" | "type">;

const BadgeItem: FC<BadgeItemProps> = (props) => {
  const { image, name, criteria, awarded, type } = props;

  return (
    <div css={badgeItemContainer}>
      <BadgeFigure type={type} image={image} awarded={awarded} criteria={criteria} />

      <Text fontSize="sm" weight="700" className="name">
        {name}
      </Text>

      <Text fontSize="sm" as="div" className="criteria">
        {t(`badges.criteria.${[type]}`, { count: criteria.count })}
      </Text>
    </div>
  );
};

export default BadgeItem;

import { css } from "@emotion/react";

export const completedSplashScreenContainer = ({ units }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .completion-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;

    .image-banner {
      margin-bottom: 2rem;
    }

    .results-info {
      margin-bottom: 2rem;
      text-align: center;

      .results-info-item {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .score-button {
        cursor: default;
        font-size: 1.75rem;
        width: 120px;
        border-radius: 20px;
        height: 50px;
      }
    }

    .achievements-container {
      display: flex;
      justify-content: center;
      gap: 1rem;
      flex-wrap: wrap;

      svg {
        height: 1rem;
        fill: currentColor;
      }
    }

    .repetitions-container {
      text-align: center;

      .repetitions-left {
        margin-bottom: 0.5rem;
      }

      .try-again-button {
        height: auto;
      }
    }

    .message-container {
      padding: 2.5rem;
      border-radius: 5px;
      margin-top: 2rem;

      &.completed {
        border: 1px solid ${units.test.successColor};
      }

      &.failed {
        border: 1px solid ${units.test.errorColor};
      }
    }
  }
`;

export const snapshotSplashScreenContainer = css`
  &.show-canvas {
    .canvas-container {
      .canvas {
        visibility: visible;
      }
    }
  }

  .label {
    text-align: center;
  }

  .picture-capture {
    text-align: center;

    .canvas-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .media-player-wrapper {
      width: 300px;
    }

    .canvas {
      display: flex;
      flex-direction: column;
      visibility: hidden;

      #canvas {
        height: 240px;
        width: 300px;
        object-fit: fill;
      }
    }

    .capture-btn,
    .reset-btn {
      margin-top: 1rem;
      background: transparent;
      border: none;
      padding: 0;
      color: #1b68b3;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      &:hover {
        color: #509be5;
      }

      svg {
        margin-inline-end: 0.5rem;
      }
    }
  }
`;

export const passwordFormContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  .label {
    text-align: center;
  }

  .input-container {
    margin: 1rem auto;
    max-width: 400px;

    svg {
      width: 14px;
    }
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactChildren, ReactNode } from "react";
import { QueryStatus } from "react-query";
import { Loader } from "@epignosis_llc/gnosis";
import { ErrorResult } from "@components/ReusableComponents";

export type SkeletonLoaderProps = {
  status: QueryStatus;
  error: unknown | null;
  children: Exclude<null | undefined | any, ReactChildren>;
  loader?: ReactNode;
  fullScreen?: boolean;
};

const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  status,
  error,
  children,
  fullScreen = false,
  loader = <Loader fullScreen={fullScreen} />,
}) => {
  const isLoading = status === "loading";
  const shouldShowSkeletonError =
    error &&
    typeof error === "object" &&
    "shouldNotShowSkeletonError" in error &&
    !error.shouldNotShowSkeletonError;

  if (isLoading) return loader;

  if (error && shouldShowSkeletonError) return <ErrorResult />;
  return children;
};

export default SkeletonLoader;

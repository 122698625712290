import React, { FC } from "react";
import ContentLoader from "react-content-loader";
import i18n from "@utils/i18n";

const SkeletonWidget: FC = () => {
  const isRTL = i18n.dir() === "rtl";

  return (
    <ContentLoader
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ minHeight: 500, minWidth: "100%", width: "100%" }}
      rtl={isRTL}
    >
      <rect x="10" y="10" rx="5" ry="5" width="85" height="15" />
      <rect x="110" y="10" rx="5" ry="5" width="85" height="15" />
      <rect x="210" y="10" rx="5" ry="5" width="85" height="15" />
      <rect x="310" y="10" rx="5" ry="5" width="85" height="15" />

      <circle cx="42" cy="94" r="30" />
      <rect x="90" y="86" rx="5" ry="5" width="100%" height="15" />
      <circle cx="42" cy="163" r="30" />
      <rect x="90" y="155" rx="5" ry="5" width="100%" height="15" />
      <circle cx="42" cy="232" r="30" />
      <rect x="90" y="224" rx="5" ry="5" width="100%" height="15" />
      <circle cx="42" cy="301" r="30" />
      <rect x="90" y="293" rx="5" ry="5" width="100%" height="15" />
      <circle cx="42" cy="370" r="30" />
      <rect x="90" y="362" rx="5" ry="5" width="100%" height="15" />
    </ContentLoader>
  );
};

export default SkeletonWidget;

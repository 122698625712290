import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { typeScale } from "@styles/typography";

export const searchResultsContainer = css`
  padding: 1rem;

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;

export const helpContainer = css`
  nav {
    margin-bottom: 2rem;
  }

  article {
    padding: 0;

    section {
      padding: 0 1.5rem;

      ${mq["sm"]} {
        padding: 0 2.5rem;
      }
    }
  }

  .title {
    font-size: ${typeScale.xl};
    margin-bottom: 1rem;
  }

  .search {
    margin-bottom: 2.5rem;
  }

  .list {
    margin: 1rem 0 1.5rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;

    dl,
    dt,
    dd {
      margin: 0;
      padding: 0;
    }

    dt {
      margin-bottom: 1rem;
    }

    dd {
      margin-bottom: 2.5rem;
    }
  }

  .list-action {
    display: flex;
    justify-content: center;
  }
`;

export const mainMenuContainer = css`
  .inner-container {
    display: block;
  }

  .nav-handle {
    min-height: auto;

    .nav-item {
      border: 0;
    }
  }
`;

export const ActionDrawerContainer = ({ actionDrawer }) => css`
  header {
    display: flex;
    align-items: start;
    border-bottom: 1px solid ${actionDrawer.border};
    justify-content: space-between;

    .title-container {
      flex-direction: column;
    }
  }

  .action-drawer-body {
    padding: 1rem 2rem;
  }

  footer {
    display: flex;
    justify-content: flex-start;
    padding: 1rem 2rem;
    border-top: 1px solid ${actionDrawer.border};

    button {
      margin: 0 0.5rem;
    }
  }
`;

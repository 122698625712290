import { userHasRequiredPermissions } from "./index";
import useConfigurationStore from "@stores/configuration";
import { featureFlags } from "@config";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const accountPermissions = {
  read: "account.read",
  update: "account.update",
};

const { read, update } = accountPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => message.read
   * message.read is required in order to have all the other messages related permissions
   *
   *  Allowed actions:
   *  GET recipients, singleMessage, unread, inbox, sent
   *  POST attachment, reply, read
   *  PUT unread
   *  DELETE delete
   *
   * @returns boolean
   */
  canAccessAccount: (): boolean => {
    // Temporarily we give admin permission to learner when comes to account permissions
    const userRole = featureFlags.roleSwitching ? authService.getDefaultRole() : "administrator";
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => message.create
   *
   *  Allowed actions:
   *  POST messages, forward
   *
   * @returns boolean
   */
  canUpdateAccount: (): boolean => {
    const userRole = featureFlags.roleSwitching ? authService.getDefaultRole() : "administrator";
    return userHasRequiredPermissions(
      [read, update],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

import { Button, Tooltip } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import React, { FC } from "react";
import { Session } from "types/entities/Unit";

type RegisterButtonProps = {
  session: Session;
  disabled: boolean;
  isLoading: boolean;
  canUnregister: boolean;
  tooltipContent?: string;
  handleClick: () => void;
};

const getButtonText = (canUnregister: boolean, is_registered: boolean): string => {
  if (is_registered && !canUnregister) {
    return "ilt.registeredUpper";
  } else {
    return is_registered ? "ilt.unregister" : "ilt.register";
  }
};

const RegisterButton: FC<RegisterButtonProps> = (props) => {
  const { session, handleClick, disabled, isLoading, canUnregister, tooltipContent } = props;

  return (
    <div>
      <Tooltip content={tooltipContent} disabled={!tooltipContent}>
        <Button
          disabled={disabled}
          isLoading={isLoading}
          variant={session.is_registered ? "outline" : "solid"}
          onClick={handleClick}
        >
          {t(getButtonText(canUnregister, session.is_registered))}
        </Button>
      </Tooltip>
    </div>
  );
};

export default RegisterButton;

import { palletColorsGenerator, getPlusTheme, getGnosisTheme } from "../utils";
import { COLORS } from "../constants";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#F20000",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultRed = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultRed;

import React, { FC, useState, useEffect, useRef, useCallback, ReactNode } from "react";
import ReactPlayer from "react-player";
import { transformSrtTracks } from "srt-support-for-html5-videos";
import { SerializedStyles } from "@emotion/react";
import { videoContainer } from "./styles";
import { MyUnit, MediaOptions, PlayerState } from "types/entities";
import { disableRightClick } from "@utils/helpers";
import { FileFailed, FileProcessing } from "@components";

type VideoProps = MyUnit & {
  playerState: PlayerState;
  onPlay: () => void;
  onPause: () => void;
  onDurationChange: (duration: number) => void;
  children?: ReactNode;
};

const Video: FC<VideoProps> = ({
  url,
  content,
  secondary_content,
  media_options,
  poster_image,
  captions_url,
  status,
  playerState,
  onPlay,
  onPause,
  onDurationChange,
  children,
}) => {
  const video = document.getElementsByTagName("video")[0];
  const videoEl = useRef<ReactPlayer>(null);

  const { auto_pause, show_speed, sync_timer } = media_options as MediaOptions;
  const isSRT = captions_url && captions_url.search(".srt") > -1;
  const hasUrlAndContent = Boolean(url && content);
  const hasUrlAndSecondaryContent = Boolean(url && secondary_content);
  const { isPlaying } = playerState;
  const [initializeOnce, setInitializeOnce] = useState(true);

  const config = {
    file: {
      attributes: {
        controlsList: `nodownload ${show_speed ? "" : "noplaybackrate"}`,
        disablePictureInPicture: true,
        poster: poster_image?.url,
        crossOrigin: "true",
      },
      ...(captions_url && {
        tracks: [
          {
            kind: "subtitles",
            src: captions_url,
            default: true,
            srcLang: "",
            label: "On",
          },
        ],
      }),
    },
  };

  const previousTime = useRef(0);

  if (video && sync_timer) {
    video.ontimeupdate = (): void => {
      setTimeout(() => {
        previousTime.current = video.currentTime;
      }, 1000);
    };

    video.onseeking = (): void => {
      if (video.currentTime > previousTime.current) {
        video.currentTime = previousTime.current;
      }
    };
  }

  const handleOnPause = (): void => {
    const videoRef = videoEl?.current;
    const currentTime = videoRef?.getCurrentTime() ?? 0;
    const duration = videoRef?.getDuration() ?? 0;

    // disable playing only when the video has not ended
    if (currentTime < duration) onPause();
  };

  // Preventing users to right click a video.
  useEffect(() => {
    disableRightClick("video");
  }, [videoEl]);

  const onReady = useCallback((): void => {
    if (initializeOnce && isSRT) {
      transformSrtTracks(videoEl.current?.getInternalPlayer() as HTMLVideoElement);

      const videoElem = document.querySelector("video");

      if (videoElem && videoElem.children) {
        videoElem.removeChild(videoElem.children[0]);
      }

      setInitializeOnce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializeOnce]);

  useEffect(() => {
    if (auto_pause) {
      window.addEventListener("focus", onPlay);
      window.addEventListener("blur", onPause);
    }

    return () => {
      window.removeEventListener("focus", onPlay);
      window.removeEventListener("blur", onPause);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auto_pause]);

  const renderVideoContent = (): JSX.Element => {
    switch (status) {
      case "ready":
        return (
          <div className="video-player-container">
            <ReactPlayer
              ref={videoEl}
              id="video"
              className="react-player"
              height="100%"
              width="100%"
              url={url ?? undefined}
              config={config}
              controls
              playing={isPlaying}
              pip={false}
              onPlay={onPlay}
              onPause={handleOnPause}
              onDuration={onDurationChange}
              onReady={onReady}
            />
          </div>
        );
      case "failed":
        return <FileFailed />;
      case "processing":
        return <FileProcessing />;
      default:
        return <></>;
    }
  };

  return (
    <div
      css={(): SerializedStyles => videoContainer(hasUrlAndContent, hasUrlAndSecondaryContent)}
      className="unit-container"
    >
      {content && <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>}
      {renderVideoContent()}
      {secondary_content && (
        <div
          className="secondary-content"
          dangerouslySetInnerHTML={{ __html: secondary_content }}
        ></div>
      )}
      {children}
    </div>
  );
};

export default Video;

import {
  enUS,
  es,
  de,
  fr,
  el,
  it,
  ru,
  fi,
  sr,
  uk,
  ca,
  lv,
  cs,
  sl,
  sk,
  tr,
  pt,
  zhCN,
  zhTW,
  nl,
  ja,
  vi,
  hr,
  ka,
  bs,
  he,
  ar,
  lt,
  et,
  faIR,
  hu,
  sv,
  nb,
  hy,
  mn,
  ms,
  da,
  pl,
  ko,
  id,
  th,
  ptBR,
  ro,
  az,
  hi,
} from "date-fns/locale";

export default {
  "en-US": enUS,
  "es-ES": es,
  "de-DE": de,
  "fr-FR": fr,
  "el-GR": el,
  "it-IT": it,
  "ru-RU": ru,
  "fi-FI": fi,
  "sr-RS": sr,
  "uk-UA": uk,
  "ca-ES": ca,
  "lv-LV": lv,
  "cs-CZ": cs,
  "sl-SI": sl,
  "sk-SK": sk,
  "tr-TR": tr,
  "pt-PT": pt,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
  "nl-NL": nl,
  "ja-JP": ja,
  "vi-VN": vi,
  "hr-HR": hr,
  "ka-GE": ka,
  "bs-BA": bs,
  "he-IL": he,
  "ar-AE": ar,
  "lt-LT": lt,
  "et-EE": et,
  "fa-IR": faIR,
  "hu-HU": hu,
  "sv-SE": sv,
  "nb-NO": nb,
  "hy-AM": hy,
  "mn-MN": mn,
  "ms-MY": ms,
  "da-DK": da,
  "pl-PL": pl,
  "ko-KR": ko,
  "id-ID": id,
  "th-TH": th,
  "pt-BR": ptBR,
  "ro-RO": ro,
  "az-AZ": az,
  "hi-IN": hi,
};

import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import classNames from "classnames";
import { publicHeaderContainer } from "./styles";
import { BrandLogoSVG } from "@assets/images";
import { URLS } from "@constants/urls";
import useConfigurationStore from "@stores/configuration";

const PublicHeader: FC = () => {
  const { domainSettings } = useConfigurationStore();
  const logo = domainSettings?.logo?.default ?? null;
  const isExternalCatalogEnabled = Boolean(domainSettings?.external_catalog);
  const navigate = useNavigate();

  return (
    <header
      css={publicHeaderContainer}
      className={classNames({
        "show-catalog": isExternalCatalogEnabled,
      })}
    >
      <div className="logo" onClick={(): void => navigate(URLS.dashboard)}>
        {logo ? <img src={logo} /> : <BrandLogoSVG height={28} />}
      </div>

      {domainSettings?.external_catalog && (
        <Button variant="link" as={Link} to={URLS.externalCatalog.index}>
          {t("courseCatalog.browseCourseCatalog")}
        </Button>
      )}
    </header>
  );
};

export default PublicHeader;

import { t } from "i18next";
import StringSchema from "yup/lib/string";
import { AnyObject } from "yup/lib/types";
import { yup } from "./validation";

export const ProfileValidationSchema = yup.object().shape({
  name: yup.string().trim().required().max(50),
  surname: yup.string().trim().required().max(50),
  email: yup.string().trim().email().required().max(150),
  login: yup
    .string()
    .trim()
    .required()
    .matches(/^(?=.*[a-zA-Z])[^\s]*$/g)
    .max(150),
  description: yup.string().max(800),
  credits: yup.string().matches(/^[0-9]*(.[^,]\d{1,3})?$/, t("errors.groups.priceIsNotValid")),
});

export const MandatoryCustomFieldSchema = yup.string().trim().required();

export const MaxLengthCustomFieldSchema = (
  maxLength: number,
): StringSchema<string | undefined, AnyObject> => yup.string().trim().max(maxLength);

export const PasswordValidationSchema = yup.object().shape({
  current_password: yup.string().trim().required().max(30),
  password: yup.string().trim().required().max(30),
  confirm_password: yup
    .string()
    .trim()
    .required()
    .max(30)
    .oneOf([yup.ref("password"), null], t("validationMessages.passwordsShouldMatch")),
});

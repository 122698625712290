import React, { FC, MouseEvent } from "react";
import ActionButton from "./ActionButton";
import { IconType } from "types/common";
import { t } from "i18next";
import { CopyIconSVG } from "@assets/icons";

export type CloneActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  onClick: (e: MouseEvent) => void;
};

const CloneAction: FC<CloneActionProps> = ({
  tooltipContent,
  icon: Icon = CopyIconSVG,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.clone")} onClick={onClick}>
      <Icon height={30} />
    </ActionButton>
  );
};

export default CloneAction;

import React, { FC, useEffect, useMemo, useState } from "react";
import { t } from "i18next";
import { MyUnit } from "types/entities";
import { TimeLimitBar } from "@components";
import { getDelayInSeconds } from "@utils/helpers";

export type AvailabilityTimeLimitBarProps = {
  nextUnit: MyUnit | null;
  children?: never;
};

const AvailabilityTimeLimitBar: FC<AvailabilityTimeLimitBarProps> = ({ nextUnit }) => {
  const duration = useMemo(() => {
    return nextUnit?.availability?.delay ? getDelayInSeconds(nextUnit.availability) : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [counter, setCounter] = useState(duration);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    if (counter === 0 || counter < 0) clearInterval(intervalId);

    return (): void => {
      clearInterval(intervalId);
    };
  }, [counter]);

  if (!counter) {
    return <></>;
  }

  return (
    <TimeLimitBar
      initialTime={duration}
      currentTime={counter}
      text={t("units.nextAvailableInText")}
    />
  );
};

export default AvailabilityTimeLimitBar;

import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useResponsive } from "ahooks";
import { mainHeaderContainer } from "./styles";
import { BrandLogoSVG } from "@assets/images";
import { URLS } from "@constants/urls";
import { useConfigurationStore, useUIStore } from "@stores";
import { HamburgerButton } from "@layouts/components";

type MainHeaderProps = {
  hideContent?: boolean;
};

const MainHeader: FC<MainHeaderProps> = ({ hideContent = false }) => {
  const { mainDrawer, showMainDrawer, hideMainDrawer } = useUIStore((state) => state);
  const { sm } = useResponsive();
  const { domainSettings } = useConfigurationStore();
  const logo = domainSettings?.logo?.default;

  const handleDrawer = (toggle: boolean): void =>
    toggle ? showMainDrawer("mainMenu") : hideMainDrawer();

  return (
    <header
      css={mainHeaderContainer}
      onClick={(): void | null => (mainDrawer.show ? handleDrawer(false) : null)}
    >
      <div className="hamburger-menu">
        <HamburgerButton onClick={(): void => handleDrawer(true)} />
      </div>

      <Link to={URLS.dashboard} className="logo">
        {logo ? <img src={logo} /> : <BrandLogoSVG height={sm ? 26 : 20} />}
      </Link>

      {!hideContent && (
        <div className="main-header-content">
          {/* Toolbar content exists in page component */}
          <div id="toolbar" data-testid="toolbar" />
        </div>
      )}
    </header>
  );
};

export default MainHeader;

import React, { FC } from "react";
import { useQuery } from "react-query";
import { Loader } from "@epignosis_llc/gnosis";
import IltFooter from "../Footers/IltFooter";
import Ilt from "./Ilt";
import { Course, MyUnit, Section } from "types/entities";
import queryKeys from "@constants/queryKeys";
import { getIltSessions } from "@api/courses";

type ILtContainerProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isPublic?: boolean;
};

const IltContainer: FC<ILtContainerProps> = (props) => {
  const { unit, course, isPublic = false } = props;

  const { data: sessions, isLoading } = useQuery(
    [queryKeys.iltUnit.sessions, unit.id],
    () => getIltSessions(unit.id.toString()),
    {
      select: (sessions) => ({
        data: sessions._data,
      }),
    },
  );

  const isRegistered = sessions?.data.map((item) => item.is_registered).some((x) => x === true);
  const registeredSession = sessions?.data.filter((x) => x.is_registered)[0];

  if (isLoading) {
    return <Loader fullScreen />;
  }

  return (
    <>
      <article className="unit-wrapper center">
        <Ilt
          course={course}
          sessions={sessions?.data ?? []}
          unit={unit}
          isRegistered={Boolean(isRegistered)}
          isPublic={isPublic}
        />
      </article>

      <IltFooter
        {...props}
        hasSessions={Boolean(sessions?.data.length)}
        isRegistered={Boolean(isRegistered)}
        registeredSession={registeredSession}
        isPublic={isPublic}
      />
    </>
  );
};

export default IltContainer;

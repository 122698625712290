import React, { FC } from "react";
import ContentLoader from "react-content-loader";
import i18n from "@utils/i18n";

const SkeletonPieChart: FC = () => {
  const isRTL = i18n.dir() === "rtl";

  return (
    <ContentLoader
      speed={2}
      backgroundColor="#F3F3F3"
      foregroundColor="#ECEBEB"
      style={{ minHeight: 190, minWidth: "100%", width: "100%" }}
      viewBox="0 0 400 200"
      rtl={isRTL}
    >
      <rect x="0" y="5" rx="0" ry="0" width="100" height="12" />
      <circle cx="230" cy="110" r="70" />
      <circle cx="6" cy="38" r="4" />
      <rect x="15" y="34" rx="0" ry="0" width="40" height="8" />
      <circle cx="6" cy="52" r="4" />
      <rect x="15" y="48" rx="0" ry="0" width="40" height="8" />
      <circle cx="6" cy="66" r="4" />
      <rect x="15" y="62" rx="0" ry="0" width="40" height="8" />
      <circle cx="6" cy="80" r="4" />
      <rect x="15" y="76" rx="0" ry="0" width="40" height="8" />
    </ContentLoader>
  );
};

export default SkeletonPieChart;

import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "@epignosis_llc/gnosis";
import { ShowIconSVG, PlayUnitSVG } from "@epignosis_llc/gnosis/icons";
import { bodyWrapper } from "../styles";
import { CardHeader, CardBody, CardFooter } from "../components";
import CatalogCourseCardFooter from "./components/CatalogCourseCardFooter";
import { Course } from "types/entities";
import { URLS } from "@constants/urls";
import { useIsExternalView } from "@hooks";

export type CatalogCardProps = {
  course: Course;
};

const CatalogCard: FC<CatalogCardProps> = ({ course }) => {
  const isExternalView = useIsExternalView();
  const [isHovering, setIsHovering] = useState(false);
  const catalogCourseLink = isExternalView
    ? URLS.externalCatalog.createCourseLink({ courseId: course.id.toString() })
    : URLS.catalog.createCourseLink({ courseId: course.id.toString() });
  const { Header, Body } = Card;
  const isUserEnrolled = Boolean(course.availability); //When availability is null, means that the user is not enrolled to the course

  return (
    <Card
      onMouseEnter={(): void => setIsHovering(true)}
      onMouseLeave={(): void => setIsHovering(false)}
    >
      <Header ratio={[3, 2]}>
        <CardHeader
          course={course}
          actions={[]}
          courseLink={catalogCourseLink}
          icon={isUserEnrolled ? PlayUnitSVG : ShowIconSVG}
        />
      </Header>

      <Link to={catalogCourseLink} css={bodyWrapper} data-testid="card-body">
        <Body>
          <CardBody course={course} isCatalogView className="body-container" />
        </Body>

        <CardFooter>
          <CatalogCourseCardFooter course={course} isHovering={isHovering} />
        </CardFooter>
      </Link>
    </Card>
  );
};

export default CatalogCard;

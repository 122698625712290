import React, { FC } from "react";
import Iframe from "../IFrame/Iframe";
import PopupIframe from "../IFrame/PopupIframe";
import XAPIFooter from "../Footers/XAPIFooter";
import { Course, MyUnit, Section } from "types/entities";
import { FileFailed, FileProcessing } from "@components";

type XAPIContainerProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isPublic?: boolean;
};

const XAPIContainer: FC<XAPIContainerProps> = (props) => {
  const { unit, isPublic = false } = props;
  return (
    <>
      <div className="unit-wrapper">
        {unit.status === "ready" ? (
          unit.embed_type === "popup" ? (
            <PopupIframe {...props} />
          ) : (
            <Iframe {...unit} />
          )
        ) : unit.status === "processing" ? (
          <FileProcessing />
        ) : (
          <FileFailed />
        )}
      </div>
      <XAPIFooter {...props} isPublic={isPublic} />
    </>
  );
};

export default XAPIContainer;

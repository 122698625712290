import React, { FC } from "react";
import { t } from "i18next";
import { container } from "./styles";
import { Page } from "@components";
import { ErrorResult } from "@components/ReusableComponents";
import { InactiveBranchErrorSVG } from "@assets/images";

const InactiveBranchError: FC = () => {
  return (
    <Page pageTitle={t("general.inactiveSite")} siteNamePrefix={false}>
      <div css={container}>
        <ErrorResult errorImg={InactiveBranchErrorSVG} title={t("general.inactiveSite")} />
      </div>
    </Page>
  );
};

export default InactiveBranchError;

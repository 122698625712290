import React, { FC, memo } from "react";
import { Plugin, Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { pageThumbnailPlugin } from "./Plugins/pageThumbnailPlugin";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { renderNonPreviewable } from "@utils/helpers";

type PdfThumbnailProps = {
  fileUrl: string;
  pageIndex: number;
  thumbnailWidth?: number;
};

const PdfThumbnail: FC<PdfThumbnailProps> = ({ fileUrl, pageIndex, thumbnailWidth = 350 }) => {
  const thumbnailPluginInstance = thumbnailPlugin({ thumbnailWidth });
  const { Cover } = thumbnailPluginInstance;

  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={(): number => pageIndex} />,
  });

  return (
    <Viewer
      renderError={renderNonPreviewable}
      fileUrl={fileUrl}
      plugins={[pageThumbnailPluginInstance, thumbnailPluginInstance as Plugin]}
    />
  );
};

export default memo(PdfThumbnail);

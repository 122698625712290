import Color from "color";
import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  background: string;
  shadow: string;
  hover: {
    background: string;
  };
  overlay: {
    background: string;
  };
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  background: colors.white,
  shadow: colors.secondary.light,
  hover: {
    background: Color(colors.primary.darker).alpha(0.5).string(),
  },
  overlay: {
    background: Color(colors.primary.darker).alpha(0.5).string(),
  },
});

import React, { FC } from "react";
import { t } from "i18next";
import { Heading, Modal, Tooltip } from "@epignosis_llc/gnosis";
import { useConfigurationStore } from "@stores";
import { PointsSettings, LevelsSettings } from "types/entities/GamificationSettings";
import { InfoButtonSVG } from "@assets/icons";
import { pointsContainer } from "./styles";

type InfoModalProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

const translationsMapping = {
  points: {
    login_points: "loginPoints",
    unit_completion_points: "unitCompletionPoints",
    course_completion_points: "courseCompletionPoints",
    certification_points: "certificationPoints",
    test_completion_points: "testCompletionPoints",
    assignment_completion_points: "assignmentCompletionPoints",
    ilt_completion_points: "iltCompletionPoints",
    discussion_topic_comment_points: "discussionTopicCommentPoints",
    discussion_upvote_comment_points: "discussionUpvoteCommentPoints",
  },
  levels: {
    upgrade_level_points: "upgradeLevelPoints",
    upgrade_level_completed_courses: "upgradeLevelCompletedCourses",
    upgrade_level_badges: "upgradeLevelBadges",
  },
};

const InfoModal: FC<InfoModalProps> = ({ isOpen, title, onClose }) => {
  const { gamificationSettings } = useConfigurationStore();

  // Filter out null values from points object in order not to show the in the info modal
  const filteredPoints =
    gamificationSettings?.points &&
    (Object.fromEntries(
      Object.entries(gamificationSettings?.points).filter(([_key, value]) => value !== null),
    ) as PointsSettings);

  // Filter out null values from points object in order not to show the in the info modal
  const filteredLevels =
    gamificationSettings?.levels &&
    (Object.fromEntries(
      Object.entries(gamificationSettings?.levels).filter(([_key, value]) => value !== null),
    ) as LevelsSettings);

  // Filter out translations keys that don't match with the filteredPoints object
  const matchingPointsKeys =
    filteredPoints &&
    Object.keys(translationsMapping.points).filter((item) => filteredPoints[item] !== undefined);

  const pointsItemsHasExtraInfo = {
    ilt_completion_points: "iltCompletionPoints",
    test_completion_points: "testCompletionPoints",
    assignment_completion_points: "assignmentCompletionPoints",
  };

  // Filter out translations keys that don't match with the filteredPoints object
  const matchingLevelsKeys =
    filteredLevels &&
    Object.keys(translationsMapping.levels).filter((item) => filteredLevels[item] !== undefined);

  const isPointsModal = title === "points";

  const headingText =
    title === "points" ? t("gamification.collectMorePoints") : t("gamification.howToLevelUp");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Header>
        <Heading as="h3" size="sm" className="header-text">
          {headingText}
        </Heading>
      </Modal.Header>
      <Modal.Body css={{ padding: "1rem 1.5rem" }}>
        {isPointsModal ? (
          <div css={pointsContainer}>
            <ul style={{ paddingInlineStart: "1.5rem" }}>
              {matchingPointsKeys &&
                matchingPointsKeys.map((item) => {
                  const translationMappingPoints = translationsMapping.points[item];
                  const gamificationSettingsPoints = filteredPoints;
                  const showInfo = Boolean(pointsItemsHasExtraInfo[item]);
                  return (
                    <li key={item} className="points-item">
                      {t(`gamification.collectPoints.${translationMappingPoints}`, {
                        count: gamificationSettingsPoints && gamificationSettingsPoints[item],
                        number: gamificationSettingsPoints && gamificationSettingsPoints[item],
                      })}
                      {showInfo && (
                        <Tooltip
                          content={t(`gamification.tooltips.${pointsItemsHasExtraInfo[item]}`)}
                        >
                          <InfoButtonSVG height={16} />
                        </Tooltip>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          <div className="levels-container">
            <ul style={{ paddingInlineStart: "1.5rem" }}>
              {matchingLevelsKeys &&
                matchingLevelsKeys.map((item) => {
                  const translationMappingLevels = translationsMapping.levels[item];
                  const gamificationSettingsLevels = filteredLevels;

                  return (
                    <li key={item}>
                      {t(`gamification.levelUp.${translationMappingLevels}`, {
                        count: gamificationSettingsLevels && gamificationSettingsLevels[item],
                        number: gamificationSettingsLevels && gamificationSettingsLevels[item],
                      })}
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InfoModal;

import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  iconHover: string;
  color: string;
  border: string;
  content: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  iconHover: colors.secondary.base,
  color: colors.black,
  border: colors.secondary.lighter,
  content: colors.white,
});

import React, { FC, SyntheticEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";
import { t } from "i18next";
import { MainNavData } from "./MainNav";
import { mainMenuInner } from "./MainMenu.styles";
import MenuItem from "./components/MenuItem";
import ProfileMenuItem from "./components/ProfileMenuItem";
import RoleMenuItem from "./components/RoleMenuItem";
import { isMainMenuItemActiveForDrawer } from "@utils/helpers";
import { URLS } from "@constants/urls";
import useUIStore, { MainDrawerContent } from "@stores/ui";
import { useConfigurationStore, useUserPreferencesStore } from "@stores";
import queryKeys from "@constants/queryKeys";
import { getActiveDiscussions } from "@api/discussions";
import permissions from "@utils/permissions";
import AutoLoginButton from "@components/Autologin/AutologinButton";
import { getUnreadMessages } from "@api/messages";
import { getCalendatStatistics } from "@api/calendar";
import { featureFlags } from "@config";
import authService from "@utils/services/AuthService";
import { getMenuEntries } from "./helpers";
import { SerializedStyles } from "@emotion/react";
import userRoles from "@constants/userRoles";

type MainMenuProps = {
  isCollapsed?: boolean;
  onToggle?: () => void;
  topMenuOnClick?: (e: SyntheticEvent) => void;
  bottomMenuOnClick?: (e: SyntheticEvent, string: MainDrawerContent) => void;
  navState?: MainNavData;
};

const MainMenu: FC<MainMenuProps> = ({
  isCollapsed = false,
  onToggle = (): void => undefined,
  bottomMenuOnClick = (): void => undefined,
  navState = null,
}) => {
  const navigate = useNavigate();
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};
  const { setShowOnboardingTour, hideMainDrawer } = useUIStore((state) => state);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const { userProfileData } = useConfigurationStore();
  const menuEntries = getMenuEntries(userProfileData);
  const userRole = authService.getDefaultRole();
  const isLearner = userRole === "learner";
  const isImpersonationEnabled = userProfileData?.impersonated;

  // permissions related
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const { canAccessCourses, canAccessUnionCourses } = permissions.coursesPermissions;
  const { canAccessCatalog } = permissions.catalogPermissions;
  const { canAccessMessages } = permissions.messagesPermissions;
  const { canAccessCalendar } = permissions.calendarPermissions;
  const { canReadBranches } = permissions.branchPermissions;
  const { canAccessCategories } = permissions.categoriesPermissions;
  const { canExport, canImport } = permissions.importExportPermissions;
  const { canAccessUsers } = permissions.usersPermissions;
  const { canReadGroups } = permissions.groupPermissions;
  const { canAccessTimeline } = permissions.reportsPermissions;
  const { canAccessAccount } = permissions.accountPermissions;

  const allowCatalogView = canAccessCatalog();
  const allowDiscussionsView = canAccessDiscussions();
  const allowMyCoursesView = isLearner && canAccessCourses();
  const allowAccountAndSettingsView =
    userRole !== userRoles.LEARNER &&
    featureFlags.accountAndSettings &&
    (canAccessAccount() || canExport() || canImport());
  const allowCoursesView = !isLearner && (canAccessCourses() || canAccessUnionCourses());
  const allowMessagesView = canAccessMessages();
  const allowCalendarView = canAccessCalendar();
  const allowBranchesView = canReadBranches();
  const allowCategoriesView = canAccessCategories();
  const allowTourView = isLearner;
  const allowUsersView = canAccessUsers();
  const allowGroupsView = canReadGroups();
  const allowReportsView =
    canAccessTimeline() && featureFlags.reports && userRole !== userRoles.LEARNER;
  //TODO: uncomment this when we have more tabs on Reports page
  // const allowReportsView =
  //   (canAccessReports() || canAccessTimeline()) &&
  //   featureFlags.reports &&
  //   userRole === "administrator";

  useQuery(queryKeys.messages.unreadMessages, getUnreadMessages, {
    enabled: canAccessMessages(),
    onSuccess: (res) => setUnreadMessages(res._data.total),
  });

  const { data: activeDiscussions } = useQuery(queryKeys.discussions.active, getActiveDiscussions, {
    select: (activeDiscussionsRes) => ({
      data: activeDiscussionsRes._data,
    }),
    enabled: allowDiscussionsView,
  });

  const { data: calendarEvents } = useQuery(queryKeys.calendar.statistics, getCalendatStatistics, {
    select: (eventCalendarRes) => ({
      data: eventCalendarRes._data,
    }),
    enabled: allowCalendarView,
  });

  // filter primary menu items based on the permissions
  const primaryMenuEntries = useMemo(() => {
    return menuEntries[0].filter((menuEntry) => {
      if (menuEntry.drawerKey === "backToAdmin") return isImpersonationEnabled;
      if (menuEntry.drawerKey === "catalog") return allowCatalogView;
      if (menuEntry.drawerKey === "myCourses") return allowMyCoursesView;
      if (menuEntry.drawerKey === "accountAndSettings") return allowAccountAndSettingsView;
      if (menuEntry.drawerKey === "courses") return allowCoursesView;
      if (menuEntry.drawerKey === "calendar") return allowCalendarView;
      if (menuEntry.drawerKey === "branches") return allowBranchesView;
      if (menuEntry.drawerKey === "groups") return allowGroupsView;
      if (menuEntry.drawerKey === "role") return featureFlags.roleSwitching; //role switching feature
      if (menuEntry.drawerKey === "users") return allowUsersView;
      if (menuEntry.drawerKey === "categories")
        return allowCategoriesView && featureFlags.categories;
      if (menuEntry.drawerKey === "reports") return allowReportsView;
      return true;
    });
  }, [
    allowCatalogView,
    allowCoursesView,
    allowAccountAndSettingsView,
    menuEntries,
    allowCalendarView,
    allowCategoriesView,
    allowBranchesView,
    allowUsersView,
    allowGroupsView,
    allowMyCoursesView,
    allowReportsView,
    isImpersonationEnabled,
  ]);

  // filter secondary menu items based on the permissions
  const secondaryMenuEntries = useMemo(() => {
    return menuEntries[1].filter((menuEntry) => {
      if (menuEntry.drawerKey === "messages") return allowMessagesView;
      if (menuEntry.drawerKey === "leaveBeta") return !isImpersonationEnabled;
      if (menuEntry.drawerKey === "discussions") return allowDiscussionsView;
      if (menuEntry.drawerKey === "tour") return allowTourView;
      return true;
    });
  }, [allowDiscussionsView, allowMessagesView, menuEntries, isImpersonationEnabled, allowTourView]);

  const getBadgeNumber = (drawerKey: string): number => {
    if (drawerKey === "messages") return unreadMessages;
    if (drawerKey === "discussions") return activeDiscussions?.data.total ?? 0;
    if (drawerKey === "calendar") return calendarEvents?.data.upcoming ?? 0;
    return 0;
  };

  const handleBottomMenuClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    drawerKey: string,
  ): void => {
    // redirect in dashboard and open onboarding tour
    if (drawerKey === "tour") {
      e.preventDefault();
      setShowOnboardingTour(true);
      hideMainDrawer();
      navigate(URLS.dashboard);
    } else {
      const drawerKeysToNotReset = ["profile", "messages", "discussions"];

      if (!drawerKeysToNotReset.includes(drawerKey)) {
        // Reset userPreference for table states
        resetSettings();
      }

      bottomMenuOnClick(e, drawerKey as MainDrawerContent);
    }
  };

  const handleTopMenuClick = (): void => {
    resetSettings();
    hideMainDrawer();
  };

  return (
    <Sidebar
      isCollapsed={isCollapsed}
      onToggle={onToggle}
      navHandleLabel={t("mainMenu.menu")}
      className="sidebar-container"
    >
      <div
        className="inner-container"
        css={(theme): SerializedStyles => mainMenuInner(theme, { isImpersonationEnabled })}
      >
        <ul id="main-menu">
          {primaryMenuEntries.map(({ url, title, icon, drawerKey }) => {
            const withBadge = ["calendar"].some((item) => item === drawerKey);

            if (drawerKey === "backToAdmin") {
              return (
                <AutoLoginButton
                  key={title}
                  title={title}
                  icon={icon}
                  drawerKey={drawerKey}
                  navState={navState}
                  isCollapsed={isCollapsed}
                />
              );
            }

            if (drawerKey === "profile") {
              return (
                <ProfileMenuItem
                  key={title}
                  title={title}
                  drawerKey={drawerKey}
                  userProfileData={userProfileData}
                  isActive={isMainMenuItemActiveForDrawer(navState, drawerKey as MainDrawerContent)}
                  isCollapsed={isCollapsed}
                  handleClick={(e, drawerKey): void =>
                    handleBottomMenuClick(e, drawerKey as MainDrawerContent)
                  }
                />
              );
            }

            if (drawerKey === "role") {
              return (
                <RoleMenuItem
                  key={title}
                  title={title}
                  badgeNumber={getBadgeNumber(drawerKey ?? "")}
                  icon={icon}
                  drawerKey={drawerKey}
                  userProfileData={userProfileData}
                  isActive={isMainMenuItemActiveForDrawer(navState, drawerKey as MainDrawerContent)}
                  isCollapsed={isCollapsed}
                />
              );
            }

            return (
              <MenuItem
                key={title}
                title={t(title)}
                url={url as string}
                drawerKey={drawerKey ?? ""}
                icon={icon}
                navState={navState}
                isActive={isMainMenuItemActiveForDrawer(navState, drawerKey as MainDrawerContent)}
                isCollapsed={isCollapsed}
                withBadge={withBadge}
                badgeNumber={getBadgeNumber(drawerKey ?? "")}
                handleClick={handleTopMenuClick}
              />
            );
          })}
        </ul>

        <ul>
          {secondaryMenuEntries.map(({ title, icon, drawerKey }) => {
            const withBadge = ["messages", "discussions"].some((item) => item === drawerKey);

            if (drawerKey === "leaveBeta") {
              return (
                <AutoLoginButton
                  key={title}
                  title={title}
                  drawerKey={drawerKey}
                  navState={navState}
                  isCollapsed={isCollapsed}
                />
              );
            }

            return (
              <MenuItem
                key={title}
                title={t(title)}
                drawerKey={drawerKey ?? ""}
                icon={icon}
                isActive={isMainMenuItemActiveForDrawer(navState, drawerKey as MainDrawerContent)}
                isCollapsed={isCollapsed}
                withBadge={withBadge}
                badgeNumber={getBadgeNumber(drawerKey ?? "")}
                handleClick={(e, drawerKey): void =>
                  handleBottomMenuClick(e, drawerKey as MainDrawerContent)
                }
              />
            );
          })}
        </ul>
      </div>
    </Sidebar>
  );
};

export default MainMenu;

import { URLS } from "@constants/urls";
import create, { StoreApi, UseBoundStore } from "zustand";
import { persist } from "zustand/middleware";
import { useConfigurationStore } from "@stores";
import { Pagination as IPagination } from "types/responses/index";
import { QueryFilter } from "types/common";

type PageSettings = {
  sorting?: string[];
  filters?: object | QueryFilter[];
  pagination?: IPagination;
};

type Settings = Record<string, PageSettings>;

type UserPreferencesProps = {
  settings: Settings;
  setSettings: (settings: PageSettings, path: string) => void;
  resetSettings: () => void;
};

const initialSettings = {
  [`${URLS.user.courses}/inProgress`]: {
    sorting: ["-enroll_date"],
  },
  [`${URLS.user.courses}/completed`]: {
    sorting: ["-enroll_date"],
  },
  [URLS.catalog.index]: {
    sorting: ["-last_updated_on"],
  },
  [URLS.user.files]: {
    sorting: ["-uploaded_at"],
  },
  [URLS.user.calendar]: {
    sorting: [],
    filters: {
      eventType: "enrolled",
      view: "month",
    },
  },
  [URLS.user.groups]: {
    sorting: ["name"],
  },
};

const initialTableSettings = {
  // Users / User
  [URLS.users.users]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/groups`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/branches`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/payments`]: {
    sorting: ["-date"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/files`]: {
    sorting: ["-uploaded_at"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Groups / Group
  [URLS.groups.groups]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.groups.group}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.groups.group}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.groups.group}/files`]: {
    sorting: ["-uploaded_at"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Branches / Branch
  [URLS.branches.branches]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.branches.branch}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.branches.branch}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.branches.branch}/files`]: {
    sorting: ["-uploaded_at"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Courses / Course
  [URLS.courses.courses]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.courses.singleCourse}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.courses.singleCourse}/branches`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.courses.singleCourse}/groups`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Categories
  [URLS.categories.categories]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Messages
  [`${URLS.user.messages}/inbox`]: {
    sorting: ["-sent_at"],
    filters: {
      sender: null,
      dateFrom: null,
      dateUntil: null,
      attachment: "all",
    },
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.user.messages}/sent`]: {
    sorting: ["-sent_at"],
    filters: {
      sender: null,
      dateFrom: null,
      dateUntil: null,
      attachment: "all",
    },
    pagination: { number: 1, size: 10 },
  },
  // Discussions
  [URLS.user.discussions]: {
    sorting: ["-latest_reply"],
    filters: {
      title: null,
      description: null,
      created_at: null,
      latest_reply: "week",
    },
    pagination: { number: 1, size: 10 },
  },
  // Certificates
  [URLS.user.certificates]: {
    sorting: ["course_name"],
    filters: {
      dateFromIssueAt: null,
      dateUntilIssueAt: null,
      dateFromExpiresAt: null,
      dateUntilExpiresAt: null,
    },
    pagination: { number: 1, size: 10 },
  },
};

const useUserPreferencesStore = (): UseBoundStore<
  UserPreferencesProps,
  StoreApi<UserPreferencesProps>
> => {
  const { getState: getConfigurationState } = useConfigurationStore;
  const userId = getConfigurationState().userProfileData?.id;
  const storeKey = `user-store-${userId}`;

  const useUserStore = create<UserPreferencesProps>(
    persist(
      (set, get) => {
        return {
          settings: { ...initialSettings, ...initialTableSettings },
          setSettings: (newSettings: PageSettings, path: string): void => {
            const clonedSettings = { ...get().settings };
            clonedSettings[path] = newSettings;
            set((state) => ({ ...state, settings: clonedSettings }));
          },
          resetSettings: (): void => {
            // Reset settings to the initialTableSetting
            set((state) => ({
              settings: { ...state.settings, ...initialTableSettings },
            }));
          },
        };
      },
      {
        name: storeKey, // Unique store key based on user ID
        getStorage: () => localStorage,
      },
    ),
  );

  return useUserStore;
};

export default useUserPreferencesStore;

import React, { FC, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { textWithIconContainer } from "./styles";

type TextWithIconProps = {
  icon?: JSX.Element;
  label: ReactNode;
  labelTag?: HeadingTag;
  labelSize?: string;
  labelColor?: string;
  description?: ReactNode;
  hasDescription?: boolean;
  boldDescription?: boolean;
};

type HeadingTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

const TextWithIcon: FC<TextWithIconProps> = ({
  icon,
  label,
  labelTag = "h2",
  labelSize = "md",
  labelColor = "black",
  description,
  hasDescription = false,
  boldDescription = false,
}) => {
  return (
    <div css={(theme): SerializedStyles => textWithIconContainer(theme)}>
      {icon && <span className="prefix-icon">{icon}</span>}
      <div className="text-with-icon-content">
        <Heading as={labelTag} size={labelSize} className={`heading-${labelColor}`}>
          {label}
        </Heading>
        {hasDescription && (
          <Text as="div" fontSize="xs" weight={boldDescription ? "700" : "400"}>
            {description}
          </Text>
        )}
      </div>
    </div>
  );
};

export default TextWithIcon;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const drawerMessageContainer = ({ messages }) => css`
  .link-wrapper {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid ${messages.border};

    ${mq["md"]} {
      padding: 1.5rem 2rem;
    }

    .message-info {
      width: 100%;
      margin-inline-start: 0.5rem;

      .header {
        display: flex;
        align-items: center;
        margin-top: 0.25rem;

        ${mq["sm"]} {
          justify-content: space-between;
        }

        .date {
          display: flex;
          align-items: center;
          margin-inline-start: 1rem;
          color: ${messages.color};

          ${mq["sm"]} {
            margin-inline-start: 0;
          }

          svg {
            margin-inline-end: 0.5rem;
            color: ${messages.linkColor};
          }
        }

        .message-heading {
          color: ${messages.linkColor};
        }
      }
    }

    .message-content {
      display: flex;
      flex-wrap: wrap;

      .subject {
        width: 100%;
        color: ${messages.linkColor};
      }
    }
  }
`;

export const messagesActions = () => css`
  .active-filters {
    margin-top: 2rem;
    display: flex;
    align-items: center;

    .filter {
      margin-inline-end: 1rem;

      button {
        color: inherit;
      }
    }
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;

    .left-actions {
      display: flex;

      .left-actions-item {
        margin-inline-end: 0;

        ${mq["sm"]} {
          margin-inline-end: 1rem;
        }
      }
    }

    .right-actions {
      display: flex;

      .right-actions-item {
        margin-inline-start: 0;

        ${mq["sm"]} {
          margin-inline-start: 1rem;
        }
      }
    }
  }
`;

export const actionsFooter = () => css`
  .actions {
    display: flex;
    justify-content: center;

    button {
      margin: 0 0.5rem;
    }
  }
`;

export const messageThreadReplyInputContainer = css`
  display: flex;

  .grid-container {
    width: 100%;

    footer {
      .cancel-btn {
        margin-inline-start: 1rem;
      }
    }
  }
`;

export const reorderColumns = ({ messages }) => css`
  .draggable-item {
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0.75rem;
    background: ${messages.reorder.background};
    margin-bottom: 0.5rem;

    top: auto !important;
    left: auto !important;

    &:hover {
      border-radius: 5px;
      box-shadow: 0px 3px 6px ${messages.reorder.boxShadow};
    }

    &:active {
      border-radius: 5px;
      box-shadow: 0px 3px 6px ${messages.reorder.boxShadow};
      border: 1px solid ${messages.reorder.icon};

      svg {
        color: ${messages.reorder.icon};
      }
    }

    .grip-icon {
      margin-inline-end: 1.5rem;
    }
  }
`;

import { Input, InputError } from "@epignosis_llc/gnosis";
import React, { FC, useCallback, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { HexColorPicker } from "react-colorful";
import { SerializedStyles } from "@emotion/react";
import { colorPickerInput } from "../styles";
import { useClickOutside } from "@hooks";
import { isShadeOfWhite, whiteShadeThreshold } from "@styles/utils";

const BLACK_COLOR = "#232323";
const DEFAULT_WHITE_SHADE = "#EEEEEE";

type ColorPickerInputProps = React.HTMLAttributes<HTMLInputElement> & {
  id: string;
  label: string;
  color: string;
  error?: FieldError;
  onChange: (newColor: string) => void;
};

const ColorPickerInput: FC<ColorPickerInputProps> = ({
  id,
  label,
  color,
  error,
  onChange,
  ...rest
}) => {
  const popover = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useState(false);
  const [currentColor, setCurrentColor] = useState(color ? color : BLACK_COLOR);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const handleColorChange = (newColor: string): void => {
    setCurrentColor(newColor);
    onChange(newColor);
  };

  const limitWhiteShade = React.useMemo(() => {
    // This function limits the range of white shades that can be applied in order
    // to avoid the user selecting a white shade of color that is too light or totally white.
    const hasReachedWhiteShadeThreshold =
      parseInt(currentColor.substring(1, 3), 16) > whiteShadeThreshold;

    return (colorValue: string): string =>
      isShadeOfWhite(colorValue) && hasReachedWhiteShadeThreshold
        ? DEFAULT_WHITE_SHADE
        : colorValue;
  }, [currentColor]);

  return (
    <div css={(): SerializedStyles => colorPickerInput(currentColor)}>
      <Input
        id={id}
        label={label}
        status={error ? "error" : "valid"}
        value={limitWhiteShade(currentColor)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          handleColorChange(limitWhiteShade(e.target.value))
        }
        {...rest}
      />
      {error && <InputError>{error.message}</InputError>}

      <div className="swatch" onClick={(): void => toggle(true)}>
        {isOpen && (
          <div className="popover" ref={popover}>
            <HexColorPicker
              color={currentColor}
              onChange={(color: string): void => {
                handleColorChange(limitWhiteShade(color));
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorPickerInput;

import { DropdownItem } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { UserFilterSVG, BranchesFilterSVG, GroupsFilterSVG } from "@epignosis_llc/gnosis/icons";
import { Branch, Group } from "types/entities";
import permissions from "@utils/permissions";
import { QueryFilter } from "types/common";

export enum Filters {
  Status = "[status]",
  Branch = "[branch_id]",
  Group = "[group_id]",
}

type FilterOptions = {
  groups?: Group[];
  branches?: Branch[];
  showStatusOptions?: boolean;
};

// This helper function is used to get the list of filters for branches, groups and statuses for tables
export const getFilterDropdownOptions = ({
  groups = [],
  branches = [],
  showStatusOptions = false,
}: FilterOptions): DropdownItem[] => {
  const dropdownOptions: DropdownItem[] = [];
  const { canReadBranches } = permissions.branchPermissions;
  const { canReadGroups } = permissions.groupPermissions;
  const allowReadBranches = canReadBranches();
  const allowReadGroups = canReadGroups();

  const statusOptions: DropdownItem[] = [
    {
      label: t("myCourses.instructor.filters.active"),
      value: "active",
      category: Filters.Status,
      icon: UserFilterSVG,
    },
    {
      label: t("myCourses.instructor.filters.inactive"),
      value: "inactive",
      category: Filters.Status,
      icon: UserFilterSVG,
    },
  ];

  if (showStatusOptions) {
    dropdownOptions.push({
      label: t("myCourses.instructor.filters.status"),
      value: "status",
      category: Filters.Status,
      items: statusOptions,
    });
  }

  if (branches.length > 0 && allowReadBranches) {
    const branchOptions: DropdownItem[] = branches.map((branch) => {
      return {
        label: branch.name,
        value: branch.id.toString(),
        category: Filters.Branch,
        icon: BranchesFilterSVG,
      };
    });

    dropdownOptions.push({
      label: t("general.branches_one", { count: 2 }),
      value: "branches",
      category: Filters.Branch,
      items: branchOptions,
      icon: BranchesFilterSVG,
    });
  }

  if (groups.length > 0 && allowReadGroups) {
    const groupOptions: DropdownItem[] = groups.map((group) => {
      return {
        label: group.name,
        value: group.id.toString(),
        category: Filters.Group,
        icon: GroupsFilterSVG,
      };
    });

    dropdownOptions.push({
      label: t("general.groups_one", { count: 2 }),
      value: "groups",
      category: Filters.Group,
      items: groupOptions,
      icon: GroupsFilterSVG,
    });
  }

  return dropdownOptions;
};

export const hasPermissionsChanged = (
  filters: QueryFilter[],
  handleRestoreDefault: () => void,
  branchPermission?: boolean,
  groupPermission?: boolean,
): void => {
  if (!branchPermission) {
    const hasBranchFilter = filters.some((item) => item.key === "[branch_id]");
    hasBranchFilter && handleRestoreDefault();
  }

  if (!groupPermission) {
    const hasGroupFilter = filters.some((item) => item.key === "[group_id]");
    hasGroupFilter && handleRestoreDefault();
  }
};

import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import { CatalogCategoriesRes } from "types/responses";

export const getCategories = async (
  flat = false,
  searchQuery = "",
): Promise<CatalogCategoriesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.categories.categories}${searchQuery}`, {
    params: { flat },
  });
  return res.data;
};

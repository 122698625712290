import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  headerBackground: string;
  headerBorder: string;
  linkBorder: string;
  linkHover: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  headerBackground: colors.white,
  headerBorder: colors.secondary.base,
  linkBorder: colors.primary.base,
  linkHover: colors.primary.base,
});

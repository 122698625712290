import React, { FC, useEffect } from "react";
import { Course, MyUnit, Section } from "types/entities";
import { TalentCraftStyles } from "./styles";
import InteractiveContentFooter from "@components/Units/Footers/InteractiveContentFooter";
import { useMutation, useQueryClient } from "react-query";
import queryKeys from "@constants/queryKeys";
import { postCraftCommit } from "@api/courses";
import { CraftCommitRes } from "types/responses";
import { CraftData } from "types/entities/Unit";

type TalentCraftProps = {
  unit: MyUnit;
  course: Course;
  sections: Section[];
  isPublic?: boolean;
};

const CRAFT_URL = "https://cdn.talentcraft.ai";

const TalentCraftContainer: FC<TalentCraftProps> = ({ unit, course, sections, isPublic }) => {
  const { url } = unit;
  const queryClient = useQueryClient();

  const { mutate: commitMutation } = useMutation(
    queryKeys.contentTokens,
    (data: CraftData) => postCraftCommit(unit.id.toString(), data),
    {
      retry: false,
      onSuccess: (res: CraftCommitRes) => {
        // we only want to run the invalidation if the unit changes from incomplete to complete in order for the UI to update, any further invalidation is redundant
        if (
          res._data.unit_progress.status === "completed" &&
          unit.progress?.status !== "completed"
        ) {
          // invalidate queries to update UI after CRAFT commit
          queryClient.invalidateQueries([queryKeys.myCourse, course.id.toString()]);
          queryClient.invalidateQueries([queryKeys.units, course.id.toString()]);
        }
      },
      onError: (error) => {
        // eslint-disable-next-line no-console
        console.error("Commit-Fetch error", error);
      },
    },
  );

  const handleMessage = (event: MessageEvent): void => {
    if (!event.origin.startsWith(CRAFT_URL)) return;

    const data: CraftData = JSON.parse(event.data);
    commitMutation(data);
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <article className="unit-wrapper craft" css={TalentCraftStyles}>
        {url && (
          <iframe src={url} data-testid="talent-craft-content" className="talent-craft-content" />
        )}
      </article>
      <InteractiveContentFooter
        unit={unit}
        course={course}
        sections={sections}
        isPublic={isPublic}
      />
    </>
  );
};

export default React.memo(TalentCraftContainer);

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { UpdateExpirationDateSVG } from "@assets/icons";

export type UpdateExpirationDateProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const UpdateExpirationDateAction: FC<UpdateExpirationDateProps> = ({
  tooltipContent,
  icon: Icon = UpdateExpirationDateSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.updateExpirationDate")}
      onClick={onClick}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default UpdateExpirationDateAction;

import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";
import { t } from "i18next";
import { YOUTUBE_VIMEO_URL_REGEX } from "@constants/validation";

// https://github.com/jquense/yup/issues/312
// add custom methods
yup.addMethod<yup.NumberSchema>(
  yup.number,
  "maxDigitsAfterDecimal",
  function (maxDigitsAfterDecimal: number) {
    return this.test({
      name: "maxDigitsAfterDecimal",
      message: t("validationMessages.maxDecimalPlaces", { number: maxDigitsAfterDecimal }),
      test: (value) => {
        if (value === null || value === undefined) return true;
        return /^\d+(\.\d{1,2})?$/.test(value.toString());
      },
    });
  },
);

yup.addMethod<yup.StringSchema>(yup.string, "isValidYoutubeOrVimeoUrl", function () {
  return this.test({
    name: "isValidYoutubeOrVimeoUrl",
    message: t("validationMessages.invalidUrl"),
    test: (value) => {
      if (!value) return true;
      return !!YOUTUBE_VIMEO_URL_REGEX.test(value);
    },
  });
});

// Augment the yup module
declare module "yup" {
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    maxDigitsAfterDecimal(maxDigitsAfterDecimal: number): NumberSchema<TType, TContext>;
  }

  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType,
  > extends yup.BaseSchema<TType, TContext, TOut> {
    isValidYoutubeOrVimeoUrl(): StringSchema<TType, TContext>;
  }
}

export default yup;

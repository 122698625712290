import { useQuery, UseQueryResult, UseQueryOptions } from "react-query";
import { getUnit, getMyCourseUnits, getQuestionById } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { MyUnit } from "types/entities";
import { MyUnitRes, QuestionRes, UnitsRes } from "types/responses";

export const useGetUnit = <T = MyUnit>(
  unitId: string,
  options?: UseQueryOptions<MyUnitRes, unknown, T>,
): UseQueryResult<T, unknown> =>
  useQuery<MyUnitRes, unknown, T>([queryKeys.unit, unitId], () => getUnit(unitId), options);

export const useGetUnits = <T = UnitsRes>(
  courseId: string,
  options?: UseQueryOptions<UnitsRes, unknown, T>,
): UseQueryResult<T, unknown> =>
  useQuery<UnitsRes, unknown, T>(
    [queryKeys.units, courseId],
    () => getMyCourseUnits(courseId),
    options,
  );

export const useGetTestQuestionById = <T = QuestionRes>(
  unitId: string,
  index: string,
  options?: UseQueryOptions<QuestionRes, unknown, T>,
): UseQueryResult<T, unknown> =>
  useQuery<QuestionRes, unknown, T>(
    [queryKeys.units, unitId, index],
    () => getQuestionById(unitId, index),
    options,
  );

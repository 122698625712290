import React, { FC } from "react";
import { Text, Heading } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import { instructorReplyContainer } from "./styles";
import { MyUnit } from "types/entities";

type InstructorReplyProps = {
  unit: MyUnit;
};

const InstructorReply: FC<InstructorReplyProps> = ({ unit }) => {
  const { sm } = useResponsive();
  const textSize = sm ? "sm" : "xs";
  const instructorComment = unit.progress?.submission?.instructor_comment?.html;

  return (
    <div css={instructorReplyContainer}>
      {unit.progress?.score && (
        <div className="grade-wrapper">
          <Heading as="h5"> {t("assignment.grade")}: </Heading>
          <Text fontSize={textSize}>{unit.progress.score}</Text>
        </div>
      )}

      {instructorComment && (
        <div className="reply-wrapper">
          <Heading as="h5" className="header">
            {t("assignment.replyFromInstructor")}
          </Heading>
          <Text fontSize={textSize} dangerouslySetInnerHTML={{ __html: instructorComment }} />
        </div>
      )}
    </div>
  );
};

export default InstructorReply;

import { userHasRequiredPermissions } from "./index";
import { useConfigurationStore } from "@stores";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const reportsPermissions = {
  read: "report.read",
  courseReports: "reports.course",
  userReports: "reports.user",
  testReports: "reports.test",
  surveyReports: "reports.survey",
  assignmentReports: "reports.assignment",
  iltReports: "reports.ilt",
  groupReports: "reports.group",
  branchReports: "reports.branch",
  customReports: "reports.custom",
  timeline: "timeline.read",
};

const {
  read,
  courseReports,
  userReports,
  testReports,
  surveyReports,
  assignmentReports,
  iltReports,
  groupReports,
  branchReports,
  customReports,
  timeline,
} = reportsPermissions;
const { getState } = useConfigurationStore;

export default {
  // Can access the ilt reports or assignment reports or test reports or survey reports
  canAccessUnitReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [testReports, surveyReports, assignmentReports, iltReports],
      getState().userProfileData?.permissions[userRole],
      false,
    );
  },

  // User has ANY report permission
  // Can access courseReports or surveyReports or assignmentReports or assignmentReports or branchReports or groupsReports or userReports or testReports or iltReports or customReports
  canAccessReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [
        courseReports,
        userReports,
        testReports,
        surveyReports,
        assignmentReports,
        iltReports,
        groupReports,
        branchReports,
        customReports,
      ],
      getState().userProfileData?.permissions[userRole],
      false,
    );
  },

  /**
   * Permission => report.read
   *
   *  Allowed actions:
   *  GET user.certificates, user.statistics (courses, units), dashboard.today (certificates_expiring)
   *
   * @returns boolean
   */
  canAccessLearnerReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },

  /**
   * Permission => reports.courses
   *
   *  Allowed actions:
   *  GET course users, GET user units, GET unit users, GET timeline
   *
   * @returns boolean
   */
  // TODO: remove not used
  canAccessCourseReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [courseReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.user
   *
   *  Allowed actions:
   *  GET course users
   *
   * @returns boolean
   */
  canAccessUserReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [userReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.test
   *
   *  Allowed actions:
   *  GET course units, GET user units, GET unit users
   *
   * @returns boolean
   */
  canAccessTestReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [testReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.survey
   *
   *  Allowed actions:
   *  GET course units, GET user units, GET unit users
   *
   * @returns boolean
   */
  canAccessSurveyReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [surveyReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.assignment
   *
   *  Allowed actions:
   *  GET course units, GET user units, GET unit users
   *
   * @returns boolean
   */
  canAccessAssignmentReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [assignmentReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.ilt
   *
   *  Allowed actions:
   *  GET course units, GET user units, GET unit users
   *
   * @returns boolean
   */
  canAccessIltReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [iltReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.group
   *
   *
   * @returns boolean
   */
  canAccessGroupReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [groupReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => reports.branch
   *
   *
   * @returns boolean
   */
  canAccessBranchReports: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [branchReports],
      getState().userProfileData?.permissions[userRole],
    );
  },

  /**
   * Permission => timeline.read
   * GET timeline reports, Get timeline widget
   *
   * @returns boolean
   */
  canAccessTimeline: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [timeline],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

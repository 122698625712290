import { css } from "@emotion/react";

export const loginForm = ({ signInPage }, { showOnModal }, { isRestrictedBranchError }) => css`
  padding: ${!showOnModal && "2.25rem 2rem"};

  h2 {
    text-align: ${showOnModal ? "left" : "center"};
    padding: ${showOnModal && "1.25rem"};
  }

  .form-content {
    display: flex;
    flex-direction: column;
    padding: ${showOnModal && "2.5rem 2.75rem"};

    .svg-text-wrapper {
      display: flex;
      flex-direction: column;

      .content-svg {
        margin: 1rem auto 1rem;
        width: 60%;
      }
    }

    .form-item {
      margin-bottom: ${!showOnModal && "1.25rem"};
    }
  }

  .login-error-wrapper {
    margin-top: ${showOnModal && "1rem"};

    .branches-error-container {
      ${isRestrictedBranchError && "padding: 0;"}
    }

    .login-error-container {
      margin: 0;
      text-align: center;
    }
  }

  .password-text {
    text-align: center;
    margin: 1rem 0;
  }

  .modal-title-container {
    border-bottom: 1px solid ${signInPage.borderColor};
  }

  .footer-container {
    padding: 1.25rem;
    border-top: 1px solid ${signInPage.borderColor};

    .btns-wrapper {
      text-align: start;

      .login-btn {
        margin-inline-start: 1rem;
      }
    }
  }

  .sign-in-btn {
    margin-top: 1rem;
  }

  .reset-text-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .reset-svg-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .reset-heading {
    margin-bottom: 1.5rem;
  }

  .change-pass-subtext {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .reset-enforce-heading {
    margin-bottom: 0.5rem;
  }

  .psw-bar {
    margin-top: 0.5rem;
  }

  .psw-note {
    font-style: italic;
    margin-top: 1rem;
  }

  .sign-in-or-up-link {
    text-align: center;
    margin-bottom: ${!showOnModal && "1.25rem"};
  }
`;

export const signInModalBodyContainer = () => css`
  form {
    margin: 0;
    max-width: 100%;
    border-radius: 5px;
  }
`;

export const branchesErrorContainer = ({ isImpersonation }) => css`
  .title {
    text-align: start;
    margin-bottom: ${!isImpersonation ? "1rem" : "0.75rem"};
    padding: ${!isImpersonation ? "1.5rem 2rem 0 2rem" : 0};
    max-width: ${isImpersonation ? "320px" : "auto"};
  }

  .overflow-text {
    word-break: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 320px;
  }

  .branches-container {
    padding-left: ${!isImpersonation ? "2rem " : 0};
    padding-bottom: ${!isImpersonation ? "1.5rem " : 0};
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    max-height: 200px;
    overflow-y: auto;
  }
`;

import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Button, Modal, Text } from "@epignosis_llc/gnosis";
import { PrivateSVG } from "@epignosis_llc/gnosis/icons";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { getSubsriptionInterval } from "../helpers";
import { modalFooter, subscriptionModalBody, subscriptionModalHeader } from "./styles";
import CouponForm from "./CouponForm";
import { useConfigurationStore } from "@stores";
import { CatalogSubscription } from "types/entities/Catalog";
import { postCatalogSubscribe } from "@api/catalog";
import queryKeys from "@constants/queryKeys";
import { Price } from "types/entities/Common";
import { getCurrencyPrice } from "@utils/helpers";
import localStorageKeys from "@constants/localStorageKeys";
import { AxiosError } from "axios";
import { handleSubscriptionErrors } from "@errors";

export type PaymentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type NewPriceData = {
  newPrice: Price;
  coupon: string;
};

const SubscriptionModal: FC<PaymentModalProps> = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const { courseId } = useParams() as { courseId: string };
  const { catalogSettings, domainSettings } = useConfigurationStore();
  const { coupons_enabled, subscription } = catalogSettings ?? {};
  const { trial_enabled, interval = 1, price = null } = subscription ?? {};
  const [subscriptionPrice, setSubscriptionPrice] = useState<Price | null>(price);
  const [discountCoupon, setDiscountCoupon] = useState("");
  const { locale } = domainSettings ?? {};
  const priceText = subscriptionPrice
    ? getCurrencyPrice(
        subscriptionPrice?.amount as number,
        subscriptionPrice?.currency as string,
        locale ?? "",
      )
    : "";
  const translationText = trial_enabled
    ? t("payments.subscriptions.subscriptionInfoFirstMonthFree", {
        amount: priceText,
        interval: getSubsriptionInterval(interval),
      })
    : `${priceText} / ${getSubsriptionInterval(interval)}*`;

  const { mutate: subscriptionMutation, isLoading: subscriptionMutationLoading } = useMutation(
    [queryKeys.courseSubscription],
    (dataToShow: CatalogSubscription) => postCatalogSubscribe(dataToShow),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries([queryKeys.course, courseId]);
        // Create a payment status local storage key in order to capture correctly the payment event from the return_url.
        localStorage.setItem(localStorageKeys.PAYMENT_STATUS, "pending");
        window.location.replace(res._data.redirect_url ?? "");
        onClose();
      },
      onError: (error: AxiosError) => {
        handleSubscriptionErrors(error);
      },
    },
  );

  const handleSubscription = (): void => {
    const pathURL = window.location.pathname;

    const newData = {
      processor: "stripe",
      // Add status and the current processor on the url to capture the payment event
      return_path: `${pathURL}?status=success&processor=stripe&type=subscription`,
      cancel_path: pathURL,
      ...(discountCoupon.length > 0 && { coupon: discountCoupon }),
    } as CatalogSubscription;

    subscriptionMutation(newData);
  };

  const handleValidCoupon = (newData: NewPriceData): void => {
    const { newPrice, coupon } = newData;
    setSubscriptionPrice(newPrice);

    coupon.length > 0 && setDiscountCoupon(coupon);
  };

  const handleModalClose = (): void => {
    setSubscriptionPrice(price); //reset the data
    setDiscountCoupon("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Modal.Header css={subscriptionModalHeader}>
        <Text fontSize="xl" weight="700">
          {t("general.subscription")}
        </Text>
      </Modal.Header>
      <Modal.Body css={subscriptionModalBody}>
        <div className="body-wrapper">
          <div className="subscription-info">
            <Text fontSize="md" as="div" weight="700">
              {t("payments.subscriptions.unlimitedAccess")}
            </Text>
            <Text fontSize="md" as="div">
              {translationText}
            </Text>
          </div>

          {coupons_enabled && (
            <CouponForm
              dataType="subscription"
              isSubsription
              handleValidCoupon={handleValidCoupon}
            />
          )}

          <div className="redirection-info">
            <PrivateSVG height={24} />
            <Text fontSize="sm">
              {t("payments.redirectSafely", {
                name: "Stripe",
              })}
            </Text>
          </div>

          <Text fontSize="sm" as="div" className="cancel-info">
            {t("payments.subscriptions.cancelSubscription")}
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button onClick={handleSubscription} isLoading={subscriptionMutationLoading}>
            {t("general.subscribe")}
          </Button>
          <Button color="secondary" onClick={handleModalClose} className="cancel-btn">
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SubscriptionModal;

import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { Link } from "react-router-dom";
import FooterWrapper from "./components/FooterWrapper";
import UnitFooterNavigationButtons from "./components/UnitFooterNavigationButtons";
import AvailabilityTimeLimitBar from "./components/AvailabilityTimeLimitBar";
import { footerInfoTextContainer } from "./styles";
import { Course, MyUnit, Section } from "types/entities";
import permissions from "@utils/permissions";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import i18n from "@utils/i18n";

type AssignmentFooterProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isPublic?: boolean;
};

const BTN_TEXT = {
  pending: "assignment.completionStatus.pending",
  failed: "assignment.completionStatus.failed",
};

const AssignmentFooter: FC<AssignmentFooterProps> = ({
  course,
  sections,
  unit,
  isPublic = false,
}) => {
  const flatUnits = sections ? getFlatUnits(sections) : [];
  const { nextUnit } = getPrevAndNextUnits(flatUnits, unit?.id) || {};
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);
  const isCompleted = unit?.progress?.status === "completed";

  // permissions related
  const { canCompleteCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsComplete = isPublic ? true : canCompleteCourseUnits();

  const notCompletedOrFailed =
    unit?.progress?.status === "pending" ||
    unit?.progress?.status === "incomplete" ||
    unit?.progress?.status === "not_attempted";

  // hide button if you don't have permission and the assignment unit is not completed or failed
  const hideButton = !allowCourseUnitsComplete && notCompletedOrFailed;
  const showText = unit.progress?.status === "pending" || unit.progress?.status === "failed";
  const isContinueButtonTraversalDisabled =
    course?.rules.traversal === "sequential"
      ? unit?.progress?.status !== "completed"
      : !nextUnit && course.progress?.completion_status !== "completed";

  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    isContinueButtonTraversalDisabled,
    course.progress?.completion_status,
    unit.progress?.status,
  );

  const nextUnitLink = goToNextUnitLink(
    isContinueButtonTraversalDisabled,
    nextUnit,
    course,
    isPublic,
  );
  const isRtl = i18n.dir() === "rtl";

  return (
    <>
      {/* if the next unit has delayed availability */}
      {!isNextUnitAvailable && isCompleted && <AvailabilityTimeLimitBar nextUnit={nextUnit} />}

      {showText && (
        <div css={footerInfoTextContainer}>
          <Text fontSize="sm">{t(BTN_TEXT[unit?.progress?.status ?? ""])}</Text>
        </div>
      )}

      <FooterWrapper>
        <UnitFooterNavigationButtons
          course={course}
          sections={sections}
          unit={unit}
          isPublic={isPublic}
        >
          {!hideButton && (
            <Button
              type="button"
              as={Link}
              disabled={continueButtonDisabled}
              to={nextUnitLink}
              iconAfter={isRtl ? ArrowLeftSVG : ArrowRightSVG}
              className="btn-complete-wrapper"
            >
              {t("general.continue")}
            </Button>
          )}
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default AssignmentFooter;

import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  color: string;
  background: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  color: colors.secondary.dark,
  background: colors.secondary.lighter,
});

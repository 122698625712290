export const COLORS = {
  primary: "#1B68B3",
  secondary: "#9EA5A9",
  green: "#3CB744",
  orange: "#FF9C28",
  red: "#D12525",
  blue: "#1B68B3",
  black: "#232323",
  white: "#FFFFFF",
};

import React from "react";
import { t } from "i18next";
import { Text, Button, TableProps } from "@epignosis_llc/gnosis";
import { IconEmptyStateSVG } from "@epignosis_llc/gnosis/icons";
import {
  NoUsers,
  NoResults,
  EmptyMatrix,
  NoAutomations,
  NoCategories,
  NoBranches,
  NoCourses,
  NoFiles,
  NoGroups,
  NoNotifications,
  NoPayments,
} from "@assets/images";
import classNames from "classnames";
import { EmptyStateProps, EmptyStateType, EmptyStatePropType } from "./types";
import { Link } from "react-router-dom";
import { URLS } from "@constants/urls";
import { PlusIconSVG } from "@assets/icons";
import { emptyStateContainer } from "./styles";

export const buildEmptyStateProps = ({
  filters,
  tableType,
  isDrawer,
  opensDrawer,
  permission,
  onClick,
  handleRestoreDefault,
}: EmptyStatePropType): EmptyStateType => {
  const emptyStateProps = {
    title: t("search.noResults"),
    info: t("search.differentKeywordOrFilter"),
    permission: true,
    icon: NoResults,
  };

  if (filters.length > 0) {
    return isDrawer ? emptyStateProps : { ...emptyStateProps, handleRestoreDefault };
  }

  switch (tableType) {
    case "users":
      return isDrawer
        ? {
            title: t("groups.users.noUsersToAdd"),
            info: "",
            icon: NoUsers,
          }
        : opensDrawer
        ? {
            title: t("groups.users.noUsersYet"),
            info: t("groups.users.addSomeUsers"),
            addItemText: t("users.addNew"),
            onClick: onClick,
            opensDrawer: opensDrawer,
            icon: NoUsers,
            permission,
          }
        : {
            title: t("groups.users.noUsersYet"),
            info: t("groups.users.addSomeUsers"),
            addItemText: t("users.addNew"),
            opensDrawer: opensDrawer,
            redirectionUrl: URLS.users.create,
            icon: NoUsers,
            permission,
          };
    case "courses":
      return isDrawer
        ? {
            title: t("groups.courses.noCoursesToAdd"),
            info: "",
            icon: NoCourses,
          }
        : opensDrawer
        ? {
            title: t("groups.courses.noCoursesYet"),
            info: t("groups.courses.addNewCourse"),
            addItemText: t("myCourses.instructor.addNew"),
            opensDrawer: opensDrawer,
            onClick: onClick,
            icon: NoCourses,
            permission,
          }
        : {
            title: t("groups.courses.noCoursesYet"),
            info: t("groups.courses.createNewCourse"),
            addItemText: t("myCourses.instructor.addNew"),
            opensDrawer: true, // This one has to return true, because it's a special page which doesn't open a drawer but does not redirect the user either
            onClick: onClick,
            icon: NoCourses,
            permission,
          };
    case "branches":
      return isDrawer
        ? {
            title: t("branches.noBranchesToAdd"),
            info: "",
            icon: NoBranches,
          }
        : opensDrawer
        ? {
            title: t("branches.noBranchesYet"),
            info: t("branches.addNewBranch"),
            addItemText: t("branches.courses.addToBranch"),
            opensDrawer: opensDrawer,
            onClick: onClick,
            icon: NoBranches,
            permission,
          }
        : {
            title: t("branches.noBranchesYet"),
            info: t("branches.addNewBranch"),
            opensDrawer: opensDrawer,
            icon: NoBranches,
            permission,
          };
    case "groups":
      return isDrawer
        ? {
            title: t("groups.noGroupsToAdd"),
            info: "",
            icon: NoGroups,
          }
        : opensDrawer
        ? {
            title: t("groups.noGroupsYet"),
            info: t("groups.addNewGroup"),
            addItemText: t("groups.courses.addToGroup"),
            opensDrawer: opensDrawer,
            onClick: onClick,
            icon: NoGroups,
            permission,
          }
        : {
            title: t("groups.noGroupsYet"),
            info: t("groups.addNewGroup"),
            addItemText: t("groups.addGroup"),
            opensDrawer: opensDrawer,
            redirectionUrl: URLS.groups.create,
            icon: NoGroups,
            permission,
          };
    case "payments":
      return {
        title: t("payments.noPaymentsYet"),
        info: "",
        icon: NoPayments,
      };
    case "categories":
      return {
        title: t("categories.emptyStateTitle"),
        info: t("categories.addNewCategory"),
        addItemText: t("categories.addCategory"),
        opensDrawer: opensDrawer,
        onClick: onClick,
        icon: NoCategories,
        permission,
      };
    case "automations":
      return {
        title: t("automations.noAutomationsYet"),
        info: t("automations.addNewAutomation"),
        icon: NoAutomations,
      };
    case "notifications":
      return {
        title: t("notifications.noNotificationsYet"),
        info: t("notifications.addNewNotification"),
        icon: NoNotifications,
      };
    case "files":
      return isDrawer
        ? {
            title: t("files.noFilesYet"),
            info: t("files.addNewFile"),
            icon: NoFiles,
          }
        : {
            title: t("files.noFilesYet"),
            info: t("files.addNewFile"),
            addItemText: t("files.uploadFiles"),
            opensDrawer: opensDrawer,
            onClick: onClick,
            icon: NoFiles,
            permission,
          };
    case "matrix":
      return {
        title: t("trainingMatrix.noDataYet"),
        info: t("trainingMatrix.addData"),
        icon: EmptyMatrix,
      };
    default:
      return emptyStateProps;
  }
};

export default buildEmptyStateProps;

export const emptyState = ({
  title,
  info = "",
  icon = IconEmptyStateSVG,
  // handleRestoreDefault,
  addItemText,
  opensDrawer,
  permission,
  redirectionUrl,
  onClick,
  hideInfo = false,
}: EmptyStateProps): TableProps["emptyState"] => {
  return {
    title,
    info: !permission ? "" : info,
    icon,
    footer: (
      <div css={emptyStateContainer}>
        <div className="body">
          <br />
          {/* {handleRestoreDefault && (
            <Button
              variant="link"
              className="add-item link-text"
              onClick={(): void => handleRestoreDefault()}
            >
              <Text fontSize="md">{t("general.restoreDefaultStateAction")}</Text>
            </Button>
          )} */}
          {permission &&
            addItemText &&
            (opensDrawer
              ? onClick && (
                  <Button
                    as="button"
                    variant="link"
                    iconBefore={PlusIconSVG}
                    onClick={onClick}
                    className="add-item"
                  >
                    <Text fontSize="md">{addItemText}</Text>
                  </Button>
                )
              : redirectionUrl && (
                  <Button
                    as={Link}
                    variant="link"
                    className="add-item"
                    iconBefore={PlusIconSVG}
                    to={redirectionUrl}
                  >
                    <Text fontSize="md">{addItemText}</Text>
                  </Button>
                ))}
        </div>
      </div>
    ),
    hideInfo,
  };
};

export const actionsClassNames = (isHovered: boolean): string =>
  classNames({
    "hover-actions": true,
    "is-visible": isHovered,
  });

export const actionIconClassNames = (isHovered: boolean): string =>
  classNames({
    "actions-icon": true,
    "is-visible": !isHovered,
  });

import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";
import { ThemesRes } from "types/responses";

export type createThemePostData = {
  name: string;
  primary_color: string;
};

export const getThemes = async (): Promise<ThemesRes> => {
  const res = await HttpClient.get(ENDPOINTS.themes.themes);

  return res.data;
};

export const postTheme = async (data: createThemePostData): Promise<void> => {
  await HttpClient.post(ENDPOINTS.themes.create, data);
};

export const patchTheme = async (themeId: string, data: createThemePostData): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.themes.edit(themeId), data);
};

export const putActiveTheme = async (themeId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.themes.active(themeId));
};

export const deleteTheme = async (themeId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.themes.delete(themeId));
};

import React, { FC } from "react";
import { Heading } from "@epignosis_llc/gnosis";
import CourseTocItem from "./CourseTocItem";
import { sectionList } from "./styles";
import { Course, Section } from "types/entities";
import {
  getFlatUnits,
  getIncompleteUnits,
  isTocItemActive,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import { AvailabilityTag } from "@components";
import { QuoteLeftSVG } from "@assets/icons";

export type CourseToCProps = {
  course: Course;
  sections: Section[];
  isReadonly: boolean;
  isLearnerView?: boolean;
};

const CourseToC: FC<CourseToCProps> = ({ course, sections, isReadonly, isLearnerView = true }) => {
  const { rules } = course;
  const isSequentialCourse = rules?.traversal === "sequential";
  const flatUnits = getFlatUnits(sections);
  const incompleteUnits = getIncompleteUnits(isSequentialCourse, flatUnits);

  return (
    <>
      {sections.map((section) => {
        if (section.units?.every((unit) => !unit.is_active)) return null;

        const isSectionAvailable = !unitHasDelayedAvailability(section.availability);

        return (
          <section key={section.id} css={sectionList} data-testid="toc-section">
            {(section.name || section?.availability) && (
              <Heading as="h5" className="section-title">
                <QuoteLeftSVG height={32} />
                {section.name}
                {isLearnerView && !isSectionAvailable && section?.availability && (
                  <AvailabilityTag availability={section.availability} />
                )}
              </Heading>
            )}

            {section.units
              ?.filter((unit) => unit.is_active)
              .map((unit) => {
                const isActive = isLearnerView
                  ? isTocItemActive(unit, isSequentialCourse, incompleteUnits, isReadonly)
                  : true;

                return (
                  <CourseTocItem
                    key={unit.id}
                    course={course}
                    isActive={isActive}
                    isReadonly={isReadonly}
                    isLearnerView={isLearnerView}
                    hasParentSection={Boolean(section.id)}
                    {...unit}
                  />
                );
              })}
          </section>
        );
      })}
    </>
  );
};

export default CourseToC;

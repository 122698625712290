import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Text } from "@epignosis_llc/gnosis";
import QuestionsContainer from "../Question/components/QuestionsContainer";
import FooterWrapper from "./components/FooterWrapper";
import UnitFooterNavigationButtons from "./components/UnitFooterNavigationButtons";
import UnitTimeLimitBar from "./components/UnitTimeLimitBar";
import AvailabilityTimeLimitBar from "./components/AvailabilityTimeLimitBar";
import { putCompleteUnit } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  showCompleteUnitNotification,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import {
  Course,
  MyUnit,
  Section,
  QuestionAnswers,
  PlayerState,
  QuestionType,
} from "types/entities";
import permissions from "@utils/permissions";
import { FooterButton } from "@components/ReusableComponents";

type DefaultFooterProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  playerState?: PlayerState;
  isPublic?: boolean;
};

const DefaultFooter: FC<DefaultFooterProps> = ({
  course,
  sections,
  unit,
  playerState,
  isPublic = false,
}) => {
  const { courseId, unitId } = useParams() as { courseId: string; unitId: string };
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [userAnswers, setUserAnswers] = useState<QuestionAnswers>([]);
  const question = unit.completion_method?.question;
  const isCompleted = unit?.progress?.status === "completed";
  const flatUnits = getFlatUnits(sections);
  const { nextUnit } = (unit && getPrevAndNextUnits(flatUnits, unit.id)) || {};
  const nextUnitLink = goToNextUnitLink(false, nextUnit, course, isPublic);
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);

  // on plain units isContinueButtonTraversalDisabled is false because you can always complete it and you cannot fail it
  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    false,
    course.progress?.completion_status,
    unit.progress?.status,
  );

  const isTimeLimitUnit = unit?.completion_method?.type === "time_limit";
  const isUnitWithQuestion = unit.completion_method?.type === "question";

  // permissions related
  const { canCompleteCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsComplete = isPublic ? true : canCompleteCourseUnits();
  const completedWithNoPermission = !allowCourseUnitsComplete && isCompleted;
  const shouldShowTimer = isTimeLimitUnit && allowCourseUnitsComplete && !isCompleted;
  const shouldShowQuestion =
    allowCourseUnitsComplete &&
    unit.completion_method &&
    isUnitWithQuestion &&
    !isCompleted &&
    question;

  //plain unit completion method "checkbox"
  const { mutate: completeUnitMutation, isLoading: isCompleteUnitMutationLoading } = useMutation(
    () => putCompleteUnit(unitId.toString()),
    {
      onError: () => {
        showCompleteUnitNotification(unit as MyUnit, "error");
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.unit, unitId]);
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);

        //if mutation is successful, navigate to the next unit
        if (nextUnitLink !== "#" && !isTimeLimitUnit) navigate(nextUnitLink);
      },
    },
  );

  const onComplete = (): void => {
    if (allowCourseUnitsComplete && !isCompleted) {
      completeUnitMutation();
    } else {
      if (nextUnitLink !== "#") navigate(nextUnitLink);
    }
  };

  const onCompleteWithTimer = (): void => {
    if (nextUnitLink !== "#") navigate(nextUnitLink);
  };

  return (
    <>
      {shouldShowQuestion && (
        <div className="unit-question-container">
          <div className="question-wrapper">
            {question?.text && question.type !== QuestionType.FillTheGap && (
              <Text
                fontSize="sm"
                as="div"
                className="question-title"
                dangerouslySetInnerHTML={{ __html: question?.text.html }}
              />
            )}
            <QuestionsContainer
              questionEntry={question}
              userAnswers={userAnswers}
              onAnswersChange={setUserAnswers}
            />
          </div>
        </div>
      )}

      {/* if unit has completion method "time_limit" */}
      {shouldShowTimer && (
        <UnitTimeLimitBar onComplete={onComplete} unit={unit} playerState={playerState} />
      )}

      {/* if the next unit has delayed availability */}
      {!isNextUnitAvailable && isCompleted && <AvailabilityTimeLimitBar nextUnit={nextUnit} />}
      <FooterWrapper>
        <UnitFooterNavigationButtons
          course={course}
          sections={sections}
          unit={unit}
          isPublic={isPublic}
        >
          <>
            {isTimeLimitUnit ? (
              allowCourseUnitsComplete && (
                <FooterButton
                  type="button"
                  unit={unit}
                  isLoading={isCompleteUnitMutationLoading}
                  disabled={!isCompleted || continueButtonDisabled}
                  onClick={onCompleteWithTimer}
                />
              )
            ) : (
              <>
                {/* if completion method is with checkbox (plain complete button) */}
                {(completedWithNoPermission || allowCourseUnitsComplete) && (
                  <FooterButton
                    unit={unit}
                    isLoading={isCompleteUnitMutationLoading}
                    onClick={onComplete}
                    disabled={continueButtonDisabled}
                  />
                )}
              </>
            )}
          </>
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default DefaultFooter;

import React, { FC } from "react";
import { Tag } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { useTheme } from "@emotion/react";
import { availabilityTagStyles } from "./styles";
import { availabilityDelayInDaysHoursMinutes, getDelayInSeconds } from "@utils/helpers";
import { Availability } from "types/entities";

type AvailabilityTagProps = {
  availability: Availability;
};

const AvailabilityTag: FC<AvailabilityTagProps> = ({ availability }) => {
  const delay = getDelayInSeconds(availability);
  const { availabilityTag } = useTheme();
  if (!delay) return null;

  return (
    <Tag
      className="availability-tag"
      css={availabilityTagStyles}
      style={{ backgroundColor: availabilityTag.backgroundColor, color: availabilityTag.textColor }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t("general.availableInTag", {
            time: availabilityDelayInDaysHoursMinutes(delay),
          }),
        }}
      />
    </Tag>
  );
};

export default AvailabilityTag;

import React, { FC } from "react";
import { Card } from "@epignosis_llc/gnosis";
import { AudioFileSVG, NonPreviewableSVG } from "@assets/icons";
import { PdfThumbnail } from "@components";
import { FileThumbnailStyles } from "./styles";
import { HighlithedText } from "types/common";

const { Thumbnail } = Card;

type FileThumbnailProps = {
  fileType: string;
  fileUrl: string;
  fileName: string | HighlithedText;
};

const FileThumbnail: FC<FileThumbnailProps> = ({ fileType, fileUrl, fileName }) => {
  return (
    <div css={FileThumbnailStyles}>
      {((): JSX.Element => {
        switch (fileType) {
          case "pdf":
            return <PdfThumbnail fileUrl={fileUrl} pageIndex={1} />;
          case "mp4":
            return (
              <div className="video-container">
                <video src={`${fileUrl}#t=5`} controls={false} />
              </div>
            );
          case "gif":
          case "png":
          case "jpeg":
            return <Thumbnail src={fileUrl} alt={fileName as string} />;
          case "mp3":
            return (
              <div className="no-thumbnail-cover">
                <AudioFileSVG height={40} />
              </div>
            );
          default:
            return (
              <div className="no-thumbnail-cover">
                <NonPreviewableSVG height={40} />
              </div>
            );
        }
      })()}
    </div>
  );
};

export default FileThumbnail;

import React, { FC } from "react";
import { t } from "i18next";
import { SerializedStyles } from "@emotion/react";
import { Button, Chip, Tooltip, DropdownItem, Dropdown } from "@epignosis_llc/gnosis";
import { FilterSVG } from "@epignosis_llc/gnosis/icons";
import { SearchInput } from "@components";
import { tableActionsStyles } from "./styles";
import { IconType, QueryFilter } from "types/common";

type CustomTableActionsProps = {
  filterDropdownOptions?: DropdownItem[];
  selectedFilters?: QueryFilter[];
  resetInput?: boolean;
  hideFilters?: boolean;
  maxFilterWidth?: number;
  searchValue?: string;
  tableHasData?: boolean;
  onSearchChange: (searchValue: string) => void;
  onFilterSelect?: (selectedFilter: DropdownItem) => void;
  onFilterRemove?: (removedFilter: DropdownItem) => void;
};

const MIN_FILTER_NUMBER = 10;

const CustomTableActions: FC<CustomTableActionsProps> = ({
  filterDropdownOptions = [],
  selectedFilters = [],
  resetInput,
  hideFilters = false,
  maxFilterWidth = 200,
  searchValue = "",
  tableHasData = true,
  onSearchChange,
  onFilterSelect,
  onFilterRemove,
  children,
}) => {
  // make a flat array without all available filters
  const allFilters = filterDropdownOptions.map((filter) => filter.items ?? []).flat();

  // Only show search if there are more than 10 filter options
  const showSearch = allFilters.length > MIN_FILTER_NUMBER;

  // filterDropdownOptions that are in the selected filters
  const activeFilters = allFilters.reduce((array, filter): DropdownItem[] => {
    const foundFilter = selectedFilters.find((selectedFilter) => {
      return filter.category === selectedFilter.key && filter.value === selectedFilter.value;
    });

    if (foundFilter) {
      array.push(filter);
    }

    return array;
  }, [] as DropdownItem[]);

  const handleFilterRemove = (filter: DropdownItem): void => {
    onFilterRemove && onFilterRemove(filter);
  };

  return selectedFilters.length === 0 && !tableHasData ? (
    <></>
  ) : (
    <div css={(theme): SerializedStyles => tableActionsStyles(theme)} className="actions-container">
      <div className="start-actions-container">
        <SearchInput
          id="sender-search"
          value={searchValue}
          placeholder={t("general.search")}
          onInputChanged={onSearchChange}
          resetInput={resetInput}
        />
        {/* Should hide filters if there are not filter options to populate. */}
        {!hideFilters && filterDropdownOptions.length > 0 && (
          <Dropdown
            list={filterDropdownOptions}
            onListItemSelect={onFilterSelect}
            isSearchable={showSearch}
          >
            <Tooltip content={t("general.filters")}>
              <Button
                color="primary"
                variant="ghost"
                as="div"
                aria-label="filters-dropdown"
                className="filter-button"
                noGutters
              >
                <FilterSVG height={32} />
              </Button>
            </Tooltip>
          </Dropdown>
        )}

        {activeFilters.length > 0 && (
          <div className="filters">
            {activeFilters.map((filter) => {
              return (
                <Chip
                  key={filter.value}
                  className="action-item"
                  icon={filter.icon as unknown as IconType}
                  onClose={(): void => handleFilterRemove(filter)}
                  maxWidth={maxFilterWidth}
                >
                  {filter.label}
                </Chip>
              );
            })}
          </div>
        )}
      </div>
      {children && <div className="end-actions-container">{children}</div>}
    </div>
  );
};

export default CustomTableActions;

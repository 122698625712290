import React, { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import classNames from "classnames";
import { Text, MediaPlayer } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import TestIntroHeader from "../TestIntroHeader";
import FooterNavigationButtons from "../FooterNavigationButtons";
import QuestionsInfo from "../QuestionsInfo";
import { snapshotSplashScreenContainer } from "./styles";
import FooterWrapper from "@components/Units/Footers/components/FooterWrapper";
import { useRecording } from "@hooks";
import { postUnitSnapshot } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { MyUnitRes } from "types/responses";
import { TestUnit } from "types/entities";
import { CameraRegularSVG } from "@assets/icons";
import { convertBase64ToBlob, generalNotification } from "@utils/helpers";
import { FooterButton } from "@components/ReusableComponents";

type SnapshotSplashScreenProps = {
  unitRes: MyUnitRes;
  isPublic?: boolean;
  children?: never;
};

const SnapshotSplashScreen: FC<SnapshotSplashScreenProps> = ({ unitRes, isPublic = false }) => {
  const unit = unitRes._data as TestUnit;
  const { mediaRecorder } = useRecording("video");
  const canvasEl = useRef<HTMLCanvasElement | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [hasCapture, setHasCapture] = useState(false);
  const queryClient = useQueryClient();
  const totalQuestions = unitRes._meta.total_questions;

  const { mutate: snapshotMutate } = useMutation(
    [queryKeys.unitSnapshot, { unitId: unit.id }],
    (file: File) => postUnitSnapshot(unit.id.toString(), file),
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.unit, unit.id.toString()]);
        mediaRecorder?.stop();
      },
      onError: () => generalNotification("error", <p>{t("notifications.snapshotFailed")}</p>),
    },
  );

  const containerClassNames = classNames({
    "show-canvas": hasCapture,
    "unit-container": true,
  });

  const capture = (): void => {
    const context = canvasEl.current?.getContext("2d");
    const video = document.getElementsByTagName("video")[0];
    context?.drawImage(video, 0, 0, 300, 150);
    setIsCameraOpen(false);
    setHasCapture(true);
  };

  const clearphoto = (): void => {
    const context = canvasEl.current?.getContext("2d");
    context?.clearRect(0, 0, 300, 150);
    setHasCapture(false);
    setIsCameraOpen(true);
  };

  const uploadSnapshot = (): void => {
    const dataURI = canvasEl.current?.toDataURL("image/jpeg");
    const blob = convertBase64ToBlob(dataURI ?? "");
    const imgFile = new File([blob], "snapshot.jpeg", { type: "image/jpeg" });

    snapshotMutate(imgFile);
  };

  useEffect(() => {
    canvasEl.current = document.getElementById("canvas") as HTMLCanvasElement;
    setHasCapture(hasCapture);
  }, [hasCapture]);

  return (
    <>
      <article className="unit-wrapper">
        <div css={snapshotSplashScreenContainer} className={containerClassNames}>
          <TestIntroHeader unitRes={unitRes} />

          <div className="picture-capture">
            <Text
              fontSize="sm"
              weight="700"
              className="label"
              dangerouslySetInnerHTML={{
                __html: t("test.beforeStartingOpenCameraForSecurity"),
              }}
            />

            <div className="canvas-container">
              {isCameraOpen && (
                <>
                  <div className="media-player-wrapper">
                    <MediaPlayer
                      id="video"
                      type="video"
                      src={mediaRecorder?.stream}
                      muted
                      onStart={(): void => mediaRecorder?.start()}
                      playing
                      controls={false}
                    />
                  </div>
                  <button onClick={(): void => capture()} className="capture-btn">
                    <CameraRegularSVG height={18} /> {t("test.capture")}
                  </button>
                </>
              )}

              <div className={`canvas ${isCameraOpen && "is-open"}`}>
                <canvas id="canvas" ref={canvasEl}></canvas>

                <button className="reset-btn" onClick={(): void => clearphoto()}>
                  <CameraRegularSVG height={18} /> {t("test.takeNewPhoto")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
      <QuestionsInfo totalQuestions={totalQuestions} />
      <FooterWrapper>
        <FooterNavigationButtons isPublic={isPublic}>
          <FooterButton
            type={hasCapture ? "submit" : "button"}
            unit={unit}
            onClick={!hasCapture ? (): void => setIsCameraOpen(true) : (): void => uploadSnapshot()}
            buttonText={hasCapture ? t("test.startTest") : t("test.openCamera")}
          />
        </FooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default SnapshotSplashScreen;

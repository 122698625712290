import React, { FC, HTMLAttributes } from "react";
import { Drawer, Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { m, useReducedMotion, Variants } from "framer-motion";
import { Link } from "react-router-dom";
import { tocContainer, tocDrawerBodyContainer, tocDrawerHeaderContainer } from "./styles";
import ToCAdminTrigger from "./ToCAdminTrigger";
import i18n from "@utils/i18n";
import { useUIStore } from "@stores";
import { GlobalStyleVariables } from "@styles";
import classNames from "classnames";

const { unitsSidebarMaxWidth } = GlobalStyleVariables;

export const asideContainerVariants: Variants = {
  expanded: (isReducedMotion) => ({
    maxWidth: unitsSidebarMaxWidth,
    opacity: 1,
    paddingTop: "1rem",
    paddingInline: "0",
    transition: {
      when: "beforeChildren",
      duration: isReducedMotion ? 0 : 0.2,
    },
  }),
  hidden: (isReducedMotion) => ({
    maxWidth: "2.5rem",
    opacity: 1,
    padding: 0,
    transition: {
      when: "afterChildren",
      duration: isReducedMotion ? 0 : 0.2,
    },
  }),
};

const asideContentCVariants: Variants = {
  expanded: (isReducedMotion) => ({
    display: "block",
    opacity: 1,
    transition: {
      duration: isReducedMotion ? 0 : 0.1,
    },
    transitionEnd: {
      // overflow: "auto",
    },
  }),
  hidden: (isReducedMotion) => ({
    opacity: 0,
    overflow: "hidden",
    transition: {
      duration: isReducedMotion ? 0 : 0.1,
    },
    transitionEnd: {
      display: "none",
    },
  }),
};

type ToCProps = {
  backLinkText: string;
  backLink: string;
};

const tocContainerClassNames = (isOpen: boolean): string =>
  classNames({
    admin: true,
    "is-closed": !isOpen,
  });

const ToCAdminContainer: FC<ToCProps & HTMLAttributes<HTMLDivElement>> = ({
  backLinkText,
  backLink,
  children,
  ...rest
}) => {
  const { lg } = useResponsive();
  const { showUnitSidebar, toggleUnitSidebar } = useUIStore();
  const isReducedMotion = useReducedMotion();
  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  const handleContainerClick = (): void => {
    if (!showUnitSidebar) toggleUnitSidebar();
  };

  if (!lg) {
    return (
      <>
        <Drawer isOpen={showUnitSidebar} placement={placement} onClose={toggleUnitSidebar}>
          <Drawer.Header closable>
            <Link css={tocDrawerHeaderContainer} to={backLink}>
              <Text fontSize="md">{backLinkText}</Text>
            </Link>
          </Drawer.Header>
          <Drawer.Body>
            <div css={tocDrawerBodyContainer} {...rest}>
              {children}
            </div>
          </Drawer.Body>
        </Drawer>
        <ToCAdminTrigger isToCOpen={showUnitSidebar} toggleTocMenu={toggleUnitSidebar} />
      </>
    );
  }

  return (
    <>
      <m.aside
        css={tocContainer}
        className={tocContainerClassNames(showUnitSidebar)}
        initial="expanded"
        animate={showUnitSidebar ? "expanded" : "hidden"}
        variants={asideContainerVariants}
        custom={isReducedMotion}
        onClick={handleContainerClick}
      >
        <m.div variants={asideContentCVariants} custom={isReducedMotion}>
          {children}
        </m.div>
      </m.aside>
      <ToCAdminTrigger isToCOpen={showUnitSidebar} toggleTocMenu={toggleUnitSidebar} />
    </>
  );
};

export default ToCAdminContainer;

import React, { FC, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { useDebounceFn, useResponsive } from "ahooks";
import { t } from "i18next";
import { courseRatingContainer } from "./styles";
import { Course } from "types/entities";
import StarRating from "@components/StarRating/StarRating";
import { usePostCourseRating, useResetCourseRating } from "@hooks/api/courses";

export type CourseRatingProps = {
  course: Course;
};

const CourseRating: FC<CourseRatingProps> = ({ course }) => {
  const { id, availability, rating } = course;
  const { sm } = useResponsive();
  const [isHovered, setIsHovered] = useState(false);
  const isUserEnrolled = Boolean(availability);
  const ratingTxt = isHovered ? t("courseCatalog.rateCourse") : t("courseCatalog.averageRating");
  const userRating = rating?.user;

  const { mutate: postRating } = usePostCourseRating(id);
  const { mutate: resetRating } = useResetCourseRating(id);

  const { run } = useDebounceFn(
    (rating: number) => {
      postRating(rating);
    },
    { wait: 500 },
  );

  const hoverTriggered = (hovered: boolean): void => {
    setIsHovered(hovered);
  };
  return (
    <div css={courseRatingContainer}>
      <div className="rating-actions">
        <Text fontSize="sm" as="div">
          {ratingTxt}
        </Text>
        {isUserEnrolled ? (
          <div
            onMouseEnter={(): void => hoverTriggered(true)}
            onMouseLeave={(): void => hoverTriggered(false)}
            className="star-wrapper"
          >
            <StarRating
              count={course.rating?.average ?? 0}
              onRate={(rating): void => run(rating)}
            />
          </div>
        ) : (
          <div className="star-wrapper">
            <StarRating count={course.rating?.average ?? 0} readonly />
          </div>
        )}
        {sm && userRating && (
          <Button variant="link" noGutters onClick={resetRating} className="reset-btn">
            {t("courseCatalog.resetRating")}
          </Button>
        )}
      </div>

      {isUserEnrolled && (
        <>
          {userRating ? (
            <Text fontSize="sm" className="user-rating">
              {t("courseCatalog.userRating")} {userRating}{" "}
              {userRating && userRating > 1 ? t("courseCatalog.stars") : t("courseCatalog.star")}
            </Text>
          ) : rating?.average ? (
            <Text fontSize="sm" as="div" className="no-ratings-text">
              {t("courseCatalog.notRatedCourse")}
            </Text>
          ) : (
            <Text fontSize="sm" as="div" className="no-ratings-text">
              {t("courseCatalog.noUsersRatings")}
            </Text>
          )}
        </>
      )}

      {!sm && userRating && (
        <Button variant="link" noGutters onClick={resetRating} className="reset-btn">
          {t("courseCatalog.resetRating")}
        </Button>
      )}
    </div>
  );
};

export default CourseRating;

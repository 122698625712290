import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { useResponsive } from "ahooks";
import classNames from "classnames";
import { t } from "i18next";
import FooterNavigationButtons from "../FooterNavigationButtons";
import { completedSplashScreenContainer } from "./styles";
import FooterWrapper from "@components/Units/Footers/components/FooterWrapper";
import { Course, Section, TestUnit } from "types/entities";
import {
  formatUtcDate,
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  nth,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import {
  TestFailureSVG,
  TestSuccessSVG,
  // PointsSVG
} from "@assets/images";
import { getUnitResults, postResetTest } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { UnitRouterParams } from "@views/Unit/Unit";
import { MyUnitRes } from "types/responses";
import { URLS } from "@constants/urls";
import { Skeletons, TimeLimitBar } from "@components";
import { datesDifferenceInSeconds } from "@utils/helpers/date-time";
import { Link } from "@components/ReusableComponents";
import { ListCheckRegularSVG, RotateRightSVG } from "@assets/icons";
import AvailabilityTimeLimitBar from "@components/Units/Footers/components/AvailabilityTimeLimitBar";
import i18n from "@utils/i18n";

type CompletedSplashScreenProps = {
  unitRes: MyUnitRes;
  course: Course;
  units: Section[];
  isPublic?: boolean;
  children?: never;
};

const containerClassNames = (result_status: "completed" | "failed" | ""): string =>
  classNames({
    "message-container": true,
    completed: result_status === "completed",
    failed: result_status === "failed",
  });

const CompletedSplashScreen: FC<CompletedSplashScreenProps> = ({
  unitRes,
  units,
  course,
  isPublic = false,
}) => {
  const queryClient = useQueryClient();
  const { xs } = useResponsive();
  const { courseId } = useParams() as UnitRouterParams;
  const unit = unitRes._data as TestUnit;
  const { progress, test_options } = unit;
  const isUnitCompleted = ["completed", "failed"].some(
    (status) => status === unit?.progress?.status,
  );
  const isRtl = i18n.dir() === "rtl";

  // calculate time needed in seconds to be able to retry test
  const initialTime = useMemo(() => {
    if (!progress?.repetitions || !progress.repetitions.repetition_allowed_after) return 0;
    return datesDifferenceInSeconds(progress.repetitions.repetition_allowed_after);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress?.repetitions, courseId, unit.id]);
  const [counter, setCounter] = useState(initialTime);
  const countdown_text = t("test.youCanRetryTestIn", {
    numberOfTries: progress?.repetitions?.repetitions_left,
  });

  const flatUnits = units ? getFlatUnits(units) : [];
  const { nextUnit } = getPrevAndNextUnits(flatUnits, unit?.id) || {};
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);
  const isContinueButtonTraversalDisabled =
    course?.rules.traversal === "sequential"
      ? unit?.progress?.status !== "completed"
      : !nextUnit && course.progress?.completion_status !== "completed";

  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    isContinueButtonTraversalDisabled,
    course.progress?.completion_status,
    unit.progress?.status,
  );
  const nextUnitLink = goToNextUnitLink(
    isContinueButtonTraversalDisabled,
    nextUnit,
    course,
    isPublic,
  );

  const {
    data: unitResults,
    status: unitResultsResStatus,
    error: unitResultsResError,
  } = useQuery(
    [queryKeys.unitResults, unit.id.toString()],
    () => getUnitResults(unit.id.toString()),
    {
      select: (res) => res._data,
      refetchOnWindowFocus: false,
      enabled: isUnitCompleted,
    },
  );

  const { questions, statistics, status, completion_date, score, instructor_message } =
    unitResults ?? {};

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((counter) => counter - 1);
    }, 1000);

    if (counter === 0) clearInterval(intervalId);

    return (): void => {
      clearInterval(intervalId);
    };
  });

  const { mutate: resetMutation } = useMutation(
    [queryKeys.unitReset, { unitId: unit.id.toString() }],
    () => postResetTest(unit.id.toString()),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([queryKeys.unit, unit.id.toString()]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);
      },
    },
  );

  const viewResultsUrl =
    questions && questions?.length > 0
      ? isPublic
        ? URLS.externalCatalog.publicUnit.createUnitResultsLink({
            courseId,
            unitId: unit.id.toString(),
          })
        : URLS.user.createUnitResultsLink({
            courseId,
            unitId: unit.id.toString(),
          })
      : "#";

  return (
    <Skeletons.Loader status={unitResultsResStatus} error={unitResultsResError}>
      {unitResults && (
        <>
          <article className="unit-wrapper">
            <div css={completedSplashScreenContainer} className="unit-container">
              <div className="completion-details">
                {xs && (
                  <>
                    {/*If the option Show score is disabled, then we show the success banner */}
                    {status === "completed" || !test_options.show_score ? (
                      <TestSuccessSVG className="image-banner" data-testid="image-banner" />
                    ) : (
                      <TestFailureSVG className="image-banner" data-testid="image-banner" />
                    )}
                  </>
                )}

                <section className="results-info">
                  {completion_date && (
                    <Text fontSize="lg" as="div" className="completion-date results-info-item">
                      {t("test.youCompletedTestOn", {
                        dateOfCompletion: formatUtcDate(completion_date),
                        timeOfCompletion: formatUtcDate(completion_date, "time"),
                      })}
                    </Text>
                  )}

                  {statistics && (
                    <Text
                      fontSize="sm"
                      as="div"
                      className="completion-stats-rank results-info-item"
                      dangerouslySetInnerHTML={{
                        __html: t("test.peopleRankTest", {
                          totalTries: statistics.all_tries,
                          rank: statistics.rank,
                          nth: nth(statistics.rank),
                        }),
                      }}
                    />
                  )}
                  {test_options.show_score && (
                    <>
                      <Text fontSize="sm" as="div" className="score-text results-info-item">
                        {t("test.yourScore")}
                      </Text>
                      <Button
                        color={status === "completed" ? "success" : "danger"}
                        className="score-button results-info-item"
                      >
                        {score ?? 0}&rlm;%
                      </Button>
                    </>
                  )}

                  {/* If the option show stats is enabled, check if show_score is disabled
                  (if show_score is disabled then we do not show the average score) */}
                  {Boolean(statistics?.average_score) && test_options.show_score && (
                    <Text
                      fontSize="sm"
                      as="div"
                      className="completion-stats-average-score results-info-item"
                      dangerouslySetInnerHTML={{
                        __html: t("test.averageTestScore", {
                          avgScore: statistics?.average_score,
                        }),
                      }}
                    />
                  )}
                </section>

                <section className="achievements-container">
                  {/* TODO: when gamification info is available from API */}
                  {/* <div className="icon-container">
                    <PointsSVG height={32} data-testid="badge" />
                    <Text fontSize="sm">You&apos;ve earned</Text>
                    <Text fontSize="sm">
                      <strong>200</strong> points
                    </Text>
                  </div> */}

                  {questions?.length && (
                    <>
                      <Button
                        as={Link}
                        data-testid="view-results-button"
                        iconBefore={ListCheckRegularSVG}
                        to={viewResultsUrl}
                      >
                        {t("test.testResults")}
                      </Button>

                      {/* TODO: Uncomment when PRINT FEATURE IS IMPLEMENTED */}
                      {/* <Button
                        as={Link}
                        variant="outline"
                        data-testid="view-results-button"
                        to={viewResultsUrl}
                      >
                        {t("test.print")}
                      </Button> */}
                    </>
                  )}

                  {progress?.repetitions?.repetition_allowed && counter <= 0 && (
                    <Button
                      data-testid="view-results-button"
                      onClick={(): void => {
                        if (counter === 0) {
                          resetMutation();
                        }
                      }}
                      iconBefore={RotateRightSVG}
                      disabled={counter > 0}
                    >
                      {t("test.clickToTryAgain")}
                    </Button>
                  )}

                  {/* TODO: when get icon */}
                  {/* {questions?.length && (
                    <div className="icon-container">
                    <TestResultsSVG height={32} data-testid="badge" />
                    <Text fontSize="sm" as="div">
                    <a onClick={featureNotReadyNotification}> Print</a>
                    </Text>
                    <Text fontSize="sm" as="div">
                    test results
                    </Text>
                    </div>
                  )} */}
                </section>

                {instructor_message && (
                  <div
                    className={containerClassNames(status ?? "")}
                    dangerouslySetInnerHTML={{ __html: instructor_message }}
                  ></div>
                )}
              </div>
            </div>
          </article>
          {unitResults && (
            <>
              {counter > 0 && (
                <TimeLimitBar
                  initialTime={initialTime}
                  currentTime={counter}
                  text={countdown_text}
                />
              )}

              {/* if next unit has delayed availability */}
              {counter <= 0 && !isNextUnitAvailable && (
                <AvailabilityTimeLimitBar nextUnit={nextUnit} />
              )}

              <FooterWrapper>
                <FooterNavigationButtons isPublic={isPublic}>
                  <Button
                    type="button"
                    as={Link}
                    disabled={continueButtonDisabled}
                    to={nextUnitLink}
                    iconAfter={isRtl ? ArrowLeftSVG : ArrowRightSVG}
                    className="btn-complete-wrapper"
                  >
                    {t("general.continue")}
                  </Button>
                </FooterNavigationButtons>
              </FooterWrapper>
            </>
          )}
        </>
      )}
    </Skeletons.Loader>
  );
};

export default CompletedSplashScreen;

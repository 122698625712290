import { css } from "@emotion/react";

export const drawerBodyContainer = css`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  .back-btn {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
  }
`;

export const footerNavigationButtonsContainer = css`
  display: flex;
  align-items: center;

  .prev-btn {
    margin-inline-end: 0.75rem;
  }

  .btn-complete-wrapper {
    button {
      height: auto;
      min-height: 2.5rem;
    }
  }
`;

export const questionsInfoContainer = css`
  text-align: center;
  margin-bottom: 0.75rem;
`;

export const testIntroHeaderContainer = (applyMargin) => css`
  text-align: center;
  margin-bottom: ${applyMargin ? "1.5rem" : "0"};

  .icon {
    margin-bottom: 2rem;
  }

  .description {
    max-width: 80%;
    margin: auto;
  }

  .duration,
  .description {
    margin-bottom: 0.5rem;
  }
`;

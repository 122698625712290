import React from "react";
import { Text } from "@epignosis_llc/gnosis";
import { MimeType } from "types/entities";
import { t } from "i18next";

type UploadTooltipProps = { mimeType: MimeType[]; sizeLimit: number };

const BuildUploadTooltip: React.FC<UploadTooltipProps> = ({ mimeType, sizeLimit }) => {
  const acceptedImageTypes = mimeType.map((item) => item.split("/")[1]).join(", ");

  return (
    <>
      <Text
        fontSize="sm"
        dangerouslySetInnerHTML={{
          __html: t("general.acceptedFileTypes", { fileTypes: acceptedImageTypes }),
        }}
      />
      <br />
      <Text
        fontSize="sm"
        dangerouslySetInnerHTML={{
          __html: t("files.maxFileSize", { maxFileSize: sizeLimit }),
        }}
      />
    </>
  );
};

export default BuildUploadTooltip;

import React, { FC } from "react";
import { Modal, Text } from "@epignosis_llc/gnosis";
import { CatalogSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { externalModalBody } from "./styles";
import { Course } from "types/entities";
import { Bundle } from "types/entities/Catalog";
import { truncate } from "@utils/helpers";
import { images } from "@constants/assets";
import ExternalFooterButtons from "./components/ExternalFooterButtons";
import { useConfigurationStore } from "@stores";

type ActionType = "enrollment" | "payment";

export type ExternalPaymentModalProps = {
  data: Course | Bundle;
  isOpen: boolean;
  onClose: () => void;
};

function isCourse(data: Course | Bundle): data is Course {
  return (data as Course).category !== undefined;
}

const getActionType = (data: Course | Bundle): ActionType => {
  // If the data type is Course, check for price
  if (isCourse(data)) {
    if (data.price?.amount) return "payment";
    else return "enrollment";
  }

  return "payment";
};

const ExternalPaymentModal: FC<ExternalPaymentModalProps> = ({ data, isOpen, onClose }) => {
  const { domainSettings } = useConfigurationStore();
  const isSignUpEnabled = Boolean(domainSettings?.signup.enabled);
  const { cover_image, code } = data as Course;
  const { name } = data;
  const isCourseType = isCourse(data);
  const headerName = isCourseType && data.code ? `${name} (${code})` : name;
  const translationText = isCourseType
    ? isSignUpEnabled
      ? "externalCatalog.youSelectedTheCourseSignUp"
      : "externalCatalog.youSelectedTheCourse"
    : isSignUpEnabled
    ? "externalCatalog.youSelectedTheBundleSignUp"
    : "externalCatalog.youSelectedTheBundle";

  const actionType = getActionType(data);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <Text fontSize="sm">{truncate(headerName, 40)}</Text>
      </Modal.Header>
      <Modal.Body>
        <div css={externalModalBody}>
          {isCourseType ? (
            <img
              src={cover_image?.default ?? images.cover}
              alt={`${name} cover image.`}
              className="course-img"
            />
          ) : (
            <div className="bundle-img">
              <CatalogSVG height={32} />
            </div>
          )}
          <Text
            fontSize="sm"
            dangerouslySetInnerHTML={{
              __html: t(translationText, { name: headerName }),
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ExternalFooterButtons
          onClose={onClose}
          actionType={actionType}
          activeBundle={isCourseType ? undefined : data}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ExternalPaymentModal;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const roleMenuItem = ({ sidebar }, { dir, defaultRole }) => css`
  margin-bottom: 1rem;
  cursor: pointer;

  &.single-role {
    .nav-item {
      cursor: default;

      &:hover {
        background: ${sidebar.background};
      }
    }
  }

  .dropdown {
    width: 100%;
    color: ${sidebar.textColor};

    .dropdown-button {
      .sidebar-wrapper {
        width: 100%;
        align-items: center;
        display: flex;

        .nav-item {
          width: 100%;
        }

        .arrow-right {
          position: absolute;
          align-self: center;
          display: flex;
          transform: rotate(${dir === "rtl" ? "270deg" : "90deg"});
          inset-inline-end: 1rem;

          ${mq["md"]} {
            transform: rotate(${"0deg"});
          }
        }
      }
    }

    .dropdown-wrapper {
      &.bottom-start {
        inset-inline-start: 2rem;
      }

      .dropdown-list {
        color: initial;
        min-width: 12rem;

        li {
          &.${defaultRole} {
            background-color: ${sidebar.selectedColor};
          }
        }
      }
    }
  }
`;

import React, { FC, MouseEvent } from "react";
import { useResponsive } from "ahooks";
import { useNavigate } from "react-router-dom";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { actionsFooter } from "./styles";
import { URLS } from "@constants/urls";
import { useUIStore, useUserPreferencesStore } from "@stores";
import permissions from "@utils/permissions";

const MessagesFooter: FC = () => {
  const navigate = useNavigate();
  const { md } = useResponsive();
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};
  const { hideMainDrawer } = useUIStore((state) => state);
  const { canCreateMessages } = permissions.messagesPermissions;
  const allowMessagesCreate = canCreateMessages();

  const goToMessages = (e: MouseEvent): void => {
    e.preventDefault();
    hideMainDrawer();
    // Reset userPreference for table states
    resetSettings();
    navigate(URLS.user.messages);
  };

  const goToNewMessage = (e: MouseEvent): void => {
    if (allowMessagesCreate) {
      e.preventDefault();
      hideMainDrawer();
      // Reset userPreference for table states
      resetSettings();
      navigate(URLS.user.newMessage);
    }
  };

  return (
    <section css={actionsFooter}>
      <div className="actions">
        <Button color="secondary" onClick={goToMessages}>
          {md ? t("messages.goToInbox") : t("messages.inbox")}
        </Button>
        {allowMessagesCreate && (
          <Button onClick={goToNewMessage} className="create-btn">
            {md ? t("messages.newMessage") : t("general.create")}
          </Button>
        )}
      </div>
    </section>
  );
};

export default MessagesFooter;

import React, { FC } from "react";
import classNames from "classnames";
import { badgeFigure } from "./styles";
import { Badge } from "types/entities";
import { Tooltip } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { BadgeType } from "types/entities/Badge";

type BadgeFigureProps = Pick<Badge, "image" | "awarded" | "type" | "criteria"> & { name?: string };

const constructContent = (name: string, criteria: number, type: BadgeType): JSX.Element => {
  return (
    <>
      <span>{name}</span>
      <div
        className="reward-label"
        dangerouslySetInnerHTML={{
          __html: t(`badges.criteria.${type}`, {
            count: criteria,
          }),
        }}
      />
    </>
  );
};

const BadgeFigure: FC<BadgeFigureProps> = (props) => {
  const { image, awarded, type, name, criteria } = props;
  const hasName = Boolean(name);

  const imageFigureClassname = classNames({
    [`set-${image.set}`]: true,
    [`badge-${type}-${image.index}`]: true,
    awarded,
  });

  const badgeFigureClassname = classNames({
    "custom-badge": true,
    awarded,
  });

  return (
    <Tooltip content={constructContent(name ?? "", criteria?.count, type)} disabled={!hasName}>
      <figure css={badgeFigure}>
        {image.custom_url ? (
          <div
            className={badgeFigureClassname}
            style={{
              backgroundImage: `url(${image.custom_url})`,
            }}
          />
        ) : (
          <div className={imageFigureClassname} />
        )}
      </figure>
    </Tooltip>
  );
};

export default BadgeFigure;

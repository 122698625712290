import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  boxShadowColor: string;
  backgroundColor: string;
  emptyStateColor: string;
  hoverBackgroundColor: string;
  borderBottomColor: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  boxShadowColor: colors.secondary.light,
  backgroundColor: colors.white,
  emptyStateColor: colors.secondary.base,
  hoverBackgroundColor: colors.secondary.lighter,
  borderBottomColor: colors.secondary.lighter,
});

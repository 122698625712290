import { Typography, typeScaleCalc } from "./utils";

export const fontSize = {
  11: "0.6875rem",
  12: "0.75rem",
  13: "0.8125rem",
  14: "0.875rem",
  16: "1rem",
  18: "1.125rem",
  20: "1.25rem",
  22: "1.375rem",
  24: "1.5rem",
  25: "1.5625rem",
  28: "1.75rem",
  30: "1.875rem",
  40: "2.5rem",
  48: "3rem",
  70: "4.625rem",
} as const;

export const fontWeight = {
  normal: "400",
  bold: "700",
} as const;

export const typographyInit: Typography = {
  baseFontSize: 1, // rem
  lineHeight: 1.5715,
  sizeRatio: 1.125, // major second
  levels: {
    base: "md",
    up: ["lg", "xl", "2xl", "3xl", "4xl"],
    down: ["sm", "xs", "2xs"],
  },
};

export const typeScaleInit = typeScaleCalc(typographyInit);

export const typeScale = new Proxy(typeScaleInit, {
  get: (target, prop): string => `${target[prop]}rem`,
});

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { MagnifierSVG } from "@epignosis_llc/gnosis/icons";

export type ViewActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const ViewAction: FC<ViewActionProps> = ({
  tooltipContent,
  icon: Icon = MagnifierSVG,
  iconHeight = 20,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.actions.view")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default ViewAction;

import React, { FC, ReactNode } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { MyUnit } from "types/entities";
import { UnitUnsupportedSVG } from "@assets/images";
import Iframe from "../IFrame/Iframe";
import FileFailed from "../FileResult/FileFailed";
import FileProcessing from "../FileResult/FileProcessing";

type DocumentProps = {
  unit: MyUnit;
  children: ReactNode;
};

const Document: FC<DocumentProps> = (props) => {
  const { unit, children } = props;
  const { file } = unit;
  const { status } = file ?? {};
  const isFailed = status === "failed";
  const isProcessing = status === "processing";

  if (isFailed) return <FileFailed />;
  if (isProcessing) return <FileProcessing />;

  switch (unit?.type) {
    case "document-slideshare":
    case "document-uploaded":
      return <Iframe {...unit}>{children}</Iframe>;
    default:
      return (
        <Result
          icon={UnitUnsupportedSVG}
          title="Unsupported document unit"
          info="This unit is under construction"
        />
      );
  }
};

export default Document;

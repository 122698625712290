import React, { FC } from "react";
import classNames from "classnames";
import { Button } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightSVG } from "@epignosis_llc/gnosis/icons";
import { tocTriggerContainer } from "./styles";
import i18n from "@utils/i18n";

type ToCTriggerProps = {
  isToCOpen: boolean;
  toggleTocMenu: (value?: boolean) => void;
};

const classNamesContainer = (isToCOpen: boolean): string => classNames({ "is-open": !isToCOpen });

const ToCTrigger: FC<ToCTriggerProps> = ({ isToCOpen, toggleTocMenu }) => {
  const dir = i18n.dir();
  const ArrowIconOpened =
    dir === "rtl" ? <ArrowRightSVG height={24} /> : <ArrowLeftSVG height={24} />;
  const ArrowIconClosed =
    dir === "rtl" ? <ArrowLeftSVG height={24} /> : <ArrowRightSVG height={24} />;

  return (
    <div css={tocTriggerContainer} className={classNamesContainer(isToCOpen)}>
      <Button
        className="toc-trigger admin"
        onClick={(): void => toggleTocMenu()}
        aria-label={`${isToCOpen ? "Close" : "Open"} table of contents`}
        noGutters
        color="secondary"
      >
        {!isToCOpen ? ArrowIconClosed : ArrowIconOpened}
      </Button>
    </div>
  );
};

export default ToCTrigger;

import React from "react";
import {
  MyCoursesSVG,
  CatalogSVG,
  CalendarSVG,
  OpenedMessageSVG,
  ChatCloudsSVG,
  HomeSVG,
  ShowTourSVG,
  GroupsSVG,
} from "@epignosis_llc/gnosis/icons";
import { getFormattedUserName } from "@utils/helpers";
import { URLS } from "@constants/urls";
import { UserProfile } from "types/entities";
import {
  CategoriesSVG,
  BranchIconSVG,
  UserAvatarSVG,
  UserRoleSVG,
  ReportsSVG,
  UserExitSVG,
  GearIconSVG,
} from "@assets/icons";
import { MenuEntry } from "./types";

export const getMenuEntries = (userProfileData: UserProfile | null): MenuEntry[][] => {
  const { name = "", surname = "", login = "" } = (userProfileData as UserProfile) ?? {};

  return [
    [
      {
        title: "general.backToAdmin",
        url: null,
        icon: <UserExitSVG height={32} />,
        drawerKey: "backToAdmin",
      },
      {
        title: getFormattedUserName({ name, surname, login }),
        url: null,
        icon: null,
        drawerKey: "profile",
      },
      {
        title: "Role",
        url: null,
        icon: <UserRoleSVG height={32} />,
        drawerKey: "role",
      },
      {
        title: "mainMenu.home",
        url: URLS.dashboard,
        icon: <HomeSVG height={32} />,
        drawerKey: "dashboard",
      },
      {
        title: "general.accountAndSettings",
        url: URLS.accountAndSettings,
        icon: <GearIconSVG height={32} />,
        drawerKey: "accountAndSettings",
      },
      {
        title: "mainMenu.myCourses",
        url: URLS.user.courses,
        icon: <MyCoursesSVG height={32} />,
        drawerKey: "myCourses",
      },
      {
        title: "mainMenu.courses",
        url: URLS.courses.courses,
        icon: <MyCoursesSVG height={32} />,
        drawerKey: "courses",
      },
      {
        title: "mainMenu.users",
        url: URLS.users.users,
        icon: <UserAvatarSVG height={32} />,
        drawerKey: "users",
      },
      {
        title: "mainMenu.branches",
        url: URLS.branches.branches,
        icon: <BranchIconSVG height={32} />,
        drawerKey: "branches",
      },
      {
        title: "mainMenu.groups",
        url: URLS.groups.groups,
        icon: <GroupsSVG height={32} />,
        drawerKey: "groups",
      },
      {
        title: "mainMenu.categories",
        url: URLS.categories.categories,
        icon: <CategoriesSVG height={32} />,
        drawerKey: "categories",
      },
      {
        title: "mainMenu.reports",
        url: URLS.reports.reports,
        icon: <ReportsSVG height={32} />,
        drawerKey: "reports",
      },
      {
        title: "mainMenu.courseCatalog",
        url: URLS.catalog.index,
        icon: <CatalogSVG height={32} />,
        drawerKey: "catalog",
      },
      {
        title: "mainMenu.calendar",
        url: URLS.user.calendar,
        icon: <CalendarSVG height={32} />,
        drawerKey: "calendar",
      },
    ],
    [
      {
        title: "mainMenu.messages",
        url: null,
        icon: <OpenedMessageSVG height={32} />,
        drawerKey: "messages",
      },
      {
        title: "mainMenu.discussions",
        url: null,
        icon: <ChatCloudsSVG height={32} />,
        drawerKey: "discussions",
      },
      {
        title: "mainMenu.showTour",
        url: null,
        icon: <ShowTourSVG height={32} />,
        drawerKey: "tour",
      },
      {
        title: "general.leaveTalentPlus",
        url: null,
        icon: null,
        drawerKey: "leaveBeta",
      },
    ],
  ];
};

import React, { FC } from "react";
import { FormError, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";

export type PrerequisitesInfoProps = {
  coursesNum: number;
  hasMultiPrerequisites: boolean;
};

const PrerequisitesInfo: FC<PrerequisitesInfoProps> = ({ coursesNum, hasMultiPrerequisites }) => {
  const translationText = hasMultiPrerequisites
    ? t("payments.multiplePrerequisiteSets")
    : t("payments.courseDependsOn", { count: coursesNum });

  return (
    <div className="prerequisites-info-wrapper">
      <FormError>
        <Text
          fontSize="sm"
          as="div"
          dangerouslySetInnerHTML={{
            __html: translationText,
          }}
        />
      </FormError>
    </div>
  );
};

export default PrerequisitesInfo;

import { UseMutationResult, useQueryClient, useMutation } from "react-query";
import { t } from "i18next";
import { postEnrollment } from "@api/catalog";
import queryKeys from "@constants/queryKeys";
import { generalNotification } from "@utils/helpers";
import { Bundle } from "types/entities";
import { EnrollmentPostData } from "types/entities/Courses";

export const useBundleEnrollment = (
  activeBundle: Bundle | null,
): UseMutationResult<void, unknown, EnrollmentPostData, void> => {
  const queryClient = useQueryClient();
  const { name } = activeBundle ?? {};

  return useMutation(
    [queryKeys.courseEnrollment],
    (data: EnrollmentPostData) => postEnrollment(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.catalog]);
        queryClient.invalidateQueries([queryKeys.bundles]);

        generalNotification("success", t("general.paymentSuccessBundle", { name: name }));
      },
    },
  );
};

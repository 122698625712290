import React, { FC, useRef, useState } from "react";
import FroalaEditor from "react-froala-wysiwyg";
import ReactFroalaEditor from "froala-editor";

// Plugins
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/line_height.min.js";
import "froala-editor/js/plugins/quote.min.js";
import "froala-editor/js/plugins/save.min.js";
// CSS
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { Label } from "@epignosis_llc/gnosis";
import { SerializedStyles } from "@emotion/react";
import { EditorStyles } from "./styles";
import { insertImageBadLInkMessage } from "./helpers";
import { DEFAULT_CONFIGURATION } from "./constants";
import "./config";
import { InsertCustomOptions, ToolbarButton } from "./types";
import i18n from "@utils/i18n";
import { useClickAway } from "ahooks";

export type EditorProps = {
  toolbarButtons: string[] | ToolbarButton;
  id: string;
  model: string;
  placeholderText?: string;
  minHeight?: number;
  label?: string;
  status?: "valid" | "error";
  autofocus?: boolean;
  toolbarInline?: boolean;
  saveInterval?: number;
  required?: boolean;
  insertImagesOptions?: InsertCustomOptions;
  insertSmartTagsOptions?: InsertCustomOptions;
  onChange?: (html: string) => void;
  onBlur?: () => void;
  onSave?: (html: string) => void;
  onCodeViewToggle?: (isActive: boolean) => void;
  onFocus?: (isFocused: boolean) => void;
};

const Editor: FC<EditorProps> = ({
  toolbarButtons,
  id,
  placeholderText = "",
  minHeight = 150,
  label,
  required = false,
  status = "valid",
  model = "",
  autofocus = false,
  toolbarInline = false,
  // Note: It is NOT recommended to use values lower than 2000ms in order to prevent overload
  saveInterval = 0,
  insertImagesOptions,
  insertSmartTagsOptions,
  onChange,
  onBlur,
  onSave,
  onCodeViewToggle,
  onFocus,
}): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const hasError = status === "error";

  if (insertImagesOptions && Object.keys(insertImagesOptions).length > 0) {
    ReactFroalaEditor.DefineIcon("insertImagesDropdownIcon", {
      NAME: "folder-image",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand("insertImagesDropdown", {
      title: "Insert images dropdown",
      type: "dropdown",
      icon: "insertImagesDropdownIcon",
      options: insertImagesOptions,
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function (_: string, val: string) {
        this.image.insert(val, false, {}, null);
      },
    });
  }

  if (insertSmartTagsOptions && Object.keys(insertSmartTagsOptions).length > 0) {
    ReactFroalaEditor.DefineIcon("insertSmartTagsDropdownIcon", {
      NAME: "tag",
      template: "my_fa",
    });
    ReactFroalaEditor.RegisterCommand("insertSmartTagsDropdown", {
      title: "Smart tags",
      type: "dropdown",
      icon: "insertSmartTagsDropdownIcon",
      options: insertSmartTagsOptions,
      undo: true,
      focus: true,
      refreshAfterCallback: true,
      callback: function (_: string, val: string) {
        this.html.insert(val);
      },
    });
  }

  // On click outside fire blur callback
  // Used instead of Froala'a blur event and the reason behind this approach is
  // that image caption is firing blur event as well (probably Froala's bug).
  useClickAway((e) => {
    const target = e.target as HTMLElement;

    // check if the target is a froala element because,
    // there some editor's pop-ups elements that shouldn't trigger blur
    // eg. insert image and image pop-up
    const isFroalaElement = Array.from(target.classList).some((className) =>
      className.startsWith("fr"),
    );

    if (!isFroalaElement) {
      setIsFocused(false);
      onFocus && onFocus(false);
      onBlur && onBlur();
    }
  }, editorRef);

  // editor handled events
  const events = {
    focus: (): void => {
      setIsFocused(true);
      onFocus && onFocus(true);
    },
    "image.error": (error: { code: number }): void => {
      // bad link error
      if (error.code === 1) {
        const editor = editorRef.current;
        const errorHeading = editor?.querySelector(".fr-image-progress-bar-layer h3");

        if (errorHeading) {
          errorHeading.innerHTML = insertImageBadLInkMessage();
        }
      }
    },
    // Will trigger only if saveInterval value is set > 0
    "save.before": function (html: string): void {
      onSave && onSave(html ?? "");
    },
    "commands.after": function (cmd: string): void {
      if (cmd === "html") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isActive = (this as any)?.codeView?.isActive();
        onCodeViewToggle && onCodeViewToggle(isActive);
      }
    },
  };

  // final editor's configuration
  const config = {
    ...DEFAULT_CONFIGURATION,
    toolbarButtons: toolbarButtons,
    placeholderText: placeholderText,
    heightMin: minHeight,
    toolbarInline: toolbarInline,
    direction: i18n.dir(),
    autofocus: autofocus,
    saveInterval: saveInterval,
    events,
  };

  return (
    <div
      id={id}
      ref={editorRef}
      css={(theme): SerializedStyles => EditorStyles(theme, { required, isFocused, hasError })}
    >
      {label && <Label>{label}</Label>}
      <FroalaEditor
        tag="textarea"
        config={config}
        model={model}
        onModelChange={(object: string): void => {
          onChange && onChange(object);
        }}
      />
    </div>
  );
};

export default React.memo(Editor);

import { css } from "@emotion/react";

export const publicCourseContainer = css`
  text-align: center;
  margin-top: 2rem;

  .sign-up-btn {
    margin: 1.5rem 0;
  }

  .social-buttons-wrapper {
    display: flex;
    justify-content: center;
    gap: 0.5rem;

    .social-button {
      height: 2.5rem;
      width: 2.5rem;
      min-width: 2.5rem;
    }
  }
`;

import create from "zustand";
import { themes } from "@styles";
import localStorageKeys from "@constants/localStorageKeys";
import { CustomTheme } from "@styles/themes/defaultBlue";
import { URLS } from "@constants/urls";

export type MainDrawerContent = "none" | "mainMenu" | "profile" | "discussions" | "messages";
type LoginType = "direct" | "ldap";

export type UIState = {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  theme: CustomTheme;
  setTheme: (newTheme: CustomTheme) => void;
  mainNavState: "collapsed" | "expanded";
  mainDrawer: {
    show: boolean;
    content: MainDrawerContent;
  };
  showGamificationDrawer: boolean;
  showOnboardingTour: boolean;
  showSessionExpirationModal: boolean;
  showSignInModal: boolean;
  showSignUpModal: boolean;
  showTermsModal: boolean;
  showRedirectLoading: boolean;
  showUnitSidebar: boolean;
  shouldShowConfirmationModal: boolean;
  setShouldShowConfirmationModal: (show: boolean) => void;
  toggleCollapsedMainNav: () => void;
  getCollapsedMainNav: () => void;
  showMainDrawer: (content: MainDrawerContent) => void;
  hideMainDrawer: () => void;
  toggleGamificationDrawer: () => void;
  setShowOnboardingTour: (show: boolean) => void;
  setShowSessionExpirationModal: (show: boolean) => void;
  setShowSignInModal: (show: boolean) => void;
  setShowSignUpModal: (show: boolean) => void;
  setShowTermsModal: (show: boolean) => void;
  setShowRedirectLoading: (show: boolean) => void;
  getLoginType: () => LoginType;
  setLoginType: (loginType: LoginType) => void;
  toggleUnitSidebar: () => void;
};

const useUIStore = create<UIState>((set, get) => ({
  activeTab: URLS.dashboard,
  setActiveTab: (tab: string): void => {
    set((state) => ({ ...state, activeTab: tab }));
  },
  theme: themes.defaultBlue,
  setTheme: (newTheme: CustomTheme): void => {
    set((state) => ({ ...state, theme: newTheme }));
  },
  mainNavState: "expanded",
  mainDrawer: {
    show: false,
    content: "none",
  },
  showGamificationDrawer: false,
  showOnboardingTour: false,
  showSessionExpirationModal: false,
  showSignInModal: false,
  showSignUpModal: false,
  showTermsModal: false,
  showRedirectLoading: false,
  loginType: "direct",
  showUnitSidebar: true,
  shouldShowConfirmationModal: true,
  setShouldShowConfirmationModal: (show: boolean): void => {
    set((state) => ({ ...state, shouldShowConfirmationModal: !show }));
  },
  toggleCollapsedMainNav: (): void => {
    const newNavState = get().mainNavState === "expanded" ? "collapsed" : "expanded";

    set((state) => ({ ...state, mainNavState: newNavState }));
    localStorage.setItem(localStorageKeys.MAIN_NAV, newNavState);
  },
  getCollapsedMainNav: (): void => {
    const storedNavState = localStorage.getItem(localStorageKeys.MAIN_NAV);

    if (storedNavState) {
      set((state) => ({ ...state, mainNavState: storedNavState as "collapsed" | "expanded" }));
    }
  },
  showMainDrawer: (content): void => {
    set((state) => ({ ...state, mainDrawer: { show: true, content } }));
  },
  hideMainDrawer: (): void => {
    set((state) => ({ ...state, mainDrawer: { show: false, content: "none" } }));
  },
  toggleGamificationDrawer: (): void => {
    const currentGamification = get().showGamificationDrawer;
    set((state) => ({ ...state, showGamificationDrawer: !currentGamification }));
  },
  setShowOnboardingTour: (show: boolean): void => {
    set((state) => ({ ...state, showOnboardingTour: show }));
    localStorage.setItem(localStorageKeys.SHOW_ONBOARDING, JSON.stringify(show));
  },
  setShowSessionExpirationModal: (show: boolean): void => {
    set((state) => ({ ...state, showSessionExpirationModal: show }));
  },
  setShowSignInModal: (show: boolean): void => {
    set((state) => ({ ...state, showSignInModal: show }));
  },
  setShowSignUpModal: (show: boolean): void => {
    set((state) => ({ ...state, showSignUpModal: show }));
  },
  setShowTermsModal: (show: boolean): void => {
    set((state) => ({ ...state, showTermsModal: show }));
  },
  setShowRedirectLoading: (show: boolean): void => {
    set((state) => ({ ...state, showRedirectLoading: show }));
  },
  getLoginType: (): LoginType => {
    const loginType = localStorage.getItem("LOGIN_TYPE");

    if (!loginType) return "direct";
    return JSON.parse(loginType as LoginType);
  },
  setLoginType: (loginType: LoginType): void => {
    set((state) => ({ ...state, loginType }));
    localStorage.setItem("LOGIN_TYPE", JSON.stringify(loginType));
  },
  toggleUnitSidebar: (): void => {
    set((state) => ({ ...state, showUnitSidebar: !state.showUnitSidebar }));
  },
}));

export default useUIStore;

import { t } from "i18next";

const GB = 1000000000;
const MB = 1000000;
const KB = 1000;

const ONE_MINUTE_IN_SECONDS = 60;

// This fn is used to format large numbers
export const formatLargeValues = (value: number): string => {
  let suffix = "";

  if (value > GB) {
    value /= GB;
    suffix = "G";
  } else if (value > MB) {
    value /= MB;
    suffix = "M";
  } else if (value > KB) {
    value /= KB;
    suffix = "K";
  }

  return Math.round(value * 100) / 100 + suffix;
};

// This fn is used to format training time on microstats pane and dashboard widgets
export const formatTrainingTime = (totalTrainingTime: number): string => {
  let trainingEntriesCount = 0;

  const diffInSeconds = totalTrainingTime;

  const years = Math.floor(diffInSeconds / (365 * 24 * 60 * 60));
  const months = Math.floor((diffInSeconds % (365 * 24 * 60 * 60)) / (30 * 24 * 60 * 60));
  const days = Math.floor((diffInSeconds % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
  const hours = Math.floor((diffInSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);

  const formattedEntries = [];

  if (!totalTrainingTime || totalTrainingTime < ONE_MINUTE_IN_SECONDS) {
    //0h 0m
    const emptyTrainingText = `${t("general.hoursShort", { hours: 0 })} ${t(
      "general.minutesShort",
      {
        minutes: 0,
      },
    )}`;

    return emptyTrainingText;
  }

  if (years > 0) {
    formattedEntries.push(t("general.yearsShort", { years: years }));
    trainingEntriesCount++;
  }

  if (months > 0 && trainingEntriesCount < 2) {
    formattedEntries.push(t("general.monthsShort", { months: months }));
    trainingEntriesCount++;
  }

  if (days > 0 && trainingEntriesCount < 2) {
    formattedEntries.push(t("general.daysShort", { days: days }));
    trainingEntriesCount++;
  }

  if (hours > 0 && trainingEntriesCount < 2) {
    formattedEntries.push(t("general.hoursShort", { hours: hours }));
    trainingEntriesCount++;
  }

  if (minutes > 0 && trainingEntriesCount < 2) {
    formattedEntries.push(t("general.minutesShort", { minutes: minutes }));
  }

  return formattedEntries.join(" ");
};

// This fn is used to format training time on tables where the is a column "training_time"
export const formatTimeInterval = (interval: number): string => {
  const seconds = interval % 60;
  const minutes = Math.floor((interval - seconds) / 60) % 60;
  const hours = Math.floor((interval - seconds - minutes * 60) / 3600) % 24;
  const days = Math.floor((interval - hours * 3600 + minutes * 60 + seconds) / (24 * 3600));

  const str = [];

  if (days >= 1) {
    str.push(t("general.daysShort", { days: days }));
  }

  if (hours > 0) {
    str.push(t("general.hoursShort", { hours: hours }));
  }

  if (minutes > 0) {
    str.push(t("general.minutesShort", { minutes: minutes }));
  }

  if (seconds > 0) {
    str.push(t("general.secondsShort", { seconds: seconds }));
  }

  return str.join(" ");
};

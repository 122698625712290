import axios from "axios";
import { logAxiosError } from "./helpers";
import { refreshToken } from "@api/app";
import authService from "@utils/services/AuthService";
import { useUIStore } from "@stores";
import { config, getBaseUrl } from "@config";
import { URLS } from "@constants/urls";

const axiosApiInstance = axios.create();
let isRefreshingToken = false;
const { getState: getUIStoreState } = useUIStore;
const { showSessionExpirationModal, setShowSessionExpirationModal, setShowTermsModal } =
  getUIStoreState();
const logoutErrorsStatuses = [403, 404, 409];

// error that required user logout
const logoutErrors = [
  "forbidden.locked_portal",
  "forbidden.user_not_allowed_to_access_plus",
  "forbidden.user_not_member_in_branch",
  "forbidden.branch_inactive",
  // deleted user errors
  "not_found.user_not_found",
  "conflict.user_not_active",
];

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = authService.getAccessToken();
    const userRole = authService.getDefaultRole();

    // Config Headers
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        "Current-Role": userRole,
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const originalRequest = error?.config;
    const errorResponse = error?.response;
    const responseStatus = errorResponse?.status;
    const tokenExpired = responseStatus === 401 && !originalRequest._retry;
    const isAuthenticated = Boolean(authService.getAccessToken());
    const errorId = errorResponse?.data?._errors?.[0]?.id;

    logAxiosError(error);

    if (isAuthenticated) {
      if (logoutErrorsStatuses.includes(responseStatus) && logoutErrors.includes(errorId)) {
        authService.removeTokens();
        authService.removeRole();
        window.location.href = getBaseUrl();
        return Promise.reject(error);
      }

      // there are empty mandatory custom fields in user's profile
      if (responseStatus === 403 && errorId === "forbidden.empty_custom_fields") {
        if (!window.location.pathname.includes(URLS.user.profile)) {
          window.location.href = config.isDev()
            ? URLS.user.profile
            : `${getBaseUrl()}${URLS.user.profile}`;
        }
        return Promise.reject(error);
      }
    }

    if (!tokenExpired || isRefreshingToken || !isAuthenticated || showSessionExpirationModal) {
      error.shouldNotShowSkeletonError = true;
      return Promise.reject(error);
    }

    // set refresh token flag to true in order to not have multiple refresh token requests run the same time
    isRefreshingToken = true;

    originalRequest._retry = true;
    const oldRefreshToken = authService.getRefreshToken() as string;
    const response = await refreshToken(oldRefreshToken);

    // reset refresh token flag
    isRefreshingToken = false;

    // success token refresh
    if (response && response.status === 200) {
      authService.setTokens(response.data);
      axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.access_token}`;
      return axiosApiInstance(originalRequest);

      // error on token refresh
    } else {
      authService.removeTokens();

      if (!showSessionExpirationModal) {
        setShowSessionExpirationModal(true);
        setShowTermsModal(false);
      }

      return Promise.reject(response);
    }
  },
);

export default axiosApiInstance;

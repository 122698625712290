import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AnnouncementContent from "./AnnouncementContent";
import { useConfigurationStore } from "@stores";
import { useAuth } from "@hooks";
import localStorageKeys from "@constants/localStorageKeys";
import authService from "@utils/services/AuthService";
import { Announcement, ShowAnnouncements } from "types/entities";
import { t } from "i18next";
import { URLS } from "@constants/urls";

type ShownAnnouncements = {
  [key in Announcement["type"]]: Announcement[];
};

const adminAsLearnerAnnouncementPages: string[] = [URLS.dashboard, URLS.user.courses];

const getExternal = (isAuthenticated: boolean, announcements: Announcement[]): Announcement[] => {
  return !isAuthenticated
    ? announcements?.filter((announcement) => announcement.type === "external")
    : [];
};

const getInternal = (
  isAuthenticated: boolean,
  announcements: Announcement[],
  show: boolean,
  type: "admin" | "internal",
): Announcement[] => {
  return isAuthenticated && show
    ? announcements?.filter((announcement) => announcement.type === type)
    : [];
};

const getAnnouncements = (
  isAuthenticated: boolean,
  announcements: Announcement[],
): ShownAnnouncements => {
  const showAnnouncementsString = localStorage.getItem(localStorageKeys.ANNOUNCEMENTS);
  const showAnnouncements: ShowAnnouncements = showAnnouncementsString
    ? JSON.parse(showAnnouncementsString)
    : { internal: true, admin: true };

  const { admin, internal } = showAnnouncements;

  return {
    external: getExternal(isAuthenticated, announcements ?? []),
    admin: getInternal(isAuthenticated, announcements ?? [], admin, "admin"),
    internal: getInternal(isAuthenticated, announcements ?? [], internal, "internal"),
  };
};

const Announcements: FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const announcements = useConfigurationStore((state) => state.announcements);
  const userProfileData = useConfigurationStore((state) => state.userProfileData);
  const defaultRole = authService.getDefaultRole();

  const [shown, setShown] = useState<ShownAnnouncements>(() =>
    getAnnouncements(isAuthenticated, announcements ?? []),
  );

  const { external, admin, internal } = shown;

  // set announcements on admin or external close
  const handleClose = (): void => {
    setShown(getAnnouncements(isAuthenticated, announcements ?? []));
  };

  // set announcements when announcements or isAuthenticated have changed
  useEffect(() => {
    const newAnnouncements = [...(announcements ?? [])];
    const hasAdminRole = userProfileData?.available_roles.includes("administrator");
    const isLearner = defaultRole === "learner";

    // Temporary announcement for admin as learner (in Home and MyCourses pages)
    if (adminAsLearnerAnnouncementPages.includes(location.pathname) && hasAdminRole && isLearner) {
      newAnnouncements.push({
        html: t("general.adminAsLearnerAnnouncement", {
          url: "https://help.talentlms.com/hc/en-us/requests/new",
        }),
        text: t("general.adminAsLearnerAnnouncement", {
          url: "https://help.talentlms.com/hc/en-us/requests/new",
        }),
        type: "admin",
      });
    }

    setShown(getAnnouncements(isAuthenticated, newAnnouncements));
  }, [
    announcements,
    isAuthenticated,
    location.pathname,
    defaultRole,
    userProfileData?.available_roles,
  ]);

  return (
    <>
      {external.length > 0 && <AnnouncementContent announcements={external} type="external" />}
      {admin.length > 0 && (
        <AnnouncementContent announcements={admin} type="admin" onClose={handleClose} />
      )}
      {internal.length > 0 && (
        <AnnouncementContent announcements={internal} type="internal" onClose={handleClose} />
      )}
    </>
  );
};

export default Announcements;

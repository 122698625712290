import React, { FC, useState } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import Video from "./Video/Video";
import Audio from "./Audio/Audio";
import DefaultFooter from "./Footers/DefaultFooter";
import SampleUnitFooter from "./Footers/SampleUnitFooter";
import AssignmentContainer from "./Assignment/AssignmentContainer";
import CompleteWithQuestionFooter from "./Question/components/CompleteWithQuestionFooter";
import CompleteWithQuestion from "./Question/components/CompleteWithQuestion";
import PopupIframe from "./IFrame/PopupIframe";
import ScormContainer from "./Scorm/ScormContainer";
import TestContainer from "./Test/components/TestContainer";
import IltContainer from "./Ilt/IltContainer";
import SurveyContainer from "./Survey/SurveyContainer";
import XAPIContainer from "./Xapi-Cmi5/XAPIContainer";
import Document from "./Document/Document";
import Iframe from "./IFrame/Iframe";
import TalentCraftContainer from "./TalentCraft/TalentCraftContainer";
import { UnitUnsupportedSVG } from "@assets/images";
import { MyUnitRes } from "types/responses";
import { Course, PlayerState, QuestionAnswers, ScormUnit, Section } from "types/entities";

export type UnitContentContainerProps = {
  course: Course;
  units: Section[];
  unitRes: MyUnitRes;
  completeWithQuestion: boolean;
  isPublic?: boolean;
};

const UNITS_WITHOUT_QUESTION = [
  "xapi",
  "cmi5",
  "survey",
  "assignment",
  "scorm",
  "test",
  "ilt",
  "craft",
];

const UnitContentContainer: FC<UnitContentContainerProps> = ({
  course,
  units,
  unitRes,
  completeWithQuestion,
  isPublic = false,
}) => {
  const unit = unitRes._data;
  const { type } = unit;
  const { auto_play = false } = unit.media_options ?? {};

  const [isAnswerWrong, setIsAnswerWrong] = useState(false);
  const [userAnswers, setUserAnswers] = useState<QuestionAnswers>([]);
  const [videoState, setVideoState] = useState<PlayerState>({
    isPlaying: Boolean(auto_play),
    isReady: false,
    duration: null,
  });
  const [audioState, setAudioState] = useState<PlayerState>({
    isPlaying: Boolean(auto_play),
    isReady: false,
    duration: null,
  });

  const unitWithoutQuestion = UNITS_WITHOUT_QUESTION.includes(unit.type);
  const isSampleUnit = unit.sample && !course.availability;
  const isVideoUnit = ["video-youtube", "video-uploaded", "video-vimeo"].includes(type);
  const isAudioUnit = ["audio"].includes(type);
  const playerState = isVideoUnit ? videoState : isAudioUnit ? audioState : undefined;

  const setIsAnswer = (answer: boolean): void => {
    setIsAnswerWrong(answer);
  };

  const setAnswers = (answers: QuestionAnswers): void => {
    setUserAnswers(answers);
  };

  const CompleteWithQuestionComponent = (
    <CompleteWithQuestion
      unit={unit}
      course={course}
      sections={units}
      isAnswerWrong={isAnswerWrong}
      setUserAnswersParent={setAnswers}
    />
  );

  const getUnitContent = (): JSX.Element => {
    switch (unit.type) {
      case "video-youtube":
      case "video-uploaded":
      case "video-vimeo":
        return (
          <article className="unit-wrapper center">
            <Video
              {...unit}
              playerState={videoState}
              onPlay={(): void => setVideoState((state) => ({ ...state, isPlaying: true }))}
              onPause={(): void => setVideoState((state) => ({ ...state, isPlaying: false }))}
              onDurationChange={(duration): void =>
                setVideoState((state) => ({ ...state, duration }))
              }
            >
              {completeWithQuestion && CompleteWithQuestionComponent}
            </Video>
          </article>
        );
      case "audio":
        return (
          <article className="unit-wrapper center">
            <Audio
              {...unit}
              playerState={audioState}
              onPlay={(): void => setAudioState((state) => ({ ...state, isPlaying: true }))}
              onPause={(): void => setAudioState((state) => ({ ...state, isPlaying: false }))}
              onDurationChange={(duration): void =>
                setAudioState((state) => ({ ...state, duration }))
              }
            >
              {completeWithQuestion && CompleteWithQuestionComponent}
            </Audio>
          </article>
        );
      case "document-uploaded":
      case "document-slideshare":
        return (
          <article className="unit-wrapper">
            <Document unit={unit}>{completeWithQuestion && CompleteWithQuestionComponent}</Document>
          </article>
        );
      case "unit": // Todo: Old Unit, we MUST handle all cases
      case "webpage":
      case "iframe":
        return (
          <article className="unit-wrapper">
            <>
              {unit.embed_type === "popup" ? (
                <PopupIframe unit={unit} course={course}>
                  {completeWithQuestion && CompleteWithQuestionComponent}
                </PopupIframe>
              ) : (
                <Iframe {...unit}>{completeWithQuestion && CompleteWithQuestionComponent}</Iframe>
              )}
            </>
          </article>
        );
      default:
        // Todo: log the Unsupported unit to external service
        return (
          <Result
            icon={UnitUnsupportedSVG}
            title={t("test.unsupportedUnit")}
            info={t("test.unitUnderConstruction")}
          />
        );
    }
  };

  const getUnitFooter = (): JSX.Element => {
    // If the unit is sample and the user is not enrolled and the course is not public
    if (isSampleUnit && !isPublic) return <SampleUnitFooter />;

    if (completeWithQuestion)
      return (
        <CompleteWithQuestionFooter
          unit={unit}
          course={course}
          sections={units}
          setIsAnswerWrong={setIsAnswer}
          setUserAnswers={setUserAnswers}
          userAnswers={userAnswers}
          isPublic={isPublic}
        />
      );

    return (
      <DefaultFooter
        unit={unit}
        course={course}
        sections={units}
        playerState={playerState}
        isPublic={isPublic}
      />
    );
  };

  // units that cannot be completed by answering a question
  if (unitWithoutQuestion) {
    switch (unit.type) {
      case "xapi":
      case "cmi5":
        return <XAPIContainer unit={unit} course={course} sections={units} isPublic={isPublic} />;
      case "survey":
        return (
          <SurveyContainer unitRes={unitRes} course={course} sections={units} isPublic={isPublic} />
        );
      case "assignment":
        return (
          <AssignmentContainer
            unit={unitRes?._data}
            course={course}
            sections={units}
            isPublic={isPublic}
          />
        );
      case "scorm":
        return (
          <ScormContainer
            unit={unit as ScormUnit}
            course={course}
            sections={units}
            isPublic={isPublic}
          />
        );
      case "test":
        //TODO: refactor TestContainer similarly to SurveyContainer in order to remove FooterNavigationButtons.tsx
        return (
          <TestContainer unitRes={unitRes} course={course} units={units} isPublic={isPublic} />
        );
      case "ilt":
        return <IltContainer unit={unit} course={course} sections={units} isPublic={isPublic} />;
      case "craft":
        return (
          <TalentCraftContainer unit={unit} course={course} sections={units} isPublic={isPublic} />
        );
      default:
        // Todo: log the Unsupported unit to external service
        return (
          <Result
            icon={UnitUnsupportedSVG}
            title={t("test.unsupportedUnit")}
            info={t("test.unitUnderConstruction")}
          />
        );
    }
  }

  // units that can be completed by answering a question
  return (
    <>
      {getUnitContent()}
      {getUnitFooter()}
    </>
  );
};

export default UnitContentContainer;

import React, { FC, useEffect, useMemo, useState } from "react";
import { equals, indexOf } from "ramda";
import { SingleValue } from "react-select";
import MatchPairs from "./components/MatchPairs";
import { matchPairsContainer } from "./styles";
import { MatchPairsQuestion, QuestionAnswers } from "types/entities";
import { createUserAnswerArray } from "@utils/helpers";

type MatchPairsContainerProps = {
  questionEntry: MatchPairsQuestion;
  userAnswers: QuestionAnswers;
  onAnswersChange: (answers: QuestionAnswers) => void;
};

const initialState = (
  questionEntry: MatchPairsQuestion,
): {
  left: string[];
  right: string[];
  answersPool: string[];
} => ({
  left: questionEntry.answers.pairs.left,
  right: Array(questionEntry.answers.pairs.left.length).fill(""),
  answersPool: questionEntry.answers.pairs.right,
});

const MatchPairsContainer: FC<MatchPairsContainerProps> = ({
  questionEntry,
  onAnswersChange,
  userAnswers,
}) => {
  const [state, setState] = useState(() => ({ ...initialState(questionEntry) }));
  const { left, right, answersPool } = state;

  // move item from pairs back to answers pool
  const moveToAnswersPool = (answer: string, index: number, answerIndex: number): void => {
    if (index !== answerIndex) {
      const newState = { ...state };
      newState.answersPool = [...answersPool, right[answerIndex]];

      const newRight = [...right];
      newRight[index] = "";
      newRight[answerIndex] = answer;
      newState.right = newRight;

      setState(newState);
      onAnswersChange(createUserAnswerArray(left, newRight));
    }
  };

  const handleClear = (index: number): void => {
    const newState = { ...state };
    newState.answersPool = [...answersPool, right[index]];

    const newRight = [...right];
    newRight[index] = "";
    newState.right = newRight;

    setState(newState);
    onAnswersChange(createUserAnswerArray(left, newRight));
  };

  //when selecting an answer remove it from answers pool and add it to answered questions array
  const handleChange = (
    selectedOptions: SingleValue<{ label: string; value: string }>,
    index: number,
  ): void => {
    // clone right pairs array
    const newRight = [...right];

    //remove item from answers pool
    const newAnswersPool = [...answersPool];
    const answersPoolIndex = indexOf(selectedOptions?.value, answersPool);
    newAnswersPool.splice(answersPoolIndex, 1);

    const rightPairValue = newRight[index];

    // add item to right pair array
    newRight[index] = answersPool[answersPoolIndex];

    //set new state
    const newState = { ...state };
    newState.right = newRight;
    newState.answersPool = newAnswersPool;
    setState(newState);

    // push previous value from selected pair back to the pool
    if (rightPairValue) {
      newAnswersPool.push(rightPairValue);
    }

    onAnswersChange(createUserAnswerArray(left, newRight));
  };

  useEffect(() => {
    if (userAnswers.length > 0) {
      const userAnswersRight = Object.keys(userAnswers).map((key) => userAnswers[key][1]);
      const userAnswersLeft = Object.keys(userAnswers).map((key) => userAnswers[key][0]);

      //this code should run only one time to set the userAnswers to the component state
      if (!equals(userAnswersRight, right)) {
        const newState = { ...state };
        newState.answersPool = [];

        newState.right = left.reduce((arr, leftPair): string[] => {
          const index = userAnswersLeft.indexOf(leftPair);
          return arr.concat(userAnswersRight[index]);
        }, [] as string[]);

        setState(newState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAnswers]);

  //when rendering a question update init state
  useEffect(() => {
    setState({ ...initialState(questionEntry) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionEntry.id]);

  const options = useMemo(() => {
    return answersPool
      .filter((item) => item)
      .map((item) => {
        return {
          label: item,
          value: item,
        };
      });
  }, [answersPool]);

  return (
    <form css={matchPairsContainer}>
      <div className="answers-content-container">
        {left.map((leftPair, index) => {
          return (
            <MatchPairs
              key={`${index}-${leftPair}`}
              index={index}
              leftPair={leftPair}
              answeredQuestions={right}
              options={options}
              handleChange={handleChange}
              handleClear={handleClear}
              moveToAnswersPool={moveToAnswersPool}
            />
          );
        })}
      </div>
    </form>
  );
};

export default MatchPairsContainer;

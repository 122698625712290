import React, { FC } from "react";
import { Result } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { ProcessingFailedSVG } from "@assets/images";
import { FileResultStyles } from "./styles";

export type FileFailedProps = React.HTMLAttributes<HTMLElement> & {
  info?: string;
  size?: "md" | "lg";
};

const FileFailed: FC<FileFailedProps> = ({ info, size = "md", ...rest }): JSX.Element => {
  return (
    <Result
      title={t("errors.units.fileProcessingFailed")}
      icon={ProcessingFailedSVG}
      info={info}
      size={size}
      css={FileResultStyles}
      {...rest}
    />
  );
};

export default FileFailed;

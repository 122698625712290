import React, { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import { t } from "i18next";
import QuestionsInfo from "../QuestionsInfo";
import FooterNavigationButtons from "../FooterNavigationButtons";
import TestIntroHeader from "../TestIntroHeader";
import FooterWrapper from "@components/Units/Footers/components/FooterWrapper";
import { postUnitStart } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { MyUnitRes } from "types/responses";
import { TestUnit } from "types/entities";
import { FooterButton } from "@components/ReusableComponents";

type PlainSplashScreenProps = {
  unitRes: MyUnitRes;
  isPublic?: boolean;
  children?: never;
};

const PlainSplashScreen: FC<PlainSplashScreenProps> = ({ unitRes, isPublic = false }) => {
  const queryClient = useQueryClient();
  const unit = unitRes._data as TestUnit;
  const testWithZeroQuestions = unitRes._meta.total_questions === 0;
  const unitId = unit.id.toString();
  const totalQuestions = unitRes._meta.total_questions;

  const { mutate, isLoading } = useMutation(
    [queryKeys.unitStart, { unitId: unitId }],
    () => postUnitStart(unitId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.unit, unitId]);
      },
    },
  );

  return (
    <>
      <article className="unit-wrapper">
        <div className="unit-container">
          <TestIntroHeader unitRes={unitRes} />
        </div>
      </article>
      <QuestionsInfo totalQuestions={totalQuestions} />
      <FooterWrapper>
        <FooterNavigationButtons isPublic={isPublic}>
          <FooterButton
            unit={unit}
            isLoading={isLoading}
            onClick={(): void => mutate()}
            buttonText={t("test.startTest")}
            disabled={testWithZeroQuestions}
          />
        </FooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default PlainSplashScreen;

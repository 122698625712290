import React, { FC } from "react";
import SampleUnitFooter from "../Footers/SampleUnitFooter";
import Assignment from "./Assignment";
import { Course, MyUnit, Section } from "types/entities";
import { AssignmentFooter } from "@components";

type AssignmentContainerProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isPublic?: boolean;
};

const AssignmentContainer: FC<AssignmentContainerProps> = (props) => {
  const { unit, course, isPublic = false } = props;
  const isSampleUnit = unit.sample && !course.availability;

  return (
    <>
      <article className="unit-wrapper">
        <Assignment unit={unit} isSampleUnit={isSampleUnit} isPublic={isPublic} />
      </article>
      {isSampleUnit ? <SampleUnitFooter /> : <AssignmentFooter {...props} isPublic={isPublic} />}
    </>
  );
};

export default AssignmentContainer;

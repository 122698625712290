import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { availabilityDelayInDaysHoursMinutes } from "@utils/helpers";
import { TypographyLevels } from "@styles/utils";

type AvailabilityTextProps = {
  delay: number;
  fontSize?: TypographyLevels;
  weight?: "400" | "700";
  as?: "div" | "span";
};

const AvailabilityText: FC<AvailabilityTextProps> = ({
  delay,
  fontSize = "2xs",
  weight = "400",
  as = "div",
}) => {
  return (
    <Text fontSize={fontSize} weight={weight} as={as} className="availability-text">
      {t("general.availableIn")}{" "}
      <Text fontSize={fontSize} weight="700">
        {availabilityDelayInDaysHoursMinutes(delay)}
      </Text>
    </Text>
  );
};

export default AvailabilityText;

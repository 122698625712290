import { Button, Text } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { tableHeaderContainer } from "./styles";

type TableHeaderProps = {
  title: string;
  backLink: string;
  secondaryActions?: JSX.Element;
};

const TableHeader: FC<TableHeaderProps> = (props) => {
  const { title, backLink, secondaryActions } = props;

  return (
    <header css={tableHeaderContainer}>
      <div className="title-wrapper">
        <Button color="secondary" variant="link" as={Link} to={backLink} noGutters>
          <ArrowLeftSVG height={28} />
        </Button>
        <Text fontSize="2xl" weight="700">
          {title}
        </Text>
      </div>

      {secondaryActions && <div className="secondary-actions-wrapper">{secondaryActions}</div>}
    </header>
  );
};

export default TableHeader;

import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import { completedSplashScreenContainer } from "./styles";
import { formatUtcDate } from "@utils/helpers";
import { TestSuccessSVG, TestResultsSVG } from "@assets/images";
import { getUnitResults } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import { UnitRouterParams } from "@views/Unit/Unit";
import { MyUnitRes } from "types/responses";
import { URLS } from "@constants/urls";
import { SurveyUnit } from "types/entities/Survey";
import { Link } from "@components/ReusableComponents";
import { Skeletons } from "@components";

type CompletedSplashScreenProps = {
  unitRes: MyUnitRes;
  isPublic?: boolean;
  children?: never;
};

const CompletedSplashScreen: FC<CompletedSplashScreenProps> = ({ unitRes, isPublic = false }) => {
  const { xs } = useResponsive();
  const { courseId } = useParams() as UnitRouterParams;
  const unit = unitRes._data as SurveyUnit;
  const isUnitCompleted = ["completed", "failed"].some(
    (status) => status === unit?.progress?.status,
  );

  const {
    data: unitResultsRes,
    status: unitResultsResStatus,
    error: unitResultsResError,
  } = useQuery(
    [queryKeys.unitResults, unit.id.toString()],
    () => getUnitResults(unit.id.toString()),
    {
      refetchOnWindowFocus: false,
      enabled: isUnitCompleted,
    },
  );

  const results = unitResultsRes?._data;

  const viewResultsUrl =
    results?.questions && results.questions.length > 0
      ? isPublic
        ? URLS.externalCatalog.publicUnit.createUnitResultsLink({
            courseId,
            unitId: unit.id.toString(),
          })
        : URLS.user.createUnitResultsLink({
            courseId,
            unitId: unit.id.toString(),
          })
      : "#";

  return (
    <Skeletons.Loader status={unitResultsResStatus} error={unitResultsResError}>
      {results && (
        <div css={completedSplashScreenContainer}>
          {xs && <TestSuccessSVG className="image-banner" />}

          {results.completion_date && (
            <section className="results-info">
              <Text fontSize="sm" as="div" className="completion-date results-info-item">
                {t("survey.youCompletedThisSurveyOn", {
                  dateOfCompletion: formatUtcDate(results.completion_date),
                  timeOfCompletion: formatUtcDate(results.completion_date, "time"),
                })}
              </Text>
            </section>
          )}

          {unit.survey_options?.show_answers && results.questions?.length && (
            <div className="icon-container">
              <TestResultsSVG height={32} data-testid="badge" />
              <Text fontSize="sm" as="div">
                <Link to={viewResultsUrl}>{t("messages.view")}</Link>
              </Text>
              <Text fontSize="sm" as="div">
                {t("survey.surveyResults")}
              </Text>
            </div>
          )}
        </div>
      )}
    </Skeletons.Loader>
  );
};

export default CompletedSplashScreen;

import { palletColorsGenerator, getPlusTheme, getGnosisTheme } from "../utils";
import { COLORS } from "../constants";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#232323",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultBlack = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultBlack;

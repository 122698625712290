import React, { FC } from "react";
import { Button } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { Answer } from "../Assignment";
import ReplyOptions from "./ReplyOptions";
import { replyOptionsContainer } from "./styles";

type PreviewModifySubmittedAnswerProps = {
  showOptions: boolean;
  setShowOptions: (arg: boolean) => void;
  setSelectAnswerType: (arg: Answer) => void;
};

const PreviewModifySubmittedAnswer: FC<PreviewModifySubmittedAnswerProps> = ({
  showOptions,
  setShowOptions,
  setSelectAnswerType,
}) => {
  return (
    <div className="submitted-answer">
      <div css={replyOptionsContainer}>
        <div className="reply-option">
          {!showOptions && (
            <Button onClick={(): void => setShowOptions(true)}>
              {t("assignment.modifyAnswer")}
            </Button>
          )}

          {showOptions && (
            <div className="modify-answer-options-wrapper">
              <ReplyOptions onSelectAnswer={setSelectAnswerType} />
              <div>
                <Button
                  variant="link"
                  className="cancel-btn"
                  aria-label={t("general.delete")}
                  onClick={(): void => setShowOptions(false)}
                  title={t("general.delete")}
                >
                  {t("general.cancel")}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewModifySubmittedAnswer;

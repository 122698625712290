import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Button, Loader, Text } from "@epignosis_llc/gnosis";
import {
  BadgeIconSVG,
  FolderSVG,
  SettingsSVG,
  GroupsSVG,
  PaletteSVG,
} from "@epignosis_llc/gnosis/icons";
import { useQuery } from "react-query";
import { t } from "i18next";
import { profileContainer } from "./styles";
import BranchesSelect from "./BranchesSelect";
import { URLS } from "@constants/urls";
import { useConfigurationStore, useUIStore, useUserPreferencesStore } from "@stores";
import reports from "@utils/permissions/reports";
import permissions from "@utils/permissions";
import { buildApiBranchesSearchQuery } from "@utils/helpers/url";
import { getMyBranches } from "@api/user";
import queryKeys from "@constants/queryKeys";
import { Skeletons } from "@components";
import { useLogout } from "@hooks";
import { CertificateWithViewboxSVG, CreditCardSVG } from "@assets/icons";

const defaultState = {
  pagination: { number: 1, size: 1000 },
  sorting: ["name"],
  search: "",
};

const Profile: FC = () => {
  const { hideMainDrawer } = useUIStore((state) => state);
  const { handleLogOut } = useLogout();
  const useUserStore = useUserPreferencesStore();
  const { resetSettings } = useUserStore() ?? {};
  const { gamificationSettings, userStatistics, userProfileData } = useConfigurationStore();
  const { certificates, badges, groups, files } = userStatistics ?? {};
  const { total_invoices = 0 } = userProfileData ?? {};

  const { canAccessProfile } = permissions.profilePermissions;
  const { canAccessGamification } = permissions.gamificationPermissions;
  const { canAccessAccount, canUpdateAccount } = permissions.accountPermissions;
  const allowAccessMyInfo = canAccessProfile();
  const allowAccessAccount = canAccessAccount();
  const allowUpdateAccount = canUpdateAccount();

  const searchQuery = buildApiBranchesSearchQuery(defaultState);
  const {
    data: branches,
    status: branchesStatus,
    error: branchesError,
  } = useQuery([queryKeys.myBranches, searchQuery], () => getMyBranches(searchQuery), {
    select: (branches) => branches._data,
  });

  const drawerClick = (): void => {
    hideMainDrawer();
    // Reset userPreference for table states
    resetSettings();
  };

  return (
    <article css={profileContainer}>
      <Skeletons.Loader
        status={branchesStatus}
        error={branchesError}
        loader={
          <section>
            <Loader />
          </section>
        }
      >
        {branches && branches.length > 0 && <BranchesSelect branches={branches} />}
      </Skeletons.Loader>

      <section>
        <ul>
          {allowAccessMyInfo && (
            <li key="settings">
              <Link to={URLS.user.profile} onClick={drawerClick}>
                <SettingsSVG height={32} /> <Text fontSize="md">{t("myProfile.myProfile")}</Text>
              </Link>
            </li>
          )}
          {reports.canAccessLearnerReports() && Boolean(certificates?.total) && (
            <li key="certificates">
              <Link to={URLS.user.certificates} onClick={drawerClick}>
                <CertificateWithViewboxSVG height={32} />
                <Text fontSize="md">{t("myProfile.myCertificates")}</Text>
              </Link>
            </li>
          )}
          {Boolean(
            canAccessGamification() &&
              gamificationSettings?.badges?.enabled &&
              Boolean(badges?.total),
          ) && (
            <li key="badges" onClick={drawerClick}>
              <Link to={URLS.user.badges}>
                <BadgeIconSVG height={32} /> <Text fontSize="md">{t("myProfile.myBadges")}</Text>
              </Link>
            </li>
          )}
          {allowAccessMyInfo && Boolean(groups?.total) && (
            <li key="groups">
              <Link to={URLS.user.groups} onClick={drawerClick}>
                <GroupsSVG height={32} /> <Text fontSize="md">{t("myProfile.myGroups")}</Text>
              </Link>
            </li>
          )}
          {allowAccessMyInfo && Boolean(files?.total) && (
            <li key="files">
              <Link to={URLS.user.files} onClick={drawerClick}>
                <FolderSVG height={32} /> <Text fontSize="md">{t("myProfile.myFiles")}</Text>
              </Link>
            </li>
          )}

          {allowAccessMyInfo && Boolean(total_invoices) && (
            <li key="payments">
              <Link to={URLS.user.payments} onClick={drawerClick}>
                <CreditCardSVG height={32} /> <Text fontSize="md">{t("myProfile.myPayments")}</Text>
              </Link>
            </li>
          )}
        </ul>
      </section>

      {allowAccessAccount && allowUpdateAccount && (
        <section>
          <ul>
            <li key="customizeTheme">
              <Link to={URLS.user.customizeTheming} onClick={drawerClick}>
                <PaletteSVG height={32} /> <Text fontSize="md">{t("themes.customizeTheming")}</Text>
              </Link>
            </li>
          </ul>
        </section>
      )}

      <footer>
        <Button onClick={handleLogOut}>{t("general.logOut")}</Button>
      </footer>
    </article>
  );
};

export default Profile;

import React, { FC } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { useResponsive } from "ahooks";
import { t } from "i18next";
import { container } from "./styles";
import { Page } from "@components";
import { URLS } from "@constants/urls";
import { Link } from "@components/ReusableComponents";
import { images } from "@constants/assets";

const NotFound: FC = () => {
  const { md } = useResponsive();
  const imageHeight = md ? 400 : 200;

  return (
    <Page pageTitle={t("general.pageNotExist")} siteNamePrefix={false}>
      <div css={container}>
        <img src={images.errors.notFound} height={imageHeight} alt="not found error" />
        <div className="error-msg">
          <Text fontSize={md ? "xl" : "md"} as="div">
            {t("general.pageNotExist")}
          </Text>
          <Link to={URLS.dashboard}>
            <Text fontSize={md ? "xl" : "md"} as="div">
              {t("general.returnToHomepage")}
            </Text>
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default NotFound;

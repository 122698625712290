import Color from "color";
import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  backgroundColor: string;
  color: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  backgroundColor: Color(colors.primary.lightest).alpha(0.25).string(),
  color: colors.black,
});

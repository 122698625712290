import { css } from "@emotion/react";

export const modalFooter = css`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`;

export const modalBody = () => css`
  overflow-y: auto;
  max-height: 20rem;

  .subject-text {
    word-break: break-word;
    margin-bottom: 1.3rem;
  }

  .dontaskagain-checkbox {
    margin-top: 1.25rem;

    div {
      padding-block: 0;
    }
  }
`;

export const externalModalBody = ({ payments }) => css`
  display: flex;
  align-items: flex-start;

  .course-img,
  .bundle-img {
    max-width: 55px;
    margin-inline-end: 0.75rem;
  }

  .bundle-img {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 5px;
    background: ${payments.bundleBackground};
    padding: 0.5rem;
  }

  .course-img {
    border-radius: 3px;
  }
`;

export const invoiceModalBody = ({ payments }) => css`
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: ${payments.invoiceModal.headerBackground};
    border-radius: 5px;

    .logo {
      height: 50px;
    }
  }

  .invoice-info {
    margin-top: 1rem;

    div {
      margin-bottom: 0.5rem;
    }
  }

  .info-table {
    margin-top: 1rem;

    table {
      width: 100%;
      border-collapse: collapse;

      tbody {
        tr {
          border-top: 1px solid ${payments.invoiceModal.borderColor};
        }
      }
    }

    th {
      text-align: start;
      padding: 0.5rem 0;
    }

    td {
      padding: 0.5rem 0;
      text-align: center;
    }

    .description-data,
    .discount-data {
      text-align: start;
    }

    .description-header {
      width: 50%;
    }

    .price-header,
    .date-header {
      width: 25%;
      text-align: center;
    }
  }

  .final-price,
  .payment-status {
    display: flex;

    .title {
      width: 75%;
      text-align: end;
    }

    .info {
      width: 25%;
      text-align: center;
    }

    .paid-container {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-inline-start: 0.25rem;
      }
    }
  }

  .final-price {
    margin-top: 2rem;
  }
`;

export const confirmationModalWithDateContent = () => css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;

  .expiration-date-input-wrapper {
    width: 100%;
  }
`;

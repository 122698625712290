import React, { FC } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { ArrowRightSVG, ArrowLeftSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { useQueryClient } from "react-query";
import FooterWrapper from "./components/FooterWrapper";
import UnitFooterNavigationButtons from "./components/UnitFooterNavigationButtons";
import AvailabilityTimeLimitBar from "./components/AvailabilityTimeLimitBar";
import { footerInfoTextContainer } from "./styles";
import queryKeys from "@constants/queryKeys";
import { Course, MyUnit, Section } from "types/entities";
import permissions from "@utils/permissions";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  goToNextUnitLink,
  isContinueButtonDisabled,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import { Link } from "@components/ReusableComponents";
import i18n from "@utils/i18n";

type XAPIFooterProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit;
  isPublic?: boolean;
};

const XAPIFooter: FC<XAPIFooterProps> = ({
  course,
  sections: sections,
  unit,
  isPublic = false,
}) => {
  const isUnitCompleted = unit?.progress?.status === "completed";
  const isUnitFailed = unit?.progress?.status === "failed";
  const flatUnits = sections ? getFlatUnits(sections) : [];
  const { nextUnit } = getPrevAndNextUnits(flatUnits, unit.id) || {};
  const isNextUnitAvailable = !unitHasDelayedAvailability(nextUnit?.availability ?? null);
  const isContinueButtonTraversalDisabled =
    course?.rules.traversal === "sequential"
      ? unit?.progress?.status !== "completed"
      : !nextUnit && course.progress?.completion_status !== "completed";
  const continueButtonDisabled = isContinueButtonDisabled(
    nextUnit,
    isContinueButtonTraversalDisabled,
    course.progress?.completion_status,
    unit.progress?.status,
  );
  const nextUnitLink = goToNextUnitLink(
    isContinueButtonTraversalDisabled,
    nextUnit,
    course,
    isPublic,
  );

  const queryClient = useQueryClient();
  const courseId = course?.id.toString();
  const unitId = unit?.id.toString();

  // permissions related
  const { canCompleteCourseUnits } = permissions.coursesPermissions;
  const allowCourseUnitsComplete = isPublic ? true : canCompleteCourseUnits();
  const completedWithnoPermission = !allowCourseUnitsComplete && isUnitCompleted;
  const isRtl = i18n.dir() === "rtl";

  const refetchData = (): void => {
    if (!isUnitCompleted) {
      queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
      queryClient.invalidateQueries([queryKeys.units, courseId]);
      queryClient.invalidateQueries([queryKeys.unit, unitId]);
    }
  };

  return (
    <>
      {/* if the next unit has delayed availability */}
      {!isNextUnitAvailable && isUnitCompleted && <AvailabilityTimeLimitBar nextUnit={nextUnit} />}

      {!isUnitCompleted && (
        <div css={footerInfoTextContainer}>
          <Button variant="link" noGutters onClick={refetchData}>
            {t("general.checkStatusWithThisUnit")}
          </Button>
          <Text fontSize="sm" as="div" className="info-text">
            {isUnitFailed ? t("general.notPassedTryAgain") : t("general.pendingUnitCompletion")}
          </Text>
        </div>
      )}

      <FooterWrapper>
        <UnitFooterNavigationButtons
          course={course}
          sections={sections}
          unit={unit}
          isPublic={isPublic}
        >
          {(allowCourseUnitsComplete || completedWithnoPermission) && (
            <Button
              type="button"
              as={Link}
              disabled={continueButtonDisabled}
              to={nextUnitLink}
              iconAfter={isRtl ? ArrowLeftSVG : ArrowRightSVG}
              className="btn-complete-wrapper"
            >
              {t("general.continue")}
            </Button>
          )}
        </UnitFooterNavigationButtons>
      </FooterWrapper>
    </>
  );
};

export default XAPIFooter;

import { yup } from "./validation";

export const ThemeValidationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required()
    .max(80)
    .matches(/^(([a-zA-Z\d][a-zA-Z\d ]{0,78}[a-zA-Z\d])|[a-zA-Z\d])$/),
  primary_color: yup
    .string()
    .trim()
    .required()
    .max(7)
    .matches(/^#[a-fA-F\d]{6}$/),
});

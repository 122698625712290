import React, { FC, useEffect, useState } from "react";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import { t } from "i18next";
import { COURSE_FILES_DEFAULT_STATE, toolbarButtons, toolbarButtonsMinimal } from "./constants";
import { Editor } from "@components";
import { InsertCustomOptions } from "@components/FormElements/Editor/types";
import { buildPaginatedSearchQuery } from "@utils/helpers";
import { useQuery } from "react-query";
import queryKeys from "@constants/queryKeys";
import { getCourseFiles } from "@api/courses";
import { Course, CourseFile } from "types/entities";
import { getUnitSmartTags } from "@views/CourseEdit/api";

const DEFAULT_SAVE_INTERVAL = 10000;

type EditableContentProps = {
  course: Course;
  id: string;
  initialContent: string;
  placeholder?: string;
  canEdit: boolean;
  saveInterval?: number;
  isMinimalVersion?: boolean;
  onChange?: (newContent: string) => void;
  onContentSave?: (newContent: string) => void;
};

type courseFileMapping = {
  [key: string]: string;
};

// Return previewable version of provided content
// Find all file ids in content and replace them with the file's url
const builtContentForPreview = (content: string, courseFiles: CourseFile[]): string => {
  if (courseFiles.length === 0) return content;
  if (!content) return "";

  const regex = /\[File:#([0-9]{1,})#\]/gi;
  // Find all occurrences of files in the given
  const filesOccurrences: string[] = content.match(regex) ?? [];

  // Array with all files found in content
  const filesMappingObj = filesOccurrences.reduce((object, contentFile) => {
    const fileId = contentFile.match(/#(.*?)#/)?.[1];
    const courseFile = courseFiles.find(({ id }) => id === Number(fileId));

    if (!courseFile) return object;

    const { id, url } = courseFile;
    return { ...object, [id]: url };
  }, {} as courseFileMapping);

  // replace all files occurrences in content with their url
  const newContent = content
    .split(regex)
    .map((str) => {
      const fileId = Number(str);
      return !fileId ? str : filesMappingObj[fileId];
    })
    .join("");

  return newContent;
};

// Return savable version of provided content
// Find all file urls in content and replace them with the file's id
const builtContentForSave = (content: string, courseFiles: CourseFile[]): string => {
  if (courseFiles.length === 0) return content;
  if (!content) return "";

  // create element and append current content
  const newDiv = document.createElement("div");
  newDiv.innerHTML = content;

  // find all images in the created element
  const images = newDiv.querySelectorAll("img");

  // replace course images url with file id
  images.forEach((image) => {
    const imageUrl = image.src;
    const courseFile = courseFiles.find(({ url }) => url === imageUrl);

    if (courseFile) {
      image.src = `[File:#${courseFile.id}#]`;
    }
  });

  const newContent = newDiv.innerHTML;
  newDiv.remove();

  return newContent;
};

const EditableContent: FC<EditableContentProps> = ({
  course,
  id,
  initialContent,
  placeholder = t("unitEdit.addDescription"),
  canEdit,
  saveInterval = DEFAULT_SAVE_INTERVAL,
  isMinimalVersion = false,
  onChange,
  onContentSave,
}) => {
  const { policies } = course;
  const { can_view_course_files = false, can_update_content = false } = policies ?? {};
  const courseId = course.id.toString();
  const searchQuery = buildPaginatedSearchQuery(COURSE_FILES_DEFAULT_STATE);

  const { data: filesRes } = useQuery(
    [queryKeys.courses.images, courseId],
    () => getCourseFiles(courseId, searchQuery),
    { enabled: can_view_course_files },
  );

  const courseImages = filesRes?._data ?? [];
  const insertImagesOptions: InsertCustomOptions = courseImages.reduce(
    (object, { url, name }) => ({ ...object, [url]: name }),
    {},
  );

  const { data: smartTags = [] } = useQuery([queryKeys.courses.unitSmartTags], getUnitSmartTags, {
    select: (smartTagsRes) => smartTagsRes._data ?? [],
    enabled: can_update_content,
  });

  const insertSmartTagsOptions: InsertCustomOptions = smartTags.reduce(
    (object, { value, label }) => ({ ...object, [value]: label }),
    {},
  );

  const [previewContent, setPreviewContent] = useState<string>(() =>
    builtContentForPreview(initialContent, courseImages),
  );
  const [saveContent, setSaveContent] = useState<string>(initialContent);
  const [isCodeViewActive, setIsCodeViewActive] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleContentSave = (): void => {
    if (isFocused) onContentSave && onContentSave(saveContent);
  };

  const handleCodeViewChange = (content: string): void => {
    const newPreviewContent = builtContentForPreview(content, courseImages);
    setPreviewContent(newPreviewContent);
    setSaveContent(content);
    onChange && onChange(content);
  };

  const handleTextViewChange = (content: string): void => {
    setPreviewContent(content);
    const newSaveContent = builtContentForSave(content, courseImages);
    setSaveContent(newSaveContent);
    onChange && onChange(newSaveContent);
  };

  // set preview content on image change
  useEffect(() => {
    if (courseImages.length > 0) {
      setPreviewContent(builtContentForPreview(initialContent, courseImages));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseImages]);

  return (
    <section className="editable-content">
      {!canEdit ? (
        <FroalaEditorView model={previewContent || placeholder} />
      ) : (
        <Editor
          toolbarButtons={isMinimalVersion ? toolbarButtonsMinimal : toolbarButtons}
          id={id}
          model={isCodeViewActive ? saveContent : previewContent}
          placeholderText={placeholder}
          toolbarInline
          minHeight={0}
          saveInterval={saveInterval}
          insertImagesOptions={insertImagesOptions}
          insertSmartTagsOptions={insertSmartTagsOptions}
          onChange={isCodeViewActive ? handleCodeViewChange : handleTextViewChange}
          onBlur={handleContentSave}
          onSave={handleContentSave}
          onCodeViewToggle={setIsCodeViewActive}
          onFocus={setIsFocused}
        />
      )}
    </section>
  );
};

export default React.memo(EditableContent);

import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import {
  arAE,
  azAZ,
  bsBA,
  caES,
  csCZ,
  daDK,
  deDE,
  elGR,
  enUS,
  esES,
  etEE,
  faIR,
  fiFI,
  frFR,
  heIL,
  hiIN,
  hrHR,
  huHU,
  hyAM,
  idID,
  itIT,
  jaJP,
  kaGE,
  koKR,
  ltLT,
  lvLV,
  mnMN,
  msMY,
  nbNO,
  nlNL,
  plPL,
  ptBR,
  ptPT,
  roRO,
  ruRU,
  skSK,
  slSI,
  srRS,
  svSE,
  thTH,
  trTR,
  ukUA,
  viVN,
  zhCN,
  zhTW,
} from "@translations";
import { config } from "@config";

export const resources = {
  "ar-AE": { translation: arAE },
  "az-AZ": { translation: azAZ },
  "bs-BA": { translation: bsBA },
  "ca-ES": { translation: caES },
  "cs-CZ": { translation: csCZ },
  "da-DK": { translation: daDK },
  "de-DE": { translation: deDE },
  "el-GR": { translation: elGR },
  "en-US": { translation: enUS },
  "es-ES": { translation: esES },
  "et-EE": { translation: etEE },
  "fa-IR": { translation: faIR },
  "fi-FI": { translation: fiFI },
  "fr-FR": { translation: frFR },
  "he-IL": { translation: heIL },
  "hi-IN": { translation: hiIN },
  "hr-HR": { translation: hrHR },
  "hu-HU": { translation: huHU },
  "hy-AM": { translation: hyAM },
  "id-ID": { translation: idID },
  "it-IT": { translation: itIT },
  "ja-JP": { translation: jaJP },
  "ka-GE": { translation: kaGE },
  "ko-KR": { translation: koKR },
  "lt-LT": { translation: ltLT },
  "lv-LV": { translation: lvLV },
  "mn-MN": { translation: mnMN },
  "ms-MY": { translation: msMY },
  "nb-NO": { translation: nbNO },
  "nl-NL": { translation: nlNL },
  "pl-PL": { translation: plPL },
  "pt-BR": { translation: ptBR },
  "pt-PT": { translation: ptPT },
  "ro-RO": { translation: roRO },
  "ru-RU": { translation: ruRU },
  "sk-SK": { translation: skSK },
  "sl-SI": { translation: slSI },
  "sr-RS": { translation: srRS },
  "sv-SE": { translation: svSE },
  "th-TH": { translation: thTH },
  "tr-TR": { translation: trTR },
  "uk-UA": { translation: ukUA },
  "vi-VN": { translation: viVN },
  "zh-CN": { translation: zhCN },
  "zh-TW": { translation: zhTW },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: config.isDev() ? true : false,
    lng: "en-US",
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

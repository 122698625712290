import { PalletThemeColors, hexToRGBA } from "@styles/utils";

type ThemeObjectReturn = {
  borderBottomColor: string;
  rowBackgroundColor: string;
  rowHoverColor: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  borderBottomColor: colors.secondary.base,
  rowBackgroundColor: colors.secondary.lighter,
  rowHoverColor: hexToRGBA(colors.primary.lightest, 0.25),
});

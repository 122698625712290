import { getGnosisTheme, hexToRGBA, palletColorsGenerator } from "../utils";
import { COLORS } from "../constants";
import Color from "color";
import {
  alert,
  badge,
  breadcrumb,
  button,
  formElements,
  card,
  chip,
  drawer,
  dropdown,
  loader,
  modal,
  progressBar,
  result,
  sidebar,
  tabs,
  table,
  tag,
  tooltip,
  pagination,
  scrollbar,
} from "./config";

const colors = palletColorsGenerator(COLORS);

/**
 * Introduce new theme used only in Plus
 */
const plusTheme = {
  accordion: {
    backgroundColor: colors.white,
    shadowColor: colors.secondary.light,
    disabledColor: colors.secondary.base,
  },
  achievementsStats: {
    scrollbarBackgroundColor: colors.secondary.base,
    iconBackground: colors.secondary.lighter,
    certificateHoverBackground: colors.white,
    dateColor: colors.black,
    buttonBackgroundColor: hexToRGBA(colors.primary.lightest, 0.25),
    iconColor: colors.black,
    dateExpiredColor: colors.red.base,
    icon: {
      color: colors.black,
      hover: colors.primary.base,
    },
  },
  actionDrawer: {
    border: colors.secondary.lighter,
  },
  announcements: {
    internal: {
      background: colors.secondary.lighter,
    },
    external: {
      background: colors.white,
    },
    admin: {
      background: hexToRGBA(colors.primary.lightest, 0.25),
    },
    color: colors.black,
    borderColor: colors.secondary.base,
  },
  autocompleteInput: {
    background: colors.secondary.lighter,
    backgroundWhite: colors.white,
    backgroundHover: colors.secondary.lighter,
    border: colors.secondary.lighter,
    blueColor: colors.blue.base,
    primaryColor: colors.primary.base,
    tagColor: colors.secondary.lighter,
    textColor: colors.black,
    borderColor: colors.secondary.lighter,
    disabledBorder: colors.secondary.base,
    errorBorderColor: colors.red.base,
  },
  availabilityTag: {
    backgroundColor: colors.secondary.lighter,
    textColor: colors.black,
  },
  avatar: {
    background: colors.primary.base,
  },
  branches: {
    info: {
      backgroundColor: colors.secondary.base,
      textColor: colors.white,
    },
  },
  colors: {
    ...colors,
  },
  calendar: {
    primaryColor: hexToRGBA(colors.primary.base, 0.3),
    eventColor: colors.primary.base,
    pastEventColor: colors.secondary.light,
    pastEventTextColor: colors.black,
    pastDayColor: colors.secondary.lighter,
    modal: {
      passwordBorder: colors.secondary.light,
      passwordShadow: hexToRGBA(colors.secondary.light, 0.3),
    },
  },
  carousel: {
    dot: {
      activeBackground: colors.primary.light,
      inactiveBackground: colors.secondary.base,
    },
  },
  catalog: {
    activeButton: {
      background: colors.primary.light,
      borderColor: colors.primary.light,
      color: colors.white,
    },
    color: colors.black,
  },
  categories: {
    price: colors.black,
  },
  categoryCard: {
    color: colors.black,
  },
  chip: {
    textColor: colors.black,
    success: {
      background: hexToRGBA(colors.primary.lightest, 0.25),
      color: colors.primary.base,
    },
    failed: {
      background: hexToRGBA(colors.red.lightest, 0.25),
      color: colors.red.base,
    },
  },
  courseEdit: {
    topBarBackgroundColor: colors.secondary.lighter,
    courseImageOverlayBackground: hexToRGBA(colors.secondary.darker.toString(), 0.4),
    boxShadowColor: "#00000029",
    grayBase: colors.secondary.base,
    uploadFile: {
      borderColor: hexToRGBA(colors.primary.lightest.toString(), 0.25),
      textColor: colors.primary.light,
      textHoverColor: colors.primary.lighter,
    },
    weights: {
      borderColor: colors.secondary.lighter,
      percentageColor: colors.black,
      percentageBackgroundColor: colors.secondary.lighter,
    },
    learningPath: {
      dotColor: colors.primary.base,
      dotBorderColor: colors.primary.base,
      emptyDotColor: colors.white,
      lineColor: colors.primary.base,
    },
    certificatePreview: {
      backgroundColor: colors.secondary.lighter,
      borderColor: colors.secondary.lighter,
    },
  },
  courseCard: {
    cardHeader: {
      infoBtn: {
        color: colors.black,
        background: colors.white,
        labelBackground: colors.primary.darkest,
        labelColor: colors.white,
      },
    },
    cardBody: {
      code: colors.secondary.dark,
    },
    cardFooter: {
      borderColor: colors.secondary.lighter,
      color: colors.primary.base,
    },
    progressBar: {
      background: colors.secondary.lighter,
      progressBackground: colors.green.base,
      color: colors.white,
      percentageColor: colors.green.darker,
    },
  },
  courseOverview: {
    codeColor: colors.secondary.dark,
    completed: colors.green.base,
    category: {
      color: colors.primary.base,
      hover: colors.primary.lighter,
    },
    customFieldTag: colors.secondary.lighter,
    textColor: colors.black,
    thumbnailSvgColor: colors.primary.base,
    thumbnailHover: Color(colors.primary.darker).alpha(0.5).string(),
  },
  courseToc: {
    color: colors.black,
    hover: colors.primary.lighter,
    sequentialColor: colors.secondary.base,
    completed: colors.green.base,
    failed: colors.red.base,
    iconColor: colors.black,
    sampleTagBackground: colors.primary.base,
    sampleTagColor: colors.white,
    edit: {
      background: colors.secondary.lighter,
      unitBackground: colors.white,
      active: colors.primary.light,
      selected: colors.primary.base,
      inactive: {
        color: colors.red.base,
        background: hexToRGBA(colors.red.light, 0.15),
      },
      unpublished: {
        color: colors.orange.base,
        background: hexToRGBA(colors.orange.light, 0.15),
      },
      availability: {
        color: colors.secondary.base,
      },
    },
  },
  courseUnits: {
    tagBackground: colors.secondary.base,
  },
  customLink: {
    primary: {
      color: colors.primary.base,
      hoverColor: colors.primary.lighter,
    },
    secondary: {
      color: colors.black,
      hoverColor: colors.primary.lighter,
    },
    defaultLink: {
      color: colors.blue.base,
      hoverColor: hexToRGBA(colors.blue.base, 0.8),
    },
  },
  customBadge: {
    background: colors.primary.base,
  },
  dateInput: {
    borderColor: colors.secondary.lighter,
    textColor: colors.black,
    contentActiveColor: colors.primary.base,
    contentInactiveColor: colors.secondary.base,
    activeBackgroundColor: hexToRGBA(colors.primary.lightest, 0.25),
    iconColor: colors.secondary.light,
    iconColorHover: colors.secondary.light,
    headerBackgroundColor: colors.white,
    boxShadowColor: hexToRGBA(colors.black, 0.1),
  },
  dropdown: {
    boxShadowColor: colors.secondary.light,
    backgroundColor: colors.white,
    emptyStateColor: colors.secondary.base,
    hoverBackgroundColor: colors.secondary.lighter,
    borderBottomColor: colors.secondary.lighter,
  },
  discussions: {
    color: colors.secondary.base,
    hover: hexToRGBA(colors.primary.lightest.toString(), 0.4),
    linkColor: colors.black,
    border: colors.secondary.lighter,
    textColor: colors.black,
    listBackgroundColor: colors.secondary.lighter,
    boxShadowColor: colors.secondary.light,
    filledIcon: colors.orange.base,
    hoverIcon: colors.orange.light,
  },
  editableText: {
    placeholderColor: colors.secondary.base,
  },
  editor: {
    background: colors.secondary.lighter,
    borderColor: colors.secondary.lighter,
    placeholderColor: colors.secondary.base,
    hoverColor: hexToRGBA(colors.primary.lightest, 0.25),
    focusColor: colors.primary.base,
    errorColor: colors.red.base,
    disabledColor: colors.secondary.base,
    moreBackground: colors.white,
  },
  formFields: {
    requiredFieldAsterisk: colors.red.base,
  },
  featuredCard: {
    background: colors.green.lightest, //FeaturedCard not used in PLUS
    color: colors.primary.darkest,
  },
  importArea: {
    addFilesText: colors.primary.base,
    dragAndDropFilesText: colors.secondary.base,
    dragAndDropAreaBorder: hexToRGBA(colors.primary.lightest, 0.25),
  },
  importResults: {
    background: colors.secondary.lighter,
  },
  ftpConfigDrawer: {
    sftpText: colors.secondary.base,
  },
  accountAndSettings: {
    boxedContainer: {
      background: colors.white,
      title: colors.secondary.base,
      boxShadow: colors.secondary.light,
    },
    settingToggle: {
      labelText: colors.secondary.base,
    },
    formActionsDivider: {
      background: colors.white,
      border: colors.secondary.lighter,
    },
  },
  fileCard: {
    detailsColor: colors.secondary.base,
    fileCover: {
      color: colors.secondary.light,
      background: colors.secondary.lighter,
      textColor: colors.white,
    },
    fileType: {
      color: colors.white,
      blueColor: colors.primary.base,
      background: colors.primary.base,
    },
  },
  fileInput: {
    border: colors.secondary.light,
    background: hexToRGBA(colors.white, 0.85),
  },
  gamification: {
    color: colors.secondary.darker,
    hover: colors.black,
  },
  gamificationDrawer: {
    color: colors.primary.base,
    background: colors.secondary.lighter,
    closeBtn: colors.secondary.base,
  },
  gamificationNotification: {
    success: {
      background: colors.white,
      color: colors.black,
      borderColor: colors.green.base,
    },
    error: {
      background: colors.white,
      color: colors.black,
      borderColor: colors.red.base,
    },
  },
  general: {
    primary: colors.primary.base,
  },
  groups: {
    info: {
      backgroundColor: Color(colors.primary.lightest).alpha(0.25).string(),
      text: colors.primary.base,
    },
    courses: {
      info: {
        backgroundColor: colors.secondary.base,
        textColor: colors.white,
      },
    },
  },
  icon: {
    primary: colors.primary.base,
    secondary: colors.secondary.base,
    success: colors.green.base,
    danger: colors.red.base,
    white: colors.white,
  },
  infoPanel: {
    background: colors.secondary.lighter,
    color: colors.black,
  },
  innerPage: {
    background: colors.white,
  },
  imageEditor: {
    thumb: colors.primary.base,
    background: colors.secondary.lighter,
  },
  iltContainer: {
    textColor: colors.secondary.base,
  },
  leaveBeta: {
    color: colors.orange.light,
  },
  leaderboard: {
    selectedUserBackground: colors.primary.light,
  },
  loader: {
    color: colors.primary.lightest,
  },
  mainHeader: {
    background: colors.white,
    boxShadowColor: colors.secondary.light, // TODO: replace with theme colour
    mobileSearch: {
      color: colors.secondary.darker, // TODO: remove it and make btns secondary
    },
  },
  messages: {
    color: colors.secondary.base,
    hover: colors.primary.lighter,
    linkColor: colors.black,
    border: colors.secondary.lighter,
    icon: colors.primary.base,
    reorder: {
      icon: colors.primary.light,
      background: colors.white,
      boxShadow: colors.secondary.light,
    },
  },
  microstats: {
    background: colors.secondary.lighter,
  },
  modal: {
    iconHover: colors.primary.base,
    color: colors.black,
    border: colors.secondary.lighter,
  },
  multiSelect: {
    hover: colors.secondary.lighter,
    background: colors.white,
    borderHoverColor: colors.primary.base,
    inputBorderColor: colors.secondary.light,
  },
  notificationsWidget: {
    iconColor: colors.primary.base,
  },
  onboarding: {
    border: colors.secondary.lighter,
    closeBtn: {
      color: colors.black,
      hover: colors.primary.base,
    },
  },
  passwordStrentghBar: {
    background: colors.secondary.lighter,
  },
  payments: {
    creditsValid: colors.green.base,
    creditsInvalid: colors.red.base,
    code: colors.secondary.dark,
    bundleBackground: colors.secondary.lighter,
    priceColor: colors.black,
    invoiceModal: {
      headerBackground: colors.secondary.lighter,
      borderColor: colors.secondary.light,
    },
    activeSubscriptionBackground: colors.secondary.lighter,
    tagBackground: colors.primary.base,
    tagColor: colors.white,
    couponValid: colors.green.base,
    subscriptionButtonHover: colors.primary.light,
    subscriptionButtonBackground: colors.white,
  },
  pressedRectangle: {
    background: colors.white,
    color: colors.primary.base,
    border: colors.secondary.lighter,
    borderHoverColor: colors.primary.base,
    borderPressedColor: colors.primary.base,
  },
  profile: {
    emailColor: colors.secondary.base,
    menuItem: {
      color: colors.black,
      hoverColor: colors.primary.base,
    },
  },
  pdfPreview: {
    toolbar: {
      background: colors.black,
      color: colors.white,
    },
  },
  price: {
    valueColor: colors.primary.base,
    prevValueColor: colors.secondary.base,
  },
  quickActionsWidget: {
    activeBackgroundColor: colors.primary.base,
    backgroundColor: colors.primary.light,
    hoverColor: colors.white,
  },
  rangeInput: {
    color: colors.primary.base,
    backgroundColor: colors.secondary.lighter,
  },
  rating: {
    color: colors.orange.base,
    background: colors.secondary.light,
    hover: colors.orange.base,
  },
  recording: {
    audio: {
      backgroundColor: colors.secondary.lighter,
      color: colors.secondary.light,
    },
  },
  reminderWidget: {
    color: colors.black,
    hover: colors.primary.base,
    backgroundColor: colors.secondary.lighter,
  },
  resultsPage: {
    userResults: {
      color: colors.primary.base,
      hoverColor: colors.primary.lightest,
      svgColor: colors.black,
    },
  },
  rewardsTabWidget: {
    borderBottomColor: colors.secondary.lighter,
    iconBackgroundColor: colors.white,
    titleColor: colors.secondary.base,
    points: {
      iconBorderColor: COLORS.primary,
    },
    badges: {
      iconBorderColor: colors.green.base,
    },
    level: {
      iconBorderColor: colors.orange.base,
    },
    extra: {
      iconBorderColor: colors.black,
    },
  },
  scrollbar: {
    color: colors.secondary.dark,
    background: colors.secondary.lighter,
  },
  sidebar: {
    background: colors.primary.base,
    backgroundHover: colors.primary.dark,
    backgroundHoverShadow: colors.primary.dark,
    color: colors.white,
    colorHover: colors.white,
    hamburgerBackground: colors.white,
    border: colors.primary.base,
    selectedColor: hexToRGBA(colors.primary.lightest.toString(), 0.4),
  },
  signInPage: {
    formBackground: colors.white,
    boxShadow: colors.secondary.light,
    borderColor: colors.secondary.lighter,
    headerBackground: colors.white,
    background: colors.secondary.lighter,
  },
  stats: {
    numericStat: {
      background: colors.secondary.lighter,
      color: colors.primary.darkest,
      textColor: colors.white,
      lastLoginDifColor: colors.green.base,
    },
  },
  statusTag: {
    inactive: {
      backgroundColor: colors.secondary.base,
      textColor: colors.white,
    },
    info: {
      backgroundColor: hexToRGBA(colors.primary.lightest, 0.25),
      textColor: colors.primary.base,
    },
    failed: {
      backgroundColor: hexToRGBA(colors.red.lightest, 0.25),
      textColor: colors.red.base,
    },
    processing: {
      background: hexToRGBA(colors.primary.lightest, 0.25),
      color: colors.primary.base,
    },
  },
  screenSizeAlert: {
    subtitle: colors.secondary.base,
  },
  terms: {
    background: hexToRGBA(colors.primary.lightest, 0.25),
    contentBackground: colors.white,
  },
  textCard: {
    primary: {
      background: colors.primary.base,
      color: colors.white,
      border: colors.primary.base,
    },
    secondary: {
      background: colors.secondary.lighter,
      color: colors.black,
      border: colors.secondary.light,
    },
  },
  textWithIcon: {
    headingBlack: colors.black,
    headingGrey: colors.secondary.base,
  },
  timeLimitBar: {
    background: colors.primary.base,
    sucessBackground: colors.green.base,
    dangerBackground: colors.red.base,
    barBackground: colors.secondary.lighter,
  },
  timeline: {
    dateColor: colors.secondary.base,
    borderColor: colors.secondary.lighter,
    deleteActionBackground: colors.red.light,
    deleteActionText: colors.white,
    primary: {
      background: hexToRGBA(colors.blue.lightest, 0.25),
      color: colors.blue.base,
    },
    success: {
      background: hexToRGBA(colors.green.lightest, 0.25),
      color: colors.green.base,
    },
    danger: {
      background: hexToRGBA(colors.red.lightest, 0.25),
      color: colors.red.base,
    },
    warning: {
      background: colors.orange.lighter,
      color: colors.orange.base,
    },
  },
  toggleButton: {
    text: {
      color: colors.black,
    },
  },
  toggleSwitch: {
    backgroundEnabled: colors.primary.base,
    backgroundDisabled: colors.secondary.base,
    disabledBackground: colors.secondary.light,
  },
  unitTopNav: {
    background: colors.white,
    shadowColor: colors.secondary.lighter,
  },
  unitFooter: {
    borderColor: colors.secondary.lighter,
    textColor: colors.black,
  },
  units: {
    assignment: {
      grade: colors.green.base,
      color: colors.primary.base,
      hover: colors.primary.lighter,
      recordingButton: {
        color: colors.white,
        background: colors.orange.base,
      },
      durationColor: colors.white,
      durationColorScreen: colors.black,
    },
    edit: {
      borderColor: colors.secondary.lighter,
      secondaryTextColor: colors.secondary.base,
      uploadOptions: {
        backgroundColor: colors.secondary.lighter,
        color: colors.secondary.light,
        hoverBackgroundColor: colors.primary.light,
        hoverColor: colors.white,
      },
    },
    survey: {
      border: colors.secondary.light,
      background: colors.secondary.lighter,
    },
    test: {
      successColor: colors.green.base,
      successColorLight: colors.green.lightest,
      errorColor: colors.red.base,
      errorColorLight: colors.red.lightest,
      questionBackground: colors.secondary.lighter,
      questionBorder: colors.secondary.lighter,
      notAnsweredBackground: colors.secondary.base,
      notAnsweredColor: colors.white,
      borderNotAnswered: colors.black,
      disabledColor: colors.secondary.base,
      matchPairs: {
        background: colors.white,
        backgroundRight: colors.primary.lightest,
        border: colors.secondary.lighter, //check
        borderRight: colors.primary.lightest, //check
      },
    },
    completedScreen: {
      background: colors.secondary.lighter, //check
    },
    unitsCreationMenu: {
      primary: colors.primary.lighter,
      description: colors.secondary.base,
      border: colors.secondary.lighter,
      hover: colors.secondary.lighter,
    },
  },
  unitsToC: {
    titleColor: colors.black,
    sequentialColor: colors.secondary.base,
    borderColor: colors.secondary.lighter,
    hoverColor: colors.primary.base,
    completedColor: colors.green.base,
    failedColor: colors.red.base,
    headerBackgroundColor: colors.white,
  },
  uploadArea: {
    grayBase: colors.secondary.base,
    uploadFile: {
      borderColor: hexToRGBA(colors.primary.lightest.toString(), 0.25),
      textColor: colors.primary.light,
      textHoverColor: colors.primary.lighter,
    },
  },
  users: {
    info: {
      backgroundColor: colors.secondary.base,
      text: colors.white,
    },
  },
  widgetsDashboard: {
    background: colors.secondary.lighter,
    droppableBackground: hexToRGBA(colors.primary.lightest, 0.25),
    previewBackground: colors.white,
  },
  widget: {
    background: {
      primary: colors.white,
      secondary: colors.secondary.lighter,
    },
    color: colors.primary.darkest,
    borderColor: colors.secondary.light,
  },
  widgets: {
    iconColor: colors.primary.base,
    descriptionColor: colors.secondary.base,
    completionRate: {
      barBg: colors.primary.base,
      barBgHover: colors.primary.lightest,
    },
    coursePie: {
      iconColor: colors.primary.base,
      completed: colors.green.light,
      completedFocused: colors.green.base,
      inProgress: colors.orange.base,
      notStarted: colors.secondary.base,
      notPassed: colors.red.light,
      notPassedFocused: colors.red.base,
    },
    overview: {
      color: colors.primary.base,
      iconBackground: colors.white,
      iconBorder: colors.primary.lightest,
    },
    portalActivity: {
      loginsBg: COLORS.primary,
      completionsBg: colors.orange.light,
      inactive: colors.secondary.base,
    },
    users: {
      color: colors.primary.base,
    },
  },
  brandedForms: {
    actionsBackground: colors.white,
    dividerColor: colors.secondary.lighter,
  },
};

const gnosisTheme = {
  alert: alert(colors),
  badge: badge(colors),
  breadcrumb: breadcrumb(colors),
  button: button(colors),
  card: card(colors),
  chip: chip(colors),
  drawer: drawer(colors),
  dropdown: dropdown(colors),
  formElements: formElements(colors),
  loader: loader(colors),
  modal: modal(colors),
  progressBar: progressBar(colors),
  result: result(colors),
  sidebar: sidebar(colors),
  tabs: tabs(colors),
  table: table(colors),
  tag: tag(colors),
  tooltip: tooltip(colors),
  pagination: pagination(colors),
  scrollbar: scrollbar(colors),
};

const defaultBlue = { ...getGnosisTheme(colors), ...plusTheme };

export type GnosisTheme = typeof gnosisTheme;
export type PlusTheme = typeof plusTheme;
export type CustomTheme = typeof defaultBlue;

export default defaultBlue;

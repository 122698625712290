import queryKeys from "@constants/queryKeys";
import { generalNotification } from "@utils/helpers";
import { convertBinaryToPDF } from "@utils/helpers/payments";
import { getMyInvoice, getDownloadInvoice } from "@views/MyPayments/api";
import { AxiosError } from "axios";
import { t } from "i18next";
import { UseMutationResult, useMutation } from "react-query";
import { Invoice } from "types/entities";
import { MyInvoiceRes } from "types/entities/Payments";

export const useViewInvoiceMutation = (
  handleSuccess: (data: Invoice) => void,
): UseMutationResult<MyInvoiceRes, AxiosError, string, unknown> => {
  return useMutation<MyInvoiceRes, AxiosError, string, unknown>(
    [queryKeys.invoices.invoice],
    (invoiceId: string) => getMyInvoice(invoiceId),
    {
      onSuccess: (res) => {
        handleSuccess(res._data);
      },
      onError: () => {
        generalNotification("error", t("payments.errors.cannotView"));
      },
    },
  );
};

export const useDownloadInvoiceMutation = (): UseMutationResult<
  string,
  AxiosError,
  string,
  unknown
> => {
  return useMutation<string, AxiosError, string, unknown>(
    [queryKeys.invoices.invoiceDownload],
    (invoiceId: string) => getDownloadInvoice(invoiceId),
    {
      onSuccess: (res) => {
        convertBinaryToPDF(res);
      },
      onError: () => {
        generalNotification("error", t("payments.errors.cannotDownload"));
      },
    },
  );
};

import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  titleColor: string;
  infoColor: string;
  shadowColor: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  titleColor: colors.black,
  infoColor: colors.secondary.base,
  shadowColor: colors.secondary.light,
});

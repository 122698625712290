import React, { FC } from "react";
import { Button, Dropdown, DropdownItem } from "@epignosis_llc/gnosis";
import { IconChevronDownSVG } from "@epignosis_llc/gnosis/icons";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { bundleListContainer } from "./styles";
import { Bundle } from "types/entities";
import { URLS } from "@constants/urls";
import i18n from "@utils/i18n";
import { useIsExternalView } from "@hooks";

export type BundleListProps = {
  bundles: Bundle[];
};

const BundleList: FC<BundleListProps> = ({ bundles }) => {
  const navigate = useNavigate();
  const isExternalView = useIsExternalView();
  const dir = i18n.dir();

  const dropdownListData = bundles.map((bundle) => {
    return {
      label: bundle.name,
      value: bundle.type,
      id: bundle.id.toString(),
    };
  });

  const handleBundleSelect = (item: DropdownItem): void => {
    const bundleFound = bundles.find(
      (bundle) => bundle.id.toString() === item.id && bundle.type === item.value,
    );

    if (bundleFound) {
      const catalogLink = isExternalView ? URLS.externalCatalog.index : URLS.catalog.index;

      navigate(catalogLink, { state: { activeBundle: bundleFound } });
    }
  };

  return (
    <div css={bundleListContainer({ dir })}>
      <Dropdown list={dropdownListData} onListItemSelect={handleBundleSelect} fullWidth>
        <Button
          className="dropdown-btn"
          variant="outline"
          as="div"
          block
          iconAfter={IconChevronDownSVG}
        >
          {t("myCourses.courseBundles", { count: bundles.length })}
        </Button>
      </Dropdown>
    </div>
  );
};

export default BundleList;

import { css } from "@emotion/react";
import { hexToRGBA } from "@styles/utils";
import { mq } from "@utils/breakpoints";

export const carousel = ({ carousel }) => css`
  width: 100%;
  height: 100%;

  .slick-list {
    padding-bottom: 1rem;

    ${mq["sm"]} {
      padding-bottom: 2rem;
    }

    .slick-slide {
      text-align: start;
    }
  }

  .slick-dots {
    position: relative;
    bottom: 0;

    li {
      width: 0.75rem;
      height: 0.75rem;
      opacity: 1;
      background-color: ${hexToRGBA(carousel.dot.inactiveBackground, 0.6)};
      margin: 0 0.5rem;
      border-radius: 50%;

      button {
        opacity: 0;
      }

      &.slick-active {
        background-color: ${carousel.dot.activeBackground};
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { Step } from "react-joyride";
import { useResponsive } from "ahooks";
import { MyCoursesSVG } from "@epignosis_llc/gnosis/icons";
import {
  CourseCardsSVG,
  DiscussionsSVG,
  EndTourSVG,
  MessagesSVG,
  MyProfileSVG,
  NavigationSVG,
  StartTourSVG,
} from "@assets/images";
import permissions from "@utils/permissions";

type OnboardingStep = {
  step: Step;
  show: boolean;
};

type ResponsiveInfo = {
  [key: string]: boolean;
};

type StepsPermissions = {
  hasCourses: boolean;
  showCourseProgress: boolean;
  showMessages: boolean;
  showDiscussions: boolean;
};

const getSteps = (
  { hasCourses, showMessages, showDiscussions }: StepsPermissions,
  { md }: ResponsiveInfo,
): OnboardingStep[] => {
  const skipButtonText = t("onboarding.skipTourAndExplore");

  return [
    {
      step: {
        title: <Heading>{t("onboarding.welcomeToTalentLMS")}</Heading>,
        content: (
          <>
            <StartTourSVG className="content-svg start-tour" />
            <Text fontSize="sm" as="p">
              {t("onboarding.thankYouForJoining")}!
            </Text>
            <Text fontSize="sm" as="p">
              {t("onboarding.youAreOneOfTheFirstPeople")}.<br></br>
              <br />
              {t("onboarding.weHopeToHear")}.
            </Text>
            <Text fontSize="sm" as="p">
              {t("onboarding.happyExploring")}!
            </Text>
          </>
        ),
        locale: {
          skip: t("onboarding.exploreAlone"),
          next: t("onboarding.guidedTour"),
        },
        placement: "center",
        target: "body",
      },
      show: true,
    },
    {
      step: {
        title: <Heading>{t("onboarding.mainNavigation")}</Heading>,
        content: (
          <>
            <NavigationSVG className="content-svg" />
            <Text fontSize="sm" as="p">
              {t("onboarding.anywhereYouGoIsAccessible")}.
            </Text>
            <Text fontSize="sm" as="p">
              {t("onboarding.youCanUseLeftNavigation")}.
            </Text>
          </>
        ),
        locale: {
          skip: skipButtonText,
        },
        placement: md ? "right-end" : "center",
        target: md ? "#main-menu" : "body",
      },
      show: true,
    },
    {
      step: {
        title: <Heading>{t("mainMenu.myCourses")}</Heading>,
        content: (
          <>
            <CourseCardsSVG className="content-svg" />
            <Text fontSize="sm" as="p">
              {t("onboarding.youCanAlwaysFindListCourses")} <MyCoursesSVG height={20} />{" "}
              <b>{t("mainMenu.myCourses")}</b>.
            </Text>
          </>
        ),
        locale: {
          skip: skipButtonText,
        },
        placement: md ? "right-start" : "center",
        target: md ? "#myCourses" : "body",
      },
      show: hasCourses,
    },
    {
      step: {
        title: <Heading>{t("mainMenu.myProfile")}</Heading>,
        content: (
          <>
            <MyProfileSVG className="content-svg" />
            <Text
              fontSize="sm"
              as="p"
              dangerouslySetInnerHTML={{
                __html: t("onboarding.accessPersonalPortalSettings"),
              }}
            />
          </>
        ),
        locale: {
          skip: skipButtonText,
        },
        placement: md ? "right-end" : "center",
        target: md ? "#profile" : "body",
      },
      show: true,
    },
    {
      step: {
        title: <Heading>{t("mainMenu.messages")}</Heading>,
        content: (
          <>
            <MessagesSVG className="content-svg" />
            <Text fontSize="sm" as="p">
              {t("onboarding.notifiedNewIncomingMessages")}.
            </Text>
          </>
        ),
        locale: {
          skip: skipButtonText,
        },
        placement: md ? "right-end" : "center",
        target: md ? "#messages" : "body",
      },
      show: showMessages,
    },
    {
      step: {
        title: <Heading>{t("mainMenu.discussions")}</Heading>,
        content: (
          <>
            <DiscussionsSVG className="content-svg" />
            <Text fontSize="sm" as="p">
              {t("onboarding.subscribeToYourFavouriteThreads")}.
            </Text>
          </>
        ),
        locale: {
          skip: skipButtonText,
        },
        placement: md ? "right-end" : "center",
        target: md ? "#discussions" : "body",
      },
      show: showDiscussions,
    },
    {
      step: {
        title: <Heading>{t("onboarding.goExplore")}!</Heading>,
        content: (
          <>
            <EndTourSVG className="content-svg" />
            <Text fontSize="sm" as="p">
              {t("onboarding.thatsAll")}.
            </Text>
            <Text fontSize="sm" as="p">
              {t("onboarding.enjoy")}!
            </Text>
          </>
        ),
        locale: {
          skip: t("onboarding.restartTour"),
          last: t("onboarding.letsGo"),
        },
        placement: "center",
        target: "body",
      },
      show: true,
    },
  ];
};

const stepsReducer = (arr: Step[], item: OnboardingStep): Step[] => {
  if (item.show) {
    arr.push(item.step);
  }
  return arr;
};

const useOnboardingSteps = (hasCourses: boolean, showCourseProgress: boolean): Step[] => {
  const responsiveInfo = useResponsive();

  // permissions related
  const { canAccessMessages } = permissions.messagesPermissions;
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const allowDiscussionsView = canAccessDiscussions();
  const allowMessagesView = canAccessMessages();

  const stepsPermissions: StepsPermissions = {
    hasCourses,
    showCourseProgress,
    showMessages: allowMessagesView,
    showDiscussions: allowDiscussionsView,
  };

  const [state, setState] = useState<Step[]>(() =>
    getSteps(stepsPermissions, responsiveInfo).reduce(stepsReducer, []),
  );

  useEffect(() => {
    setState(getSteps(stepsPermissions, responsiveInfo).reduce(stepsReducer, []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCourses, showCourseProgress, allowMessagesView, allowDiscussionsView]);

  return state;
};

export default useOnboardingSteps;

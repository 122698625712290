import React, { FC } from "react";
import { UnitCompleteSVG, UnitFailedSVG } from "@epignosis_llc/gnosis/icons";

type CompletionIconProps = {
  isSuccessful: boolean;
  height?: number;
};

const CompletionIcon: FC<CompletionIconProps> = ({ isSuccessful, height = 32 }) => {
  return isSuccessful ? <UnitCompleteSVG height={height} /> : <UnitFailedSVG height={height} />;
};

export default CompletionIcon;

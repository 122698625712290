import React, { FC, ReactChild } from "react";
import Slider from "react-slick";
import { carousel } from "./styles";
import i18n from "@utils/i18n";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type CarouselProps = {
  children: ReactChild[];
};

const Carousel: FC<CarouselProps> = ({ children }) => {
  const isRtl = i18n.dir() === "rtl";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings} css={carousel} rtl={isRtl}>
      {children}
    </Slider>
  );
};

export default Carousel;

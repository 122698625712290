type ValidUrlKeys =
  | "courseId"
  | "unitId"
  | "questionId"
  | "messageId"
  | "messageAction"
  | "discussionId"
  | "replyId"
  | "commentId"
  | "branchId"
  | "userId"
  | "groupId"
  | "term"
  | "activeTab";

export type UrlConfig = { [key in ValidUrlKeys]?: string };
export type LocationState = {
  from: {
    pathname: string;
  };
};

export const URLS = {
  root: "/",
  login: "/login",
  signup: "/signup",
  autologin: "/autologin",
  passwordReset: "/password-reset",
  changePassword: "/password-change",
  dashboard: "/dashboard",
  widgetsDashboard: "/widgets-dashboard",
  terms: "/terms-of-service",
  emailVerification: "/verify-email",
  accountAndSettings: "/account",
  user: {
    profile: "/my/profile",
    customizeTheming: "/my/profile/customize-theming",
    calendar: "/my/calendar",
    certificates: "/my/certificates",
    files: "/my/files",
    badges: "/my/badges",
    branches: "/my/branches",
    courses: "/my/courses",
    course: "/my/courses/:courseId",
    courseFiles: "/my/courses/:courseId/files",
    createCourseFilesLink: ({ courseId }: UrlConfig): string => `/my/courses/${courseId}/files`,
    createCourseLink: ({ courseId }: UrlConfig): string => `/my/courses/${courseId}`,
    createCourseResultsLink: ({ courseId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/completed`,
    unitContinue: "/my/courses/:courseId/units/continue",
    unit: "/my/courses/:courseId/units/:unitId",
    createUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/${unitId}`,
    unitResults: "/my/courses/:courseId/units/:unitId/results",
    createUnitResultsLink: ({ courseId, unitId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/${unitId}/results`,
    testQuestion: "/my/courses/:courseId/units/:unitId/test/question/:questionId",
    createTestQuestionLink: ({ courseId, unitId, questionId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/${unitId}/test/question/${questionId}`,
    surveyQuestion: "/my/courses/:courseId/units/:unitId/survey/question/:questionId",
    createSurveyQuestionLink: ({ courseId, unitId, questionId }: UrlConfig): string =>
      `/my/courses/${courseId}/units/${unitId}/survey/question/${questionId}`,
    messages: "/my/messages",
    newMessage: "/my/messages/new",
    inboxMessage: "/my/messages/:messageId/inbox",
    createInboxMessageLink: ({ messageId }: UrlConfig, { messageAction }: UrlConfig): string =>
      `/my/messages/${messageId}/inbox${messageAction ? `?messageAction=${messageAction}` : ""}`,
    sentMessage: "/my/messages/:messageId/sent",
    createSentMessageLink: ({ messageId }: UrlConfig, { messageAction }: UrlConfig): string =>
      `/my/messages/${messageId}/sent${messageAction ? `?messageAction=${messageAction}` : ""}`,
    discussions: "/my/discussions",
    newDiscussion: "/my/discussions/new",
    singleDiscussion: "/my/discussions/:discussionId",
    getSingleDiscussionLink: ({ discussionId }: UrlConfig): string =>
      `/my/discussions/${discussionId}`,
    getSingleDiscussionReplyLink: ({ discussionId, replyId }: UrlConfig): string =>
      `/my/discussions/${discussionId}${replyId ? `?replyId=${replyId}` : ""}`,
    getSingleDiscussionCommentLink: ({ discussionId, commentId }: UrlConfig): string =>
      `/my/discussions/${discussionId}?commentId=${commentId}`,
    editDiscussion: "/my/discussions/edit/:discussionId",
    editDiscussionLink: ({ discussionId }: UrlConfig): string =>
      `/my/discussions/edit/${discussionId}`,
    groups: "/my/groups",
    payments: "/my/payments",
  },
  catalog: {
    index: "/catalog",
    course: "/catalog/courses/:courseId",
    courseFiles: "/catalog/courses/:courseId/files",
    createCourseLink: ({ courseId }: UrlConfig): string => `/catalog/courses/${courseId}`,
    createCatalogCourseFilesLink: ({ courseId }: UrlConfig): string =>
      `/catalog/courses/${courseId}/files`,
    sampleUnit: "/catalog/courses/:courseId/units/:unitId",
    createSampleUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/catalog/courses/${courseId}/units/${unitId}`,
  },
  externalCatalog: {
    index: "/external-catalog",
    course: "/external-catalog/courses/:courseId",
    createCourseLink: ({ courseId }: UrlConfig): string => `/external-catalog/courses/${courseId}`,
    sampleUnit: "/external-catalog/courses/:courseId/units/:unitId",
    createSampleUnitLink: ({ courseId, unitId }: UrlConfig): string =>
      `/external-catalog/courses/${courseId}/units/${unitId}`,
    publicUnit: {
      index: "/external-catalog/courses/public/:courseId/units/:unitId",
      createPublicUnitLink: ({ courseId, unitId }: UrlConfig): string =>
        `/external-catalog/courses/public/${courseId}/units/${unitId}`,
      unitResults: "/external-catalog/courses/public/:courseId/units/:unitId/results",
      createUnitResultsLink: ({ courseId, unitId }: UrlConfig): string =>
        `/external-catalog/courses/public/${courseId}/units/${unitId}/results`,
      testQuestion:
        "/external-catalog/courses/public/:courseId/units/:unitId/test/question/:questionId",
      createTestQuestionLink: ({ courseId, unitId, questionId }: UrlConfig): string =>
        `/external-catalog/courses/public/${courseId}/units/${unitId}/test/question/${questionId}`,
      surveyQuestion:
        "/external-catalog/courses/public/:courseId/units/:unitId/survey/question/:questionId",
      createSurveyQuestionLink: ({ courseId, unitId, questionId }: UrlConfig): string =>
        `/external-catalog/courses/public/${courseId}/units/${unitId}/survey/question/${questionId}`,
      createCourseResultsLink: ({ courseId }: UrlConfig): string =>
        `/external-catalog/courses/public/${courseId}/units/completed`,
    },
  },
  search: {
    all: "/search/all",
    getSearchAllLink: ({ term }: UrlConfig): string => `/search/all/?term=${term}`,
    courses: "/search/courses",
    users: "/search/users",
    files: "/search/files",
  },
  courses: {
    courses: "/courses",
    users: "/courses/:courseId/users",
    edit: "/courses/:courseId/edit",
    getCourseEditLink: ({ courseId }: UrlConfig): string => `/courses/${courseId}/edit`,
    singleCourse: "/courses/:courseId",
    getSingleCourse: ({ courseId, activeTab }: UrlConfig): string =>
      `/courses/${courseId}${activeTab ? `?activeTab=${activeTab}` : ""}`,
  },
  units: {
    edit: "/courses/:courseId/units/:unitId/edit",
    getUnitEditLink: ({ courseId, unitId }: UrlConfig): string =>
      `/courses/${courseId}/units/${unitId}/edit`,
    singleUnitReports: "/courses/:courseId/units/:unitId",
    getSingleUnitReports: ({ courseId, unitId }: UrlConfig): string =>
      `/courses/${courseId}/units/${unitId}`,
  },
  branches: {
    branches: "/branches",
    branch: "branches/:branchId",
    getBranchLink: ({ branchId }: UrlConfig): string => `/branches/${branchId}`,
    edit: "/branches/:branchId/edit",
    getBranchEditLink: ({ branchId }: UrlConfig): string => `/branches/${branchId}/edit`,
  },
  groups: {
    create: "/groups/create",
    groups: "/groups",
    group: "groups/:groupId",
    getGroupLink: ({ groupId }: UrlConfig): string => `/groups/${groupId}`,
    getGroupEditLink: ({ groupId }: UrlConfig): string => `/groups/${groupId}/edit`,
    edit: "/groups/:groupId/edit",
  },
  categories: {
    categories: "/categories",
  },
  automations: {
    automations: "/automations",
  },
  notifications: {
    notifications: "/notifications",
    notificationHistory: "/notifications/sent",
    notificationHistoryItem: "notifications/sent/:notificationHistoryId",
    resend: "/notifications/sent/:notificationHistoryId/resend",
  },
  users: {
    users: "/users",
    create: "/users/create",
    user: "users/:userId",
    getUserLink: ({ userId }: UrlConfig): string => `/users/${userId}`,
    getEditLink: ({ userId }: UrlConfig): string => `/users/${userId}/edit`,
    edit: "/users/:userId/edit",
  },
  reports: {
    reports: "/reports",
  },
} as const;

export const rootURLS = [
  URLS.accountAndSettings,
  URLS.courses.courses,
  URLS.users.users,
  URLS.groups.groups,
  URLS.branches.branches,
  URLS.categories.categories,
  URLS.reports.reports,
  URLS.dashboard,
  URLS.user.badges,
  URLS.user.files,
  URLS.user.calendar,
  URLS.user.payments,
  URLS.user.profile,
  URLS.user.groups,
  URLS.user.messages,
  URLS.user.discussions,
  URLS.user.customizeTheming,
  URLS.user.courses,
  URLS.catalog.index,
  URLS.user.newMessage,
  URLS.user.inboxMessage,
  URLS.user.sentMessage,
  URLS.user.newDiscussion,
  URLS.user.editDiscussion,
  URLS.user.singleDiscussion,
];

export const profileURLS = [
  URLS.user.badges,
  URLS.user.files,
  URLS.user.payments,
  URLS.user.profile,
  URLS.user.groups,
  URLS.user.customizeTheming,
];

export const secondaryURLS = [
  URLS.user.messages,
  URLS.user.newMessage,
  URLS.user.discussions,
  URLS.user.newDiscussion,
  URLS.user.singleDiscussion,
  URLS.user.editDiscussion,
  URLS.user.inboxMessage,
  URLS.user.sentMessage,
];

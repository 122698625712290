import { css } from "@emotion/react";

export const ClearIndicatorStyles = ({ autocompleteInput, base }) => ({
  ...base,
  cursor: "pointer",
  color: autocompleteInput.blueColor,
  padding: "0 0.75rem",
  "&:hover": {
    color: autocompleteInput.blueColor,
  },
});

export const ControlStyles = ({ autocompleteInput, base, state, status }) => {
  let borderColorStyle;
  if (state.isDisabled) {
    borderColorStyle = autocompleteInput.disabledBorder;
  } else if (state.isFocused) {
    borderColorStyle = autocompleteInput.primaryColor;
  } else if (status === "error") {
    borderColorStyle = autocompleteInput.errorBorderColor;
  } else {
    borderColorStyle = autocompleteInput.border;
  }
  const cursorStyle = state.isDisabled ? "not-allowed" : "text";

  return {
    ...base,
    backgroundColor: !state.isFocused
      ? autocompleteInput.background
      : autocompleteInput.backgroundWhite,
    borderColor: borderColorStyle,
    boxShadow: "0 !important",
    minHeight: "2.5rem",

    "&:hover": {
      borderColor: !state.isDisabled
        ? autocompleteInput.primaryColor
        : autocompleteInput.disabledBorder,
      backgroundColor: !state.isFocused
        ? autocompleteInput.backgroundHover
        : autocompleteInput.backgroundWhite,
    },
    cursor: cursorStyle,
    pointerEvents: "all",
  };
};

export const DropdownIndicatorStyles = ({ base }) => ({
  ...base,
  color: "inherit",
  cursor: "default",
  "&:hover": {
    color: "inherit",
  },
});

export const PlaceholderStyles = ({ autocompleteInput, base }) => ({
  ...base,
  margin: "0 2px",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  fontStyle: "italic",
  color: autocompleteInput.placeholderColor,
  cursor: "text",
});

export const OptionStyles = ({ autocompleteInput, base, state }) => ({
  ...base,
  padding: "0.5rem 1rem",
  backgroundColor:
    state.isSelected || state.isFocused ? autocompleteInput.background : "transparent",
  color: "initial",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: autocompleteInput.background,
  },
  "&:active": {
    backgroundColor: autocompleteInput.background,
  },
});

export const GroupStyles = ({ autocompleteInput, base }) => ({
  ...base,
  borderBottom: `1px solid ${autocompleteInput.borderColor}`,
  padding: "0.5rem 0",
  "&:last-child": {
    border: "none",
  },
});

export const GroupHeadingStyles = ({ base }) => ({
  ...base,
  fontWeight: 700,
  fontSize: "14px",
  textTransform: "none",
  color: "inherit",
  marginBottom: 0,
  padding: "0.5rem 1rem",
});

export const customInputContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  flex: 1;

  .custom-input {
    overflow: hidden;
  }

  .close-btn {
    padding: 0;
    border: 0;
    background: transparent;
    height: 16px;
    padding-inline-start: 0.5rem;
  }
`;

import React, { FC } from "react";
import { format } from "date-fns";
import { Text } from "@epignosis_llc/gnosis";
import { ArrowLeftSVG, ArrowRightSVG } from "@epignosis_llc/gnosis/icons";
import { getLocale, getYearsPickerRange } from "@utils/helpers";
import i18n from "@utils/i18n";
import { useRtlArrowIcons } from "@hooks";

type CustomHeaderProps = {
  isYearPicker: boolean;
  isMonthPicker: boolean;
  date: Date;
  prevMonthButtonDisabled: boolean | undefined;
  nextMonthButtonDisabled: boolean | undefined;
  increaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined;
  increaseYear: React.MouseEventHandler<HTMLButtonElement> | undefined;
  decreaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined;
  decreaseYear: React.MouseEventHandler<HTMLButtonElement> | undefined;
  setIsYearPicker: (isYearPicker: boolean) => void;
  setIsMonthPicker: (isMonthPicker: boolean) => void;
};

const CustomHeader: FC<CustomHeaderProps> = (props) => {
  const {
    isYearPicker,
    isMonthPicker,
    date,
    increaseMonth,
    increaseYear,
    decreaseMonth,
    decreaseYear,
    setIsYearPicker,
    setIsMonthPicker,
  } = props;

  const isRtl = i18n.dir() === "rtl";
  const Icon = useRtlArrowIcons();

  const handleClick = (): void => {
    //day view
    if (!isMonthPicker && !isYearPicker) {
      setIsMonthPicker(true);
      setIsYearPicker(false);
    }

    if (isMonthPicker) {
      setIsYearPicker(true);
      setIsMonthPicker(false);
    }
  };

  return (
    <div className="custom-header">
      <button
        type="button"
        onClick={isYearPicker || isMonthPicker ? decreaseYear : decreaseMonth}
        className="icon-btn"
      >
        {<Icon height={32} />}
      </button>

      <button className="date-label" type="button" onClick={(): void => handleClick()}>
        <Text fontSize="md" weight="700">
          {isYearPicker
            ? getYearsPickerRange(date as Date)
            : format(date, isMonthPicker ? "yyyy" : "MMMM yyyy", { locale: getLocale() })}
        </Text>
      </button>

      <button
        type="button"
        onClick={isYearPicker || isMonthPicker ? increaseYear : increaseMonth}
        className="icon-btn"
      >
        {isRtl ? <ArrowLeftSVG height={32} /> : <ArrowRightSVG height={32} />}
      </button>
    </div>
  );
};

export default CustomHeader;

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { CertificateSmSVG } from "@epignosis_llc/gnosis/icons";

export type PreviewCertificateActionProps = {
  tooltipContent?: string;
  disabled?: boolean;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const PreviewCertificateAction: FC<PreviewCertificateActionProps> = ({
  tooltipContent,
  disabled = false,
  icon: Icon = CertificateSmSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton
      tooltipContent={tooltipContent ?? t("general.previewCertificate")}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default PreviewCertificateAction;

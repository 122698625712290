import { userHasRequiredPermissions } from "./index";
import useConfigurationStore from "@stores/configuration";
import authService from "@utils/services/AuthService";

// map with all the available permissions
export const messagesPermissions = {
  read: "message.read",
  create: "message.create",
};

const { read, create } = messagesPermissions;
const { getState } = useConfigurationStore;

export default {
  /**
   * Permission => message.read
   * message.read is required in order to have all the other messages related permissions
   *
   *  Allowed actions:
   *  GET recipients, singleMessage, unread, inbox, sent
   *  POST attachment, reply, read
   *  PUT unread
   *  DELETE delete
   *
   * @returns boolean
   */
  canAccessMessages: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions([read], getState().userProfileData?.permissions[userRole]);
  },
  /**
   * Permission => message.create
   *
   *  Allowed actions:
   *  POST messages, forward
   *
   * @returns boolean
   */
  canCreateMessages: (): boolean => {
    const userRole = authService.getDefaultRole();
    return userHasRequiredPermissions(
      [read, create],
      getState().userProfileData?.permissions[userRole],
    );
  },
};

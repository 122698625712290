import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Tag, Tooltip } from "@epignosis_llc/gnosis";
import { textWithStatusContainer } from "./style";
import { SerializedStyles } from "@emotion/react";

type TextWithStatusProps = {
  content: string | JSX.Element;
  tagText: string;
  variant: "failed" | "inactive" | "info" | "processing" | "success" | "warning" | "ready";
};

const TextWithStatus: FC<TextWithStatusProps> = ({ content, tagText, variant, children }) => {
  const componentRef = useRef<HTMLSpanElement | null>(null);
  const overflowRef = useRef<HTMLElement | null>(null);
  const [isOverflowActive, setIsOverflowActive] = useState(false);

  useLayoutEffect(() => {
    if (componentRef.current) {
      overflowRef.current = componentRef.current.querySelector(".overflow-text");
    }
  }, []);

  useEffect(() => {
    const el = overflowRef.current;

    if (el) {
      setIsOverflowActive(el.offsetWidth < el.scrollWidth);
    }
  }, [overflowRef]);

  return (
    <div css={(theme): SerializedStyles => textWithStatusContainer(theme, { variant })}>
      <div className="column-description">
        <Tooltip content={content} disabled={!isOverflowActive}>
          <span ref={componentRef}>{content}</span>
        </Tooltip>
        {children}
        {tagText && variant != "ready" && <Tag className="status-tag">{tagText}</Tag>}
      </div>
    </div>
  );
};

export default TextWithStatus;

import React, { FC } from "react";
import { Text, Heading, Tooltip } from "@epignosis_llc/gnosis";
import { UnitPendingSVG } from "@epignosis_llc/gnosis/icons";
import { t } from "i18next";
import { prerequisitesContainer } from "./styles";
import { URLS } from "@constants/urls";
import { Course } from "types/entities";
import { Link } from "@components/ReusableComponents";
import { getIconByCompletion } from "@utils/helpers";
import { useIsExternalView } from "@hooks";

type CourseRules = NonNullable<Course["rules"]>;
type CoursePrerequisites = NonNullable<CourseRules["prerequisites"]>;

export type PrerequisitesProps = {
  course: Course;
  unlocksOnHigherLevel?: boolean;
};

const Prerequisites: FC<PrerequisitesProps> = ({ course, unlocksOnHigherLevel }) => {
  const { rules, level } = course;
  const isExternalView = useIsExternalView();

  const prerequisitesMap = rules.prerequisites?.reduce((acc, val) => {
    if (!acc.has(val.rule_set)) {
      acc.set(val.rule_set, [val]);
    } else {
      const previousPrerequisites = acc.get(val.rule_set) as CoursePrerequisites;
      acc.set(val.rule_set, [...previousPrerequisites, val]);
    }

    return acc;
  }, new Map<number, CoursePrerequisites>());
  const prerequisitesArray = Array.from(prerequisitesMap?.values() ?? []);

  return (
    <section css={prerequisitesContainer} className="section-wrapper">
      <Heading className="section-title">{t("myCourses.prerequisites")}</Heading>
      {prerequisitesArray.map((val, i) => (
        <ul key={i}>
          {i > 0 && (
            <Text fontSize="sm" weight="700" className="divider" data-testid="divider">
              {t("general.or")}
            </Text>
          )}
          {val.map((course) => {
            const { id, name, completion_status, available_in_catalog } = course;
            const catalogLink = isExternalView
              ? URLS.externalCatalog.createCourseLink({ courseId: id.toString() })
              : URLS.catalog.createCourseLink({ courseId: id.toString() });

            const link = available_in_catalog ? catalogLink : "#";

            return (
              <li key={id} className="list-container">
                <Tooltip
                  content={t("general.currentlyUnavailable")}
                  disabled={available_in_catalog}
                >
                  <Link to={link} color="secondary" readonly={!available_in_catalog}>
                    <div className="icon-wrapper">
                      {getIconByCompletion(
                        completion_status ?? "not_attempted",
                        32,
                        <UnitPendingSVG height={32} />,
                      )}
                    </div>
                    <Text fontSize="sm" className="course-title">
                      {name}
                    </Text>
                  </Link>
                </Tooltip>
              </li>
            );
          })}
        </ul>
      ))}

      {unlocksOnHigherLevel && (
        <div className="level-container">
          <Text fontSize={"sm"}>{t("restrictions.unlockedOnLevel", { level: level })}</Text>
        </div>
      )}
    </section>
  );
};
export default Prerequisites;

import React, { FC, MouseEvent } from "react";
import { IconType } from "types/common";
import ActionButton from "./ActionButton";
import { t } from "i18next";
import { SynchronizeSVG } from "@assets/icons";

export type SynchronizeActionProps = {
  tooltipContent?: string;
  icon?: IconType;
  iconHeight?: number;
  onClick: (e: MouseEvent) => void;
};

const SynchronizeAction: FC<SynchronizeActionProps> = ({
  tooltipContent,
  icon: Icon = SynchronizeSVG,
  iconHeight = 32,
  onClick,
}) => {
  return (
    <ActionButton tooltipContent={tooltipContent ?? t("general.synchronize")} onClick={onClick}>
      <Icon height={iconHeight} />
    </ActionButton>
  );
};

export default SynchronizeAction;

import React, { FC, Suspense, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { LazyMotion, domAnimation, domMax } from "framer-motion";
import classNames from "classnames";
import { Loader, Drawer } from "@epignosis_llc/gnosis";
import { MainNav } from "@components";
import { MainHeader, MainDrawer, GamificationDrawer } from "@layouts/DashboardLayout/components";
import { main, content } from "@layouts/DashboardLayout/styles";
import { Announcements, ScreenSizeAlert } from "@components/ReusableComponents";
import { URLS, rootURLS } from "@constants/urls";
import { useUIStore } from "@stores";
import { isValidPathname } from "@utils/helpers/url";
import { useResponsive } from "ahooks";
import authService from "@utils/services/AuthService";

type DashboardLayoutProps = {
  noMainContentPadding?: boolean;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({ noMainContentPadding = false }) => {
  const { md } = useResponsive();
  const contentContainerClassNames = classNames({
    "no-padding": noMainContentPadding,
  });
  // URL should change when implementation is ready.

  const location = useLocation();
  const pathname = location.pathname;
  const userRole = authService.getDefaultRole();

  const isDashboard = pathname.includes(URLS.dashboard);
  // Screen Size Alert Page paths
  // Here you can add the path that you might want to have a screen size alert.
  const includeScreenSizeAlertPaths = [
    URLS.courses.courses,
    URLS.courses.edit,
    URLS.courses.singleCourse,
    URLS.units.edit,
    URLS.branches.branches,
    URLS.branches.branch,
    URLS.users.users,
    URLS.users.user,
    URLS.users.edit,
    URLS.users.create,
    URLS.reports.reports,
    URLS.groups.groups,
    URLS.groups.group,
    URLS.groups.create,
    URLS.categories.categories,
    URLS.automations.automations,
    URLS.notifications.notifications,
  ];
  const shouldHaveScreenSizeAlert = includeScreenSizeAlertPaths.some((pattern) =>
    pathname.startsWith(pattern),
  );

  const dashboardClassNames = classNames({
    "main-content-container": true,
    "widgets-dashboard": isDashboard,
  });

  const { setActiveTab } = useUIStore();
  const findPath = rootURLS.find((url) => pathname.includes(url));
  const isValid = isValidPathname(pathname, rootURLS);
  const showScreenSizeAlert =
    (userRole === "administrator" || userRole === "instructor") && !md && shouldHaveScreenSizeAlert;

  useEffect(() => {
    isValid ? setActiveTab(pathname) : findPath ? setActiveTab(findPath) : "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div css={main}>
      <LazyMotion features={domMax} strict>
        <MainNav />
      </LazyMotion>
      <div className={dashboardClassNames}>
        <MainHeader />
        <LazyMotion features={domAnimation} strict>
          <Drawer.Root />
          <MainDrawer />
          <GamificationDrawer />
          <Suspense fallback={<Loader fullScreen />}>
            <div id="scroll-container" css={content} className={contentContainerClassNames}>
              <div id="breadcrumb" />
              <main className="main-content-wrapper">
                <Announcements />
                {showScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
              </main>
            </div>
          </Suspense>
        </LazyMotion>
      </div>
    </div>
  );
};

export default DashboardLayout;

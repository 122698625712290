import React, { FC } from "react";
import { Button, Grid, Input, InputError, Modal } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { themeDialogFooter } from "./styles";
import { ThemeValidationSchema } from "@utils/validation";
import { createThemePostData, patchTheme, postTheme } from "@api/themes";
import { ColorPickerInput } from "@components";
import queryKeys from "@constants/queryKeys";
import { generalNotification } from "@utils/helpers";
import { Theme } from "types/entities";
import { AxiosError } from "axios";
import { handleCreateThemeErrors } from "@errors";

type CreateThemeDialogProps = {
  isOpen: boolean;
  theme: Theme | null;
  onHandleClose: () => void;
};

const ThemeDialog: FC<CreateThemeDialogProps> = ({ isOpen, theme, onHandleClose }) => {
  const queryClient = useQueryClient();

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<createThemePostData>({
    defaultValues: {
      name: theme?.name ?? "",
      primary_color: theme?.primary_color ?? "",
    },
    resolver: yupResolver(ThemeValidationSchema),
    mode: "onChange",
  });

  const handleData = async (data: createThemePostData): Promise<void> => {
    theme ? patchThemeMutation({ themeId: theme.id, data }) : postCreateThemeMutation(data);
  };

  const { mutate: postCreateThemeMutation, isLoading: postCreateThemeMutationLoading } =
    useMutation(queryKeys.themes.create, postTheme, {
      onSuccess: () => handleOnSuccess(),
      onError: (error: AxiosError) => handleOnError(error),
    });

  const { mutate: patchThemeMutation, isLoading: patchThemeMutationLoading } = useMutation(
    queryKeys.themes.edit,
    ({ themeId, data }: { themeId: string; data: createThemePostData }) =>
      patchTheme(themeId, data),
    {
      onSuccess: () => handleOnSuccess(),
      onError: (error: AxiosError) => handleOnError(error),
    },
  );

  const handleOnSuccess = (): void => {
    theme
      ? generalNotification("success", <p>{t("themes.editSuccessfully")}</p>)
      : generalNotification("success", <p>{t("themes.createSuccessfully")}</p>);

    queryClient.invalidateQueries([queryKeys.themes.themes]);
    onHandleClose();
  };

  const handleOnError = (error: AxiosError): void => {
    handleCreateThemeErrors(error);
  };

  const title = theme ? t("themes.edit") : t("themes.addNew");
  const isLoading = theme ? patchThemeMutationLoading : postCreateThemeMutationLoading;

  return (
    <Modal isOpen={isOpen} size="md">
      <Modal.Header>{title}</Modal.Header>
      <form onSubmit={handleSubmit(handleData)} autoComplete="off">
        <Modal.Body>
          <Grid templateColumns={1} rowGap={2}>
            <Controller
              name="primary_color"
              control={control}
              render={({ field: { onChange, value } }): JSX.Element => (
                <ColorPickerInput
                  id="primaryColor"
                  color={value}
                  error={errors.primary_color}
                  label={t("themes.primaryColor")}
                  placeholder={t("themes.primaryColor")}
                  onChange={onChange}
                />
              )}
            />
            <div>
              <Input
                id="name"
                label={t("general.name")}
                status={errors.name ? "error" : "valid"}
                placeholder={t("general.name")}
                {...register("name")}
              />
              {errors.name && <InputError>{errors.name.message}</InputError>}
            </div>
          </Grid>
        </Modal.Body>
        <Modal.Footer css={themeDialogFooter}>
          <Button type="button" color="secondary" onClick={onHandleClose}>
            {t("general.cancel")}
          </Button>
          <Button type="submit" isLoading={isLoading}>
            {t("general.save")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ThemeDialog;

import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";
import { GlobalStyleVariables } from "@styles";
const { unitsMaxWidth } = GlobalStyleVariables;

export const questionContainer = ({ units }) => css`
  display: flex;
  flex-direction: column;
  max-width: ${unitsMaxWidth};
  margin: auto;
  word-break: break-word;

  .title-container {
    background: ${units.test.questionBackground};
    padding: 3rem 1.5rem 1.5rem;

    ${mq["sm"]} {
      padding: 3rem 2rem 2rem;
    }

    ${mq["md"]} {
      padding: 3rem;
    }

    .title-content-container {
      max-width: ${unitsMaxWidth};
      margin: auto;

      .question-subtitle {
        margin-bottom: 0.5rem;
        margin-top: 0.75rem;

        ${mq["lg"]} {
          margin-top: 0;
        }
      }
    }
  }

  .answers-container {
    padding: 1.5rem;
    height: 100%;

    ${mq["md"]} {
      padding: 2rem;
    }

    &.no-padding {
      padding: 0;
    }

    .not-answered-tag-container {
      max-width: ${unitsMaxWidth};
      margin: auto;

      .not-answered-tag {
        margin-bottom: 1rem;
        background: ${units.test.notAnsweredBackground} !important;
        color: ${units.test.notAnsweredColor} !important;
      }
    }

    .answers-content-container {
      max-width: ${unitsMaxWidth};
      margin: auto;
      word-break: break-word;

      .points-percentage-container {
        margin-top: 1rem;
        font-style: italic;
      }
    }
  }
`;

export const orderingContainer = ({ units }) => css`
  .draggable-item {
    display: grid;
    grid-template-columns: calc(17.5px + 1rem) auto;
    align-items: center;
    padding: 1rem;
    margin: 0 auto 1.5rem;
    border: 1px solid ${units.test.borderNotAnswered};
    border-radius: 10px;
    background: #fff;

    &:last-of-type {
      margin-bottom: 0;
    }

    .draggable-item-text {
      word-break: break-word;
      margin-inline-start: 0.5rem;
    }
  }
`;

export const feedbackContainer = ({ units }) => css`
  padding-top: 1.5rem;

  ${mq["lg"]} {
    padding-top: 3rem;
  }

  .feedback-grid {
    background-color: ${units.test.questionBackground};
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    margin-bottom: 1rem;
  }

  .feedback-text {
    font-style: italic;
    padding-inline-start: 1rem;
  }
`;

export const fillTheGapContainer = css`
  .question-text,
  .question-options-container {
    margin-inline-end: 0.5rem;
  }

  .question-text {
    line-height: 2.5rem;
  }

  .question-options {
    height: 2.5rem;
    width: auto;
    min-width: 120px;

    /* TODO: apply these changes to DS for small size select */
    select {
      background-size: 0.75rem;
      padding-inline-end: 2rem;
    }
  }
`;

export const matchPairsContainer = ({ units }) => css`
  display: flex;
  flex-direction: column;
  height: 100%;

  .pair {
    padding: 1rem;
    background: ${units.test.matchPairs.background};
    border: 1px solid ${units.test.borderNotAnswered};
    border-radius: 5px;
    width: 100%;
    text-align: start;
    z-index: 3;
    min-height: 60px;
    word-break: break-word;
  }

  .answers-content-container {
    width: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex: 1;

    .pairs-header {
      padding-bottom: 1rem;
      text-align: center;

      ${mq["md"]} {
        padding-bottom: 1.5rem;
      }
    }

    .pairs-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      align-items: center;
      padding: 1rem;
      background: #e6eef6;
      border-radius: 10px;

      &:last-of-type {
        margin-bottom: 1rem;
      }

      ${mq["sm"]} {
        align-items: flex-start;
        flex-direction: row;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .left-pair-container {
        margin-bottom: 1rem;

        ${mq["sm"]} {
          margin-inline-end: 1rem;
          margin-bottom: 0;
        }
      }

      .left-pair-container,
      .right-pair-container {
        width: 100%;

        ${mq["sm"]} {
          width: 50%;
        }
      }
    }
  }
`;

export const mutlipleChoiceContainer = css`
  input[type="radio"] {
    display: none;
  }

  label {
    word-break: break-word;
  }
`;

export const ControlStyles = ({ base, isMenuOpen }) => ({
  ...base,
  backgroundColor: !isMenuOpen ? "#fff" : "#1978F3",
  minHeight: 60,
  borderColor: "#232323",
  borderRadius: 5,
  padding: "0 0.5rem 0 1.5rem",
  cursor: "pointer",
  "&:hover": {
    borderColor: "#1B68B3",
  },
});

export const PlaceholderStyles = ({ base, isMenuOpen }) => ({
  ...base,
  color: !isMenuOpen ? "#1B68B3" : "white",
  padding: 0,
  margin: 0,
});

export const OptionStyles = ({ base }) => ({
  ...base,
  padding: "0.75rem 1.75rem",
  backgroundColor: "transparent",
  color: "initial",
  wordBreak: "break-word",
  "&:hover": {
    backgroundColor: "#E6F2FF",
  },
  cursor: "pointer",
});

export const SingleValueStyles = ({ base, isMenuOpen }) => ({
  ...base,
  color: !isMenuOpen ? "#1B68B3" : "white",
  overflow: "unset",
  textOverflow: "unset",
  whiteSpace: "unset",
  wordBreak: "break-word",
  padding: "1rem 0",
  margin: 0,
});

export const ValueContainerStyles = ({ base }) => ({
  ...base,
  padding: 0,
  margin: 0,
});

export const ClearIndicatorStyles = ({ base, isMenuOpen }) => ({
  ...base,
  cursor: "pointer",
  color: !isMenuOpen ? "#1B68B3" : "white",
  "&:hover": {
    color: !isMenuOpen ? "#1B68B3" : "white",
  },
});

export const DropdownIndicatorStyles = ({ base, isMenuOpen }) => ({
  ...base,
  cursor: "pointer",
  color: !isMenuOpen ? "#1B68B3" : "white",
  "&:hover": {
    color: !isMenuOpen ? "#1B68B3" : "white",
  },
});

export const customMenu = css`
  .answered-questions {
    border-top: 1px solid #d4d4d4;

    .answered-questions-container {
      max-height: 350px;
      overflow-y: auto;
    }
  }

  .answer {
    background-color: transparent;
    color: initial;
    cursor: pointer;
    display: block;
    font-size: inherit;
    padding: 0.75rem 1.75rem;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    word-break: break-word;

    &:hover {
      background-color: #e6f2ff;
    }
  }

  .btn-container {
    text-align: center;
  }
`;

export const likertContainer = ({ units }) => css`
  max-width: ${unitsMaxWidth};
  margin: auto;

  .likert-wrapper {
    margin-bottom: 4rem;

    &:last-of-type {
      margin: 0;
    }
  }

  .likert-question-title {
    align-items: center;
    display: flex;
    min-height: 4rem;
    background: ${units.survey.background};
    border-radius: 20px;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
`;

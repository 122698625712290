import { css } from "@emotion/react";
import { typeScale } from "@styles/typography";

export const AccordionStyles = ({ accordion }, { canToggle }) => css`
  background-color: ${accordion.backgroundColor};
  box-shadow: 0 3px 6px ${accordion.shadowColor};
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.5rem 0;
    cursor: ${canToggle ? "pointer" : "default"};

    .accordion-header-content {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      width: 100%;

      .accordion-icon {
        line-height: 0;
      }

      .accordion-title {
        font-size: ${typeScale["md"]};
        width: 100%;
        font-weight: bold;
        margin-top: 2px;

        &:not(.is-expanded) {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
        }
      }
    }

    .accordion-actions {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .accordion-expand-icon {
        line-height: 0;
        transition: all 0.4s ease-in;
        fill: ${!canToggle ? accordion.disabledColor : "initial"};

        &.is-expanded {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .accordion-content {
    visibility: hidden;
    height: 0;

    &.is-expanded {
      visibility: visible;
      height: auto;
      padding: 0.5rem 0;
      transition: visibility 0.3s ease-in, height 0.2s ease-in;
    }
  }
`;

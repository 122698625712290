import React, { FC, MouseEvent, useEffect, useState } from "react";
import { Button, Modal, Text, Checkbox } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { modalFooter, modalBody } from "./styles";
import useUIStore from "@stores/ui";

export type ConfirmationModalProps = {
  id: number | string;
  header: string;
  bodyTitle: JSX.Element;
  bodyText?: JSX.Element | string;
  footerButton: JSX.Element | string;
  isOpen: boolean;
  hasDontAskAgainCheckbox?: boolean;
  closeOnOutsideClick?: boolean;
  confirmationButtonColor?: "primary" | "secondary" | "success" | "danger";
  onClose: () => void;
  onConfirm?: (id: number | string) => void;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  id,
  header,
  bodyTitle,
  bodyText,
  footerButton,
  isOpen,
  hasDontAskAgainCheckbox = false,
  closeOnOutsideClick = false,
  confirmationButtonColor = "danger",
  onClose,
  onConfirm,
}) => {
  const [shouldAskAgain, setShouldAskAgain] = useState(false);
  const { shouldShowConfirmationModal, setShouldShowConfirmationModal } = useUIStore(
    (state) => state,
  );

  useEffect(() => {
    if (!shouldShowConfirmationModal && hasDontAskAgainCheckbox) {
      id && hasDontAskAgainCheckbox && onConfirm && onConfirm(id);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    id && onConfirm && onConfirm(id);

    hasDontAskAgainCheckbox && setShouldShowConfirmationModal(shouldAskAgain);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOutsideClick={closeOnOutsideClick}>
        <Modal.Header>{header}</Modal.Header>
        <Modal.Body>
          <div css={modalBody}>
            <Text fontSize="sm" as="div" className="subject-text">
              {bodyTitle}
            </Text>
            <Text fontSize="sm" as="div" className="body-text">
              {bodyText}
            </Text>

            {hasDontAskAgainCheckbox && (
              <div className="dontaskagain-checkbox">
                <Checkbox
                  id="dont-ask-again"
                  name={t("general.dontAskAgain")}
                  label={t("general.dontAskAgain")}
                  value={shouldAskAgain.toString()}
                  onChange={(): void => setShouldAskAgain(!shouldAskAgain)}
                  checked={shouldAskAgain}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div css={modalFooter}>
            {typeof footerButton === "string" ? (
              <Button onClick={handleConfirm} color={confirmationButtonColor}>
                {footerButton}
              </Button>
            ) : (
              footerButton
            )}
            <Button color="secondary" onClick={onClose} className="cancel-btn">
              {t("general.cancel")}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;

import React, { FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { unitListItem } from "./styles";
import { Course, MyUnit } from "types/entities";
import { URLS } from "@constants/urls";
import { useIsExternalView } from "@hooks";
import CourseTocItemContent from "./CourseTocItemContent";
import { SerializedStyles } from "@emotion/react";

export type CourseTocItemProps = MyUnit & {
  course: Course;
  isActive: boolean;
  isReadonly: boolean;
  isLearnerView?: boolean;
  hasParentSection: boolean;
};

const containerClassNames = (
  isActive: boolean,
  status: string,
  isReadonly: boolean,
  isSampleUnit: boolean,
): string =>
  classNames({
    active: isActive || isSampleUnit,
    completed: !isReadonly ? status === "completed" : false,
    failed: !isReadonly ? status === "failed" : false,
    readonly: isReadonly && !isSampleUnit,
  });

type getTocItemLinkProps = {
  courseId: string;
  unitId: string;
  isSampleUnit: boolean;
  isExternalView: boolean;
  isLearnerView?: boolean;
};

const getTocItemLink = ({
  courseId,
  unitId,
  isSampleUnit,
  isLearnerView,
  isExternalView,
}: getTocItemLinkProps): string => {
  const catalogLink = isExternalView
    ? URLS.externalCatalog.createSampleUnitLink({ courseId: courseId, unitId: unitId })
    : URLS.catalog.createSampleUnitLink({ courseId: courseId, unitId: unitId });

  if (!isLearnerView) return URLS.units.getUnitEditLink({ courseId, unitId });

  return isSampleUnit ? catalogLink : URLS.user.createUnitLink({ courseId, unitId });
};

const CourseTocItem: FC<CourseTocItemProps> = (props) => {
  const {
    id: unitId,
    name,
    isActive,
    progress,
    isReadonly,
    sample,
    course,
    isLearnerView,
    hasParentSection,
  } = props;

  const { availability, id: courseId } = course;
  const isSampleUnit = sample && !availability;
  const isExternalView = useIsExternalView();

  const tocItemLink = getTocItemLink({
    courseId: courseId.toString(),
    unitId: unitId.toString(),
    isSampleUnit,
    isExternalView,
    isLearnerView,
  });

  // Instructor/admin view
  if (!isLearnerView) {
    const { can_view: canView = false } = props.policies ?? {};

    return (
      <dd
        css={(theme): SerializedStyles => unitListItem(theme, { hasParentSection })}
        className="active"
        data-testid="toc-item"
      >
        {!canView ? (
          <CourseTocItemContent {...props} />
        ) : (
          <Link to={tocItemLink} key={unitId} title={name}>
            <CourseTocItemContent {...props} />
          </Link>
        )}
      </dd>
    );
  }

  // Learner view
  return (
    <dd
      css={(theme): SerializedStyles => unitListItem(theme, { hasParentSection })}
      className={containerClassNames(isActive, progress?.status ?? "", isReadonly, isSampleUnit)}
      data-testid="toc-item"
    >
      {/* If the unit is not sample but its readonly or inactive */}
      {!isSampleUnit && (isReadonly || !isActive) ? (
        <CourseTocItemContent {...props} />
      ) : (
        <Link to={tocItemLink} key={unitId} title={name}>
          <CourseTocItemContent {...props} isSampleUnit={isSampleUnit} />
        </Link>
      )}
    </dd>
  );
};

export default CourseTocItem;

import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Badge, Sidebar, Tooltip } from "@epignosis_llc/gnosis";
import useUIStore, { MainDrawerContent } from "@stores/ui";
import classNames from "classnames";
import { MainNavData } from "../MainNav";
import { secondaryURLS } from "@constants/urls";

type MenuItemProps = {
  title: string;
  url?: string;
  drawerKey: string;
  icon: JSX.Element | null;
  isActive: boolean;
  navState?: MainNavData;
  isCollapsed?: boolean;
  withBadge?: boolean;
  badgeNumber?: number;
  handleClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    drawerKey: MainDrawerContent,
  ) => void;
};

const navItemClassNames = (isActiveTab: boolean): string =>
  classNames({
    "nav-item": true,
    active: isActiveTab,
  });

const MenuItem: FC<MenuItemProps> = ({
  title,
  url = "#",
  drawerKey,
  icon,
  isCollapsed = false,
  isActive,
  withBadge = false,
  badgeNumber = 0,
  handleClick,
}) => {
  const { activeTab } = useUIStore();
  const location = useLocation();
  const pathname = location.pathname;
  const isSecondaryOption =
    secondaryURLS.find((url) => pathname.includes(url)) &&
    pathname.includes(title.toLocaleLowerCase());
  const isActiveTab = activeTab === url || Boolean(isSecondaryOption);

  const itemIcon =
    withBadge && Boolean(badgeNumber) ? (
      <Badge
        color="inherit"
        badgeContent={badgeNumber.toString()}
        offset={{ top: "-5px", right: "-2px" }}
      >
        {icon}
      </Badge>
    ) : (
      icon
    );

  return (
    <Tooltip content={title} disabled={!isCollapsed} placement="right">
      <li key={title} id={drawerKey ?? ""} className={navItemClassNames(isActiveTab)}>
        <Link
          to={url}
          title={!isCollapsed ? title : ""}
          onClick={(e): void => {
            handleClick && handleClick(e, drawerKey as MainDrawerContent);
          }}
        >
          <Sidebar.Item
            icon={itemIcon}
            label={title}
            isExpanded={!isCollapsed}
            isActive={isActive}
          />
        </Link>
      </li>
    </Tooltip>
  );
};

export default MenuItem;

import { css } from "@emotion/react";

export const ButtonWithDropdownStyles = (hasList) => css`
  display: flex;
  position: relative;

  ${hasList &&
  `
    .start-button {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }
  `}

  /* unset position to place dropdown wrapper in component container div */
  .dropdown {
    position: unset;
  }

  .end-button {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }

  .vertical-line {
    position: absolute;
    border-left: 1px solid white;
    top: 0;
    inset-inline-end: 2.5rem;
    height: 100%;
  }
`;

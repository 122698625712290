import { css } from "@emotion/react";

export const videoContainer = (hasUrlAndContent, hasUrlAndSecondaryContent) => css`
  position: relative;

  .video-player-container {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .content {
    padding-bottom: ${hasUrlAndContent ? "2rem" : "0"};
    word-break: break-word;
  }

  .secondary-content {
    padding-top: ${hasUrlAndSecondaryContent ? "2rem" : "0"};
    word-break: break-word;
  }

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .processing {
    top: 45%;
  }
  .subtitle {
    top: 52%;
    white-space: nowrap;
    overflow-wrap: break-word;
  }
`;

import { css } from "@emotion/react";

export const mainMenuInner = ({ sidebar, leaveBeta }, { isImpersonationEnabled }) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 0%;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    .backToAdmin {
      margin-top: 1rem;
    }

    .profile {
      margin-top: ${!isImpersonationEnabled ? "1rem" : 0};

      &.active {
        background: ${sidebar.backgroundHover};
      }
    }

    .nav-item {
      &.active {
        background: ${sidebar.backgroundHover};
      }
    }
  }

  a {
    &:hover,
    &.focus-visible {
      .nav-item {
        .content-container {
          border-color: ${sidebar.backgroundHover};
        }
      }
    }

    &.leave-beta {
      .nav-item {
        color: ${leaveBeta.color};
        font-weight: bold;
      }
    }

    .has-content {
      .content-container {
        border-color: ${sidebar.background};
      }
    }
  }
`;

import React, { FC, useEffect } from "react";
import { useResponsive } from "ahooks";
import { Drawer } from "@epignosis_llc/gnosis";
import { mainDrawerContainer } from "./styles";
import {
  DrawerMainMenuMobile,
  DrawerProfile,
  MessagesDrawer,
  DiscussionsDrawer,
  DiscussionsFooter,
  MessagesFooter,
} from "@components";
import { getMainDrawerHeader } from "@utils/helpers";
import { useUIStore } from "@stores";
import { DrawerProps } from "types/common";
import { GlobalStyleVariables } from "@styles";
import i18n from "@utils/i18n";
import { profileHeader } from "@components/Drawers/Profile/styles";
const { mainDrawerMinWidth } = GlobalStyleVariables;

type MainDrawerProps = {
  layout?: "dashboard" | "unit";
};

const MainDrawer: FC<MainDrawerProps> = ({ layout = "dashboard" }) => {
  const { md } = useResponsive();
  const { mainNavState, mainDrawer, hideMainDrawer, showMainDrawer } = useUIStore((state) => state);
  const isMainNavCollapsed = mainNavState === "collapsed";
  const { show, content } = mainDrawer;
  const header = getMainDrawerHeader(content);
  const drawerWidth = content === "mainMenu" ? mainDrawerMinWidth : "100%";
  const isClosable = !md && content !== "mainMenu";
  const showFooter = ["messages", "discussions"].includes(content);

  const onClose = (): void => {
    hideMainDrawer();
  };

  const commonProps: Pick<DrawerProps, "onClose" | "dialogStyles"> = {
    onClose,
    dialogStyles:
      md && layout === "dashboard"
        ? { insetInlineStart: isMainNavCollapsed ? "4.75rem" : mainDrawerMinWidth }
        : undefined,
  };

  const isRtl = i18n.dir() === "rtl";
  const placement = isRtl ? "right" : "left";

  useEffect(() => {
    if (show && content === "mainMenu" && md) {
      hideMainDrawer();
    }
  }, [content, hideMainDrawer, show, md]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Drawer
      isOpen={show}
      width={!md ? drawerWidth : "31.25rem"}
      css={mainDrawerContainer}
      placement={placement}
      {...commonProps}
    >
      {header && (
        <Drawer.Header closable={isClosable} css={profileHeader}>
          {header}
        </Drawer.Header>
      )}

      <Drawer.Body>
        {content === "mainMenu" && (
          <DrawerMainMenuMobile showMainDrawer={showMainDrawer} hideMainDrawer={hideMainDrawer} />
        )}
        {content === "messages" && <MessagesDrawer />}
        {content === "discussions" && <DiscussionsDrawer />}
        {content === "profile" && <DrawerProfile />}
      </Drawer.Body>

      {showFooter && (
        <Drawer.Footer>
          {content === "messages" && <MessagesFooter />}
          {content === "discussions" && <DiscussionsFooter />}
        </Drawer.Footer>
      )}
    </Drawer>
  );
};

export default MainDrawer;

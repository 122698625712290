import { css } from "@emotion/react";

export const RecordingPreviewStyles = ({ recording: { audio } }) => css`
  height: 100%;

  .react-player {
    line-height: 0;
  }

  .preview-stream-container {
    &.video {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.audio {
      .audio-recording {
        height: 100%;
        background: ${audio.backgroundColor};

        svg {
          position: absolute;
          top: 50%;
          inset-inline-start: 50%;
          transform: translate(-50%, -50%);
          color: ${audio.color};
        }
      }
    }

    .actions-container {
      position: absolute;
      bottom: 0;
      width: 100%;

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-image: linear-gradient(360deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }

      .actions {
        position: relative;
        padding: 0.5rem 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
        z-index: 10;

        & > span {
          line-height: 0;
        }

        .action-item {
          color: white;
          cursor: pointer;
        }

        .recording-duration {
          color: white;
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }

  .recording-preview-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.video {
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

      .react-player {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 64px) !important;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5rem;
    }
  }
`;

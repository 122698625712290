import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useResponsive } from "ahooks";
import { Heading, Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { iltContainer } from "./styles";
import InstructorReply from "./components/InstructorReply";
import IltItem from "./components/IltItem";
import RegisterButton from "./components/RegisterButton";
import { MyUnit, Session } from "types/entities/Unit";
import { truncate, generalNotification } from "@utils/helpers";
import { putIltRegister, putIltUnregister, getConferenceURL } from "@api/courses";
import queryKeys from "@constants/queryKeys";
import ConfirmationModal from "@components/ReusableModals/ConfirmationModal";
import { Course } from "types/entities";
import { AxiosError } from "axios";
import { handleConferenceErrors } from "@errors";

type IltProps = {
  course: Course;
  sessions: Session[];
  unit: MyUnit;
  isRegistered: boolean;
  isPublic?: boolean;
};

const getHeaderText = (
  isCompleted: boolean,
  sessionsLength: number,
  isRegistered: boolean,
  hasSessionStarted: boolean,
): string => {
  if (isCompleted) {
    return t("ilt.titleCompleted");
  }

  if (isRegistered) {
    return hasSessionStarted ? t("ilt.titleCompleted") : t("ilt.titleSessions", { count: 1 });
  }

  return t("ilt.titleSessions", { count: sessionsLength });
};

export const getTooltipContent = (
  isSessionFull: boolean,
  isCompleted: boolean,
  isInstructor: boolean,
  isPublic: boolean,
): string => {
  if (isPublic) {
    return t("externalCatalog.publicCourse.signUpToRegister");
  }

  if (isInstructor) {
    return t("ilt.youAreInstructor");
  }

  if (isSessionFull) {
    return t("ilt.sessionFull");
  }

  if (isCompleted) {
    return t("ilt.youHaveCompletedSession");
  }

  return "";
};

const getIsButtonDisabled = (
  isPublic: boolean,
  is_registered: boolean,
  canRegister: boolean,
  canUnregister: boolean,
): boolean => {
  if (isPublic) return true;

  return !is_registered ? !canRegister : !canUnregister;
};

const Ilt: FC<IltProps> = ({ course, sessions, unit, isRegistered, isPublic = false }) => {
  const { md } = useResponsive();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<{ id: number; name: string } | null>(null);
  const queryClient = useQueryClient();
  const isCompleted = unit?.progress?.status === "completed" || unit.progress?.status === "failed";
  const hasSessionStarted = unit.progress?.status === "pending";
  const dataToMap = isRegistered ? sessions.filter((item) => item.is_registered) : sessions;
  const isInstructor = course?.role_in_course === "instructor"; //TODO: waiting for the API fix on checking if I am an istructor or not

  const handleRegister = (id: number, name: string): void => {
    setSelectedItem({ id, name });
    registerMutation(id.toString());
  };

  const handleUnregister = (id: number, name: string): void => {
    setSelectedItem({ id, name });
    setIsConfirmationModalOpen(true);
  };

  const { mutate: registerMutation, isLoading: registerLoading } = useMutation(putIltRegister, {
    onSuccess: async () => {
      queryClient.invalidateQueries([queryKeys.iltUnit.sessions]);
      queryClient.invalidateQueries([queryKeys.unit, unit.id]);

      generalNotification("success", <p>{t("ilt.registeredSuccessfully")}</p>);
      setSelectedItem(null);
    },
    onError: () => {
      generalNotification("error", <p>{t("ilt.youCannotRegister")}</p>);
    },
  });

  const { mutate: unregisterMutation, isLoading: unregisterLoading } = useMutation(
    putIltUnregister,
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([queryKeys.iltUnit.sessions]);

        generalNotification("success", <p>{t("ilt.unregisteredSuccessfully")}</p>);
        setSelectedItem(null);
      },
      onError: () => {
        generalNotification("error", <p>{t("ilt.youCannotUnRegister")}</p>);
      },
    },
  );

  const { mutate: joinUrlMutation, isLoading: joinUrlLoading } = useMutation(getConferenceURL, {
    onSuccess: async (res) => {
      window.open(res._data.join_url);
    },
    onError: (error: AxiosError) => {
      handleConferenceErrors(error);
    },
  });

  const handleJoin = (webinar_id: string): void => {
    if (webinar_id) {
      joinUrlMutation(webinar_id);
    }
  };

  const handleRegisterUnregister = (isRegistered: boolean, id: number, name: string): void => {
    isRegistered ? handleUnregister(id, name) : handleRegister(id, name);
  };

  const registerUnregisterLoading = registerLoading || unregisterLoading;

  return (
    <section css={iltContainer} className="unit-container">
      <Heading size="md" className="title">
        {getHeaderText(isCompleted, sessions.length, isRegistered, hasSessionStarted)}
      </Heading>

      {unit.content && (
        <Text as="div" fontSize="sm" className="unit-description">
          {unit.content}
        </Text>
      )}

      <>
        {sessions.length > 0 ? (
          <>
            {dataToMap.map((session, index) => {
              const {
                sessions,
                users_registered,
                capacity,
                is_registered,
                can_register,
                can_unregister,
              } = session;
              const isMultiSession = sessions.length > 1;
              const isSessionFull = users_registered === capacity;
              const multiSessionId = (isMultiSession && sessions[0].id) as number;
              const canRegister = Boolean(can_register) && !isInstructor;
              const canUnregister = Boolean(can_unregister);
              const isButtonDisabled = getIsButtonDisabled(
                isPublic,
                is_registered,
                canRegister,
                canUnregister,
              );

              return (
                <div
                  id={`session-${session.name}`}
                  key={`session-${session.name}-${index}`}
                  className="session-wrapper"
                >
                  {isMultiSession && (
                    <div className="multi-session-header">
                      <Text
                        fontSize="md"
                        weight="700"
                        as="div"
                        className="multi-session-header-title"
                      >
                        {truncate(session.name, md ? 50 : 20)}
                      </Text>

                      {md && (
                        <RegisterButton
                          session={session}
                          disabled={getIsButtonDisabled(
                            isPublic,
                            is_registered,
                            canRegister,
                            canUnregister,
                          )}
                          isLoading={
                            registerUnregisterLoading && multiSessionId === selectedItem?.id
                          }
                          canUnregister={canUnregister}
                          tooltipContent={getTooltipContent(
                            isSessionFull,
                            isCompleted,
                            isInstructor,
                            isPublic,
                          )}
                          handleClick={(): void =>
                            handleRegisterUnregister(
                              session.is_registered,
                              multiSessionId,
                              session.name,
                            )
                          }
                        />
                      )}
                    </div>
                  )}

                  {session.sessions.map((sessionItem) => {
                    const iltInfo = {
                      session,
                      sessionItem,
                      selectedItem,
                      isMultiSession,
                      isSessionFull,
                      canUnregister,
                      registerUnregisterLoading,
                      joinUrlLoading,
                      registerUnregisterTooltipContent: getTooltipContent(
                        isSessionFull,
                        isCompleted,
                        isInstructor,
                        isPublic,
                      ),
                      isButtonDisabled,
                      handleRegisterUnregister,
                      handleJoin,
                    };

                    return (
                      <div
                        key={`item-${sessionItem.id}`}
                        className="session-container"
                        id={`item-${sessionItem.id}`}
                      >
                        <IltItem {...iltInfo} />
                      </div>
                    );
                  })}

                  {!md && isMultiSession && (
                    <footer className="register-btn-mobile">
                      <RegisterButton
                        session={session}
                        disabled={getIsButtonDisabled(
                          isPublic,
                          is_registered,
                          canRegister,
                          canUnregister,
                        )}
                        isLoading={registerUnregisterLoading && multiSessionId === selectedItem?.id}
                        canUnregister={canUnregister}
                        tooltipContent={getTooltipContent(
                          isSessionFull,
                          isCompleted,
                          isInstructor,
                          isPublic,
                        )}
                        handleClick={(): void =>
                          handleRegisterUnregister(
                            session.is_registered,
                            multiSessionId,
                            session.name,
                          )
                        }
                      />
                    </footer>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <Text as="div" fontSize="md" weight="700" className="no-sessions-text">
            {t("ilt.noSessions")}
          </Text>
        )}
      </>

      {isCompleted && <InstructorReply unit={unit} />}

      {selectedItem && (
        <ConfirmationModal
          id={selectedItem.id}
          header={t("ilt.unregisterModalTitle")}
          bodyTitle={
            <Text
              fontSize="sm"
              dangerouslySetInnerHTML={{
                __html: t("ilt.unregisterModalBody", { name: selectedItem.name }),
              }}
            ></Text>
          }
          footerButton={t("ilt.unregister")}
          isOpen={isConfirmationModalOpen}
          onClose={(): void => setIsConfirmationModalOpen(false)}
          onConfirm={(): void => unregisterMutation(selectedItem.id.toString())}
        />
      )}
    </section>
  );
};

export default Ilt;

import { css } from "@emotion/react";

export const fileInputContainer = css`
  input[type="file"] {
    display: none;
  }

  .label-container {
    display: flex;

    button {
      padding: 0;
      margin-inline-end: 1rem;
    }

    .tooltip {
      max-width: 15rem;
    }
  }

  .attachments-layer {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;

    .attachments {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .attachment-tag {
        position: relative;
        word-break: break-word;
      }
    }
  }
`;

export const DragAndDropAreaContainer = ({ fileInput, dragging }) => css`
  width: 100%;
  position: relative;
  z-index: 4;
  min-height: ${dragging ? "15rem" : "0"};

  .label-container {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0px;
    }
  }

  .hidden {
    display: none;
  }

  .visually-hidden {
    opacity: 0;
  }

  .drop-files {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${fileInput.background};
    border: 2px dashed ${fileInput.border};
    z-index: 999;
    transition: all 0.3s linear;

    .drop-files-info {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      button {
        margin: auto;
        display: block;
        margin-bottom: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
      }

      .rules {
        width: 75%;
        margin: auto;
        margin-top: 0.5rem;
      }
    }
  }
`;

import React, { FC, ReactNode, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { SerializedStyles } from "@emotion/react";
import { audioPlayerContainer } from "./styles";
import { MyUnit, PlayerState } from "types/entities";
import { disableRightClick } from "@utils/helpers";
import { FileFailed, FileProcessing } from "@components";

type AudioProps = MyUnit & {
  playerState: PlayerState;
  onPlay: () => void;
  onPause: () => void;
  onDurationChange: (duration: number) => void;
  children: ReactNode;
};

const Audio: FC<AudioProps> = ({
  url,
  content,
  secondary_content,
  media_options,
  playerState,
  status,
  onPlay,
  onPause,
  onDurationChange,
  children,
}) => {
  const audio = document.getElementsByTagName("audio")[0];
  const audioEl = useRef<ReactPlayer>(null);

  const { auto_pause, sync_timer } = media_options ?? {};
  const hasUrlAndContent = Boolean(url && content);
  const hasUrlAndSecondaryContent = Boolean(url && secondary_content);
  const { isPlaying } = playerState;

  const config = {
    file: {
      attributes: {
        controlsList: "nodownload noplaybackrate",
        disablePictureInPicture: true,
      },
    },
  };

  const previousTime = useRef(0);

  if (audio && sync_timer) {
    audio.ontimeupdate = (): void => {
      setTimeout(() => {
        previousTime.current = audio.currentTime;
      }, 1000);
    };

    audio.onseeking = (): void => {
      if (audio.currentTime > previousTime.current) {
        audio.currentTime = previousTime.current;
      }
    };
  }

  const handleOnPause = (): void => {
    const videoRef = audioEl?.current;
    const currentTime = videoRef?.getCurrentTime() ?? 0;
    const duration = videoRef?.getDuration() ?? 0;

    // disable playing only when the video has not ended
    if (currentTime < duration) onPause();
  };

  // Preventing users to right click a video.
  useEffect(() => {
    disableRightClick("audio");
  }, [audio]);

  useEffect(() => {
    if (auto_pause) {
      window.addEventListener("focus", onPlay);
      window.addEventListener("blur", onPause);
    }

    return () => {
      window.removeEventListener("focus", onPlay);
      window.removeEventListener("blur", onPause);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auto_pause]);

  const renderAudioContent = (): JSX.Element => {
    switch (status) {
      case "ready":
        return (
          <div className="video-player-container">
            <ReactPlayer
              ref={audioEl}
              id="audio"
              className="react-player"
              height="3.5rem"
              width="100%"
              url={url ?? undefined}
              config={config}
              controls
              playing={isPlaying}
              pip={false}
              onPlay={onPlay}
              onPause={handleOnPause}
              onDuration={onDurationChange}
            />
          </div>
        );
      case "failed":
        return <FileFailed />;
      case "processing":
        return <FileProcessing />;
      default:
        return <></>;
    }
  };

  return (
    <div
      css={(): SerializedStyles =>
        audioPlayerContainer(hasUrlAndContent, hasUrlAndSecondaryContent)
      }
      data-testid="audio-player"
      className="unit-container"
    >
      {content && <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>}
      {renderAudioContent()}
      {secondary_content && (
        <div
          className="secondary-content"
          dangerouslySetInnerHTML={{ __html: secondary_content }}
        ></div>
      )}
      {children}
    </div>
  );
};

export default Audio;

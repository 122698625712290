import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import { drawerDiscussionContainer } from "./styles";
import { URLS } from "@constants/urls";
import { useUIStore } from "@stores";
import { getFormattedUserName, truncate } from "@utils/helpers";
import { Discussion } from "types/entities";
import { getDistanceFromNow } from "@utils/helpers/date-time";
import { UserAvatar } from "@components/ReusableComponents";

const getTotalReplies = (totalReplies: number): string =>
  `${totalReplies} ${t("general.commentsLowercase", {
    count: totalReplies,
  })}`;

const getCreator = ({ latest_reply, creator }: Discussion): string => {
  if (!latest_reply) {
    const { name, surname, login } = creator;
    const user = getFormattedUserName({ name, surname, login });
    return t("discussions.drawer.createdBy", {
      user: user,
    });
  } else {
    const { created_at, creator } = latest_reply;
    const { name, surname, login } = creator;
    const user = getFormattedUserName({ name, surname, login });
    const date = getDistanceFromNow(created_at);
    return t("discussions.drawer.latestBy", { user: user, date: date });
  }
};

const DrawerDiscussion: FC<Discussion> = (props) => {
  const { hideMainDrawer } = useUIStore((state) => state);
  const { id, title, creator, total_replies } = props;
  const { name, surname, avatar } = creator;

  const goToDiscussionThread = (): void => {
    hideMainDrawer();
  };

  return (
    <article css={drawerDiscussionContainer} aria-placeholder={`message-${id}`}>
      <Link
        to={URLS.user.getSingleDiscussionLink({ discussionId: id.toString() })}
        onClick={goToDiscussionThread}
        className="link-wrapper"
      >
        <UserAvatar
          avatar={avatar}
          imageSize="sm"
          avatarSize="xs"
          name={name}
          alt={`${name} ${surname}`}
        />
        <div className="discussion-info">
          <Text fontSize="sm" className="subject-title">
            {truncate(title, 55)}
          </Text>
          <div>
            <Text fontSize="xs">{getTotalReplies(total_replies)}</Text>
            <Text fontSize="xs" className="creator-latestBy">
              {getCreator(props)}
            </Text>
          </div>
        </div>
      </Link>
    </article>
  );
};

export default DrawerDiscussion;

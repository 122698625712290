import React, { FC, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LazyMotion, domMax } from "framer-motion";
import { Drawer, Loader } from "@epignosis_llc/gnosis";
import { unitLayoutContainer } from "./styles";
import UnitMainDrawer from "./components/UnitMainDrawer";
import { GamificationDrawer } from "@layouts/DashboardLayout/components";
import { useIsExternalView } from "@hooks";
import { useResponsive } from "ahooks";
import authService from "@utils/services/AuthService";
import { ScreenSizeAlert } from "@components/ReusableComponents";

const UnitLayout: FC = () => {
  const { md } = useResponsive();
  const userRole = authService.getDefaultRole();
  const isExternalView = useIsExternalView();
  const showScreenSizeAlert = (userRole === "administrator" || userRole === "instructor") && !md;

  return (
    <main css={unitLayoutContainer}>
      <Suspense fallback={<Loader fullScreen />}>
        <Drawer.Root />
        <UnitMainDrawer />
        {!isExternalView && <GamificationDrawer />}
        <LazyMotion features={domMax}>
          {showScreenSizeAlert ? <ScreenSizeAlert /> : <Outlet />}
        </LazyMotion>
      </Suspense>
    </main>
  );
};

export default UnitLayout;

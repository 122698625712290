export default {
  domainSettings: "domain-settings",
  ssoDomainSettings: "sso-domain-settings",
  socialDomainSettings: "social-domain-settings",
  autologin: "autologin",
  autologinToken: "autologin-token",
  gamificationSettings: "gamification-settings",
  userProfile: "user-profile",
  userPassword: "user-password",
  userAvatar: {
    post: "user-avatar",
    delete: "delete-user-avatar",
  },
  accountAndSettings: {
    securitySettings: {
      getSecuritySettings: "get-security-settings",
      saveSecuritySettings: "save-security-settings",
    },
    export: {
      availableTypes: "available-export-types",
      create: "create-export",
    },
    import: {
      import: "import-file",
    },
    ftpSync: {
      saveServerConfig: "save-server-config",
      getServerConfig: "get-server-config",
      getExportConfig: "get-export-config",
      getImportConfig: "get-import-config",
      saveImportConfig: "save-import-config",
      saveExportConfig: "save-export-config",
      testConnection: "test-connection",
      importNow: "import-now",
      exportNow: "export-now",
    },
    courseSettings: {
      getCourseSettings: "get-course-settings",
      updateCourseSettings: "update-course-settings",
    },
  },
  myCourses: "my-courses",
  user: {
    update: "user-update",
    create: "user-create",
    delete: "user-delete",
  },
  myFiles: "my-files",
  myGroups: "my-groups",
  files: {
    tags: "tags",
  },
  groups: {
    availableCourses: "available-courses",
    addCourse: "add-course",
    groups: "groups",
    files: "group-files",
    postFile: "post-file",
    updateFile: "update-file",
    group: "group",
    courses: "courses",
    removeCourse: "remove-course",
    update: "group-update",
    create: "group-create",
    delete: "delete",
    deleteFile: "delete-file",
    addUser: "add-user",
    availableUsers: "available-users",
    removeUser: "remove-user",
    addUserCourses: "add-user-courses",
    removeUserCourses: "remove-user-courses",
    groupOwner: "group-owner",
    users: "users",
  },
  allGroups: "all-groups",
  myBranches: "my-branches",
  branches: {
    addCourse: "add-course",
    addUser: "add-user",
    availableCourses: "available-courses",
    availableUsers: "available-users",
    branches: "branches",
    courses: "courses",
    clone: "clone-branch",
    delete: "delete-branch",
    files: "branch-files",
    postFile: "post-file",
    updateFile: "update-file",
    deleteFile: "delete-file",
    removeCourse: "remove-course",
    removeUser: "remove-user",
    users: "users",
  },
  joinGroup: "join-group",
  myCertificates: "my-certificates",
  myBadges: "my-badges",
  rewards: "my-rewards",
  allBadges: "all-badges",
  catalog: "catalog",
  catalogCategories: "catalog-categories",
  topCategories: "top-categories",
  myCourse: "my-course",
  course: "course",
  unit: "unit",
  sampleUnit: "sample-unit",
  units: "units",
  unitEnter: "enter",
  unitResults: "unit-results",
  achievements: "achievements",
  leaderboard: "leaderboard",
  userStatistics: "user-statistics",
  searchResults: "search-results",
  customFields: "custom-fields",
  userIntegrations: "user-integrations",
  assignmentUnit: {
    freeTextReply: "assignment-free-text-reply",
    fileUpload: "assignment-file-upload",
  },
  iltUnit: {
    sessions: "ilt-sessions",
    register: "session-register",
    unregister: "session-unregister",
  },
  rating: "rating",
  unitPassword: "unit-password",
  unitSnapshot: "unit-snapshot",
  unitStart: "unit-start",
  unitReset: "unit-reset",
  messages: {
    message: "message",
    inbox: "messages-inbox",
    sent: "messages-sent",
    delete: "message-delete",
    create: "message-create",
    reply: "message-reply",
    forward: "message-forward",
    recipients: "message-recipients",
    attachment: "message-attachment",
    unreadMessages: "unread-messages",
  },
  discussions: {
    discussion: "discussion",
    active: "discussions-active",
    singleDiscussion: "discussion-thread",
    delete: "discussion-delete",
    editDiscussion: "discussion-edit",
    deleteComment: "discussion-delete-comment",
    deleteRply: "discussion-delete-reply",
    create: "discussion-create",
    replies: "discussion-replies",
    comment: "discussion-comment",
    editComment: "discussion-comment-edit",
    reply: "discussion-comment-reply",
    audience: "discussions-audience",
    attachment: "discussions-attachment",
    like: "discussion-like",
    unlike: "discussion-unlike",
  },
  dashboard: {
    today: "today",
    todayInstructor: "today-instructor",
    dontMiss: "dont-miss",
    portalActivity: "portal-activity",
    coursePie: "course-pie",
    overviewWidget: "overview-widget",
    recentCourseActivity: "recent-course-activity",
  },
  search: {
    spotlight: "spotlight",
  },
  calendar: {
    events: "calendar-events",
    export: "calendar-export",
    statistics: "calendar-statistics",
    sync: "calendar-sync",
    syncReset: "calendar-sync-reset",
  },
  contentTokens: "content-tokens",
  courseEnrollment: "course-enrollment",
  courseSubscription: "course-subscription",
  bundles: "catalog-bundles",
  branchSwitch: "branch-switch",
  catalogSettings: "catalog-settings",
  announcements: {
    announcements: "announcements",
    close: "announcements-close",
  },
  domainTerms: {
    terms: "domain-terms",
    accept: "accept-domain-terms",
  },
  couponValidation: "coupon-validation",
  themes: {
    themes: "themes",
    active: "active-theme",
    create: "create-theme",
    edit: "edit-theme",
    delete: "delete-theme",
  },
  courses: {
    addGroup: "add-group",
    availableGroups: "available-groups",
    addBranch: "add-branch",
    availableBranches: "available-branches",
    branches: "branches",
    clone: "course-clone",
    create: "course-create",
    courses: "courses",
    courseEdit: "course-edit",
    customFields: "course-custom-fields",
    delete: "delete-course",
    deleteFiles: "delete-course-files",
    deleteIntroVideo: "delete-course-intro-video",
    files: "course-files",
    images: "course-images",
    groups: "groups",
    patchUser: "patch-course-user",
    postImage: "post-course-image",
    postFile: "post-course-file",
    postUnit: "post-course-unit",
    prerequisiteOptions: "prerequisite-options",
    publish: "course-publish",
    removeGroup: "remove-group",
    removeBranch: "remove-branch",
    rulesEdit: "course-rules-edit",
    sectionCreate: "section-create",
    tags: "course-tags",
    unenrolledCourseUsers: "unenrolled-course-users",
    unitCreate: "unit-create",
    unitDelete: "unit-delete",
    unitPublish: "unit-publish",
    unitUpdate: "unit-update",
    unitsOrder: "units-order",
    unitSmartTags: "unit-smart-tags",
    users: "course-users",
    editUser: "edit-user",
    syncProgress: "sync-progress",
    updateExpirationDate: "update-expiration-date",
    updateFile: "update-course-file",
    youtubeSearch: "youtube-search",
  },
  signin: "signin",
  signup: {
    signup: "signup",
    verify: "verify",
  },
  passwordReset: {
    requestReset: "request-reset",
    verifyCode: "verify-code",
    passwordReset: "password-reset",
  },
  changePassword: "change-password",
  invoices: {
    myInvoices: "my-invoices",
    invoice: "invoice",
    invoiceDownload: "invoice-download",
    updateCreditCard: "update-credit-card",
    userInvoices: "user-invoices",
  },
  courseUnits: "course-units",
  courseUnenroll: "course-unenroll",
  categories: {
    categories: "categories",
    create: "category-create",
    delete: "category-delete",
  },
  unitUsers: "unit-users",
  users: {
    addGroup: "add-group",
    badges: "badges",
    files: "user-files",
    postFile: "post-file",
    updateFile: "update-file",
    deleteFile: "delete-file",
    users: "users",
    user: "user",
    enrollToCourse: "enroll-user",
    update: "update",
    create: "create",
    courses: "courses",
    types: "types",
    delete: "delete",
    groups: "groups",
    branches: "branches",
    availableGroups: "available-groups",
    availableCourses: "available-courses",
    units: "user-units",
    completeUnit: "complete-unit",
    resetUnit: "reset-unit",
    availableBranches: "available-branches",
    impersonation: "impersonation",
    stopImpersonation: "stop-impersonation",
  },
  widgetsDashboard: {
    widgets: "widgets",
    timeline: "timeline-widget",
    smallestCompletionRate: "smallest-completion-rate",
    users: "users-widget",
  },
  timeline: {
    timelineEvents: "timeline-events",
    deleteEvent: "delete-event",
    restoreEvent: "restore-event",
  },
  publicCourseKey: "public-course-key",
  questions: {
    create: "create-question",
    get: "questions",
    submit: "question-submit",
    tags: "questions-tags",
  },
  microstats: {
    unitMicrostats: "unit-microstats",
  },
  certificates: {
    templates: "certificate-templates",
    templatePreview: "certificate-template-preview",
  },
} as const;

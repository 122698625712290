import { ENDPOINTS } from "./endpoints";
import HttpClient from "./HttpClient";
import {
  MyCoursesRes,
  CourseRes,
  UnitsRes,
  MyUnitRes,
  FileRes,
  QuestionRes,
  QuestionSubmitRes,
  UnitResultsRes,
  ContentTokensRes,
  IltSessionsRes,
  ConferenceURLRes,
  UnitSubmitWithQuestionRes,
  CourseFilesRes,
  ScormCommitRes,
  CraftCommitRes,
  CoursesRes,
  CourseGroupsRes,
  CourseBranchesRes,
  CourseCustomFieldRes,
  QuestionsTagsRes,
  CertificatePreviewRes,
} from "types/responses";
import { QuestionAnswers } from "types/entities";
import { CraftData, UploadUnitType } from "types/entities/Unit";
import { getPublicBearerHeaders } from "@utils/helpers";

const formContentType = {
  "Content-Type": "multipart/form-data",
};

type AssignmentBodyData = Partial<{
  text: string;
  file_id: number;
}>;

export type EditCourseFileBodyData = Partial<{
  name: string;
  tags: string[];
  shared: boolean;
}>;

/***** Recommended order by request method: GET, POST, PUT, PATCH, DELETE *****/
/***** COURSE endpoints *****/

export const getMyCourses = async (queryStr = ""): Promise<MyCoursesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.myCourses}${queryStr}`);

  return res.data;
};

export const getCourse = async (courseId: string): Promise<CourseRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.course(courseId), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const getCourses = async (queryStr = ""): Promise<CoursesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.courses}${queryStr}`);

  return res.data;
};

export const cloneCourse = async (courseId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.clone(courseId));
};

export const putUnenrollFromCourse = async (courseId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unenroll(courseId));
};

export const deleteCourse = async (courseId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.delete(courseId));
};

/***** COURSE FILE endpoints *****/

export const getCourseFiles = async (courseId: string, queryStr = ""): Promise<CourseFilesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.files(courseId)}${queryStr}`, {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const postCourseFile = async ({
  courseId,
  file,
  uploadUnitType,
  shared = false,
}: {
  courseId: string;
  file: File;
  uploadUnitType?: UploadUnitType;
  shared?: boolean;
}): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const urlParams = uploadUnitType ? `?unit-type=${uploadUnitType}` : `?shared=${shared}`;

  const endpoint = `${ENDPOINTS.courses.filesUpload(courseId)}${urlParams}`;

  const res = await HttpClient.post(endpoint, bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postCourseFileURL = async ({
  courseId,
  fileURL,
}: {
  courseId: string;
  fileURL: string;
}): Promise<FileRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.filesUploadURL(courseId), { url: fileURL });

  return res.data;
};

export const postCourseUnit = async ({
  courseId,
  file,
}: {
  courseId: string;
  file: File;
}): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("file", file);

  const res = await HttpClient.post(ENDPOINTS.courses.unitUpload(courseId), bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const postCourseIntroVideo = async ({
  courseId,
  file,
}: {
  courseId: string;
  file: File;
}): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("intro_video", file);

  const res = await HttpClient.post(ENDPOINTS.courses.introVideoUpload(courseId), bodyFormData, {
    headers: formContentType,
  });

  return res.data;
};

export const deleteCourseIntroVideo = async ({
  courseId,
}: {
  courseId: string;
}): Promise<FileRes> => {
  const res = await HttpClient.delete(ENDPOINTS.courses.introVideoDelete(courseId));

  return res.data;
};

export const editCourseFile = async ({
  courseId,
  fileId,
  data,
}: {
  courseId: string;
  fileId: string;
  data: EditCourseFileBodyData;
}): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.filesEdit(courseId, fileId), data);
};

export const deleteCourseFile = async (courseId: string, fileId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.filesDelete(courseId, fileId));
};

/***** COURSE RATING endpoints *****/

export const postCourseRating = async (courseId: string, rating: number): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.rating(courseId), { rating });
};

export const deleteCourseRating = async (courseId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.rating(courseId));
};

/***** COURSE GROUP endpoints *****/

export const getGroups = async (courseId: string, queryStr = ""): Promise<CourseGroupsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.groups(courseId)}${queryStr}`);
  return res.data;
};

export const getAvailableGroups = async (
  courseId: string,
  queryStr = "",
): Promise<CourseGroupsRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.availableGroups(courseId)}${queryStr}`);

  return res.data;
};

/***** COURSE BRANCH endpoints *****/

export const getCourseBranches = async (
  courseId: string,
  queryStr = "",
): Promise<CourseBranchesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.branches(courseId)}${queryStr}`);

  return res.data;
};

export const getAvailableBranches = async (
  courseId: string,
  queryStr = "",
): Promise<CourseBranchesRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.courses.availableBranches(courseId)}${queryStr}`);

  return res.data;
};

// TODO: Move all unit fns to seperate file
/***** UNIT endpoints *****/

export const getUnit = async (unitId: string): Promise<MyUnitRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.unit(unitId), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const getUnitResults = async (unitId: string): Promise<UnitResultsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.unitResults(unitId), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const getMyCourseUnits = async (courseId: string): Promise<UnitsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.myCourseUnits(courseId), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const onUnitEnter = async (unitId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.unitEnter(unitId), undefined, {
    headers: getPublicBearerHeaders(),
  });
};

export const onUnitExit = async (unitId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.unitExit(unitId), undefined, {
    headers: getPublicBearerHeaders(),
  });
};

export const postUnitPassword = async (unitId: string, password: string): Promise<void> => {
  await HttpClient.post(
    ENDPOINTS.courses.unitPassword(unitId),
    { password },
    {
      headers: getPublicBearerHeaders(),
    },
  );
};

export const postUnitSnapshot = async (unitId: string, file: File): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("snapshot", file);

  const res = await HttpClient.post(ENDPOINTS.courses.unitSnapshot(unitId), bodyFormData, {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const postUnitStart = async (unitId: string): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.unitStart(unitId), undefined, {
    headers: getPublicBearerHeaders(),
  });
};

export const putCompleteUnit = async (unitId: string): Promise<void> => {
  // The request body (we don't have a request body, so it's set to undefined)
  await HttpClient.put(ENDPOINTS.courses.unitComplete(unitId), undefined, {
    headers: getPublicBearerHeaders(),
  });
};

export const putCompleteUnitWithQuestion = async (
  data: QuestionAnswers,
  unitId: string,
  question_id: number,
): Promise<UnitSubmitWithQuestionRes> => {
  const res = await HttpClient.put(
    ENDPOINTS.courses.unitCompleteWithQuestion(unitId),
    {
      answers: data,
      question_id: question_id,
    },
    {
      headers: getPublicBearerHeaders(),
    },
  );

  return res.data;
};

/***** ILT UNIT endpoints *****/

export const getIltSessions = async (unitId: string): Promise<IltSessionsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.unitIlt.sessions(unitId), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const putIltRegister = async (sessionId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitIlt.register(sessionId));
};

export const putIltUnregister = async (sessionId: string): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.unitIlt.unregister(sessionId));
};

/***** ASSIGNMENT UNIT endpoints *****/

export const postAssignmentUnit = async (
  unitId: string,
  data: AssignmentBodyData,
): Promise<void> => {
  await HttpClient.post(ENDPOINTS.courses.unitAssignment.submit(unitId), data, {
    headers: getPublicBearerHeaders(),
  });
};

export const postAssignmentUnitFile = async (unitId: string, file: File): Promise<FileRes> => {
  const bodyFormData = new FormData();
  bodyFormData.append("submission_file", file);

  const res = await HttpClient.post(
    ENDPOINTS.courses.unitAssignment.fileUpload(unitId),
    bodyFormData,
    {
      headers: getPublicBearerHeaders(),
    },
  );

  return res.data;
};

/***** TEST UNIT endpoints *****/

export const postResetTest = async (unitId: string): Promise<void> => {
  const res = await HttpClient.post(ENDPOINTS.courses.resetTest(unitId), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

/***** SCORM UNIT endpoints *****/

export const getContentTokens = async (): Promise<ContentTokensRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.contentTokens, {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postScormCommit = async (commitUrl: string, data: any): Promise<ScormCommitRes> => {
  const res = await HttpClient.post(commitUrl, data.sendValues, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });

  return res.data;
};

/***** CRAFT UNIT endpoints *****/

export const postCraftCommit = async (id: string, data: CraftData): Promise<CraftCommitRes> => {
  const res = await HttpClient.post(ENDPOINTS.courses.craftCommit(id), data, {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const putCraftDisclaimer = async (): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.craftDisclaimer);
};

/***** CONFERENCE UNIT endpoints *****/

export const getConferenceURL = async (id: string): Promise<ConferenceURLRes> => {
  const res = await HttpClient.get(ENDPOINTS.conferences.join(id));

  return res.data;
};

/***** UNIT QUESTION endpoints *****/

export const getQuestionById = async (unitId: string, index: string): Promise<QuestionRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.testQuestion(unitId, index), {
    headers: getPublicBearerHeaders(),
  });

  return res.data;
};

export const getQuestionTags = async (): Promise<QuestionsTagsRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.questionsTags);

  return res.data;
};

export const postTestQuestion = async (
  unitId: string,
  index: string,
  data: QuestionAnswers,
): Promise<QuestionSubmitRes> => {
  const res = await HttpClient.post(
    ENDPOINTS.courses.submitTestQuestion(unitId, index),
    {
      answers: data,
    },
    {
      headers: getPublicBearerHeaders(),
    },
  );

  return res.data;
};

/***** COURSE USER endpoints *****/

export const getCourseCustomFields = async (): Promise<CourseCustomFieldRes> => {
  const res = await HttpClient.get(ENDPOINTS.courses.customFields);

  return res.data;
};

export const synchronizeProgress = async (
  courseId: string,
  userId: string,
  removeCertificate: boolean | undefined,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.user.syncProgress(courseId, userId, removeCertificate));
};

export const resetProgress = async (
  courseId: string,
  userId: string,
  removeCertificate: boolean | undefined,
): Promise<void> => {
  await HttpClient.put(ENDPOINTS.courses.user.resetProgress(courseId, userId, removeCertificate));
};

export const previewCertificate = async (
  courseId: string,
  userId: string,
): Promise<CertificatePreviewRes> => {
  const res = await HttpClient.get(ENDPOINTS.certificates.preview(courseId, userId));
  return res.data;
};

export const editCourseUser = async (
  courseId: string,
  userId: string,
  course_role: string,
): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.user.edit(courseId, userId), {
    course_role,
  });
};

export const updateExpirationDate = async (
  courseId: string,
  userId: string,
  expiration_date: string,
): Promise<void> => {
  await HttpClient.patch(ENDPOINTS.courses.user.edit(courseId, userId), {
    expiration_date,
  });
};

export const unenrollUser = async (courseId: string, userId: string): Promise<void> => {
  await HttpClient.delete(ENDPOINTS.courses.user.edit(courseId, userId));
};

import { css } from "@emotion/react";

export const AnnouncementsContainer = ({ announcements }, showMore, type) => css`
  background-color: ${announcements[type].background};
  padding: 1rem 2rem;
  word-break: break-word;

  .icon {
    display: none;
  }

  .announcements-container {
    overflow: hidden;
    text-overflow: ellipsis;
    display: ${showMore || type === "admin" ? "block" : "-webkit-box"};
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    .announcement {
      margin-bottom: 1rem;
      color: ${announcements.color};
      padding-bottom: ${type === "admin" ? `1rem` : "0"};
      border-bottom: ${type === "admin" ? `1px solid ${announcements.borderColor}` : "none"};

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }

  .buttons-container {
    padding-top: 1rem;
    display: flex;
    justify-content: ${type === "external" ? "center" : "start"};

    button {
      margin-inline-end: 1rem;

      &:last-of-type {
        margin-inline-end: 0;
      }
    }
  }

  .close-button {
    position: absolute;
    top: 0.5rem;
    inset-inline-end: 0;
  }
`;

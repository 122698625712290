import React, { FC, ReactNode } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Tooltip } from "@epignosis_llc/gnosis";
import classNames from "classnames";
import { defaultFooterContainer } from "../styles";
import {
  getFlatUnits,
  getPrevAndNextUnits,
  getUnitNotAvailableTooltipContent,
  unitHasDelayedAvailability,
} from "@utils/helpers";
import { URLS } from "@constants/urls";
import { Course, MyUnit, ScormUnit, Section } from "types/entities";
import { useRtlArrowIcons } from "@hooks";

type UnitFooterNavigationButtonsProps = {
  course: Course;
  sections: Section[];
  unit: MyUnit | ScormUnit;
  children: ReactNode;
  isPublic?: boolean;
};

const getBtnClassNames = (isHidden: boolean): string =>
  classNames({
    "btn-nav": true,
    hidden: isHidden,
  });

const goToPreviousUnitLink = (
  prevUnit: MyUnit | null,
  courseId: string,
  isPublicCourse?: boolean,
): string => {
  if (!prevUnit) return "#";

  const isAvailable = !unitHasDelayedAvailability(prevUnit?.availability ?? null);
  if (!isAvailable) return "#";

  const unitLink = isPublicCourse
    ? URLS.externalCatalog.publicUnit.createPublicUnitLink({
        courseId,
        unitId: prevUnit?.id.toString(),
      })
    : URLS.user.createUnitLink({ courseId, unitId: prevUnit?.id.toString() });

  return unitLink;
};

const isPrevButtonDisabled = (prevUnit: MyUnit | null): boolean =>
  unitHasDelayedAvailability(prevUnit?.availability ?? null);

const UnitFooterNavigationButtons: FC<UnitFooterNavigationButtonsProps> = ({
  sections,
  unit,
  children,
  isPublic = false,
}) => {
  const { courseId } = useParams() as { courseId: string };
  const flatUnits = getFlatUnits(sections);
  const { prevUnit } = (unit && getPrevAndNextUnits(flatUnits, unit.id)) || {};
  const isPrevUnitAvailable = !unitHasDelayedAvailability(prevUnit?.availability ?? null);
  const showPrevButton = Boolean(prevUnit) || isPrevButtonDisabled(prevUnit);
  const Icon = useRtlArrowIcons();

  return (
    <div css={defaultFooterContainer}>
      {showPrevButton && (
        <Tooltip
          disabled={isPrevUnitAvailable}
          content={getUnitNotAvailableTooltipContent(prevUnit?.availability ?? null)}
        >
          <Button
            as={Link}
            to={goToPreviousUnitLink(prevUnit, courseId, isPublic)}
            color="secondary"
            noGutters
            className={getBtnClassNames(!prevUnit)}
            disabled={isPrevButtonDisabled(prevUnit)}
            aria-label="Go to previous unit"
          >
            {<Icon height={24} />}
          </Button>
        </Tooltip>
      )}
      <div className="btn-complete-wrapper">{children}</div>
    </div>
  );
};

export default UnitFooterNavigationButtons;

import React, { FC } from "react";
import { Tabs } from "@epignosis_llc/gnosis";
import { t } from "i18next";
import PaymentInfo from "./PaymentInfo";
import { Course } from "types/entities";
import { Bundle, CatalogSettings, Processors } from "types/entities/Catalog";

export type PaymentTabsProps = {
  data: Course | Bundle;
  processors: Processors[];
  setActiveTab: (activeTab: number) => void;
  categoryText?: string;
};

const getTabTitle = (processor: string): string => {
  switch (processor) {
    case "credits":
      return t("general.creditsUpper");
    case "stripe":
      return t("general.creditCard");
    case "paypal":
      return "Paypal";
    default:
      return "";
  }
};

const getPaymentComponent = (
  data: Course | Bundle,
  processor: string,
  categoryText: string | undefined,
): JSX.Element => {
  switch (processor) {
    case "credits":
    case "stripe":
    case "paypal":
      return <PaymentInfo data={data} processor={processor} categoryText={categoryText} />;
    default:
      return <div className="payments-info-text">{t("general.paymentsInfo")}</div>;
  }
};

const getPaymentMethods = (
  processors: CatalogSettings["processors"],
  course: Course | Bundle,
  setActiveTab: (activeTab: number) => void,
  categoryText: string | undefined,
): JSX.Element => {
  //if we only have one payment method then dont render the tabs
  if (processors?.length === 1) return getPaymentComponent(course, processors[0], categoryText);

  return processors?.length ? (
    <Tabs
      onChangeTab={setActiveTab}
      className="payment-tabs"
      tabs={processors.sort().map((processor) => ({
        title: getTabTitle(processor),
        content: <div>{getPaymentComponent(course, processor, categoryText)}</div>,
      }))}
    />
  ) : (
    <></>
  );
};

const PaymentTabs: FC<PaymentTabsProps> = (props) => {
  const { processors, data, setActiveTab, categoryText } = props;

  return getPaymentMethods(processors, data, setActiveTab, categoryText);
};

export default PaymentTabs;

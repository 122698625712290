import create from "zustand";

type UnitState = {
  isMenuOpen: boolean;
  toggleIsMenuOpen: (show?: boolean) => void;
  canAutoSaveUnit: boolean;
  setCanAutoSaveUnit: (canSave: boolean) => void;
};

const useUnitStore = create<UnitState>((set, get) => ({
  isMenuOpen: false,
  toggleIsMenuOpen: (show): void =>
    set((state) => ({
      ...state,
      isMenuOpen: show !== undefined ? show : !get().isMenuOpen,
    })),
  canAutoSaveUnit: true,
  setCanAutoSaveUnit: (canSave: boolean): void =>
    set((state) => ({ ...state, canAutoSaveUnit: canSave })),
}));

export default useUnitStore;

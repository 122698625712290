import { palletColorsGenerator, getPlusTheme, getGnosisTheme } from "../utils";
import { COLORS } from "../constants";

export const THEME_COLOURS = {
  ...COLORS,
  primary: "#2CD1F8",
};

const colors = palletColorsGenerator(THEME_COLOURS);
const defaultTurquoise = { ...getGnosisTheme(colors), ...getPlusTheme(colors) };

export default defaultTurquoise;

import Color from "color";
import { PalletThemeColors } from "@styles/utils";

type ThemeObjectReturn = {
  color: string;
  background: string;
  boxShadowColor: string;
  hoverBackground: string;
  dropdownBackground: string;
  emptyState: string;
  textColor: string;
  iconColor: string;
  white: string;
  scrollBar: string;
};

export default (colors: PalletThemeColors): ThemeObjectReturn => ({
  color: colors.primary.base,
  background: colors.primary.lightest,
  boxShadowColor: colors.secondary.light,
  hoverBackground: Color(colors.primary.lightest).alpha(0.25).string(),
  dropdownBackground: colors.secondary.lighter,
  emptyState: colors.white,
  textColor: colors.black,
  iconColor: colors.black,
  white: colors.white,
  scrollBar: colors.secondary.light,
});

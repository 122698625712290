import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const surveyContainer = css`
  text-align: center;
  margin-top: 2rem;

  .icon {
    margin-bottom: 2rem;
  }

  .description {
    max-width: 80%;
    margin: 0.5rem auto auto;
  }
`;

export const completedSplashScreenContainer = ({ units }) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;

  .image-banner {
    margin-bottom: 2rem;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: ${units.completedScreen.background};
    border-radius: 15px;
    padding: 0.5rem;
    margin-top: 2rem;
    width: 118px;

    svg {
      margin-bottom: 0.25rem;
    }

    a {
      font-weight: 700;
    }
  }
`;

export const likertScaleContainer = (dir) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  padding-inline-start: 1.5rem;

  ${mq["md"]} {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
  }

  .likert-option {
    list-style-type: none;
    background-color: white;
    padding: 0 0.75em;
    word-break: break-word;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    ${mq["md"]} {
      position: relative;
      flex: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      padding: 2px 5px 2px 5px;
    }

    &:not(:last-child) {
      margin-inline-end: 25px;

      &:after {
        content: "";
        position: absolute;
        border-inline-start: 2px solid #cccccc;
        z-index: 1;
        height: calc(100% - 28px);
        left: ${dir === "rtl" ? "auto" : "8px"};
        right: ${dir === "rtl" ? "8px" : "auto"};
        top: 36px;

        ${mq["md"]} {
          top: 30px;
          left: ${dir === "rtl" ? "auto" : "calc(60% + 20px)"};
          right: ${dir === "rtl" ? "calc(60% + 20px)" : "auto"};
          border-bottom: 2px solid #cccccc;
          width: calc(80% - 20px);
          height: 0;
          border-inline-start: none;
        }
      }
    }
  }

  .option-label {
    margin-top: 1rem;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    ${mq["md"]} {
      padding-inline-start: 0;
    }
  }
`;

import { css } from "@emotion/react";
import { hexToRGBA } from "@styles/utils";

export const documentReview = (theme, zoomApplied, documentLoaded) => css`
  position: relative;
  height: 100%;
  display: flex;

  &:hover {
    .toolbar-container {
      opacity: 1;
      transition: opacity 0.3s linear;
    }
  }

  .viewer-container {
    flex: 1;
    overflow: hidden;
    width: 100%;
    opacity: ${zoomApplied || documentLoaded ? 1 : 0};
  }

  .exit-fullscreen {
    bottom: 1rem;
    position: fixed;
    right: 1rem;
    z-index: 1;
    .action-btn {
      background-color: ${theme.pdfPreview.toolbar.background};
      svg {
        color: ${hexToRGBA(theme.pdfPreview.toolbar.color, 0.7)};
      }
      &:hover {
        background-color: ${theme.pdfPreview.toolbar.background};
        svg {
          color: ${theme.pdfPreview.toolbar.color};
        }
      }
    }
  }
`;
